/* --
 --- Feature：整体高压系统
 --- Author: arya
 *  // fixme 取消注释
--*/
import React from 'react';
import Konva from 'konva';
import Line from './base/Line';
import Switch from './base/Switch';
import Transformer from './base/Transformer';
import BusTieSwitch from './base/BusTieSwitch';
import ATS from './base/ATS';
import MunicipalElectricity from './base/MunicipalElectricity';
import {
  CenterX,
  BusTieSwitchHeight,
  TransformerHeight,
  CityElecHeight,
  SpaceHeight,
  CenterLineLen,
  SecondColumnY1,
  lineConfig,
  CenterLineY,
  FirstRowY,
  FirstColumnY2,
  FirstRowLineLen,
  SwitchHeight,
  H1,
  H2,
  cancelTripTime,
} from './Constants';
// import { AUTO_ATS, SEMI_AUTO_ATS, MEN_ATS } from './util';
const switchImg1 = require('@/assets/desktop_exercise/switchPosition1.png');
const switchImg2 = require('@/assets/desktop_exercise/switchPosition2.png');
const switchImg3 = require('@/assets/desktop_exercise/switchPosition3.png');

class MainDistributionSystem extends React.Component {
  constructor(props) {
    super(props);
    const { parentLayer, flowLayer, setSimulateLogs } = props;
    this.diesel = 0;
    const init_state = 1;
    this.parentLayer = parentLayer;
    this.flowLayer = flowLayer;
    this.faultSimulation = false;

    this.Line10 = new Line({
      lineConfig,
      points: [CenterX - CenterLineLen / 2, CenterLineY, CenterX + CenterLineLen / 2, CenterLineY],
      direction: true,
      serialNum: 10,
      state: 1,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line9 = new Line({
      lineConfig,
      points: [
        this.Line10.getCoordinatesByPoints().x1 - FirstRowLineLen,
        FirstRowY,
        this.Line10.getCoordinatesByPoints().x1,
        FirstRowY,
      ],
      direction: true,
      serialNum: 9,
      state: 1,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line8 = new Line({
      lineConfig,
      points: [
        this.Line9.getCoordinatesByPoints().x1 - FirstRowLineLen,
        FirstRowY,
        this.Line9.getCoordinatesByPoints().x1,
        FirstRowY,
      ],
      direction: true,
      serialNum: 8,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line7 = new Line({
      lineConfig,
      points: [
        this.Line8.getCoordinatesByPoints().x1 - FirstRowLineLen,
        FirstRowY,
        this.Line8.getCoordinatesByPoints().x1,
        FirstRowY,
      ],
      direction: true,
      serialNum: 7,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line6 = new Line({
      lineConfig,
      points: [
        this.Line7.getCoordinatesByPoints().x1 - FirstRowLineLen,
        FirstRowY,
        this.Line7.getCoordinatesByPoints().x1,
        FirstRowY,
      ],
      direction: true,
      serialNum: 6,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line5 = new Line({
      lineConfig,
      points: [
        this.Line6.getCoordinatesByPoints().x1 - FirstRowLineLen,
        FirstRowY,
        this.Line6.getCoordinatesByPoints().x1,
        FirstRowY,
      ],
      direction: true,
      serialNum: 5,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line4 = new Line({
      lineConfig,
      points: [
        this.Line5.getCoordinatesByPoints().x1 - FirstRowLineLen,
        FirstRowY,
        this.Line5.getCoordinatesByPoints().x1,
        FirstRowY,
      ],
      direction: true,
      serialNum: 4,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line3 = new Line({
      lineConfig,
      points: [
        this.Line4.getCoordinatesByPoints().x1 - FirstRowLineLen,
        FirstRowY,
        this.Line4.getCoordinatesByPoints().x1,
        FirstRowY,
      ],
      direction: true,
      serialNum: 3,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line2 = new Line({
      lineConfig,
      points: [
        this.Line3.getCoordinatesByPoints().x1,
        FirstRowY - H2,
        this.Line3.getCoordinatesByPoints().x1,
        FirstRowY,
      ],
      direction: true,
      serialNum: 2,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line1 = new Line({
      lineConfig,
      points: [
        this.Line3.getCoordinatesByPoints().x1,
        this.Line2.getCoordinatesByPoints().y1 - SwitchHeight - H1,
        this.Line3.getCoordinatesByPoints().x1,
        this.Line2.getCoordinatesByPoints().y1 - SwitchHeight,
      ],
      direction: true,
      serialNum: 1,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line11 = new Line({
      lineConfig,
      points: [
        this.Line10.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line10.getCoordinatesByPoints().x2 + FirstRowLineLen,
        FirstRowY,
      ],
      direction: false,
      serialNum: 11,
      state: 1,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line12 = new Line({
      lineConfig,
      points: [
        this.Line11.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line11.getCoordinatesByPoints().x2 + FirstRowLineLen,
        FirstRowY,
      ],
      direction: false,
      serialNum: 12,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line13 = new Line({
      lineConfig,
      points: [
        this.Line12.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line12.getCoordinatesByPoints().x2 + FirstRowLineLen,
        FirstRowY,
      ],
      direction: false,
      serialNum: 13,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line14 = new Line({
      lineConfig,
      points: [
        this.Line13.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line13.getCoordinatesByPoints().x2 + FirstRowLineLen,
        FirstRowY,
      ],
      direction: false,
      serialNum: 14,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line15 = new Line({
      lineConfig,
      points: [
        this.Line14.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line14.getCoordinatesByPoints().x2 + FirstRowLineLen,
        FirstRowY,
      ],
      direction: false,
      serialNum: 15,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line16 = new Line({
      lineConfig,
      points: [
        this.Line15.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line15.getCoordinatesByPoints().x2 + FirstRowLineLen,
        FirstRowY,
      ],
      direction: false,
      serialNum: 16,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line17 = new Line({
      lineConfig,
      points: [
        this.Line16.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line16.getCoordinatesByPoints().x2 + FirstRowLineLen,
        FirstRowY,
      ],
      direction: false,
      serialNum: 17,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line18 = new Line({
      lineConfig,
      points: [
        this.Line17.getCoordinatesByPoints().x2,
        FirstRowY - H2,
        this.Line17.getCoordinatesByPoints().x2,
        FirstRowY,
      ],
      direction: true,
      serialNum: 18,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line19 = new Line({
      lineConfig,
      points: [
        this.Line17.getCoordinatesByPoints().x2,
        FirstRowY - H1 - H2 - SwitchHeight,
        this.Line17.getCoordinatesByPoints().x2,
        this.Line18.getCoordinatesByPoints().y1 - SwitchHeight,
      ],
      direction: true,
      serialNum: 19,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();

    this.Line20 = new Line({
      lineConfig,
      points: [
        this.Line3.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line3.getCoordinatesByPoints().x2,
        FirstColumnY2,
      ],
      direction: true,
      serialNum: 20,
      state: 1,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line21 = new Line({
      lineConfig,
      points: [
        this.Line4.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line4.getCoordinatesByPoints().x2,
        FirstColumnY2,
      ],
      direction: true,
      serialNum: 21,
      state: 1,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line22 = new Line({
      lineConfig,
      points: [
        this.Line5.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line5.getCoordinatesByPoints().x2,
        FirstColumnY2,
      ],
      direction: true,
      serialNum: 22,
      state: 1,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line23 = new Line({
      lineConfig,
      points: [
        this.Line6.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line6.getCoordinatesByPoints().x2,
        FirstColumnY2,
      ],
      direction: true,
      serialNum: 23,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line24 = new Line({
      lineConfig,
      points: [
        this.Line7.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line7.getCoordinatesByPoints().x2,
        FirstColumnY2,
      ],
      direction: true,
      serialNum: 24,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line25 = new Line({
      lineConfig,
      points: [
        this.Line8.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line8.getCoordinatesByPoints().x2,
        FirstColumnY2,
      ],
      direction: true,
      serialNum: 25,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line26 = new Line({
      lineConfig,
      points: [
        this.Line9.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line9.getCoordinatesByPoints().x2,
        FirstColumnY2,
      ],
      direction: true,
      serialNum: 26,
      state: 1,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line27 = new Line({
      lineConfig,
      points: [
        this.Line10.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line10.getCoordinatesByPoints().x2,
        FirstColumnY2 + SwitchHeight + H2,
      ],
      direction: true,
      serialNum: 27,
      state: 1,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line28 = new Line({
      lineConfig,
      points: [
        this.Line11.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line11.getCoordinatesByPoints().x2,
        FirstColumnY2,
      ],
      direction: true,
      serialNum: 28,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line29 = new Line({
      lineConfig,
      points: [
        this.Line12.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line12.getCoordinatesByPoints().x2,
        FirstColumnY2,
      ],
      direction: true,
      serialNum: 29,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line30 = new Line({
      lineConfig,
      points: [
        this.Line13.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line13.getCoordinatesByPoints().x2,
        FirstColumnY2,
      ],
      direction: true,
      serialNum: 30,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line31 = new Line({
      lineConfig,
      points: [
        this.Line14.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line14.getCoordinatesByPoints().x2,
        FirstColumnY2,
      ],
      direction: true,
      serialNum: 31,
      state: 1,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line32 = new Line({
      lineConfig,
      points: [
        this.Line15.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line15.getCoordinatesByPoints().x2,
        FirstColumnY2,
      ],
      direction: true,
      serialNum: 32,
      state: 1,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line33 = new Line({
      lineConfig,
      points: [
        this.Line16.getCoordinatesByPoints().x2,
        FirstRowY,
        this.Line16.getCoordinatesByPoints().x2,
        FirstColumnY2,
      ],
      direction: true,
      serialNum: 33,
      state: 1,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line34 = new Line({
      lineConfig,
      points: [
        this.Line26.getCoordinatesByPoints().x1,
        this.Line26.getCoordinatesByPoints().y2 + SwitchHeight,
        this.Line26.getCoordinatesByPoints().x1,
        this.Line26.getCoordinatesByPoints().y2 + SwitchHeight + H2,
      ],
      direction: true,
      serialNum: 34,
      state: 1,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line37 = new Line({
      lineConfig,
      points: [
        this.Line25.getCoordinatesByPoints().x1,
        SecondColumnY1,
        this.Line25.getCoordinatesByPoints().x1,
        H1 + SecondColumnY1,
      ],
      direction: true,
      serialNum: 37,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line36 = new Line({
      lineConfig,
      points: [
        this.Line24.getCoordinatesByPoints().x1,
        SecondColumnY1,
        this.Line24.getCoordinatesByPoints().x1,
        this.Line37.getCoordinatesByPoints().y2 +
          BusTieSwitchHeight +
          SpaceHeight +
          TransformerHeight +
          H2,
      ],
      direction: true,
      serialNum: 36,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line35 = new Line({
      lineConfig,
      points: [
        this.Line23.getCoordinatesByPoints().x1,
        SecondColumnY1,
        this.Line23.getCoordinatesByPoints().x1,
        this.Line36.getCoordinatesByPoints().y2 +
          BusTieSwitchHeight +
          SpaceHeight +
          TransformerHeight +
          H2,
      ],
      direction: true,
      serialNum: 35,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line38 = new Line({
      lineConfig,
      points: [
        this.Line28.getCoordinatesByPoints().x1,
        SecondColumnY1,
        this.Line28.getCoordinatesByPoints().x1,
        this.Line37.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 38,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line39 = new Line({
      lineConfig,
      points: [
        this.Line29.getCoordinatesByPoints().x1,
        SecondColumnY1,
        this.Line29.getCoordinatesByPoints().x1,
        this.Line36.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 39,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line40 = new Line({
      lineConfig,
      points: [
        this.Line30.getCoordinatesByPoints().x1,
        SecondColumnY1,
        this.Line30.getCoordinatesByPoints().x1,
        this.Line35.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 40,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line41 = new Line({
      lineConfig,
      points: [
        this.Line37.getCoordinatesByPoints().x2,
        this.Line37.getCoordinatesByPoints().y2,
        this.Line34.getCoordinatesByPoints().x2,
        this.Line37.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 41,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line42 = new Line({
      lineConfig,
      points: [
        this.Line27.getCoordinatesByPoints().x1,
        this.Line41.getCoordinatesByPoints().y1,
        this.Line38.getCoordinatesByPoints().x1,
        this.Line38.getCoordinatesByPoints().y2,
      ],
      direction: false,
      serialNum: 42,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line43 = new Line({
      lineConfig,
      points: [
        this.Line41.getCoordinatesByPoints().x2,
        this.Line41.getCoordinatesByPoints().y2 + BusTieSwitchHeight + TransformerHeight,
        this.Line41.getCoordinatesByPoints().x2,
        this.Line41.getCoordinatesByPoints().y2 + BusTieSwitchHeight + TransformerHeight + H2,
      ],
      direction: true,
      serialNum: 43,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line44 = new Line({
      lineConfig,
      points: [
        this.Line42.getCoordinatesByPoints().x1,
        this.Line43.getCoordinatesByPoints().y1,
        this.Line42.getCoordinatesByPoints().x1,
        this.Line43.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 44,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line46 = new Line({
      lineConfig,
      points: [
        this.Line43.getCoordinatesByPoints().x2,
        this.Line43.getCoordinatesByPoints().y2,
        this.Line43.getCoordinatesByPoints().x2 + (CenterLineLen / 2 - BusTieSwitchHeight / 2),
        this.Line43.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 46,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line47 = new Line({
      lineConfig,
      points: [
        this.Line46.getCoordinatesByPoints().x2 + BusTieSwitchHeight,
        this.Line46.getCoordinatesByPoints().y2,
        this.Line44.getCoordinatesByPoints().x2,
        this.Line44.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 47,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line45 = new Line({
      lineConfig,
      points: [
        this.Line46.getCoordinatesByPoints().x1 - H1,
        this.Line46.getCoordinatesByPoints().y1,
        this.Line46.getCoordinatesByPoints().x1,
        this.Line46.getCoordinatesByPoints().y1,
      ],
      direction: false,
      serialNum: 45,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line48 = new Line({
      lineConfig,
      points: [
        this.Line47.getCoordinatesByPoints().x2,
        this.Line47.getCoordinatesByPoints().y1,
        this.Line47.getCoordinatesByPoints().x2 + H1,
        this.Line47.getCoordinatesByPoints().y1,
      ],
      direction: true,
      serialNum: 48,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line49 = new Line({
      lineConfig,
      points: [
        this.Line36.getCoordinatesByPoints().x1,
        this.Line36.getCoordinatesByPoints().y2,
        this.Line41.getCoordinatesByPoints().x2,
        this.Line36.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 49,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line50 = new Line({
      lineConfig,
      points: [
        this.Line42.getCoordinatesByPoints().x1,
        this.Line39.getCoordinatesByPoints().y2,
        this.Line39.getCoordinatesByPoints().x1,
        this.Line39.getCoordinatesByPoints().y2,
      ],
      direction: false,
      serialNum: 50,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line51 = new Line({
      lineConfig,
      points: [
        this.Line35.getCoordinatesByPoints().x1,
        this.Line35.getCoordinatesByPoints().y2,
        this.Line41.getCoordinatesByPoints().x2,
        this.Line35.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 51,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line52 = new Line({
      lineConfig,
      points: [
        this.Line42.getCoordinatesByPoints().x1,
        this.Line40.getCoordinatesByPoints().y2,
        this.Line40.getCoordinatesByPoints().x2,
        this.Line40.getCoordinatesByPoints().y2,
      ],
      direction: false,
      serialNum: 52,
      state: init_state,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line53 = new Line({
      lineConfig,
      points: [
        this.Line41.getCoordinatesByPoints().x2,
        this.Line49.getCoordinatesByPoints().y2 + BusTieSwitchHeight + TransformerHeight,
        this.Line41.getCoordinatesByPoints().x2,
        this.Line49.getCoordinatesByPoints().y2 + BusTieSwitchHeight + TransformerHeight + H2,
      ],
      direction: true,
      serialNum: 53,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line54 = new Line({
      lineConfig,
      points: [
        this.Line42.getCoordinatesByPoints().x1,
        this.Line53.getCoordinatesByPoints().y1,
        this.Line42.getCoordinatesByPoints().x1,
        this.Line53.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 54,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line55 = new Line({
      lineConfig,
      points: [
        this.Line45.getCoordinatesByPoints().x1,
        this.Line53.getCoordinatesByPoints().y2,
        this.Line53.getCoordinatesByPoints().x1,
        this.Line53.getCoordinatesByPoints().y2,
      ],
      direction: false,
      serialNum: 55,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line56 = new Line({
      lineConfig,
      points: [
        this.Line46.getCoordinatesByPoints().x1,
        this.Line53.getCoordinatesByPoints().y2,
        this.Line46.getCoordinatesByPoints().x2,
        this.Line53.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 56,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line57 = new Line({
      lineConfig,
      points: [
        this.Line47.getCoordinatesByPoints().x1,
        this.Line53.getCoordinatesByPoints().y2,
        this.Line47.getCoordinatesByPoints().x2,
        this.Line53.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 57,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line58 = new Line({
      lineConfig,
      points: [
        this.Line48.getCoordinatesByPoints().x1,
        this.Line53.getCoordinatesByPoints().y2,
        this.Line48.getCoordinatesByPoints().x2,
        this.Line53.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 58,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line59 = new Line({
      lineConfig,
      points: [
        this.Line41.getCoordinatesByPoints().x2,
        this.Line51.getCoordinatesByPoints().y2 + BusTieSwitchHeight + TransformerHeight,
        this.Line41.getCoordinatesByPoints().x2,
        this.Line51.getCoordinatesByPoints().y2 + BusTieSwitchHeight + TransformerHeight + H2,
      ],
      direction: true,
      serialNum: 59,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line60 = new Line({
      lineConfig,
      points: [
        this.Line42.getCoordinatesByPoints().x1,
        this.Line59.getCoordinatesByPoints().y1,
        this.Line42.getCoordinatesByPoints().x1,
        this.Line59.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 60,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line61 = new Line({
      lineConfig,
      points: [
        this.Line45.getCoordinatesByPoints().x1,
        this.Line59.getCoordinatesByPoints().y2,
        this.Line53.getCoordinatesByPoints().x1,
        this.Line59.getCoordinatesByPoints().y2,
      ],
      direction: false,
      serialNum: 61,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line62 = new Line({
      lineConfig,
      points: [
        this.Line46.getCoordinatesByPoints().x1,
        this.Line59.getCoordinatesByPoints().y2,
        this.Line46.getCoordinatesByPoints().x2,
        this.Line59.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 62,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line63 = new Line({
      lineConfig,
      points: [
        this.Line47.getCoordinatesByPoints().x1,
        this.Line59.getCoordinatesByPoints().y2,
        this.Line47.getCoordinatesByPoints().x2,
        this.Line59.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 63,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line64 = new Line({
      lineConfig,
      points: [
        this.Line48.getCoordinatesByPoints().x1,
        this.Line59.getCoordinatesByPoints().y2,
        this.Line48.getCoordinatesByPoints().x2,
        this.Line59.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 64,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();

    this.Line67 = new Line({
      lineConfig,
      points: [
        this.Line8.getCoordinatesByPoints().x1,
        FirstRowY - H2,
        this.Line8.getCoordinatesByPoints().x1,
        FirstRowY,
      ],
      direction: true,
      serialNum: 67,
      state: 1,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line68 = new Line({
      lineConfig,
      points: [
        this.Line13.getCoordinatesByPoints().x1,
        this.Line67.getCoordinatesByPoints().y1,
        this.Line13.getCoordinatesByPoints().x1,
        this.Line67.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 68,
      state: 1,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line65 = new Line({
      lineConfig,
      points: [
        this.Line8.getCoordinatesByPoints().x1,
        this.Line67.getCoordinatesByPoints().y1 - SwitchHeight - H1 - CityElecHeight,
        this.Line8.getCoordinatesByPoints().x1,
        this.Line67.getCoordinatesByPoints().y1 - SwitchHeight,
      ],
      direction: true,
      serialNum: 65,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line66 = new Line({
      lineConfig,
      points: [
        this.Line13.getCoordinatesByPoints().x1,
        this.Line65.getCoordinatesByPoints().y1,
        this.Line13.getCoordinatesByPoints().x1,
        this.Line65.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 66,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();

    // baseline,uid,angle,closed,load,state,name
    this.switch201 = new Switch({
      baseline: this.Line1,
      angle: 0,
      closed: true,
      load: true,
      state: 2,
      left: true,
      name: '201',
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.switch202 = new Switch({
      baseline: this.Line19,
      angle: 0,
      closed: true,
      load: true,
      state: 2,
      left: false,
      name: '202',
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.switch214 = new Switch({
      baseline: this.Line23,
      angle: 0,
      closed: true,
      load: true,
      state: 1,
      left: true,
      name: '214',
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.switch213 = new Switch({
      baseline: this.Line24,
      angle: 0,
      closed: true,
      load: true,
      state: 1,
      left: true,
      name: '213',
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.switch212 = new Switch({
      baseline: this.Line25,
      angle: 0,
      closed: true,
      load: true,
      state: 2,
      left: true,
      name: '212',
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.switch245 = new Switch({
      baseline: this.Line26,
      angle: 0,
      closed: false,
      load: true,
      state: 0,
      left: true,
      name: '245',
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.switch226 = new Switch({
      baseline: this.Line28,
      angle: 0,
      closed: true,
      load: true,
      state: 2,
      left: false,
      name: '226',
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.switch227 = new Switch({
      baseline: this.Line29,
      angle: 0,
      closed: true,
      load: true,
      state: 1,
      left: false,
      name: '227',
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.switch228 = new Switch({
      baseline: this.Line30,
      angle: 0,
      closed: true,
      load: true,
      state: 1,
      left: false,
      name: '228',
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.switch210 = new Switch({
      baseline: this.Line65,
      angle: 0,
      closed: false,
      load: false,
      state: 0,
      left: true,
      name: '210',
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.switch220 = new Switch({
      baseline: this.Line66,
      angle: 0,
      closed: false,
      load: false,
      state: 0,
      left: false,
      name: '220',
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();

    lineConfig.strokeWidth = 2;
    this.transformer1 = new Transformer({
      width: (1 / 4) * CenterLineLen,
      height: TransformerHeight,
      startX: this.Line41.getCoordinatesByPoints().x2,
      startY: this.Line41.getCoordinatesByPoints().y2,
      lineConfig,
      canvas: this.parentLayer,
      state: 1,
      left: true,
      name: 'T3',
    }).addName2Canvas();
    this.transformer2 = new Transformer({
      width: (1 / 4) * CenterLineLen,
      height: TransformerHeight,
      startX: this.Line42.getCoordinatesByPoints().x1,
      startY: this.Line42.getCoordinatesByPoints().y2,
      lineConfig,
      canvas: this.parentLayer,
      state: 1,
      left: false,
      name: 'T6',
    }).addName2Canvas();
    this.transformer3 = new Transformer({
      width: (1 / 4) * CenterLineLen,
      height: TransformerHeight,
      startX: this.Line49.getCoordinatesByPoints().x2,
      startY: this.Line49.getCoordinatesByPoints().y2,
      lineConfig,
      canvas: this.parentLayer,
      state: 1,
      left: true,
      name: 'T5',
    }).addName2Canvas();
    this.transformer4 = new Transformer({
      width: (1 / 4) * CenterLineLen,
      height: TransformerHeight,
      startX: this.Line50.getCoordinatesByPoints().x1,
      startY: this.Line50.getCoordinatesByPoints().y2,
      lineConfig,
      canvas: this.parentLayer,
      state: 1,
      left: false,
      name: 'T4',
    }).addName2Canvas();
    this.transformer5 = new Transformer({
      width: (1 / 4) * CenterLineLen,
      height: TransformerHeight,
      startX: this.Line51.getCoordinatesByPoints().x2,
      startY: this.Line51.getCoordinatesByPoints().y2,
      lineConfig,
      canvas: this.parentLayer,
      state: 1,
      left: true,
      name: 'T7',
    }).addName2Canvas();
    this.transformer6 = new Transformer({
      width: (1 / 4) * CenterLineLen,
      height: TransformerHeight,
      startX: this.Line52.getCoordinatesByPoints().x1,
      startY: this.Line52.getCoordinatesByPoints().y2,
      lineConfig,
      canvas: this.parentLayer,
      state: 1,
      left: false,
      name: 'T8',
    }).addName2Canvas();

    this.elec1 = new MunicipalElectricity({
      x: this.Line1.getCoordinatesByPoints().x1 - CenterLineLen / 2,
      y: this.Line1.getCoordinatesByPoints().y1 - CityElecHeight,
      canvas: this.parentLayer,
      serial: 1,
      setSimulateLogs,
    });
    this.elec2 = new MunicipalElectricity({
      x: this.Line19.getCoordinatesByPoints().x1 - CenterLineLen / 2,
      y: this.elec1.rect.y(),
      canvas: this.parentLayer,
      serial: 2,
      setSimulateLogs,
    });
    lineConfig.stroke = 'green';
    // x1,y1,x2,y2,config,state,closed,row,name,load,left
    this.BusTieSwitchT3 = new BusTieSwitch({
      x1: this.Line43.getCoordinatesByPoints().x1,
      y1: this.Line43.getCoordinatesByPoints().y1 - BusTieSwitchHeight,
      x2: this.Line43.getCoordinatesByPoints().x1,
      y2: this.Line43.getCoordinatesByPoints().y1,
      config: lineConfig,
      state: 0,
      closed: false,
      row: false,
      name: 'T3',
      load: false,
      left: true,
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.BusTieSwitchT6 = new BusTieSwitch({
      x1: this.Line44.getCoordinatesByPoints().x1,
      y1: this.Line44.getCoordinatesByPoints().y1 - BusTieSwitchHeight,
      x2: this.Line44.getCoordinatesByPoints().x1,
      y2: this.Line44.getCoordinatesByPoints().y1,
      config: lineConfig,
      state: 0,
      closed: false,
      row: false,
      name: 'T6',
      load: false,
      left: false,
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.BusTieSwitchT36 = new BusTieSwitch({
      x1: this.Line46.getCoordinatesByPoints().x2,
      y1: this.Line46.getCoordinatesByPoints().y1,
      x2: this.Line47.getCoordinatesByPoints().x1,
      y2: this.Line47.getCoordinatesByPoints().y1,
      config: lineConfig,
      state: 0,
      closed: false,
      row: true,
      name: 'T36',
      load: false,
      parentLayer: this.parentLayer,
      setSimulateLogs,
    }).add2Canvas();

    this.BusTieSwitchT5 = new BusTieSwitch({
      x1: this.Line53.getCoordinatesByPoints().x1,
      y1: this.Line53.getCoordinatesByPoints().y1 - BusTieSwitchHeight,
      x2: this.Line53.getCoordinatesByPoints().x1,
      y2: this.Line53.getCoordinatesByPoints().y1,
      config: lineConfig,
      state: 0,
      closed: false,
      row: false,
      name: 'T5',
      load: false,
      left: true,
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.BusTieSwitchT4 = new BusTieSwitch({
      x1: this.Line54.getCoordinatesByPoints().x1,
      y1: this.Line54.getCoordinatesByPoints().y1 - BusTieSwitchHeight,
      x2: this.Line54.getCoordinatesByPoints().x1,
      y2: this.Line54.getCoordinatesByPoints().y1,
      config: lineConfig,
      state: 0,
      closed: false,
      row: false,
      name: 'T4',
      load: false,
      left: false,
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.BusTieSwitchT45 = new BusTieSwitch({
      x1: this.Line56.getCoordinatesByPoints().x2,
      y1: this.Line56.getCoordinatesByPoints().y1,
      x2: this.Line57.getCoordinatesByPoints().x1,
      y2: this.Line57.getCoordinatesByPoints().y1,
      config: lineConfig,
      state: 0,
      closed: false,
      row: true,
      name: 'T45',
      load: true,
      parentLayer: this.parentLayer,
      setSimulateLogs,
    }).add2Canvas();

    this.BusTieSwitchT7 = new BusTieSwitch({
      x1: this.Line59.getCoordinatesByPoints().x1,
      y1: this.Line59.getCoordinatesByPoints().y1 - BusTieSwitchHeight,
      x2: this.Line59.getCoordinatesByPoints().x1,
      y2: this.Line59.getCoordinatesByPoints().y1,
      config: lineConfig,
      state: 0,
      closed: false,
      row: false,
      name: 'T7',
      load: false,
      left: true,
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.BusTieSwitchT8 = new BusTieSwitch({
      x1: this.Line60.getCoordinatesByPoints().x1,
      y1: this.Line60.getCoordinatesByPoints().y1 - BusTieSwitchHeight,
      x2: this.Line60.getCoordinatesByPoints().x1,
      y2: this.Line60.getCoordinatesByPoints().y1,
      config: lineConfig,
      state: 0,
      closed: false,
      row: false,
      name: 'T8',
      load: false,
      left: false,
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.BusTieSwitchT78 = new BusTieSwitch({
      x1: this.Line62.getCoordinatesByPoints().x2,
      y1: this.Line62.getCoordinatesByPoints().y1,
      x2: this.Line63.getCoordinatesByPoints().x1,
      y2: this.Line63.getCoordinatesByPoints().y1,
      config: lineConfig,
      state: 0,
      closed: false,
      row: true,
      name: 'T78',
      load: true,
      parentLayer: this.parentLayer,
      setSimulateLogs,
    }).add2Canvas();

    this.lineGroup = [];
    this.switchGroup = [];
    this.busTieGroup = [];
    this.ATSGroup = [];
    this.ATS1 = new ATS({
      left: CenterX,
      top: this.BusTieSwitchT3.y1 - 10,
      svgUid: 'ATS1',
      status: 0,
    });
    this.ATS2 = new ATS({
      left: CenterX,
      top: this.BusTieSwitchT5.y1 - 10,
      svgUid: 'ATS2',
      status: 0,
    });
    this.ATS3 = new ATS({
      left: CenterX,
      top: this.BusTieSwitchT7.y1 - 10,
      svgUid: 'ATS3',
      status: 0,
    });
    this.timerList4T3 = [];
    this.timerList4T6 = [];
    this.timerList4T5 = [];
    this.timerList4T4 = [];
    this.timerList4T7 = [];
    this.timerList4T8 = [];
    this.timerList4T36_left = []; // 向左的定时器
    this.timerList4T36_right = []; //
    this.timerList4T45_left = [];
    this.timerList4T45_right = [];
    this.timerList4T78_left = [];
    this.timerList4T78_right = [];
    //
    // this.timerLimit4T3T6On = 2000;
    // this.timerLimitCenterOn = 3000;
    this.trunck_line_group1 = [
      this.Line2,
      this.Line3,
      this.Line4,
      this.Line5,
      this.Line6,
      this.Line7,
      this.Line8,
      this.Line9,
      this.Line20,
      this.Line21,
      this.Line22,
      this.Line23,
      this.Line24,
      this.Line25,
      this.Line26,
      this.Line67,
    ];
    this.trunck_line_group2 = [
      this.Line18,
      this.Line17,
      this.Line16,
      this.Line15,
      this.Line14,
      this.Line13,
      this.Line12,
      this.Line11,
      this.Line27,
      this.Line10,
      this.Line34,
      this.Line28,
      this.Line29,
      this.Line30,
      this.Line31,
      this.Line32,
      this.Line33,
      this.Line68,
    ];
    // this.Line10.line.set("opacity",0);

    this.busTieT3Config = {
      center: false,
      bus_tie_switch: this.BusTieSwitchT3,
      trunk_road_line_list: [
        this.Line1,
        this.Line2,
        this.Line3,
        this.Line4,
        this.Line5,
        this.Line6,
        this.Line7,
        this.Line8,
        this.Line25,
      ],
      last_switch_list: [this.switch212],
      transformer: this.transformer1,
      last_branch_road_line_list: [this.Line37, this.Line41],
      port_line_list: [this.Line43, this.Line45],
      center_line_list: [this.Line46],
      semi_power_line_list: [this.Line47],
      // "center_bus_tie_switch_confi":this.BusTieSwitchT36
    };
    this.busTieT5Config = {
      center: false,
      bus_tie_switch: this.BusTieSwitchT5,
      transformer: this.transformer3,
      trunk_road_line_list: [
        this.Line1,
        this.Line2,
        this.Line3,
        this.Line4,
        this.Line5,
        this.Line6,
        this.Line7,
        this.Line24,
      ],
      last_switch_list: [this.switch213],
      last_branch_road_line_list: [this.Line36, this.Line49],
      port_line_list: [this.Line53, this.Line55],
      center_line_list: [this.Line56],
      semi_power_line_list: [this.Line57],
      // "center_bus_tie_switch":this.BusTieSwitchT45
    };
    this.busTieT7Config = {
      center: false,
      bus_tie_switch: this.BusTieSwitchT7,
      transformer: this.transformer5,
      trunk_road_line_list: [
        this.Line1,
        this.Line2,
        this.Line3,
        this.Line4,
        this.Line5,
        this.Line6,
        this.Line23,
      ],
      last_switch_list: [this.switch214],
      last_branch_road_line_list: [this.Line35, this.Line51],
      port_line_list: [this.Line59, this.Line61],
      center_line_list: [this.Line62],
      semi_power_line_list: [this.Line63],
      // "center_bus_tie_switch":this.BusTieSwitchT78
    };
    this.busTieT6Config = {
      center: false,
      bus_tie_switch: this.BusTieSwitchT6,
      transformer: this.transformer2,
      trunk_road_line_list: [
        this.Line19,
        this.Line18,
        this.Line17,
        this.Line16,
        this.Line15,
        this.Line14,
        this.Line13,
        this.Line12,
        this.Line28,
      ],
      last_switch_list: [this.switch226],
      last_branch_road_line_list: [this.Line38, this.Line42],
      port_line_list: [this.Line44, this.Line48],
      center_line_list: [this.Line47],
      semi_power_line_list: [this.Line46],
      // "center_bus_tie_switch":this.BusTieSwitchT36
    };

    this.busTieT4Config = {
      center: false,
      bus_tie_switch: this.BusTieSwitchT4,
      transformer: this.transformer4,
      trunk_road_line_list: [
        this.Line19,
        this.Line18,
        this.Line17,
        this.Line16,
        this.Line15,
        this.Line14,
        this.Line13,
        this.Line29,
      ],
      last_switch_list: [this.switch227],
      last_branch_road_line_list: [this.Line39, this.Line50],
      port_line_list: [this.Line54, this.Line58],
      center_line_list: [this.Line57],
      semi_power_line_list: [this.Line56],
      // "center_bus_tie_switch":this.BusTieSwitchT45
    };
    this.busTieT8Config = {
      center: false,
      bus_tie_switch: this.BusTieSwitchT8,
      transformer: this.transformer6,
      trunk_road_line_list: [
        this.Line19,
        this.Line18,
        this.Line17,
        this.Line16,
        this.Line15,
        this.Line14,
        this.Line30,
      ],
      last_switch_list: [this.switch228],
      last_branch_road_line_list: [this.Line40, this.Line52],
      port_line_list: [this.Line60, this.Line64],
      center_line_list: [this.Line63],
      semi_power_line_list: [this.Line62],
      // "center_bus_tie_switch":this.BusTieSwitchT78
    };
    this.busTieT36Config = {
      center: true,
      bus_tie_switch: this.BusTieSwitchT36,
      left_line: this.Line46,
      right_line: this.Line47,
      last_bus_tie_switch_config: this.busTieT3Config,
      port_bus_tie_switch_config: this.busTieT6Config,
    };
    this.busTieT45Config = {
      center: true,
      bus_tie_switch: this.BusTieSwitchT45,
      left_line: this.Line56,
      right_line: this.Line57,
      last_bus_tie_switch_config: this.busTieT5Config,
      port_bus_tie_switch_config: this.busTieT4Config,
    };
    this.busTieT78Config = {
      center: true,
      bus_tie_switch: this.BusTieSwitchT78,
      left_line: this.Line62,
      right_line: this.Line63,
      last_bus_tie_switch_config: this.busTieT7Config,
      port_bus_tie_switch_config: this.busTieT8Config,
    };
    this.updateBusTieConfig(true, this.BusTieSwitchT36);
    this.updateBusTieConfig(true, this.BusTieSwitchT45);
    this.updateBusTieConfig(true, this.BusTieSwitchT78);

    this.uploadATS([this.ATS1, this.ATS2, this.ATS3], this.uploadATSUnit);
    this.getAllGroup();
    this.low_voltage_show = false;
    // this.addName4Line();
    this.children4T36 = [];
    this.children4T45 = [];
    this.children4T78 = [];
  }
  /**
   * 高压变化，更新低压的电压状态
   * */
  setHighVoltage4Children = (children, left, state) => {
    if (children.length > 0) {
      children.forEach((child) => child.setHighVoltage(state, left));
    }
  };

  getChildren = (children4T36, children4T45, children4T78) => {
    this.children4T36.push(...children4T36);
    this.children4T45.push(...children4T45);
    this.children4T78.push(...children4T78);
    // fixme 刚开始的时候是否需要考虑对侧
    this.timerList4T3.push(
      setTimeout(
        this.emergencyResponse1.bind(this),
        3000,
        [this.busTieT3Config],
        this.children4T36,
        true,
        true,
        this.getTrunkLineList(this.busTieT3Config.bus_tie_switch),
        this.getTrunkLineList(this.busTieT36Config.last_bus_tie_switch_config.bus_tie_switch),
        false,
      ),
    );
    this.timerList4T6.push(
      setTimeout(
        this.emergencyResponse1.bind(this),
        3000,
        [this.busTieT6Config],
        this.children4T36,
        false,
        true,
        this.getTrunkLineList(this.busTieT6Config.bus_tie_switch),
        this.getTrunkLineList(this.busTieT36Config.last_bus_tie_switch_config.bus_tie_switch),
        false,
      ),
    );
    this.timerList4T5.push(
      setTimeout(
        this.emergencyResponse1.bind(this),
        4000,
        [this.busTieT5Config],
        this.children4T45,
        true,
        true,
        this.getTrunkLineList(this.busTieT5Config.bus_tie_switch),
        this.getTrunkLineList(this.busTieT45Config.last_bus_tie_switch_config.bus_tie_switch),
        false,
      ),
    );
    this.timerList4T4.push(
      setTimeout(
        this.emergencyResponse1.bind(this),
        4000,
        [this.busTieT4Config],
        this.children4T45,
        false,
        true,
        this.getTrunkLineList(this.busTieT4Config.bus_tie_switch),
        this.getTrunkLineList(this.busTieT45Config.last_bus_tie_switch_config.bus_tie_switch),
        false,
      ),
    );
    this.timerList4T7.push(
      setTimeout(
        this.emergencyResponse1.bind(this),
        5000,
        [this.busTieT7Config],
        this.children4T78,
        true,
        true,
        this.getTrunkLineList(this.busTieT7Config.bus_tie_switch),
        this.getTrunkLineList(this.busTieT78Config.last_bus_tie_switch_config.bus_tie_switch),
        false,
      ),
    );
    this.timerList4T8.push(
      setTimeout(
        this.emergencyResponse1.bind(this),
        5000,
        [this.busTieT8Config],
        this.children4T78,
        false,
        true,
        this.getTrunkLineList(this.busTieT8Config.bus_tie_switch),
        this.getTrunkLineList(this.busTieT78Config.last_bus_tie_switch_config.bus_tie_switch),
        false,
      ),
    );
  };

  /**
   * 低压变化，设置高压的负载状态
   * //fixme 如果T4T5T7T8增加低压部分，需要补充完整该函数
   * */
  setHighVoltageLoad = (index, load) => {
    if (index === 3) {
      this.BusTieSwitchT36.left_load = load;
      // if(this.BusTieSwitchT3.load !== load){
      this.BusTieSwitchT3.setLoad(load);
      if (this.BusTieSwitchT36.on) {
        // 中间的母联开关闭合的情况
        if (load) {
          // 如果左侧有负载并且中间的母联开关闭合的话，那么T6的负载也是有的
          this.BusTieSwitchT6.setLoad(load);
        } else if (!this.children4T36[0].isLoad(false)) {
          this.BusTieSwitchT6.setLoad(load);
        }
        if (this.busTieT36Config.left_line.direction && this.busTieT36Config.left_line.state >= 1) {
          // 左侧向右侧供电
          this.locustInALine(
            this.getTrunkLineList(this.BusTieSwitchT3),
            this.busTieT3Config,
            this.busTieT36Config,
            this.children4T36,
            true,
            !load,
          );
        } else if (this.busTieT36Config.left_line.state >= 1) {
          // 右侧向左侧供电
          // console.log(`低压变化调用高压部分设置高压的负载状态，
          // 影响T3T6变压器，由于右侧向左侧供电，干路为T6，负载：${load}`);
          this.locustInALine(
            this.getTrunkLineList(this.BusTieSwitchT6),
            this.busTieT6Config,
            this.busTieT36Config,
            this.children4T36,
            true,
            !load,
            true,
          );
        }
      } else {
        this.locustInALine(
          this.getTrunkLineList(this.BusTieSwitchT3),
          this.busTieT3Config,
          this.busTieT36Config,
          this.children4T36,
          true,
          !load,
        );
      }

      // }
    } else if (index === 6) {
      this.BusTieSwitchT36.right_load = load;
      this.BusTieSwitchT6.setLoad(load);
      if (this.BusTieSwitchT36.on) {
        if (load) {
          this.BusTieSwitchT3.setLoad(load);
        } else if (!this.children4T36[0].isLoad(true)) {
          this.BusTieSwitchT3.setLoad(load);
        }
        if (this.busTieT36Config.left_line.direction && this.busTieT36Config.left_line.state >= 1) {
          // 左侧向右侧供电
          this.locustInALine(
            this.getTrunkLineList(this.BusTieSwitchT3),
            this.busTieT3Config,
            this.busTieT36Config,
            this.children4T36,
            true,
            !load,
            true,
          );
        } else if (this.busTieT36Config.left_line.state >= 1) {
          // 右侧向左侧供电
          this.locustInALine(
            this.getTrunkLineList(this.BusTieSwitchT6),
            this.busTieT6Config,
            this.busTieT36Config,
            this.children4T36,
            true,
            !load,
          );
        }
      } else {
        this.locustInALine(
          this.getTrunkLineList(this.BusTieSwitchT6),
          this.busTieT6Config,
          this.busTieT36Config,
          this.children4T36,
          true,
          !load,
        );
      }
    } else if (index === 5) {
      this.BusTieSwitchT5.load = load;
      this.BusTieSwitchT45.left_load = load;
    } else if (index === 4) {
      this.BusTieSwitchT4.load = load;
      this.BusTieSwitchT45.right_load = load;
    } else if (index === 7) {
      this.BusTieSwitchT7.load = load;
      this.BusTieSwitchT78.left_load = load;
    } else if (index === 8) {
      this.BusTieSwitchT8.load = load;
      this.BusTieSwitchT78.right_load = load;
    }
  };

  /** 设置低压部分是否在显示状态，据此计算鼠标落在高压区域的范围 */
  setLowVoltageShow = (show) => {
    this.low_voltage_show = show;
  };

  updateFaultSimulation = (simulationt) => {
    this.faultSimulation = simulationt;
  };

  /**
   * 判断是否是高压区域的点击，目前仅仅根据纵坐标的位置来进行判断
   *  //fixme
   * */
  isPointInPath = (x, y) => {
    if (this.low_voltage_show) {
      return y <= this.BusTieSwitchT36.stateRect.y() + this.BusTieSwitchT36.stateRect.height();
    }
    return true;
  };

  /**
   * 两侧的母联开关T3T6主要为了更新干路的线段列表
   * 如果是更新两侧母联开关：
   *      direction:Any
   *      bus_tie_switch:BusTieSwitch
   *      trunk_road_line_list:[]
   * 如果是中间的母联开关：
   *      direction:true(电流方向向右)/false(电流方向向左)
   *      bus_tie_switch:BusTieSwitch
   *      trunk_road_line_list:Any
   * */
  updateBusTieConfig = (direction, bus_tie_switch, trunk_road_line_list) => {
    // console.log(`更新母联开关的配置项：updateBusTieConfig: ${bus_tie_switch.name}`);
    if (typeof trunk_road_line_list === 'undefined') {
      if (direction) {
        switch (bus_tie_switch.name) {
          case 'AA-T36-01':
            [this.Line46, this.Line47, this.Line48].forEach((line) => (line.direction = direction));
            this.busTieT36Config.last_branch_road_line_list = [this.Line46];
            this.busTieT36Config.port_line_list = [this.Line47, this.Line48];
            this.busTieT36Config.origin_power_line_list = [this.Line44];
            this.busTieT36Config.last_bus_tie_switch_config = this.busTieT3Config;
            this.busTieT36Config.port_bus_tie_switch_config = this.busTieT6Config;
            this.busTieT3Config.center_bus_tie_switch_config = this.busTieT36Config;
            this.busTieT6Config.center_bus_tie_switch_config = this.busTieT36Config;
            break;
          case 'AA-T45-01':
            [this.Line56, this.Line57, this.Line58].forEach((line) => (line.direction = direction));
            this.busTieT45Config.last_branch_road_line_list = [this.Line56];
            this.busTieT45Config.port_line_list = [this.Line57, this.Line58];
            this.busTieT45Config.origin_power_line_list = [this.Line54];
            this.busTieT45Config.last_bus_tie_switch_config = this.busTieT5Config;
            this.busTieT45Config.port_bus_tie_switch_config = this.busTieT4Config;
            this.busTieT4Config.center_bus_tie_switch_config = this.busTieT45Config;
            this.busTieT5Config.center_bus_tie_switch_config = this.busTieT45Config;
            break;
          case 'AA-T78-01':
            [this.Line62, this.Line63, this.Line64].forEach((line) => (line.direction = direction));
            this.busTieT78Config.last_branch_road_line_list = [this.Line62];
            this.busTieT78Config.port_line_list = [this.Line63, this.Line64];
            this.busTieT78Config.origin_power_line_list = [this.Line60];
            this.busTieT78Config.last_bus_tie_switch_config = this.busTieT7Config;
            this.busTieT78Config.port_bus_tie_switch_config = this.busTieT8Config;
            this.busTieT7Config.center_bus_tie_switch_config = this.busTieT78Config;
            this.busTieT8Config.center_bus_tie_switch_config = this.busTieT78Config;
            break;
          default:
          // console.log(`没有匹配的中间母联开关:${bus_tie_switch.name}`);
        }
      } else {
        switch (bus_tie_switch.name) {
          case 'AA-T36-01':
            [this.Line46, this.Line47, this.Line45].forEach((line) => (line.direction = direction));
            this.busTieT36Config.last_branch_road_line_list = [this.Line47];
            this.busTieT36Config.port_line_list = [this.Line45, this.Line46];
            this.busTieT36Config.origin_power_line_list = [this.Line43];
            this.busTieT36Config.last_bus_tie_switch_config = this.busTieT6Config;
            this.busTieT36Config.port_bus_tie_switch_config = this.busTieT3Config;
            break;
          case 'AA-T45-01':
            [this.Line56, this.Line57, this.Line55].forEach((line) => (line.direction = direction));
            this.busTieT45Config.last_branch_road_line_list = [this.Line57];
            this.busTieT45Config.port_line_list = [this.Line55, this.Line56];
            this.busTieT45Config.origin_power_line_list = [this.Line53];
            this.busTieT45Config.last_bus_tie_switch_config = this.busTieT4Config;
            this.busTieT45Config.port_bus_tie_switch_config = this.busTieT5Config;
            break;
          case 'AA-T78-01':
            [this.Line62, this.Line63, this.Line61].forEach((line) => (line.direction = direction));
            this.busTieT78Config.last_branch_road_line_list = [this.Line63];
            this.busTieT78Config.port_line_list = [this.Line61, this.Line62];
            this.busTieT78Config.origin_power_line_list = [this.Line59];
            this.busTieT78Config.last_bus_tie_switch_config = this.busTieT8Config;
            this.busTieT78Config.port_bus_tie_switch_config = this.busTieT7Config;
            break;
          default:
          // console.log(`没有匹配的中间母联开关${bus_tie_switch.name}`);
        }
      }
    } else {
      switch (bus_tie_switch.name) {
        case 'AA-T3-01':
          this.busTieT3Config.trunk_road_line_list = trunk_road_line_list;
          break;
        case 'AA-T6-01':
          this.busTieT6Config.trunk_road_line_list = trunk_road_line_list;
          break;
        case 'AA-T5-01':
          this.busTieT5Config.trunk_road_line_list = trunk_road_line_list;
          break;
        case 'AA-T4-01':
          this.busTieT4Config.trunk_road_line_list = trunk_road_line_list;
          break;
        case 'AA-T7-01':
          this.busTieT7Config.trunk_road_line_list = trunk_road_line_list;
          break;
        case 'AA-T8-01':
          this.busTieT8Config.trunk_road_line_list = trunk_road_line_list;
          break;
        default:
        // console.log(`没有匹配的母联开关 ${bus_tie_switch.name}`);
      }
    }
  };

  /**
   * 这个函数主要是在画布上写上线段的编号以便写代码的时候清晰，不用看着代码对，只需要在该类初始化的时候调用一下该函数就行
   * */
  addName4Line = () => {
    // for (let value of Object.values(this)) {
    //   if (value instanceof Line) {
    //     value.addSerial2Canvas(canvas);
    //     //canvas是全局变量
    //   }
    // }
    // for (let k in this) {
    //   if (!this.hasOwnProperty(k)) continue;
    //   if (this[k] instanceof Line) {
    //     this[k].addSerial2Canvas(canvas);//canvas是全局变量
    //   }
    // }
  };

  /**
   * 点击ATS的时候调用该函数
   * */
  uploadATSUnit = (status, config, svgUid) => {
    const statusMap2Img = { 0: switchImg1, 1: switchImg2, 2: switchImg3 };
    Konva.Image.fromURL(statusMap2Img[status], (image) => {
      if (!((svgUid === 'ATS2' || svgUid === 'ATS3') && this.low_voltage_show)) {
        image.setAttrs(config);
        this.parentLayer.add(image);
        this.parentLayer.batchDraw();
      }
    });
  };

  uploadATS = (ats_list, callback) => {
    // 这个函数里面的内容除了全局变量都要作为参数传进来
    ats_list.forEach((obj) => callback(obj.ATSState, obj.getConfig(), obj.svgUid));
  };

  /**
   * 清除定时器
   * */
  clearTimer = (list) => {
    list.forEach(clearTimeout);
    list.splice(0, list.length);
  };

  /**
   * 通过成员变量的类型进行分组
   * */
  getAllGroup = () => {
    Object.values(this).forEach((obj) => {
      if (obj instanceof Line) {
        this.lineGroup.push(obj);
      } else if (obj instanceof Switch) {
        this.switchGroup.push(obj);
      } else if (obj instanceof BusTieSwitch) {
        this.busTieGroup.push(obj);
      } else if (obj instanceof ATS) {
        this.ATSGroup.push(obj);
      }
    });
    // for(let k in this){
    //   if(!this.hasOwnProperty(k)) continue;
    //     if(this[k] instanceof Line){
    //         this.lineGroup.push(this[k]);
    //     }else if(this[k] instanceof Switch){
    //         this.switchGroup.push(this[k]);
    //     }else if(this[k] instanceof BusTieSwitch){
    //         this.busTieGroup.push(this[k]);
    //     }else if(this[k] instanceof ATS){
    //         this.ATSGroup.push(this[k]);
    //     }
    // }
  };

  /**
   * 批量更新线段的状态
   * */
  updateState = (line_list, set_state) => {
    for (let i = 0; i < line_list.length; i += 1) {
      line_list[i].setState(set_state);
    }
  };

  /**
   * 这个函数主要是因为js的定时器setTimeout有很多限制，在执行定义好的延时执行函数的时候，如果是执行域未定义的量，执行延时闭合的时候会有问题，所以这里统一作为一个setTimeout设置function的函数，执行的内容就是emergencyResponse1
   * */
  setTimeOut4This = (busTieTxConfig, child, left, up2Down, thinking_opposite) => {
    const that = this;
    // fixme 本侧开关闭合的时候考虑对侧开关是否需要断开，中间的母联闭合需要定时
    /**
     * canvas:画布
     * line_switch_config_list：延时闭合的母联开关的配置列表，其实可以不用列表
     * callback：更新线段列表的回调函数
     * low_voltage_child：低压的累
     * left：是否是左侧的母联
     * setVoltageCallback：设置低压类状态的回调函数
     * up2Down：是否是由于低压变化触发该函数，如果是就不需要再更新低压类了，否则会出现死循环
     * trunkList：传递进来的干路列表
     * trunkList4Opposite：对侧的干路列表
     * thinking_opposite：是否需要考虑对侧
     * */
    const timerKey = setTimeout(
      (line_switch_config_list, callback, low_voltage_child, setVoltageCallback, trunkList) => {
        // console.log(`emergencyResponse1:${line_switch_config_list[0].bus_tie_switch.name}`);
        /**
         * 主要作用是母联开关上口通电或者断电的时候，根据母联开关的状态，做响应的反应并且更新线路的状态
         * 倒计时闭合母联开关后的状态
         * 需要更新下口的低压状态
         * @param canvas
         * @param line_switch_config = {
         * “bus_tie_switch”:this.SwitchT3,
         * //"on":True/False(通电或者断电),
         * "trunk_road_line_list":[](干路电流),//不能覆盖之前的状态，
         * "last_switch_list":[](上口开关),
         * "last_branch_road_line_list":[]//不管开关的状态这里是统一变化的
         * “port_line_list”:[](下口线段，有可能试验位状态不同),
         * "center_line_list":[](母联中间的导线列表)}
         *
         * @param callback//更新线路状态的回调函数
         *
         * @param line_switch_config_list=[line_switch_config]
         * */
        for (let i = 0; i < line_switch_config_list.length; i += 1) {
          if (line_switch_config_list[i].bus_tie_switch.state === 2) {
            // 抽出位
            // console.log(`开关
            // ${line_switch_config_list[i].bus_tie_switch.getBusTieSwitchName()}处于抽出位`);
            if (typeof line_switch_config_list[i].last_switch_list !== 'undefined') {
              line_switch_config_list[i].last_switch_list.forEach((obj) => (obj.load = false));
            }
          } else if (line_switch_config_list[i].bus_tie_switch.state === 1) {
            // 试验位
            // console.log(
            // `开关${line_switch_config_list[i].bus_tie_switch.getBusTieSwitchName()}处于试验位`,
            // );
            callback(line_switch_config_list[i].last_branch_road_line_list, 1); // 母联开关上口电压的状态，通电的时候才会执行，所以一定是有电压的状态
            if (line_switch_config_list[i].center) {
              // 如果是中间的母联开关
              callback(
                line_switch_config_list[i].port_line_list.concat(
                  line_switch_config_list[i].origin_power_line_list,
                ),
                0,
              );
              if (up2Down) {
                setVoltageCallback(low_voltage_child, left, 0);
              }
            } else {
              callback(
                line_switch_config_list[i].port_line_list.concat(
                  line_switch_config_list[i].center_line_list,
                ),
                0,
              ); // 母联开关下口线路的变化，三条线路都是无压无流的状态
              if (up2Down) {
                setVoltageCallback(low_voltage_child, left, 0);
              }
            }
            if (typeof line_switch_config_list[i].last_switch_list !== 'undefined') {
              line_switch_config_list[i].last_switch_list.forEach((obj) => (obj.load = false));
            }
          } else {
            // 工作位
            // console.log(
            //   `开关 ${line_switch_config_list[i].bus_tie_switch.getBusTieSwitchName()}处于工作位`,
            // );
            line_switch_config_list[i].bus_tie_switch.onclick(true, true);
            // 断开中间母联的时候，如果对侧的电流因为本册而起，那么应该清除对侧的电流
            if (line_switch_config_list[i].center) {
              // 中间的母联开关
              if (
                (line_switch_config_list[i].left_line.direction &&
                  line_switch_config_list[i].bus_tie_switch.right_load) ||
                (!line_switch_config_list[i].left_line.direction &&
                  line_switch_config_list[i].bus_tie_switch.left_load)
              ) {
                // 更新上口线路的状态
                const last_config = line_switch_config_list[i].last_bus_tie_switch_config;
                callback(trunkList, 2);
                callback(last_config.last_branch_road_line_list, 2);
                callback([last_config.port_line_list[0]], 2);
                if (typeof last_config.transformer !== 'undefined') {
                  last_config.transformer.setState(2);
                }
                callback(
                  line_switch_config_list[i].last_branch_road_line_list.concat(
                    line_switch_config_list[i].port_line_list,
                  ),
                  2,
                );
                if (up2Down) {
                  setVoltageCallback(low_voltage_child, left, 1);
                }

                callback(line_switch_config_list[i].origin_power_line_list, 1);
                if (typeof line_switch_config_list[i].last_switch_list !== 'undefined') {
                  line_switch_config_list[i].last_switch_list.forEach((obj) => obj.setLoad(true));
                }
              } else {
                // 没有负载
                // console.log(`母联开关的方向${line_switch_config_list[i].left_line.direction},
                // 右侧负载:${line_switch_config_list[i].bus_tie_switch.right_load},
                // 左侧负载：${line_switch_config_list[i].bus_tie_switch.left_load}`);
                callback(
                  line_switch_config_list[i].port_line_list.concat(
                    line_switch_config_list[i].origin_power_line_list,
                  ),
                  1,
                );
                if (up2Down) {
                  setVoltageCallback(low_voltage_child, left, 1);
                }
              }
            } else {
              // 非中间的母联开关
              // fixme 需要判断对侧是否有电
              // -----------------------------考虑对侧的电流-------------------------//
              // fixme 这里的附属供电线路状态改为判断变压器的状态
              // that.updateBusTieConfigByDirection(line_switch_config_list[i].center_bus_tie_switch_config);
              // line_switch_config_list[i].center_bus_tie_switch_config.port_bus_tie_switch_config.transformer.state === 0
              if (
                !that.switch245.on &&
                line_switch_config_list[i].semi_power_line_list[0].state === 0 &&
                thinking_opposite
              ) {
                // 对侧没有电流
                that.updateBusTieConfigByDirection(
                  line_switch_config_list[i].center_bus_tie_switch_config,
                );
                if (
                  line_switch_config_list[i].center_bus_tie_switch_config.bus_tie_switch.state === 0
                ) {
                  // console.log(
                  //   `低压母联${line_switch_config_list[i].center_bus_tie_switch_config.bus_tie_switch.name}处于工作位，延时闭合`,
                  // );
                  const timer_key = setTimeout(() => {
                    that.updateBusTieConfigByDirection(
                      line_switch_config_list[i].center_bus_tie_switch_config,
                    );
                    line_switch_config_list[
                      i
                    ].center_bus_tie_switch_config.port_bus_tie_switch_config.bus_tie_switch.onclick(
                      false,
                      '自动',
                    ); // 对侧母联断开
                    line_switch_config_list[i].center_bus_tie_switch_config.bus_tie_switch.onclick(
                      true,
                      '自动',
                    ); // 低压母联闭合
                    callback(
                      line_switch_config_list[i].center_bus_tie_switch_config.port_line_list.concat(
                        line_switch_config_list[i].center_bus_tie_switch_config
                          .origin_power_line_list,
                      ),
                      1,
                    );
                    setVoltageCallback(
                      child,
                      !line_switch_config_list[i].center_bus_tie_switch_config
                        .last_branch_road_line_list[0].direction,
                      1,
                    );
                  }, 500);
                  switch (
                    line_switch_config_list[i].center_bus_tie_switch_config.bus_tie_switch.name
                  ) {
                    case 'AA-T36-01':
                      if (
                        line_switch_config_list[i].center_bus_tie_switch_config
                          .last_branch_road_line_list[0].direction
                      ) {
                        // right
                        that.timerList4T36_right.push(timer_key);
                      } else {
                        that.timerList4T36_left.push(timer_key);
                      }
                      break;
                    case 'AA-T45-01':
                      if (
                        line_switch_config_list[i].center_bus_tie_switch_config
                          .last_branch_road_line_list[0].direction
                      ) {
                        // right
                        that.timerList4T45_right.push(timer_key);
                      } else {
                        that.timerList4T45_left.push(timer_key);
                      }
                      break;
                    case 'AA-T78-01':
                      if (
                        line_switch_config_list[i].center_bus_tie_switch_config
                          .last_branch_road_line_list[0].direction
                      ) {
                        // right
                        that.timerList4T78_right.push(timer_key);
                      } else {
                        that.timerList4T78_left.push(timer_key);
                      }
                      break;
                    default:
                    // console.log(
                    //   `没有匹配的母联：${line_switch_config_list[i].center_bus_tie_switch_config.bus_tie_switch.name}`,
                    // );
                  }
                } else {
                  // console.log(`低压母联
                  // ${line_switch_config_list[i].center_bus_tie_switch_config.bus_tie_switch.name}处于试验位或抽出位，不启动延时闭合`);
                }
              }
              if (line_switch_config_list[i].bus_tie_switch.load) {
                if (typeof line_switch_config_list[i].transformer !== 'undefined') {
                  line_switch_config_list[i].transformer.setState(2);
                }
                callback(
                  line_switch_config_list[i].last_branch_road_line_list.concat(
                    line_switch_config_list[i].port_line_list,
                  ),
                  2,
                );
                if (up2Down) {
                  setVoltageCallback(low_voltage_child, left, 1);
                }
                callback(trunkList, 2);
                callback(line_switch_config_list[i].center_line_list, 1);
                if (typeof line_switch_config_list[i].last_switch_list !== 'undefined') {
                  line_switch_config_list[i].last_switch_list.forEach((obj) => obj.setLoad(true));
                }
              } else {
                // console.log(`${line_switch_config_list[i].bus_tie_switch}没有负载`);
                callback(line_switch_config_list[i].port_line_list, 1);
                callback(line_switch_config_list[i].center_line_list, 1);
                if (up2Down) {
                  setVoltageCallback(low_voltage_child, left, 1);
                }
              }
            }
          }
        }
      },
      2000,
      [busTieTxConfig],
      this.updateState,
      child,
      // left,
      this.setHighVoltage4Children,
      // up2Down,
      this.getTrunkLineList(busTieTxConfig.bus_tie_switch),
      // this.getTrunkLineList(
      //   busTieTxConfig.center_bus_tie_switch_config.last_bus_tie_switch_config.bus_tie_switch,
      // ),
      // thinking_opposite,
    );
    // line_switch_config_list, callback, low_voltage_child, setVoltageCallback, trunkList
    return timerKey;
  };

  /**
   * 母联下口的开关变化通过该函数进行设置，那么在更新下口线路变化的时候更新低压部分的电压值
   * up2Down:true---从上到下更新，如果高压区域调用该函数，需要通知低压进行变化，而低压在判断有电流的情况时会通知高压更新响应的负载（load）值，在调用该含函数的时候，up2Down应设置false，防止出现死循环
   * 紧急投切会进行开关的投切工作不单单是改变线路状态
   * */
  // 如果是中间的母联开关延时闭合的话，需要将干路的线路状态一并更新
  // 解决办法，将上口母联的配置信息放入中间母联开关的配置信息中
  emergencyResponse1 = (
    line_switch_config_list,
    low_voltage_child,
    left,
    up2Down,
    trunkList,
    trunkList4Opposite,
    thinking_opposite,
  ) => {
    // console.log(`emergencyResponse1:${line_switch_config_list[0].bus_tie_switch.name}`);
    /**
     * 主要作用是母联开关上口通电或者断电的时候，根据母联开关的状态，做响应的反应并且更新线路的状态
     * 倒计时闭合母联开关后的状态
     * 需要更新下口的低压状态
     * @param canvas
     * @param line_switch_config = {
     * “bus_tie_switch”:this.SwitchT3,
     * //"on":True/False(通电或者断电),
     * "trunk_road_line_list":[](干路电流),//不能覆盖之前的状态，
     * "last_switch_list":[](上口开关),
     * "last_branch_road_line_list":[]//不管开关的状态这里是统一变化的
     * “port_line_list”:[](下口线段，有可能试验位状态不同),
     * "center_line_list":[](母联中间的导线列表)}
     *
     * @param callback//更新线路状态的回调函数
     *
     * @param line_switch_config_list=[line_switch_config]
     * */
    for (let i = 0; i < line_switch_config_list.length; i += 1) {
      if (line_switch_config_list[i].bus_tie_switch.state === 2) {
        // 抽出位
        // console.log(`开关
        // ${line_switch_config_list[i].bus_tie_switch.getBusTieSwitchName()}处于抽出位`);
        if (typeof line_switch_config_list[i].last_switch_list !== 'undefined') {
          line_switch_config_list[i].last_switch_list.forEach((obj) => (obj.load = false));
        }
      } else if (line_switch_config_list[i].bus_tie_switch.state === 1) {
        // 试验位
        // console.log(
        //   `开关${line_switch_config_list[i].bus_tie_switch.getBusTieSwitchName()}处于试验位`,
        // );
        this.updateState(line_switch_config_list[i].last_branch_road_line_list, 1); // 母联开关上口电压的状态，通电的时候才会执行，所以一定是有电压的状态
        if (line_switch_config_list[i].center) {
          // 如果是中间的母联开关
          // callback(line_switch_config_list[i].port_line_list.concat(line_switch_config_list[i].origin_power_line_list),0,canvas);
          this.updateState(
            line_switch_config_list[i].port_line_list.concat(
              line_switch_config_list[i].origin_power_line_list,
            ),
            0,
          );
          if (up2Down) {
            // setVoltageCallback(low_voltage_child,left,0);
            this.setHighVoltage4Children(low_voltage_child, left, 0);
          }
        } else {
          this.updateState(
            line_switch_config_list[i].port_line_list.concat(
              line_switch_config_list[i].center_line_list,
            ),
            0,
          ); // 母联开关下口线路的变化，三条线路都是无压无流的状态
          if (up2Down) {
            // setVoltageCallback(low_voltage_child,left,0);
            this.setHighVoltage4Children(low_voltage_child, left, 0);
          }
        }
        if (typeof line_switch_config_list[i].last_switch_list !== 'undefined') {
          line_switch_config_list[i].last_switch_list.forEach((obj) => (obj.load = false));
        }
      } else {
        // 工作位
        // console.log(
        //   '开关',
        //   line_switch_config_list[i].bus_tie_switch.getBusTieSwitchName(),
        //   '处于工作位',
        // );
        line_switch_config_list[i].bus_tie_switch.onclick(true, true);
        // 断开中间母联的时候，如果对侧的电流因为本册而起，那么应该清除对侧的电流
        if (line_switch_config_list[i].center) {
          // 中间的母联开关
          if (
            (line_switch_config_list[i].left_line.direction &&
              line_switch_config_list[i].bus_tie_switch.right_load) ||
            (!line_switch_config_list[i].left_line.direction &&
              line_switch_config_list[i].bus_tie_switch.left_load)
          ) {
            // 更新上口线路的状态
            const last_config = line_switch_config_list[i].last_bus_tie_switch_config;
            // callback(trunkList,2,canvas);
            // callback(last_config.last_branch_road_line_list,2,canvas);
            // callback([last_config.port_line_list[0]],2,canvas);
            this.updateState(trunkList, 2);
            this.updateState(last_config.last_branch_road_line_list, 2);
            this.updateState([last_config.port_line_list[0]], 2);
            if (typeof last_config.transformer !== 'undefined') {
              last_config.transformer.setState(2);
            }
            this.updateState(
              line_switch_config_list[i].last_branch_road_line_list.concat(
                line_switch_config_list[i].port_line_list,
              ),
              2,
            );
            // callback(line_switch_config_list[i].last_branch_road_line_list.concat(line_switch_config_list[i].port_line_list),2,canvas);
            if (up2Down) {
              // setVoltageCallback(low_voltage_child,left,1);
              this.setHighVoltage4Children(low_voltage_child, left, 1);
            }

            // callback(line_switch_config_list[i].origin_power_line_list,1,canvas);
            this.updateState(line_switch_config_list[i].origin_power_line_list, 1);
            if (typeof line_switch_config_list[i].last_switch_list !== 'undefined') {
              line_switch_config_list[i].last_switch_list.forEach((obj) => obj.setLoad(true));
            }
          } else {
            // 没有负载
            // console.log(
            //   `母联开关的方向：${line_switch_config_list[i].left_line.direction}，右侧负载：${line_switch_config_list[i].bus_tie_switch.right_load},左侧负载：${line_switch_config_list[i].bus_tie_switch.left_load}`,
            // );
            // callback(line_switch_config_list[i].port_line_list.concat(line_switch_config_list[i].origin_power_line_list),1,canvas);
            this.updateState(
              line_switch_config_list[i].port_line_list.concat(
                line_switch_config_list[i].origin_power_line_list,
              ),
              1,
            );
            if (up2Down) {
              // setVoltageCallback(low_voltage_child,left,1);
              this.setHighVoltage4Children(low_voltage_child, left, 1);
            }
          }
        } else {
          // 非中间的母联开关
          // fixme 需要判断对侧是否有电,这里的调用可能会报错
          if (
            !this.switch245.on &&
            line_switch_config_list[i].semi_power_line_list[0].state === 0 &&
            thinking_opposite
          ) {
            // 对侧没有电流
            this.updateBusTieConfigByDirection(
              line_switch_config_list[i].center_bus_tie_switch_config,
            );
            if (
              line_switch_config_list[i].center_bus_tie_switch_config.bus_tie_switch.state === 0
            ) {
              // console.log(
              //   `低压母联${line_switch_config_list[i].center_bus_tie_switch_config.bus_tie_switch.name}处于工作位，延时闭合`,
              // );
              const timer_key = setTimeout(() => {
                this.updateBusTieConfigByDirection(
                  line_switch_config_list[i].center_bus_tie_switch_config,
                );
                line_switch_config_list[
                  i
                ].center_bus_tie_switch_config.port_bus_tie_switch_config.bus_tie_switch.onclick(
                  false,
                  '自动',
                ); // 对侧母联断开
                line_switch_config_list[i].center_bus_tie_switch_config.bus_tie_switch.onclick(
                  true,
                  '自动',
                ); // 低压母联闭合
                // callback(line_switch_config_list[i].center_bus_tie_switch_config.port_line_list.concat(line_switch_config_list[i].center_bus_tie_switch_config.origin_power_line_list),1,canvas);
                this.updateState(
                  line_switch_config_list[i].center_bus_tie_switch_config.port_line_list.concat(
                    line_switch_config_list[i].center_bus_tie_switch_config.origin_power_line_list,
                  ),
                  1,
                );
                // setVoltageCallback(low_voltage_child,!line_switch_config_list[i].center_bus_tie_switch_config.last_branch_road_line_list[0].direction,1);
                this.setHighVoltage4Children(
                  low_voltage_child,
                  !line_switch_config_list[i].center_bus_tie_switch_config
                    .last_branch_road_line_list[0].direction,
                  1,
                );
              }, 500);
              switch (line_switch_config_list[i].center_bus_tie_switch_config.bus_tie_switch.name) {
                case 'AA-T36-01':
                  if (
                    line_switch_config_list[i].center_bus_tie_switch_config
                      .last_branch_road_line_list[0].direction
                  ) {
                    // right
                    this.timerList4T36_right.push(timer_key);
                  } else {
                    this.timerList4T36_left.push(timer_key);
                  }
                  break;
                case 'AA-T45-01':
                  if (
                    line_switch_config_list[i].center_bus_tie_switch_config
                      .last_branch_road_line_list[0].direction
                  ) {
                    // right
                    this.timerList4T45_right.push(timer_key);
                  } else {
                    this.timerList4T45_left.push(timer_key);
                  }
                  break;
                case 'AA-T78-01':
                  if (
                    line_switch_config_list[i].center_bus_tie_switch_config
                      .last_branch_road_line_list[0].direction
                  ) {
                    // right
                    this.timerList4T78_right.push(timer_key);
                  } else {
                    this.timerList4T78_left.push(timer_key);
                  }
                  break;
                default:
                // console.log(
                //   `没有匹配的母联：${line_switch_config_list[i].center_bus_tie_switch_config.bus_tie_switch.name}`,
                // );
              }
            } else {
              // console.log(
              //   `低压母联${line_switch_config_list[i].center_bus_tie_switch_config.bus_tie_switch.name}处于试验位或抽出位，不启动延时闭合`,
              // );
            }
          }
          if (line_switch_config_list[i].bus_tie_switch.load) {
            // console.log(`${line_switch_config_list[i].bus_tie_switch}有负载`);
            if (typeof line_switch_config_list[i].transformer !== 'undefined') {
              line_switch_config_list[i].transformer.setState(2);
            }
            // callback(line_switch_config_list[i].last_branch_road_line_list.concat(line_switch_config_list[i].port_line_list),2,canvas);
            this.updateState(
              line_switch_config_list[i].last_branch_road_line_list.concat(
                line_switch_config_list[i].port_line_list,
              ),
              2,
            );
            if (up2Down) {
              // setVoltageCallback(low_voltage_child,left,1);
              this.setHighVoltage4Children(low_voltage_child, left, 1);
            }
            // 要么在执行该函数之前更新母联开关的config，要么使用传进来的trunkList
            // callback(trunkList,2,canvas);
            // callback(line_switch_config_list[i].center_line_list,1,canvas);
            this.updateState(trunkList, 2);
            this.updateState(line_switch_config_list[i].center_line_list, 1);
            if (typeof line_switch_config_list[i].last_switch_list !== 'undefined') {
              // console.log('更新负载');
              line_switch_config_list[i].last_switch_list.forEach((obj) => obj.setLoad(true));
            }
          } else {
            // console.log(`${line_switch_config_list[i].bus_tie_switch}没有负载`);
            // callback(line_switch_config_list[i].port_line_list,1,canvas);
            // callback(line_switch_config_list[i].center_line_list,1,canvas);
            this.updateState(line_switch_config_list[i].port_line_list, 1);
            this.updateState(line_switch_config_list[i].center_line_list, 1);
            if (up2Down) {
              // setVoltageCallback(low_voltage_child,left,1);
              this.setHighVoltage4Children(low_voltage_child, left, 1);
            }
          }
        }
      }
    }
  };

  switchTimeOut = (switch_obj, closed) => {
    switch_obj.onclick(closed);
  };

  /**
   * 高压线路闭合或者断开任意开关都需要调用该函数
   * on_condition:中间母联开关闭合的条件，不能单单用另一侧的线路状态来判断，有可能Line43的线路来自市电2，只有断开的时候才需要这个变量
   * closed:true/false
   * left:true/false
   * ats:ATS
   * last_port_switch:上口开关
   * busTieTxConfig：母联开关配置
   * busTieTxxConfig：中间母联开关的配置
   * timer_manager_list：定时器列表
   * timer_manager_left：中间母联开关向左的定时器列表
   * timer_manager_right：中间母联开关向右的定时器列表
   * children：低压子类
   * on_condition：true（中间的母联开关符合闭合的条件）/false（只有close为false的时候才会用到这个值）
   * direction：true(电流方向向右)/false（只有close为false的时候才会用到这个值）
   * thinking_opposite：true/false（是否需要考虑对侧）
   * */
  // fixme 手动状态下通断电，中间的母联开关闭合的情况下，更新对侧状态有问题
  // false,
  // true,
  // this.ATS1,
  // this.switch212,
  // this.busTieT3Config,
  // this.busTieT36Config,
  // this.timerList4T3,
  // this.timerList4T36_left,
  // this.timerList4T36_right,
  // this.children4T36,
  // this.Line44.state >= 1,
  // false,
  timer4BusTieSwitch = (
    closed,
    left,
    ats,
    last_port_switch,
    busTieTxConfig,
    busTieTxxConfig,
    timer_manager_list,
    timer_manager_left,
    timer_manager_right,
    children,
    on_condition,
    direction,
    thinking_opposite,
  ) => {
    // console.log(`timer4BusTieSwitch  ${busTieTxConfig.bus_tie_switch.name}`);
    this.clearTimer(timer_manager_list);
    this.clearTimer(timer_manager_left);
    this.clearTimer(timer_manager_right);
    if (closed) {
      // 如果下口的母联开关闭合的话，直接改变下口的状态
      if (last_port_switch.on) {
        this.updateState(busTieTxConfig.last_branch_road_line_list, 1);
        busTieTxConfig.transformer.setState(1);
      }
      // 上口开关闭合，母联开关也闭合
      if (last_port_switch.on && busTieTxConfig.bus_tie_switch.on) {
        this.updateState(busTieTxConfig.port_line_list.concat(busTieTxConfig.center_line_list), 1);
        this.setHighVoltage4Children(children, left, 1);
        // FIXME 如果通过245供电的话，也需要断开中间的母联，通过延时闭合本侧母联进行供电
        if (busTieTxxConfig.bus_tie_switch.on) {
          // 中间的母联开关闭合,还需要知道另一侧的config
          this.updateState(
            busTieTxxConfig.port_bus_tie_switch_config.port_line_list.concat(
              busTieTxxConfig.port_bus_tie_switch_config.center_line_list,
            ),
            1,
          );
          this.setHighVoltage4Children(children, !left, 1);
        } else if (
          !this.switch245.on &&
          busTieTxConfig.semi_power_line_list[0].state === 0 &&
          thinking_opposite
        ) {
          // 开关245和低压母联不能同时闭合,只有245断开的情况才需要考虑对侧
          // 对侧没有电流
          // fixme 还有一种情况是245闭合，可能本侧来电的瞬间，由于245，226（245，212）是闭合的，对侧也跟着来电，但是本侧母联的附属供电线路此时的状态仍是0,但是此时并不需要闭合中间的低压母联，而是对对侧的低压加上定时
          this.updateBusTieConfigByDirection(busTieTxConfig.center_bus_tie_switch_config);
          if (busTieTxConfig.center_bus_tie_switch_config.bus_tie_switch.state === 0) {
            // console.log(
            //   `低压母联${busTieTxConfig.center_bus_tie_switch_config.bus_tie_switch.name}处于工作位，延时闭合`,
            // );
            const timer_key = setTimeout(() => {
              this.updateBusTieConfigByDirection(busTieTxConfig.center_bus_tie_switch_config);
              busTieTxConfig.center_bus_tie_switch_config.port_bus_tie_switch_config.bus_tie_switch.onclick(
                //
                false,
                '自动',
              ); // 对侧母联断开
              busTieTxConfig.center_bus_tie_switch_config.bus_tie_switch.onclick(
                //
                true,
                '自动',
              ); // 低压母联闭合
              this.updateState(
                busTieTxConfig.center_bus_tie_switch_config.port_line_list.concat(
                  busTieTxConfig.center_bus_tie_switch_config.origin_power_line_list,
                ),
                1,
              );
              this.setHighVoltage4Children(
                children,
                !busTieTxConfig.center_bus_tie_switch_config.last_branch_road_line_list[0]
                  .direction,
                1,
              );
            }, 500);
            switch (busTieTxConfig.center_bus_tie_switch_config.bus_tie_switch.name) {
              case 'AA-T36-01':
                if (
                  busTieTxConfig.center_bus_tie_switch_config.last_branch_road_line_list[0]
                    .direction
                ) {
                  // right
                  this.timerList4T36_right.push(timer_key);
                } else {
                  this.timerList4T36_left.push(timer_key);
                }
                break;
              case 'AA-T45-01':
                if (
                  busTieTxConfig.center_bus_tie_switch_config.last_branch_road_line_list[0]
                    .direction
                ) {
                  // right
                  this.timerList4T45_right.push(timer_key);
                } else {
                  this.timerList4T45_left.push(timer_key);
                }
                break;
              case 'AA-T78-01':
                if (
                  busTieTxConfig.center_bus_tie_switch_config.last_branch_road_line_list[0]
                    .direction
                ) {
                  // right
                  this.timerList4T78_right.push(timer_key);
                } else {
                  this.timerList4T78_left.push(timer_key);
                }
                break;
              default:
              // console.log(
              //   `没有匹配的母联：${busTieTxConfig.center_bus_tie_switch_config.bus_tie_switch.name}`,
              // );
            }
          } else {
            // console.log(
            //   `低压母联${busTieTxConfig.center_bus_tie_switch_config.bus_tie_switch.name}处于试验位或抽出位，不启动延时闭合`,
            // );
          }
        }
      } else if (ats.ATSState === 2) {
        // 或者上口开关没闭合或者母联没有闭合
        // 手动// ATS三种状态，对于下口母联闭合的情况都是不用管的，如果中间的母联开关是闭合的话也不用自动断开
        // console.log('ATS状态为手动，需要自行闭合断开开关');
        if (last_port_switch.on && busTieTxConfig.bus_tie_switch.on) {
          // 此时ATS手动状态，并且是闭合上口开关状态，如果上口开关闭合母联闭合，则立即通电
          this.updateBusTieConfigByDirection(busTieTxxConfig);
          this.emergencyResponse1(
            [busTieTxConfig],
            children,
            left,
            true,
            this.getTrunkLineList(busTieTxConfig.bus_tie_switch),
            this.getTrunkLineList(busTieTxxConfig.last_bus_tie_switch_config.bus_tie_switch),
            thinking_opposite,
          );
        } else if (last_port_switch.on) {
          busTieTxConfig.transformer.setState(1);
        }
      } else if (ats.ATSState === 1) {
        // 自投手复
        // console.log('ATS状态为自投手复，回复供电的投切需要手动');
        if (last_port_switch.on) {
          busTieTxConfig.transformer.setState(1);
        }
      } else {
        // console.log('ATS1处于自投自复状态，等待延时闭合');
        if (busTieTxConfig.bus_tie_switch.state === 2) {
          // console.log('T3开关处于抽出位，不断开母联');
        } else {
          // console.log(
          //   `中间母联的状态：${busTieTxConfig.center_bus_tie_switch_config.bus_tie_switch}`,
          // );
          busTieTxConfig.center_bus_tie_switch_config.bus_tie_switch.onclick(false, true); // 自动断开中间的母联开关
          if (children.length > 0) {
            if (children[0].isLoad(true)) {
              // 左侧有负载
              if (busTieTxxConfig.left_line.direction) {
                // last_bust_tie_switch是左侧的
                busTieTxxConfig.last_bus_tie_switch_config.bus_tie_switch.setLoad(true);
              } else {
                // 上口的母联是右侧的
                busTieTxxConfig.port_bus_tie_switch_config.bus_tie_switch.setLoad(true);
              }
            } else if (busTieTxxConfig.left_line.direction) {
              // 左侧没有负载
              busTieTxxConfig.last_bus_tie_switch_config.bus_tie_switch.setLoad(false);
            } else {
              busTieTxxConfig.port_bus_tie_switch_config.bus_tie_switch.setLoad(false);
            }
            if (children[0].isLoad(false)) {
              // 右侧有负载
              if (busTieTxxConfig.left_line.direction) {
                // last_bust_tie_switch是左侧的
                busTieTxxConfig.port_bus_tie_switch_config.bus_tie_switch.setLoad(true);
              } else {
                // 上口的母联是右侧的
                busTieTxxConfig.last_bus_tie_switch_config.bus_tie_switch.setLoad(true);
              }
            } else if (busTieTxxConfig.left_line.direction) {
              // last_bust_tie_switch是左侧的
              busTieTxxConfig.port_bus_tie_switch_config.bus_tie_switch.setLoad(false);
            } else {
              // 上口的母联是右侧的
              busTieTxxConfig.last_bus_tie_switch_config.bus_tie_switch.setLoad(false);
            }
          }

          // 这里断开中间母联的时候，会在干路上产生电流
          if (
            busTieTxConfig.semi_power_line_list[0].state === 2 &&
            busTieTxxConfig.last_bus_tie_switch_config.port_line_list[1].state < 2
          ) {
            // 如果该母联开关的附属供电线路有电流，则回复到有压无流状态
            // console.log('附属供电线路本侧无流，并向对侧供电');
            this.updateState(busTieTxConfig.semi_power_line_list, 1); // 这里要看附属供电线路是否有其他线// 路需要供电？？？
            this.updateState(
              this.getTrunkLineList(busTieTxxConfig.last_bus_tie_switch_config.bus_tie_switch),
              1,
            );
            this.updateState(
              busTieTxxConfig.last_bus_tie_switch_config.last_branch_road_line_list,
              1,
            );
            busTieTxxConfig.last_bus_tie_switch_config.transformer.setState(1);
            this.updateState(busTieTxxConfig.last_bus_tie_switch_config.port_line_list, 1);
          } else if (busTieTxConfig.semi_power_line_list[0].state === 2) {
            this.updateState(busTieTxxConfig.last_bus_tie_switch_config.center_line_list, 1);
          }
          this.updateState(
            busTieTxConfig.port_line_list.concat(busTieTxConfig.center_line_list),
            0,
          ); // 更新母联下口导线为无压无流状态  ，被供电一侧无流
          this.setHighVoltage4Children(children, left, 0); // 下口无流
        }
        if (last_port_switch.on) {
          busTieTxConfig.transformer.setState(1);
          this.updateBusTieConfigByDirection(busTieTxxConfig);
          // let timer_key = setTimeout(this.emergencyResponse1,2000,[busTieTxConfig],this.updateState,children,left,this.setHighVoltage4Children,true,this.getTrunkLineList(busTieTxConfig.bus_tie_switch),this.getTrunkLineList(busTieTxxConfig.last_bus_tie_switch_config.bus_tie_switch),thinking_opposite);
          const timer_key = this.setTimeOut4This(
            busTieTxConfig,
            children,
            left,
            true,
            thinking_opposite,
          );
          timer_manager_list.push(timer_key);
        }
      }
    } else {
      // 断开开关
      this.updateState(busTieTxConfig.last_branch_road_line_list, 0);
      busTieTxConfig.transformer.setState(0);
      if (ats.ATSState === 2) {
        // 手动模式
        if (busTieTxConfig.bus_tie_switch.on) {
          // 下口母联是闭合状态
          this.updateState(
            busTieTxConfig.center_line_list.concat(busTieTxConfig.port_line_list),
            0,
          );
          this.setHighVoltage4Children(children, left, 0);
          // fixme 如果中间的母联开关闭合，同时需要更新对侧母联开关的下口状态
          if (busTieTxxConfig.bus_tie_switch.on) {
            // 中间的母联开关是闭合的状态
            // console.log('ats是手动的状态，中间的母联开关闭合，更新对策母联开关状态');
            this.updateState(busTieTxxConfig.port_line_list, 0);
            this.updateState(busTieTxxConfig.port_bus_tie_switch_config.port_line_list, 0);
            this.setHighVoltage4Children(children, !left, 0);
          }
        } else {
          // console.log('母联开关是断开状态，不需要更新下口母联');
        }
      } else {
        // 自投手复或者自投自复状态
        if (typeof busTieTxConfig.last_switch_list !== 'undefined') {
          busTieTxConfig.last_switch_list.forEach((obj) => (obj.load = false));
        }
        // console.log('ATS1处于自投自复或者自投手复状态，等待延时闭合');
        last_port_switch.load = false;
        if (busTieTxxConfig.bus_tie_switch.on) {
          // 判断中间的母联开关是否闭合？如果闭合那么说明另一侧的开关一定没有闭合，否则的话再执行延时闭合
          // 该侧向另外一侧供电，同时需要更新低压部分的状态
          if (
            busTieTxConfig.center_line_list[0].state >= 1 &&
            busTieTxConfig.port_line_list[1].direction ===
              busTieTxConfig.center_line_list[0].direction
          ) {
            // console.log('电压是从另一侧来的，不更新母联开关下口状态');
          } else if (busTieTxConfig.center_line_list[0].state !== 0) {
            // 要么无压，要么方向不一致
            // 方向不一致,说明该侧是电源,并且另一侧没有闭合，就没有延时闭合
            this.updateState(
              busTieTxConfig.port_line_list.concat(busTieTxConfig.center_line_list),
              0,
            );
            this.setHighVoltage4Children(children, left, 0);
            this.updateState(
              busTieTxxConfig.port_line_list.concat(busTieTxxConfig.origin_power_line_list),
              0,
            );
            this.setHighVoltage4Children(children, !left, 0);
          }
        } else {
          // 中间的母联开关是断开状态
          this.updateState(
            busTieTxConfig.port_line_list.concat(busTieTxConfig.center_line_list),
            0,
          );
          this.setHighVoltage4Children(children, left, 0);
          // fixme 1.如果整个系统都没电了，母联开关保持原来的状态
          if (
            !(
              (!this.elec1.on || !this.switch201.on) &&
              (!this.switch210.on || this.Line65.state === 0) &&
              (!this.switch220.on || this.Line66.state === 0) &&
              (!this.switch202.on || !this.elec2.on)
            )
          ) {
            busTieTxConfig.bus_tie_switch.onclick(false, true);
            if (typeof on_condition !== 'undefined' && on_condition) {
              this.updateBusTieConfig(direction, busTieTxxConfig.bus_tie_switch);
              const timer_key = setTimeout(
                this.emergencyResponse1.bind(this),
                2000,
                [busTieTxxConfig],
                children,
                left,
                true,
                this.getTrunkLineList(busTieTxxConfig.last_bus_tie_switch_config.bus_tie_switch),
              );
              // let timer_key = this.setTimeOut4This(busTieTxxConfig,children,left,true,undefined);
              if (direction) {
                // 如果是正向的，中间的母联开关就是为了right
                timer_manager_right.push(timer_key);
              } else {
                timer_manager_left.push(timer_key);
              }
            }
          } else {
            // console.log(`系统断电，没有延时闭合`);
          }
        }
      }
    }
  }; // end timer4BusTieSwitch

  // 一条线上的蚂蚱
  // opposite表示是否是通过中间的额母联开关向对侧供电，假如更新T3侧得load，如果通过T36供电得话，传递的bus_tie_switch_config参数是T6
  /**
   * TrunkRoadLineList:开关对应的干路列表
   * bus_tie_config：母联配置
   * busTieTxxConfig：中间母联的配置
   * low_voltage_children：低压子类列表
   * down2Up：true（低压传递信号，不需要通知低压）/false(是高压直接调用，需要通知低压)
   * off：true/false非表示开关的闭合状态，而是线路的通断电状态
   * opposite：true（是否是对侧）/false
   * */
  locustInALine = (
    TrunkRoadLineList,
    bus_tie_config,
    busTieTxxConfig,
    low_voltage_children,
    down2Up,
    off,
    opposite,
  ) => {
    if (typeof off !== 'undefined' && off) {
      // 该线路断电
      // 低压还需要考虑其他的group是否有load
      if (typeof down2Up !== 'undefined' && down2Up) {
        // 低压改变load负载，影响高压线路电流情况
        // 以第一组为例，如果是变化一侧向另一侧供电的话，仅改变Line45；如果是另一侧向变化一侧供电的话改变Line45,Line46,Line47；如果不供电的话，改变干路的状态
        if (bus_tie_config.bus_tie_switch.left) {
          // 左侧变化
          if (busTieTxxConfig.left_line.state === 2 && busTieTxxConfig.left_line.direction) {
            // 左侧向右侧供电，仅改变Line45
            if (busTieTxxConfig.bus_tie_switch.right_load) {
              // 说明是右侧供电，传入的是右侧的配置，左侧停掉
              this.updateState([bus_tie_config.port_line_list[1]], 1);
            } else if (busTieTxxConfig.bus_tie_switch.left_load) {
              // 说明左侧有电流，这里传入的是左侧的配置
              this.updateState(busTieTxxConfig.port_line_list, 1);
              this.updateState(busTieTxxConfig.last_branch_road_line_list, 1);
            } else {
              // 左右两侧都没有了
              this.updateState(TrunkRoadLineList, 1);
              this.updateState(bus_tie_config.last_branch_road_line_list, 1);
              bus_tie_config.transformer.setState(1);
              this.updateState(bus_tie_config.port_line_list, 1);
              this.updateState(
                busTieTxxConfig.last_branch_road_line_list.concat(busTieTxxConfig.port_line_list),
                1,
              );
            }
          } else {
            this.updateState(TrunkRoadLineList, 1);
            this.updateState(bus_tie_config.last_branch_road_line_list, 1);
            bus_tie_config.transformer.setState(1);
            this.updateState(bus_tie_config.port_line_list, 1);
          }
        } else if (busTieTxxConfig.left_line.state === 2) {
          // 右侧变化
          // 右侧向左侧供电
          if (busTieTxxConfig.bus_tie_switch.right_load) {
            // 说明是右侧供电，传入的是右侧的配置，左侧停掉
            this.updateState(busTieTxxConfig.port_line_list, 1);
            this.updateState(busTieTxxConfig.last_branch_road_line_list, 1);
          } else if (busTieTxxConfig.bus_tie_switch.left_load) {
            // 说明左侧有电流，这里传入的是左侧的配置
            this.updateState([bus_tie_config.port_line_list[1]], 1);
          } else {
            // 左右两侧都没有了
            this.updateState(TrunkRoadLineList, 1);
            this.updateState(bus_tie_config.last_branch_road_line_list, 1);
            bus_tie_config.transformer.setState(1);
            this.updateState(bus_tie_config.port_line_list, 1);
            this.updateState(
              busTieTxxConfig.last_branch_road_line_list.concat(busTieTxxConfig.port_line_list),
              1,
            );
          }
        } else {
          this.updateState(TrunkRoadLineList, 1);
          this.updateState(bus_tie_config.last_branch_road_line_list, 1);
          bus_tie_config.transformer.setState(1);
          this.updateState(bus_tie_config.port_line_list, 1);
        }
      } else {
        this.updateState(TrunkRoadLineList, 0);
        this.updateState(
          TrunkRoadLineList.filter((line) => line.left === true),
          1,
        );
        this.updateState(bus_tie_config.last_branch_road_line_list, 0);
        bus_tie_config.transformer.setState(0);
      }
    } else {
      let state = 0;
      if (bus_tie_config.last_switch_list[0].on) {
        if (bus_tie_config.bus_tie_switch.on && bus_tie_config.bus_tie_switch.state === 0) {
          state = 2;
          // console.log('上口开关闭合，母联开关闭合，母联处于工作位');
          this.updateState(TrunkRoadLineList, state);
          this.updateState(bus_tie_config.last_branch_road_line_list, state);
          // 这里port_line_list的状态需要判断一下，可能本侧没有load
          if (busTieTxxConfig.left_line.direction) {
            // 正向，传递的是左侧的母联开关，判断低压的左侧是否有负载
            if (low_voltage_children.length > 0) {
              if (low_voltage_children[0].isLoad(true)) {
                // console.log(
                //   `${bus_tie_config.bus_tie_switch.name} 是左侧的母联，低压左侧有负载，设置母联下口状态为2`,
                // );
                this.updateState(bus_tie_config.port_line_list, state);
              } else {
                // console.log(
                //   `${bus_tie_config.bus_tie_switch.name} 是左侧的母联，低压左侧没有负载，设置母联下口状态为1`,
                // );
                this.updateState(bus_tie_config.port_line_list, 1);
              }
            } else {
              this.updateState(bus_tie_config.port_line_list, 1);
            }
          } else if (low_voltage_children.length > 0) {
            if (low_voltage_children[0].isLoad(false)) {
              // console.log(
              //   `${bus_tie_config.bus_tie_switch.name} 是右侧的母联，低压右侧有负载，设置母联下口状态为2`,
              // );
              this.updateState(bus_tie_config.port_line_list, state);
            } else {
              // console.log(
              //   `${bus_tie_config.bus_tie_switch.name} 是右侧的母联，低压右侧没有负载，设置母联下口状态为1`,
              // );
              this.updateState(bus_tie_config.port_line_list, 1);
            }
          } else {
            this.updateState(bus_tie_config.port_line_list, 1);
          }
          // console.log(
          //   `${bus_tie_config.bus_tie_switch.name} 调用locust函数，设置线段${bus_tie_config.center_line_list[0].serialNum}的状态为1`,
          // );
          this.updateState(bus_tie_config.center_line_list, 1);
          bus_tie_config.transformer.setState(2);
          // 通电的情况下,若是另一侧向对侧供电
          if (typeof opposite !== 'undefined' && opposite) {
            // 向对侧供电，那么传入的母联config就是对侧的config
            this.updateState([bus_tie_config.port_line_list[0]], state);
          } else {
            this.updateState(bus_tie_config.port_line_list, state);
          }

          if (typeof busTieTxxConfig !== 'undefined' && busTieTxxConfig.bus_tie_switch.on) {
            this.updateBusTieConfigByDirection(busTieTxxConfig);
            // console.log(
            //   `${busTieTxxConfig.bus_tie_switch.name} 的原来的供电线路是：${busTieTxxConfig.origin_power_line_list[0].serialNum}`,
            // );
            this.updateState(busTieTxxConfig.origin_power_line_list, 1);
            if (busTieTxxConfig.left_line.direction) {
              // 低压母联向右侧供电，考虑母联的right_load
              if (busTieTxxConfig.bus_tie_switch.right_load) {
                // console.log(
                //   `${busTieTxxConfig.bus_tie_switch.name} 右侧有负载，上口及下口线段状态都是有压有流`,
                // );
                this.updateState(
                  busTieTxxConfig.last_branch_road_line_list.concat(busTieTxxConfig.port_line_list),
                  state,
                );
              } else {
                // console.log(
                //   `${busTieTxxConfig.bus_tie_switch.name} 右侧没有负载，上口及下口线段状态都是有压无流`,
                // );
                this.updateState(
                  busTieTxxConfig.last_branch_road_line_list.concat(busTieTxxConfig.port_line_list),
                  1,
                );
              }
            } else if (busTieTxxConfig.bus_tie_switch.left_load) {
              // console.log(
              //   `${busTieTxxConfig.bus_tie_switch.name} 左侧有负载，上口及下口线段状态都是有压有流`,
              // );
              this.updateState(
                busTieTxxConfig.last_branch_road_line_list.concat(busTieTxxConfig.port_line_list),
                state,
              );
            } else {
              // console.log(
              //   `${busTieTxxConfig.bus_tie_switch.name} 左侧没有负载，上口及下口线段状态都是有压无流`,
              // );
              this.updateState(
                busTieTxxConfig.last_branch_road_line_list.concat(busTieTxxConfig.port_line_list),
                1,
              );
            }
          }
        } else {
          state = 1;
          // console.log('母联断开或者处于抽出位');
          this.updateState(TrunkRoadLineList, state);
          this.updateState(bus_tie_config.last_branch_road_line_list, state);
          bus_tie_config.transformer.setState(1);
          // transformer.setState(1);
        }
      } else {
        // console.log('上口开关是断开状态，更新下口线路状态');
        state = 1;
        this.updateState(TrunkRoadLineList, state);
      }
    }
  };

  /**
   * 非中间母联开关的点击逻辑
   * children:子类，需要调整子类的状态
   * left:左侧或者右侧字类状态
   * timer_manager_list：影响的定时器列表
   * click_state：true(点击的是状态位开关)/false(点击的是母联开关)
   * Trunk_line_list:干路列表
   * bus_tie_config：母联开关的配置
   * busTieTxxConfig：中间母联开关的配置
   * */
  busTieSwitchClickFunc = (
    timer_manager_list,
    click_state,
    Trunk_line_list,
    bus_tie_config,
    busTieTxxConfig,
    children,
    left,
  ) => {
    /**
     * 需要判断干路导线列表和电流方向
     * */
    // console.log('busTieSwitchClickFunc');
    this.clearTimer(timer_manager_list); // 清空母联对应的定时器列表
    if (!click_state) {
      // 如果是点击到母联开关
      if (bus_tie_config.bus_tie_switch.on && bus_tie_config.bus_tie_switch.load) {
        // 如果开关是闭合的状态，并且load
        if (bus_tie_config.transformer.state >= 1) {
          // 如果上口的变压器的状态有压，那么一定形成了电流
          bus_tie_config.last_switch_list[0].setLoad(true); // 更新上口开关的load状态
          this.locustInALine(Trunk_line_list, bus_tie_config, busTieTxxConfig, children); // 更新整条线路的电流和开关的状态，其中Trunk_line_list需要根据其他线路的开关的闭合情况和发电机位置判断干路的导线列表，从上口开关以上的导线开始
          this.setHighVoltage4Children(children, left, 1);
          if (busTieTxxConfig.bus_tie_switch.on) {
            // 中间得母联开关闭合
            this.updateState(busTieTxxConfig.origin_power_line_list, 1);
            if (busTieTxxConfig.bus_tie_switch.right_load) {
              // 右侧有负载
              this.updateState(
                busTieTxxConfig.last_branch_road_line_list.concat(busTieTxxConfig.port_line_list),
                2,
              );
            }
            this.setHighVoltage4Children(children, !left, 1);
          }
        } else {
          setTimeout(this.switchTimeOut, 500, bus_tie_config.bus_tie_switch, false);
        }
      } else if (bus_tie_config.bus_tie_switch.on) {
        // 开关式闭合的但是没有负载，更新下口的线路状态没有电流
        if (bus_tie_config.transformer.state >= 1) {
          // 母联上口是有压的情况
          this.updateState(
            bus_tie_config.port_line_list.concat(bus_tie_config.center_line_list),
            1,
          );
          if (busTieTxxConfig.bus_tie_switch.on) {
            // 中间的母联开关式闭合的
            this.updateState(
              busTieTxxConfig.port_line_list.concat(busTieTxxConfig.origin_power_line_list),
              1,
            );
            this.setHighVoltage4Children(children, !left, 1); // 对侧得低压情况
          }
          this.setHighVoltage4Children(children, left, 1); // 本册得低压情况
        }
      } else {
        // 断开开关
        // 母联开关断开或者没有负载，如果是断开状态的话需要判断原来电路是否有电流，有则需要清空电流
        this.setHighVoltage4Children(children, left, 0);
        if (bus_tie_config.transformer.state >= 1) {
          this.updateState(
            bus_tie_config.port_line_list.concat(bus_tie_config.center_line_list),
            0,
          );
          if (bus_tie_config.transformer.state === 2) {
            this.updateState(Trunk_line_list, 1); // 干路导线变成有压状态
            this.updateState(bus_tie_config.last_branch_road_line_list, 1); // 上口开关下面以及母联开关前的线路状态
            bus_tie_config.transformer.setState(1); // 变压器更换成有压的状态
          }
          if (busTieTxxConfig.bus_tie_switch.on) {
            // 中间的母联开关是闭合的状态的话需要连带更新另一侧的线路状态
            this.updateState(
              busTieTxxConfig.port_line_list.concat(busTieTxxConfig.origin_power_line_list),
              0,
            );
            this.setHighVoltage4Children(children, !left, 0); // 更新另一侧的高压状态
          }
        }

        bus_tie_config.last_switch_list[0].load = false;
      }
    } else if (bus_tie_config.bus_tie_switch.state === 2) {
      // 点击到母联的状态位按钮
      // 点击到抽出位
      if (bus_tie_config.bus_tie_switch.on && bus_tie_config.transformer.state >= 1) {
        // 如果母联开关式闭合状态并且变压器的状态为有电压
        bus_tie_config.bus_tie_switch.onclick(false, false); // 断开母联开关
        bus_tie_config.transformer.setState(1); // 变压器的状态为有压无流
        this.updateState(bus_tie_config.port_line_list.concat(bus_tie_config.center_line_list), 0); // 更新变压器下口的线路状态
        this.setHighVoltage4Children(children, left, 0);
        this.updateState(Trunk_line_list, 1); // 更新干路为有压无流
        this.updateState(bus_tie_config.last_branch_road_line_list, 1);
      } else {
        bus_tie_config.bus_tie_switch.onclick(false, false); // 仅需要断开开关
      }
    } else if (bus_tie_config.bus_tie_switch.state === 1) {
      // 点击到试验位
      if (bus_tie_config.bus_tie_switch.on && bus_tie_config.transformer.state >= 1) {
        bus_tie_config.bus_tie_switch.onclick(false, false);
        bus_tie_config.transformer.setState(1);
        this.updateState(bus_tie_config.port_line_list.concat(bus_tie_config.center_line_list), 0);
        this.setHighVoltage4Children(children, left, 0);
        this.updateState(Trunk_line_list, 1);
        this.updateState(bus_tie_config.last_branch_road_line_list, 1);
        if (busTieTxxConfig.bus_tie_switch.on) {
          this.updateState(
            busTieTxxConfig.port_bus_tie_switch_config.port_line_list.concat(
              busTieTxxConfig.port_bus_tie_switch_config.center_line_list,
            ),
            0,
          );
          this.setHighVoltage4Children(children, !left, 0);
        }
      } else {
        bus_tie_config.bus_tie_switch.onclick(false, false);
      }
    } else if (bus_tie_config.bus_tie_switch.on && bus_tie_config.transformer.state >= 1) {
      // 点击到工作位
      // 如果开关是闭合的，并且变压器处有电压，点击到工作位状态就添加电流
      bus_tie_config.last_switch_list[0].setLoad(true); // 更新上口开关的load状态
      // bus_tie_config.bus_tie_switch.load = true;//更新母联的load状态
      this.updateState(
        bus_tie_config.last_branch_road_line_list
          .concat(bus_tie_config.port_line_list)
          .concat(Trunk_line_list),
        2,
      ); // 更新整条线路的电流状态
      this.setHighVoltage4Children(children, left, 1);
      this.updateState(bus_tie_config.center_line_list, 1); // 更新右侧电流状态
    }
  }; // busTieSwitchClickFunc

  /**
   * 中间母联开关的点击逻辑
   * 若低压状态依据中间母联开关的闭合与否，需要更新低压的状态
   * timer_manager_list:受影响的定时器列表
   * click_state：true(点击的是状态位)/false(点击的是开关)
   * bus_tie_config：母联开关
   * children：对应的子低压部分
   * */
  busTieSwitchClickFunc4Center = (timer_manager_list, click_state, bus_tie_config, children) => {
    let left = true; // 修改左侧的电压值
    if (bus_tie_config.left_line.direction) {
      left = false; // 修改右侧的电压值
    }
    this.clearTimer(timer_manager_list);
    if (!click_state) {
      if (bus_tie_config.bus_tie_switch.on) {
        // 中间母联开关闭合
        if (bus_tie_config.left_line.state === 1 && bus_tie_config.right_line.state === 1) {
          // alert("此时不应该闭合，前面的代码判断有误");
        } else if (
          ((bus_tie_config.left_line.state === 1 && bus_tie_config.bus_tie_switch.right_load) ||
            (bus_tie_config.right_line.state === 1 && bus_tie_config.bus_tie_switch.left_load)) &&
          bus_tie_config.bus_tie_switch.state === 0
        ) {
          // 需要考虑load的情况
          this.updateState(
            bus_tie_config.last_branch_road_line_list.concat(bus_tie_config.port_line_list),
            2,
          );
          this.updateState(bus_tie_config.origin_power_line_list, 1);
          this.setHighVoltage4Children(children, left, 1);

          const last_bus_tie_config = bus_tie_config.last_bus_tie_switch_config;
          this.updateState(this.getTrunkLineList(last_bus_tie_config.bus_tie_switch), 2);
          this.updateState(last_bus_tie_config.last_branch_road_line_list, 2);
          last_bus_tie_config.transformer.setState(2);
          this.updateState([last_bus_tie_config.port_line_list[0]], 2);
        } else if (
          (bus_tie_config.left_line.state === 1 || bus_tie_config.right_line.state === 1) &&
          bus_tie_config.bus_tie_switch.state === 0
        ) {
          // 没有负载
          this.updateState(bus_tie_config.port_line_list, 1);
          this.updateState(bus_tie_config.origin_power_line_list, 1);
          this.setHighVoltage4Children(children, left, 1);
        }
      } else if (bus_tie_config.left_line.state >= 1) {
        // 中间开关断开
        // 中间开关有电流通过的话，通过电流方向判断左侧右侧
        // 有压无流或者有压有流
        // 更新母联的load状态
        // 注意这是中间的母联开关断开的情况，只需要考虑同侧的情况
        if (bus_tie_config.left_line.state === 2) {
          if (bus_tie_config.last_bus_tie_switch_config.bus_tie_switch.left) {
            // 上口母联在左侧，判断左侧是否有负载，如果左侧有电流的话，不改变母联上口的线路状态
            for (let i = 0; i < children.length; i += 1) {
              const child = children[i];
              if (!child.isFlow(true)) {
                // 没有电流的话
                if (!child.isLoad(true)) {
                  // 没有负载
                  bus_tie_config.last_bus_tie_switch_config.bus_tie_switch.load = false;
                }
                // 更新干路的状态
                const last_bus_tie_config = bus_tie_config.last_bus_tie_switch_config;
                this.updateState(this.getTrunkLineList(last_bus_tie_config.bus_tie_switch), 1);
                this.updateState(last_bus_tie_config.last_branch_road_line_list, 1);
                last_bus_tie_config.transformer.setState(1);
                this.updateState(last_bus_tie_config.port_line_list, 1);
              }
            }
          } else {
            // 上口母联在右侧，判断右侧是否有负载，如果右侧有电流的话，不改变母联上口的线路状态
            for (let i = 0; i < children.length; i += 1) {
              const child = children[i];
              if (!child.isFlow(false)) {
                // 没有电流的话
                if (!child.isLoad(false)) {
                  // 没有负载
                  bus_tie_config.last_bus_tie_switch_config.bus_tie_switch.load = false;
                }
                // 更新干路的状态
                const last_bus_tie_config = bus_tie_config.last_bus_tie_switch_config;
                this.updateState(this.getTrunkLineList(last_bus_tie_config.bus_tie_switch), 1);
                this.updateState(last_bus_tie_config.last_branch_road_line_list, 1);
                last_bus_tie_config.transformer.setState(1);
                this.updateState(last_bus_tie_config.port_line_list, 1);
              }
            }
          }
        }
        this.updateState(bus_tie_config.last_branch_road_line_list, 1);
        this.updateState(
          bus_tie_config.port_line_list.concat(bus_tie_config.origin_power_line_list),
          0,
        );
        this.setHighVoltage4Children(children, left, 0);
      }
    } else if (
      bus_tie_config.bus_tie_switch.state === 2 ||
      bus_tie_config.bus_tie_switch.state === 1
    ) {
      // 点击状态开关
      // 试验位或者抽出位
      bus_tie_config.bus_tie_switch.onclick(false, false);
      if (bus_tie_config.left_line.state === 2) {
        // 电流方向判断左右
        if (bus_tie_config.last_bus_tie_switch_config.bus_tie_switch.left) {
          // 上口母联在左侧，判断左侧是否有负载，如果左侧有电流的话，不改变母联上口的线路状态
          for (let i = 0; i < children.length; i += 1) {
            const child = children[i];
            if (!child.isFlow(true)) {
              // 没有电流的话
              if (!child.isLoad(true)) {
                // 没有负载
                bus_tie_config.last_bus_tie_switch_config.bus_tie_switch.load = false;
              }
              // 更新干路的状态
              const last_bus_tie_config = bus_tie_config.last_bus_tie_switch_config;
              this.updateState(this.getTrunkLineList(last_bus_tie_config.bus_tie_switch), 1);
              this.updateState(last_bus_tie_config.last_branch_road_line_list, 1);
              last_bus_tie_config.transformer.setState(1);
              this.updateState(last_bus_tie_config.port_line_list, 1);
            }
          }
        } else {
          // 上口母联在右侧，判断右侧是否有负载，如果右侧有电流的话，不改变母联上口的线路状态
          for (let i = 0; i < children.length; i += 1) {
            const child = children[i];
            if (!child.isFlow(false)) {
              // 没有电流的话
              if (!child.isLoad(false)) {
                // 没有负载
                bus_tie_config.last_bus_tie_switch_config.bus_tie_switch.load = false;
              }
              // 更新干路的状态
              const last_bus_tie_config = bus_tie_config.last_bus_tie_switch_config;
              this.updateState(this.getTrunkLineList(last_bus_tie_config.bus_tie_switch), 1);
              this.updateState(last_bus_tie_config.last_branch_road_line_list, 1);
              last_bus_tie_config.transformer.setState(1);
              this.updateState(last_bus_tie_config.port_line_list, 1);
            }
          }
        }
        this.updateState(bus_tie_config.last_branch_road_line_list, 1);
        this.updateState(
          bus_tie_config.port_line_list.concat(bus_tie_config.origin_power_line_list),
          0,
        );
        this.setHighVoltage4Children(children, left, 0);
      }
    } else if (bus_tie_config.bus_tie_switch.on) {
      if (
        (bus_tie_config.left_line.state === 1 &&
          bus_tie_config.right_line.state === 0 &&
          bus_tie_config.bus_tie_switch.right_load) ||
        (bus_tie_config.left_line.state === 0 &&
          bus_tie_config.right_line.state === 1 &&
          bus_tie_config.bus_tie_switch.left_load)
      ) {
        this.updateState(
          bus_tie_config.last_branch_road_line_list.concat(bus_tie_config.port_line_list),
          2,
        );
        this.setHighVoltage4Children(children, left, 1);
        this.updateState(bus_tie_config.origin_power_line_list, 1);

        const last_bus_tie_config = bus_tie_config.last_bus_tie_switch_config;
        this.updateState(this.getTrunkLineList(last_bus_tie_config.bus_tie_switch), 2);
        this.updateState(last_bus_tie_config.last_branch_road_line_list, 2);
        last_bus_tie_config.transformer.setState(2);
        this.updateState([last_bus_tie_config.port_line_list[0]], 2);
      } else if (
        (bus_tie_config.left_line.state === 1 && bus_tie_config.right_line.state === 0) ||
        (bus_tie_config.left_line.state === 0 && bus_tie_config.right_line.state === 1)
      ) {
        this.updateState(
          bus_tie_config.last_branch_road_line_list.concat(bus_tie_config.port_line_list),
          1,
        );
        this.setHighVoltage4Children(children, left, 1);
        this.updateState(bus_tie_config.origin_power_line_list, 1);
      }
    }
  };

  /**
   * 仅中间的母联开关调用该函数
   * */
  updateBusTieConfigByDirection = (bus_tie_config) => {
    // if (bus_tie_config.left_line.state === 1 && bus_tie_config.right_line.state === 1) {
    //   console.log();
    // } else if (bus_tie_config.left_line.state === 1) {
    //   this.updateBusTieConfig(true, bus_tie_config.bus_tie_switch);
    // } else if (bus_tie_config.right_line.state === 1) {
    //   this.updateBusTieConfig(false, bus_tie_config.bus_tie_switch);
    // }
    if (!(bus_tie_config.left_line.state === 1 && bus_tie_config.right_line.state === 1)) {
      if (bus_tie_config.left_line.state === 1) {
        this.updateBusTieConfig(true, bus_tie_config.bus_tie_switch);
      } else if (bus_tie_config.right_line.state === 1) {
        this.updateBusTieConfig(false, bus_tie_config.bus_tie_switch);
      }
    }
  };

  /**
   * left:左侧or右侧
   * closed:闭合or断开
   * 闭合201或者202开关调用该函数
   * 市电1，2断电，来电
   * */
  startTimer = (left, closed) => {
    if (left) {
      if (closed) {
        this.timer4BusTieSwitch(
          true,
          true,
          this.ATS1,
          this.switch212,
          this.busTieT3Config,
          this.busTieT36Config,
          this.timerList4T3,
          this.timerList4T36_left,
          this.timerList4T36_right,
          this.children4T36,
          undefined,
          undefined,
          true,
        );
        this.timer4BusTieSwitch(
          true,
          true,
          this.ATS2,
          this.switch213,
          this.busTieT5Config,
          this.busTieT45Config,
          this.timerList4T5,
          this.timerList4T45_left,
          this.timerList4T45_right,
          this.children4T45,
          undefined,
          undefined,
          true,
        );
        this.timer4BusTieSwitch(
          true,
          true,
          this.ATS3,
          this.switch214,
          this.busTieT7Config,
          this.busTieT78Config,
          this.timerList4T7,
          this.timerList4T78_left,
          this.timerList4T78_right,
          this.children4T78,
          undefined,
          undefined,
          true,
        );
      } else {
        this.timer4BusTieSwitch(
          false,
          true,
          this.ATS1,
          this.switch212,
          this.busTieT3Config,
          this.busTieT36Config,
          this.timerList4T3,
          this.timerList4T36_left,
          this.timerList4T36_right,
          this.children4T36,
          this.Line44.state >= 1 && !this.switch245.on,
          false,
        ); // 右侧能够通过中间母联向左供电的情况就是44导线的状态>=1//需要判断这里供电的条件是否可以并且245开关不能闭合，如果闭合说明对侧的电流是由本侧线路供应的
        this.timer4BusTieSwitch(
          false,
          true,
          this.ATS2,
          this.switch213,
          this.busTieT5Config,
          this.busTieT45Config,
          this.timerList4T5,
          this.timerList4T45_left,
          this.timerList4T45_right,
          this.children4T45,
          this.Line54.state >= 1 && !this.switch245.on,
          false,
        ); // 右侧能够通过中间母联向左供电的情况就是44导线的状态>=1
        this.timer4BusTieSwitch(
          false,
          true,
          this.ATS3,
          this.switch214,
          this.busTieT7Config,
          this.busTieT78Config,
          this.timerList4T7,
          this.timerList4T78_left,
          this.timerList4T78_right,
          this.children4T78,
          this.Line60.state >= 1 && !this.switch245.on,
          false,
        ); // 右侧能够通过中间母联向左供电的情况就是44导线的状态>=1
      }
    } else if (closed) {
      this.timer4BusTieSwitch(
        true,
        false,
        this.ATS1,
        this.switch226,
        this.busTieT6Config,
        this.busTieT36Config,
        this.timerList4T6,
        this.timerList4T36_left,
        this.timerList4T36_right,
        this.children4T36,
        undefined,
        undefined,
        true,
      );
      this.timer4BusTieSwitch(
        true,
        false,
        this.ATS2,
        this.switch227,
        this.busTieT4Config,
        this.busTieT45Config,
        this.timerList4T4,
        this.timerList4T45_left,
        this.timerList4T45_right,
        this.children4T45,
        undefined,
        undefined,
        true,
      );
      this.timer4BusTieSwitch(
        true,
        false,
        this.ATS3,
        this.switch228,
        this.busTieT8Config,
        this.busTieT78Config,
        this.timerList4T8,
        this.timerList4T78_left,
        this.timerList4T78_right,
        this.children4T78,
        undefined,
        undefined,
        true,
      );
    } else {
      this.timer4BusTieSwitch(
        false,
        false,
        this.ATS1,
        this.switch226,
        this.busTieT6Config,
        this.busTieT36Config,
        this.timerList4T6,
        this.timerList4T36_left,
        this.timerList4T36_right,
        this.children4T36,
        this.Line43.state >= 1 && !this.switch245.on,
        true,
      );
      this.timer4BusTieSwitch(
        false,
        false,
        this.ATS2,
        this.switch227,
        this.busTieT4Config,
        this.busTieT45Config,
        this.timerList4T4,
        this.timerList4T45_left,
        this.timerList4T45_right,
        this.children4T45,
        this.Line53.state >= 1 && !this.switch245.on,
        true,
      );
      this.timer4BusTieSwitch(
        false,
        false,
        this.ATS3,
        this.switch228,
        this.busTieT8Config,
        this.busTieT78Config,
        this.timerList4T8,
        this.timerList4T78_left,
        this.timerList4T78_right,
        this.children4T78,
        this.Line59.state >= 1 && !this.switch245.on,
        true,
      );
    }
  };

  /**
   * 获取干路列表函数，根据市电和柴发的情况判断电流的来源以及需要改变状态的线段列表，
   * 也就是说这里获取的干路列表是不全的，只获得需要改变的干路列表
   * 开关212和T3的列表相同，开关226和T6的干路列表相同，其他同理
   * */
  getTrunkLineList = (switch_obj) => {
    const trunk_road_line_list = [];
    if (switch_obj instanceof BusTieSwitch || switch_obj instanceof Switch) {
      switch (switch_obj.name) {
        case '212':
        case 'AA-T3-01':
          trunk_road_line_list.push(this.Line25);
          if (this.switch201.on && this.elec1.on) {
            // console.log('电流来自201');
            if (this.Line9.state === 2) {
              // 有电流
              // console.log('245闭合，并且右侧供电,干路不需要改变其他线段');
            } else if (this.Line24.state === 2) {
              // console.log('213有电流，干路不需要改变其他线段');
              trunk_road_line_list.push(this.Line8);
            } else if (this.Line23.state === 2) {
              // console.log('213没有电流，214有电流');
              trunk_road_line_list.push(...[this.Line7, this.Line8]);
            } else {
              // console.log('213,214，市电2一侧都没有电流，');
              trunk_road_line_list.push(
                ...[
                  this.Line1,
                  this.Line2,
                  this.Line3,
                  this.Line4,
                  this.Line5,
                  this.Line6,
                  this.Line7,
                  this.Line8,
                ],
              );
            }
            trunk_road_line_list.forEach((line) => line.setDirection(true));
          } else if (this.switch210.on && this.Line65.state >= 1) {
            // console.log('电流来自柴发');
            if (this.Line9.state === 2) {
              // console.log('通过柴发向右供电，不需要改变干路电流状态');
            } else {
              trunk_road_line_list.push(this.Line8);
              this.Line8.setDirection(true);
              if (!(this.Line24.state === 2 || this.Line23.state === 2)) {
                // 左侧都没有电流的话，需要更新柴发下口的导线状态
                trunk_road_line_list.push(
                  this.Line67,
                  this.Line65,
                  this.diesel.Line11,
                  this.diesel.Line7,
                  this.diesel.Line8,
                );
              }
            }
          } else if (this.switch245.on && this.Line10.state >= 1) {
            if (this.switch220.on && this.Line66.state >= 1) {
              // console.log('电流来自柴发2');
              if (this.Line24.state === 2 || this.Line23.state === 2) {
                // console.log('213或者214有电流，不需要更改其他');
              } else {
                [this.Line9, this.Line26, this.Line10, this.Line34, this.Line11].forEach((line) =>
                  line.setDirection(false),
                );
                this.Line27.setDirection(true);
                trunk_road_line_list.push(
                  ...[this.Line9, this.Line26, this.Line10, this.Line27, this.Line34, this.Line11],
                );
                if (this.Line28.state === 2) {
                  // console.log('226有电流');
                } else {
                  this.Line12.setDirection(false);
                  trunk_road_line_list.push(this.Line12);
                  if (!(this.Line29.state === 2 || this.Line30.state === 2)) {
                    trunk_road_line_list.push(
                      this.Line68,
                      this.Line66,
                      this.diesel.Line12,
                      this.diesel.Line9,
                      this.diesel.Line10,
                    );
                  }
                }
              }
            } else if (this.switch202.on && this.elec2.on) {
              // console.log('电流来自市电2');
              if (this.Line24.state === 2 || this.Line23.state === 2) {
                // console.log('213或者214有电流，不需要更改其他');
              } else {
                [this.Line9, this.Line26, this.Line10, this.Line34, this.Line11].forEach((line) =>
                  line.setDirection(false),
                );
                this.Line27.setDirection(true);
                trunk_road_line_list.push(
                  ...[this.Line9, this.Line26, this.Line10, this.Line27, this.Line34, this.Line11],
                );
                if (this.Line28.state === 2) {
                  // console.log('226有电流');
                } else if (this.Line29.state === 2) {
                  this.Line12.setDirection(false);
                  trunk_road_line_list.push(this.Line12);
                } else if (this.Line30.state === 2) {
                  [this.Line12, this.Line13].forEach((line) => line.setDirection(false));
                  trunk_road_line_list.push(...[this.Line12, this.Line13]);
                } else {
                  [
                    this.Line12,
                    this.Line13,
                    this.Line14,
                    this.Line15,
                    this.Line16,
                    this.Line17,
                  ].forEach((line) => line.setDirection(false));
                  trunk_road_line_list.push(
                    ...[
                      this.Line12,
                      this.Line13,
                      this.Line14,
                      this.Line15,
                      this.Line16,
                      this.Line17,
                      this.Line18,
                      this.Line19,
                    ],
                  );
                }
              }
            }
          }
          break;
        case '213':
        case 'AA-T5-01':
          trunk_road_line_list.push(this.Line24);
          if (this.switch201.on && this.elec1.on) {
            // console.log('电流来自市电1');
            if (this.Line9.state === 2 || this.Line25.state === 2) {
              // console.log('市电1向右侧或者212供电');
            } else if (this.Line23.state === 2) {
              // 214供电
              trunk_road_line_list.push(this.Line7);
            } else {
              trunk_road_line_list.push(
                ...[
                  this.Line1,
                  this.Line2,
                  this.Line3,
                  this.Line4,
                  this.Line5,
                  this.Line6,
                  this.Line7,
                ],
              );
            }
            trunk_road_line_list.forEach((line) => line.setDirection(true));
          } else if (this.switch210.on && this.Line65.state >= 1) {
            // console.log('电流来自柴发1');
            if (!(this.Line23.state === 2 || this.Line25.state === 2)) {
              // 212，214都没有电流
              trunk_road_line_list.push(
                this.Line67,
                this.Line65,
                this.diesel.Line11,
                this.diesel.Line7,
                this.diesel.Line8,
              );
            }
          } else if (this.switch245.on && this.Line10.state >= 1) {
            if (this.switch220.on && this.Line66.state >= 1) {
              // console.log('电流来自右侧柴发2');
              if (this.Line23.state !== 2) {
                // 214没有电流
                this.Line8.setDirection(false);
                trunk_road_line_list.push(this.Line8);
                if (this.Line25.state !== 2) {
                  // 212没有电流
                  [this.Line9, this.Line26, this.Line10, this.Line34, this.Line11].forEach((line) =>
                    line.setDirection(false),
                  );
                  this.Line27.setDirection(true);
                  trunk_road_line_list.push(
                    ...[
                      this.Line9,
                      this.Line26,
                      this.Line10,
                      this.Line27,
                      this.Line34,
                      this.Line11,
                    ],
                  );
                  if (this.Line28.state !== 2) {
                    // 226没有电流
                    this.Line12.setDirection(false);
                    trunk_road_line_list.push(this.Line12);
                    if (this.Line29.state !== 2 && this.Line30.state !== 2) {
                      // 227，228都没有电流
                      trunk_road_line_list.push(
                        this.Line68,
                        this.Line66,
                        this.diesel.Line12,
                        this.diesel.Line9,
                        this.diesel.Line10,
                      );
                    }
                  }
                }
              }
            } else if (this.switch202.on && this.elec2.on) {
              // console.log('电流来自市电2');
              if (!(this.Line23.state === 2)) {
                this.Line8.setDirection(false);
                trunk_road_line_list.push(this.Line8);
                if (!(this.Line25.state === 2)) {
                  [this.Line9, this.Line26, this.Line10, this.Line34, this.Line11].forEach((line) =>
                    line.setDirection(false),
                  );
                  this.Line27.setDirection(true);
                  trunk_road_line_list.push(
                    ...[
                      this.Line9,
                      this.Line26,
                      this.Line10,
                      this.Line27,
                      this.Line34,
                      this.Line11,
                    ],
                  );
                  if (!(this.Line28.state === 2)) {
                    this.Line12.setDirection(false);
                    trunk_road_line_list.push(this.Line12);
                    if (!(this.Line29.state === 2)) {
                      this.Line13.setDirection(false);
                      trunk_road_line_list.push(this.Line13);
                      if (!(this.Line30.state === 2)) {
                        [this.Line14, this.Line15, this.Line16, this.Line17].forEach((line) =>
                          line.setDirection(false),
                        );
                        trunk_road_line_list.push(
                          ...[
                            this.Line14,
                            this.Line15,
                            this.Line16,
                            this.Line17,
                            this.Line18,
                            this.Line19,
                          ],
                        );
                      }
                    }
                  }
                }
              }
            }
          }
          break;
        case '214':
        case 'AA-T7-01':
          trunk_road_line_list.push(this.Line23);
          if (this.switch201.on && this.elec1.on) {
            // console.log('电流来自市电1');
            if (!(this.Line9.state === 2 || this.Line24.state === 2 || this.Line25.state === 2)) {
              [this.Line3, this.Line4, this.Line5, this.Line6].forEach((line) =>
                line.setDirection(true),
              );
              trunk_road_line_list.push(
                ...[this.Line1, this.Line2, this.Line3, this.Line4, this.Line5, this.Line6],
              );
            }
          } else if (this.switch210.on && this.Line65.state >= 1) {
            // console.log('电流来自柴发1');
            this.Line7.setDirection(false);
            trunk_road_line_list.push(this.Line7);
            if (!(this.Line24.state === 2 || this.Line25.state === 2)) {
              trunk_road_line_list.push(
                this.Line67,
                this.Line65,
                this.diesel.Line11,
                this.diesel.Line7,
                this.diesel.Line8,
              );
            }
          } else if (this.switch245.on && this.Line10.state >= 1) {
            if (this.switch220.on && this.Line66.state >= 1) {
              // console.log('电流来自右侧柴发2');
              this.Line7.setDirection(false);
              trunk_road_line_list.push(this.Line7);
              if (!(this.Line24.state === 2)) {
                this.Line8.setDirection(false);
                trunk_road_line_list.push(this.Line8);
                if (!(this.Line25.state === 2)) {
                  [this.Line9, this.Line26, this.Line10, this.Line34, this.Line11].forEach((line) =>
                    line.setDirection(false),
                  );
                  this.Line27.setDirection(true);
                  trunk_road_line_list.push(
                    ...[
                      this.Line9,
                      this.Line26,
                      this.Line10,
                      this.Line27,
                      this.Line34,
                      this.Line11,
                    ],
                  );
                  if (!(this.Line28.state === 2)) {
                    this.Line12.setDirection(false);
                    trunk_road_line_list.push(this.Line12);
                    if (!(this.Line29.state === 2 || this.Line30.state === 2)) {
                      trunk_road_line_list.push(this.Line66);
                    }
                  }
                }
              }
            } else if (this.switch202.on && this.elec2.on) {
              // console.log('电流来自市电2');
              this.Line7.setDirection(false);
              trunk_road_line_list.push(this.Line7);
              if (!(this.Line24.state === 2)) {
                this.Line8.setDirection(false);
                trunk_road_line_list.push(this.Line8);
                if (!(this.Line25.state === 2)) {
                  [this.Line9, this.Line26, this.Line10, this.Line34, this.Line11].forEach((line) =>
                    line.setDirection(false),
                  );
                  this.Line27.setDirection(true);
                  trunk_road_line_list.push(
                    ...[
                      this.Line9,
                      this.Line26,
                      this.Line10,
                      this.Line27,
                      this.Line34,
                      this.Line11,
                    ],
                  );
                  if (!(this.Line28.state === 2)) {
                    this.Line12.setDirection(false);
                    trunk_road_line_list.push(this.Line12);
                    if (!(this.Line29.state === 2)) {
                      this.Line13.setDirection(false);
                      trunk_road_line_list.push(this.Line13);
                      if (!(this.Line30.state === 2)) {
                        [this.Line14, this.Line15, this.Line16, this.Line17].forEach((line) =>
                          line.setDirection(false),
                        );
                        trunk_road_line_list.push(
                          ...[
                            this.Line14,
                            this.Line15,
                            this.Line16,
                            this.Line17,
                            this.Line18,
                            this.Line19,
                          ],
                        );
                      }
                    }
                  }
                }
              }
            }
          }
          break;
        case '226':
        case 'AA-T6-01':
          trunk_road_line_list.push(this.Line28);
          if (this.switch202.on && this.elec2.on) {
            // console.log('电流来自市电2');
            if (this.Line9.state === 2) {
              // console.log('向左侧供电，不添加其他影响');
            } else {
              this.Line12.setDirection(false);
              trunk_road_line_list.push(this.Line12);
              if (!(this.Line29.state === 2)) {
                this.Line13.setDirection(false);
                trunk_road_line_list.push(this.Line13);
                if (!(this.Line30.state === 2)) {
                  [this.Line14, this.Line15, this.Line16, this.Line17].forEach((line) =>
                    line.setDirection(false),
                  );
                  trunk_road_line_list.push(
                    ...[
                      this.Line14,
                      this.Line15,
                      this.Line16,
                      this.Line17,
                      this.Line18,
                      this.Line19,
                    ],
                  );
                }
              }
            }
          } else if (this.switch220.on && this.Line66.state >= 1) {
            // console.log('电流来自柴发2');
            if (!(this.Line9.state === 2)) {
              this.Line12.setDirection(false);
              trunk_road_line_list.push(this.Line12);
              if (!(this.Line29.state === 2 || this.Line30.state === 2)) {
                trunk_road_line_list.push(
                  this.Line68,
                  this.Line66,
                  this.diesel.Line12,
                  this.diesel.Line9,
                  this.diesel.Line10,
                );
              }
            }
          } else if (this.switch245.on && this.Line9.state >= 1) {
            if (this.switch210.on && this.Line65.state >= 1) {
              // console.log('电流来自左侧柴发1');
              if (!(this.Line29.state === 2 || this.Line30.state === 2)) {
                [this.Line9, this.Line26, this.Line10, this.Line34, this.Line11].forEach((line) =>
                  line.setDirection(true),
                );
                this.Line27.setDirection(false);
                trunk_road_line_list.push(
                  ...[this.Line11, this.Line10, this.Line9, this.Line26, this.Line27, this.Line34],
                );
                if (!(this.Line25.state === 2)) {
                  this.Line8.setDirection(true);
                  trunk_road_line_list.push(this.Line8);
                  if (!(this.Line24.state === 2 || this.Line23.state === 2)) {
                    trunk_road_line_list.push(
                      this.Line67,
                      this.Line65,
                      this.diesel.Line11,
                      this.diesel.Line7,
                      this.diesel.Line8,
                    );
                  }
                }
              }
            } else if (this.switch201.on && this.elec1.on) {
              // console.log('电流来自市电1');
              if (!(this.Line29.state === 2 || this.Line30.state === 2)) {
                [this.Line9, this.Line26, this.Line10, this.Line34, this.Line11].forEach((line) =>
                  line.setDirection(true),
                );
                this.Line27.setDirection(false);
                trunk_road_line_list.push(
                  ...[this.Line11, this.Line10, this.Line9, this.Line26, this.Line27, this.Line34],
                );
                if (!(this.Line25.state === 2)) {
                  this.Line8.setDirection(true);
                  trunk_road_line_list.push(this.Line8);
                  if (!(this.Line24.state === 2)) {
                    this.Line7.setDirection(true);
                    trunk_road_line_list.push(this.Line7);
                    if (!(this.Line23.state === 2)) {
                      [
                        this.Line1,
                        this.Line2,
                        this.Line3,
                        this.Line4,
                        this.Line5,
                        this.Line6,
                      ].forEach((line) => line.setDirection(true));
                      trunk_road_line_list.push(
                        this.Line1,
                        this.Line2,
                        this.Line3,
                        this.Line4,
                        this.Line5,
                        this.Line6,
                      );
                    }
                  }
                }
              }
            }
          }
          break;
        case '227':
        case 'AA-T4-01':
          trunk_road_line_list.push(this.Line29);
          if (this.switch202.on && this.elec2.on) {
            // console.log('电流来自市电2');
            if (!(this.Line9.state === 2 || this.Line28.state === 2)) {
              this.Line13.setDirection(false);
              trunk_road_line_list.push(this.Line13);
              if (!(this.Line30.state === 2)) {
                [this.Line14, this.Line15, this.Line16, this.Line17].forEach((line) =>
                  line.setDirection(false),
                );
                trunk_road_line_list.push(
                  ...[this.Line14, this.Line15, this.Line16, this.Line17, this.Line18, this.Line19],
                );
              }
            }
          } else if (this.switch220.on && this.Line66.state >= 1) {
            // console.log('电流来自柴发2');
            if (!(this.Line9.state === 2 || this.Line28.state === 2 || this.Line30.state === 2)) {
              trunk_road_line_list.push(
                this.Line68,
                this.Line66,
                this.diesel.Line12,
                this.diesel.Line9,
                this.diesel.Line10,
              );
            }
          } else if (this.switch245.on && this.Line9.state >= 1) {
            if (this.switch210.on && this.Line65.state >= 1) {
              // console.log('电流来自左侧柴发1');
              if (!(this.Line30.state === 2)) {
                this.Line12.setDirection(true);
                trunk_road_line_list.push(this.Line12);
                if (!(this.Line28.state === 2)) {
                  [this.Line9, this.Line26, this.Line10, this.Line34, this.Line11].forEach((line) =>
                    line.setDirection(true),
                  );
                  this.Line27.setDirection(false);
                  trunk_road_line_list.push(
                    ...[
                      this.Line11,
                      this.Line10,
                      this.Line9,
                      this.Line26,
                      this.Line27,
                      this.Line34,
                    ],
                  );
                  if (!(this.Line25.state === 2)) {
                    this.Line8.setDirection(true);
                    trunk_road_line_list.push(this.Line8);
                    if (!(this.Line24.state === 2 || this.Line23.state === 2)) {
                      trunk_road_line_list.push(
                        this.Line67,
                        this.Line65,
                        this.diesel.Line11,
                        this.diesel.Line7,
                        this.diesel.Line8,
                      );
                    }
                  }
                }
              }
            } else if (this.switch201.on && this.elec1.on) {
              // console.log('电流来自市电1');
              if (!(this.Line30.state === 2)) {
                this.Line12.setDirection(true);
                trunk_road_line_list.push(this.Line12);
                if (!(this.Line28.state === 2)) {
                  [this.Line9, this.Line26, this.Line10, this.Line34, this.Line11].forEach((line) =>
                    line.setDirection(true),
                  );
                  this.Line27.setDirection(false);
                  trunk_road_line_list.push(
                    ...[
                      this.Line11,
                      this.Line10,
                      this.Line9,
                      this.Line26,
                      this.Line27,
                      this.Line34,
                    ],
                  );
                  if (!(this.Line25.state === 2)) {
                    this.Line8.setDirection(true);
                    trunk_road_line_list.push(this.Line8);
                    if (!(this.Line24.state === 2)) {
                      this.Line7.setDirection(true);
                      trunk_road_line_list.push(this.Line7);
                      if (!(this.Line23.state === 2)) {
                        [
                          this.Line1,
                          this.Line2,
                          this.Line3,
                          this.Line4,
                          this.Line5,
                          this.Line6,
                        ].forEach((line) => line.setDirection(true));
                        trunk_road_line_list.push(
                          ...[
                            this.Line1,
                            this.Line2,
                            this.Line3,
                            this.Line4,
                            this.Line5,
                            this.Line6,
                          ],
                        );
                      }
                    }
                  }
                }
              }
            }
          }

          break;
        case '228':
        case 'AA-T8-01':
          trunk_road_line_list.push(this.Line30);
          if (this.switch202.on && this.elec2.on) {
            // console.log('电流来自市电2');
            if (!(this.Line9.state === 2 || this.Line28.state === 2 || this.Line29.state === 2)) {
              [this.Line14, this.Line15, this.Line16, this.Line17].forEach((line) =>
                line.setDirection(false),
              );
              trunk_road_line_list.push(
                ...[this.Line14, this.Line15, this.Line16, this.Line17, this.Line18, this.Line19],
              );
            }
          } else if (this.switch220.on && this.Line66.state >= 1) {
            // console.log('电流来自柴发2');
            this.Line13.setDirection(true);
            trunk_road_line_list.push(this.Line13);
            if (!(this.Line9.state === 2 || this.Line28.state === 2 || this.Line29.state === 2)) {
              trunk_road_line_list.push(
                this.Line68,
                this.Line66,
                this.diesel.Line12,
                this.diesel.Line9,
                this.diesel.Line10,
              );
            }
          } else if (this.switch245.on && this.Line9.state >= 1) {
            if (this.switch210.on && this.Line65.state >= 1) {
              // console.log('电流来自左侧柴发1');
              this.Line13.setDirection(true);
              trunk_road_line_list.push(this.Line13);
              if (!(this.Line29.state === 2)) {
                this.Line12.setDirection(true);
                trunk_road_line_list.push(this.Line12);
                if (!(this.Line28.state === 2)) {
                  [this.Line9, this.Line26, this.Line10, this.Line34, this.Line11].forEach((line) =>
                    line.setDirection(true),
                  );
                  this.Line27.setDirection(false);
                  trunk_road_line_list.push(
                    ...[
                      this.Line11,
                      this.Line10,
                      this.Line26,
                      this.Line27,
                      this.Line34,
                      this.Line9,
                    ],
                  );
                  if (!(this.Line25.state === 2)) {
                    this.Line8.setDirection(true);
                    trunk_road_line_list.push(this.Line8);
                    if (!(this.Line24.state === 2 || this.Line23.state === 2)) {
                      trunk_road_line_list.push(
                        this.Line67,
                        this.Line65,
                        this.diesel.Line11,
                        this.diesel.Line7,
                        this.diesel.Line8,
                      );
                    }
                  }
                }
              }
            } else if (this.switch201.on && this.elec1.on) {
              // console.log('电流来自市电1');
              this.Line13.setDirection(true);
              trunk_road_line_list.push(this.Line13);
              if (!(this.Line29.state === 2)) {
                this.Line12.setDirection(true);
                trunk_road_line_list.push(this.Line12);
                if (!(this.Line28.state === 2)) {
                  [this.Line9, this.Line26, this.Line10, this.Line34, this.Line11].forEach((line) =>
                    line.setDirection(true),
                  );
                  this.Line27.setDirection(false);
                  trunk_road_line_list.push(
                    ...[
                      this.Line11,
                      this.Line10,
                      this.Line26,
                      this.Line27,
                      this.Line34,
                      this.Line9,
                    ],
                  );
                  if (!(this.Line25.state === 2)) {
                    this.Line8.setDirection(true);
                    trunk_road_line_list.push(this.Line8);
                    if (!(this.Line24.state === 2)) {
                      this.Line7.setDirection(true);
                      trunk_road_line_list.push(this.Line7);
                      if (!(this.Line23.state === 2)) {
                        [
                          this.Line1,
                          this.Line2,
                          this.Line3,
                          this.Line4,
                          this.Line5,
                          this.Line6,
                        ].forEach((line) => line.setDirection(true));
                        trunk_road_line_list.push(
                          ...[
                            this.Line1,
                            this.Line2,
                            this.Line3,
                            this.Line4,
                            this.Line5,
                            this.Line6,
                          ],
                        );
                      }
                    }
                  }
                }
              }
            }
          }
          break;
        default:
        // console.error(`该开关没有注册过${switch_obj.name}`);
      }
    }
    return trunk_road_line_list;
  };

  /**
   * 判断是否通过245开关供电
   * switch_obj:201,202,210,220
   * elecOn:true/false
   * trunk_line_group：this.TrunkLineGroup1/this.trunkLineGroup2
   * busTieTxList:母联开关列表
   * childrenList：低压子类
   * timerList：中间母联的定时器列表
   * */
  judge245 = (elecOn, switch_obj, trunk_line_group, busTieTxList, childrenList, timerList) => {
    // timerList.forEach(timer => clearTimeout(timer));
    if (this.switch245.on && switch_obj.on && elecOn) {
      // 245闭合，201或者202闭合市电有电
      this.clearTimer(timerList);
      this.updateState(trunk_line_group, 1);
      for (let i = 0; i < busTieTxList.length; i += 1) {
        if (
          busTieTxList[i].last_switch_list[0].on &&
          busTieTxList[i].bus_tie_switch.on &&
          busTieTxList[i].bus_tie_switch.load
        ) {
          // 有电流
          // console.log(`judge245函数，母联${busTieTxList[i].bus_tie_switch.name}有压有流的状态`);
          this.updateState(this.getTrunkLineList(busTieTxList[i].last_switch_list[0]), 2);
          this.updateState(
            busTieTxList[i].last_branch_road_line_list.concat(busTieTxList[i].port_line_list),
            2,
          );
          busTieTxList[i].transformer.setState(2);
          this.updateState(busTieTxList[i].center_line_list, 1);
          this.setHighVoltage4Children(childrenList[i], busTieTxList[i].bus_tie_switch.left, 1);
        } else if (busTieTxList[i].last_switch_list[0].on && busTieTxList[i].bus_tie_switch.on) {
          // 有压无流
          // console.log(
          //   `judge245函数，母联${busTieTxList[i].bus_tie_switch.name}有压无流的状态，且母联闭合`,
          // );
          this.updateState(this.getTrunkLineList(busTieTxList[i].last_switch_list[0]), 1);
          this.updateState(busTieTxList[i].last_branch_road_line_list, 1);
          busTieTxList[i].transformer.setState(1);
          this.updateState(busTieTxList[i].port_line_list, 1);
          this.updateState(busTieTxList[i].center_line_list, 1);
          this.setHighVoltage4Children(childrenList[i], busTieTxList[i].bus_tie_switch.left, 1);
        } else if (busTieTxList[i].last_switch_list[0].on) {
          // 仅上口开关有压
          // console.log(
          //   `触发judge245函数：${busTieTxList[i].bus_tie_switch.name}仅仅上口开关闭合且有压，须等待延时闭合`,
          // );
          this.clearTimer(this.getCenterBusTieSwitchTimeList(busTieTxList[i]));
          const timer_key = this.setTimeOut4This(
            busTieTxList[i],
            childrenList[i],
            busTieTxList[i].bus_tie_switch.left,
            true,
            true,
          );
          this.getCenterBusTieSwitchTimerList4Opposite(busTieTxList[i]).push(timer_key);
          this.updateState(this.getTrunkLineList(busTieTxList[i].last_switch_list[0]), 1);
          this.updateState(busTieTxList[i].last_branch_road_line_list, 1);
          busTieTxList[i].transformer.setState(1);
        }
      }
    } else if (this.switch245.on && (!switch_obj.on || !elecOn)) {
      // 245闭合，201或者202断开
      this.clearTimer(timerList);
      this.updateState(trunk_line_group, 0);
      for (let i = 0; i < busTieTxList.length; i += 1) {
        this.updateState(
          busTieTxList[i].last_branch_road_line_list
            .concat(busTieTxList[i].port_line_list)
            .concat(busTieTxList[i].center_line_list),
          0,
        );
        busTieTxList[i].transformer.setState(0);
        this.setHighVoltage4Children(childrenList[i], busTieTxList[i].bus_tie_switch.left, 0);
      }
    }
  };
  /**
   * 获取245开关的干路列表
   * left:true(电流来自左侧)/false(电流来自右侧)
   * */
  getTrunkLineList245 = (left) => {
    let list = [];
    if (left) {
      if (this.switch201.on) {
        // 电流来自201开关
        if (this.Line25.state === 2) {
          [this.Line9, this.Line26].forEach((line) => line.setDirection(true));
          list = [this.Line9, this.Line26];
        } else if (this.Line24.state === 2) {
          [this.Line8, this.Line9, this.Line26].forEach((line) => line.setDirection(true));
          list = [this.Line8, this.Line9, this.Line26];
        } else if (this.Line23.state === 2) {
          [this.Line7, this.Line8, this.Line9, this.Line26].forEach((line) =>
            line.setDirection(true),
          );
          list = [this.Line7, this.Line8, this.Line9, this.Line26];
        } else {
          [
            this.Line1,
            this.Line2,
            this.Line3,
            this.Line4,
            this.Line5,
            this.Line6,
            this.Line7,
            this.Line8,
            this.Line9,
            this.Line26,
          ].forEach((line) => line.setDirection(true));
          list = [
            this.Line1,
            this.Line2,
            this.Line3,
            this.Line4,
            this.Line5,
            this.Line6,
            this.Line7,
            this.Line8,
            this.Line9,
            this.Line26,
          ];
        }
      } else if (this.switch210.on) {
        if (this.Line25.state === 2) {
          [this.Line9, this.Line26].forEach((line) => line.setDirection(true));
          list = [this.Line9, this.Line26];
        } else if (this.Line24.state === 2 || this.Line23.state === 2) {
          [this.Line8, this.Line9, this.Line26].forEach((line) => line.setDirection(true));
          list = [this.Line8, this.Line9, this.Line26];
        } else {
          list = [
            this.Line8,
            this.Line9,
            this.Line26,
            this.Line67,
            this.Line65,
            this.diesel.Line11,
            this.diesel.Line7,
            this.diesel.Line8,
          ];
        }
      }
    } else if (this.switch202.on) {
      // right
      if (this.Line28.state === 2) {
        [this.Line10, this.Line11, this.Line34].forEach((line) => line.setDirection(false));
        this.Line27.setDirection(true);
        list = [this.Line27, this.Line10, this.Line11, this.Line34];
      } else if (this.Line29.state === 2) {
        [this.Line10, this.Line11, this.Line12, this.Line34].forEach((line) =>
          line.setDirection(false),
        );
        this.Line27.setDirection(true);
        list = [this.Line27, this.Line10, this.Line11, this.Line12, this.Line34];
      } else if (this.Line30.state === 2) {
        [this.Line10, this.Line11, this.Line12, this.Line13, this.Line34].forEach((line) =>
          line.setDirection(false),
        );
        this.Line27.setDirection(true);
        list = [this.Line27, this.Line10, this.Line11, this.Line12, this.Line13, this.Line34];
      } else {
        [
          this.Line10,
          this.Line11,
          this.Line12,
          this.Line13,
          this.Line14,
          this.Line15,
          this.Line16,
          this.Line17,
          this.Line34,
        ].forEach((line) => line.setDirection(false));
        this.Line27.setDirection(true);
        list = [
          this.Line27,
          this.Line10,
          this.Line11,
          this.Line12,
          this.Line13,
          this.Line34,
          this.Line14,
          this.Line15,
          this.Line16,
          this.Line17,
          this.Line18,
          this.Line19,
        ];
      }
    } else if (this.switch220.on) {
      if (this.Line28.state === 2) {
        [this.Line10, this.Line11, this.Line34].forEach((line) => line.setDirection(false));
        this.Line27.setDirection(true);
        list = [this.Line27, this.Line10, this.Line11, this.Line34];
      } else if (this.Line29.state === 2 || this.Line30.state === 2) {
        [this.Line10, this.Line11, this.Line12, this.Line34].forEach((line) =>
          line.setDirection(false),
        );
        this.Line27.setDirection(true);
        list = [this.Line27, this.Line10, this.Line11, this.Line12, this.Line34];
      } else {
        [this.Line10, this.Line11, this.Line12, this.Line34].forEach((line) =>
          line.setDirection(false),
        );
        this.Line27.setDirection(true);
        list = [
          this.Line27,
          this.Line10,
          this.Line11,
          this.Line12,
          this.Line34,
          this.Line68,
          this.Line66,
          this.diesel.Line12,
          this.diesel.Line9,
          this.diesel.Line10,
        ];
      }
    }
    return list;
  };

  /**
   * judge245函数调用，用户找到母联对应的中间开关的定时器列表，比如是左侧的T3母联触发该函数，那么得到的定时器列表是timerList4T36_right
   * */
  getCenterBusTieSwitchTimerList4Opposite = (busTieSwitchTx) => {
    switch (busTieSwitchTx.name) {
      case 'AA-T6-01':
        return this.timerList4T36_left;
      case 'AA-T3-01':
        return this.timerList4T36_right;
      case 'AA-T4-01':
        return this.timerList4T45_left;
      case 'AA-T5-01':
        return this.timerList4T45_right;
      case 'AA-T7-01':
        return this.timerList4T78_right;
      case 'AA-T8-01':
        return this.timerList4T78_left;
      default:
        // console.error(
        //   `getCenterBusTieSwitchTimeList没有匹配的母联 开关，输入的母联是：${busTieSwitchTx.bus_tie_switch.name}`,
        // );
        return [];
    }
  };

  getCenterBusTieSwitchTimeList = (busTieSwitchTx) => {
    switch (busTieSwitchTx.name) {
      case 'AA-T6-01':
        return this.timerList4T36_right;
      case 'AA-T3-01':
        return this.timerList4T36_left;
      case 'AA-T4-01':
        return this.timerList4T45_right;
      case 'AA-T5-01':
        return this.timerList4T45_left;
      case 'AA-T7-01':
        return this.timerList4T78_left;
      case 'AA-T8-01':
        return this.timerList4T78_right;
      default:
        // console.error(
        //   `getCenterBusTieSwitchTimeList没有匹配的母联 开关，输入的母联是：${busTieSwitchTx.bus_tie_switch.name}`,
        // );
        return [];
    }
  };

  trigger4201 = () => {
    [this.Line9, this.Line7, this.Line8].forEach((obj) => (obj.direction = true));
    if (this.elec1.on && this.switch201.on && this.switch201.load) {
      // 市电有电、闭合开关、有负载,闭合开关201时由于母联是延时闭合，所以干路状态暂时是1
      this.updateState([this.Line1], 1);
      this.updateState(this.trunck_line_group1, 1); // 更新干路的状态
      this.startTimer(true, true); // 直接的变化和定时变化都调用该函数，左侧，闭合，有负载的时候才需要调用
    } else if (this.elec1.on && this.switch201.on) {
      // 市电有电、闭合开关、没有负载
      this.updateState([this.Line1], 1);
      this.updateState(this.trunck_line_group1, 1);
    } else if (this.elec1.on) {
      // 市电有电、断开开关
      this.updateState([this.Line1], 1);
      this.updateState(this.trunck_line_group1, 0);
      this.startTimer(true, false); // 左侧断开开关
    } else if (this.switch201.on) {
      // 市电无电
      this.updateState([this.Line1], 0);
      this.updateState(this.trunck_line_group1, 0); // 更新干路的状态
      this.startTimer(true, false); // 左侧断开开关
    } else {
      this.updateState([this.Line1], 0);
    }
    this.judge245(
      this.elec1.on,
      this.switch201,
      this.trunck_line_group2,
      [this.busTieT8Config, this.busTieT4Config, this.busTieT6Config],
      [this.children4T78, this.children4T45, this.children4T36],
      this.timerList4T8.concat(this.timerList4T4).concat(this.timerList4T6),
    );
  };

  trigger4202 = () => {
    if (this.elec2.on && this.switch202.on && this.switch202.load) {
      // 市电有电、闭合开关、有负载
      this.updateState([this.Line19], 1);
      [this.Line12, this.Line13].forEach((line) => (line.direction = false));
      this.updateState(this.trunck_line_group2, 1);
      this.startTimer(false, true);
    } else if (this.elec2.on && this.switch202.on) {
      // 市电有电、闭合开关、没有负载
      this.updateState([this.Line19], 1);
      [this.Line12, this.Line13].forEach((line) => (line.direction = false));
      this.updateState(
        [this.Line18, this.Line17, this.Line16, this.Line15, this.Line14, this.Line68],
        1,
      );
    } else if (this.elec2.on) {
      // 市电有电、断开开关
      this.updateState([this.Line19], 1);
      this.updateState(this.trunck_line_group2, 0);
      this.startTimer(false, false);
    } else if (this.switch202.on) {
      this.updateState([this.Line19], 0);
      this.updateState(this.trunck_line_group2, 0); // 更新干路的状态
      this.startTimer(false, false); // 左侧断开开关
    } else {
      this.updateState([this.Line19], 0);
    }
    this.judge245(
      this.elec2.on,
      this.switch202,
      this.trunck_line_group1,
      [this.busTieT7Config, this.busTieT5Config, this.busTieT3Config],
      [this.children4T78, this.children4T45, this.children4T36],
      this.timerList4T3.concat(this.timerList4T5).concat(this.timerList4T7),
    );
  };

  trigger = (switch_obj, click_state) => {
    if (switch_obj instanceof Switch) {
      switch (switch_obj.config.svgUid) {
        case '201':
          cancelTripTime();
          this.trigger4201();
          break;
        case '202':
          cancelTripTime();
          this.trigger4202();
          break;
        case '210':
          cancelTripTime();
          if (switch_obj.on && this.Line65.state >= 1) {
            this.updateState(this.trunck_line_group1, 1);
            this.startTimer(true, true);
          } else if (!switch_obj.on && this.Line65.state >= 1) {
            // 断开开关
            this.startTimer(true, false);
            this.updateState(this.trunck_line_group1, 0);
            if (this.Line65.state === 2) {
              this.diesel.updateLineState(
                [this.diesel.Line7, this.diesel.Line8, this.diesel.Line11, this.Line65],
                1,
              );
            }
          } else if (switch_obj.on) {
            // 65==0，开关是闭合的
            this.startTimer(true, false);
            this.updateState(this.trunck_line_group1, 0);
          }
          this.judge245(
            this.Line67.state >= 1,
            this.switch210,
            this.trunck_line_group2,
            [this.busTieT8Config, this.busTieT4Config, this.busTieT6Config],
            [this.children4T78, this.children4T45, this.children4T36],
            this.timerList4T8.concat(this.timerList4T4).concat(this.timerList4T6),
          );
          break;
        case '220':
          cancelTripTime();
          if (switch_obj.on && this.Line66.state >= 1) {
            this.updateState(this.trunck_line_group2, 1);
            this.startTimer(false, true);
          } else if (!switch_obj.on && this.Line66.state >= 1) {
            // 断开开关
            this.startTimer(false, false);
            this.updateState(this.trunck_line_group2, 0);
            if (this.Line66.state === 2) {
              this.diesel.updateLineState(
                [this.diesel.Line9, this.diesel.Line10, this.diesel.Line12, this.Line66],
                1,
              );
            }
          } else if (switch_obj.on) {
            this.startTimer(false, false);
            this.updateState(this.trunck_line_group2, 0);
          }
          this.judge245(
            this.Line68.state >= 1,
            this.switch220,
            this.trunck_line_group1,
            [this.busTieT7Config, this.busTieT5Config, this.busTieT3Config],
            [this.children4T78, this.children4T45, this.children4T36],
            this.timerList4T3.concat(this.timerList4T5).concat(this.timerList4T7),
          );

          break;
        case '212':
          this.updateBusTieConfig(true, this.BusTieSwitchT3, this.getTrunkLineList(switch_obj));
          if (switch_obj.on) {
            // 闭合212开关
            if (this.Line25.state >= 1) {
              // 说明干路是有压状态
              this.timer4BusTieSwitch(
                true,
                true,
                this.ATS1,
                this.switch212,
                this.busTieT3Config,
                this.busTieT36Config,
                this.timerList4T3,
                this.timerList4T36_left,
                this.timerList4T36_right,
                this.children4T36,
                undefined,
                undefined,
                true,
              );
            }
          } else {
            // 断开212开关
            if (this.busTieT3Config.trunk_road_line_list[0].state > 1) {
              this.updateState(this.busTieT3Config.trunk_road_line_list, 1);
            }
            this.timer4BusTieSwitch(
              false,
              true,
              this.ATS1,
              this.switch212,
              this.busTieT3Config,
              this.busTieT36Config,
              this.timerList4T3,
              this.timerList4T36_left,
              this.timerList4T36_right,
              this.children4T36,
              this.Line44.state >= 1,
              false,
            );
          }
          break;
        case '226':
          this.updateBusTieConfig(true, this.BusTieSwitchT6, this.getTrunkLineList(switch_obj));
          if (switch_obj.on) {
            // 闭合226开关
            if (this.Line28.state >= 1) {
              // 201开关闭合并且市电有电,并且开关212有负载
              this.timer4BusTieSwitch(
                true,
                false,
                this.ATS1,
                this.switch226,
                this.busTieT6Config,
                this.busTieT36Config,
                this.timerList4T6,
                this.timerList4T36_left,
                this.timerList4T36_right,
                this.children4T36,
                undefined,
                undefined,
                true,
              ); // 该函数的调用需要放在下面，
            }
          } else {
            // 断开212开关
            if (this.busTieT6Config.trunk_road_line_list[0].state > 1) {
              this.updateState(this.busTieT6Config.trunk_road_line_list, 1);
            }
            this.timer4BusTieSwitch(
              false,
              false,
              this.ATS1,
              this.switch226,
              this.busTieT6Config,
              this.busTieT36Config,
              this.timerList4T6,
              this.timerList4T36_left,
              this.timerList4T36_right,
              this.children4T36,
              this.Line43.state >= 1,
              true,
            );
          }
          break;

        case '213':
          this.updateBusTieConfig(true, this.BusTieSwitchT5, this.getTrunkLineList(switch_obj));
          if (switch_obj.on) {
            // 闭合状态
            if (this.Line24.state >= 1) {
              this.timer4BusTieSwitch(
                true,
                true,
                this.ATS2,
                this.switch213,
                this.busTieT5Config,
                this.busTieT45Config,
                this.timerList4T5,
                this.timerList4T45_left,
                this.timerList4T45_right,
                this.children4T45,
                undefined,
                undefined,
                true,
              );
            }
          } else {
            if (this.busTieT5Config.trunk_road_line_list[0].state > 1) {
              this.updateState(this.busTieT5Config.trunk_road_line_list, 1);
            }
            this.timer4BusTieSwitch(
              false,
              true,
              this.ATS2,
              this.switch213,
              this.busTieT5Config,
              this.busTieT45Config,
              this.timerList4T5,
              this.timerList4T45_left,
              this.timerList4T45_right,
              this.children4T45,
              this.Line44.state >= 1,
              false,
            );
          }
          break;
        case '227':
          this.updateBusTieConfig(true, this.BusTieSwitchT4, this.getTrunkLineList(switch_obj));
          if (switch_obj.on) {
            // 闭合226开关
            if (this.Line29.state >= 1) {
              // 201开关闭合并且市电有电,并且开关212有负载
              this.timer4BusTieSwitch(
                true,
                false,
                this.ATS2,
                this.switch227,
                this.busTieT4Config,
                this.busTieT45Config,
                this.timerList4T4,
                this.timerList4T45_left,
                this.timerList4T45_right,
                this.children4T45,
                undefined,
                undefined,
                true,
              );
            }
          } else {
            // 断开212开关
            if (this.busTieT4Config.trunk_road_line_list[0].state > 1) {
              this.updateState(this.busTieT4Config.trunk_road_line_list, 1);
            }
            this.timer4BusTieSwitch(
              false,
              false,
              this.ATS2,
              this.switch227,
              this.busTieT4Config,
              this.busTieT45Config,
              this.timerList4T4,
              this.timerList4T45_left,
              this.timerList4T45_right,
              this.children4T45,
              this.Line53.state >= 1,
              true,
            );
          }
          break;
        case '214':
          this.updateBusTieConfig(true, this.BusTieSwitchT7, this.getTrunkLineList(switch_obj));
          if (switch_obj.on) {
            // 闭合状态
            if (this.Line23.state >= 1) {
              this.timer4BusTieSwitch(
                true,
                true,
                this.ATS3,
                this.switch214,
                this.busTieT7Config,
                this.busTieT78Config,
                this.timerList4T7,
                this.timerList4T78_left,
                this.timerList4T78_right,
                this.children4T78,
                undefined,
                undefined,
                true,
              );
            }
          } else {
            if (this.busTieT7Config.trunk_road_line_list[0].state > 1) {
              this.updateState(this.busTieT7Config.trunk_road_line_list, 1);
            }

            this.timer4BusTieSwitch(
              false,
              true,
              this.ATS3,
              this.switch214,
              this.busTieT7Config,
              this.busTieT78Config,
              this.timerList4T7,
              this.timerList4T78_left,
              this.timerList4T78_right,
              this.children4T78,
              this.Line60.state > 0,
              false,
            );
          }
          break;
        case '228':
          this.updateBusTieConfig(true, this.BusTieSwitchT8, this.getTrunkLineList(switch_obj));
          if (switch_obj.on) {
            // 闭合226开关
            if (this.Line30.state >= 1) {
              // 201开关闭合并且市电有电,并且开关212有负载
              this.timer4BusTieSwitch(
                true,
                false,
                this.ATS3,
                this.switch228,
                this.busTieT8Config,
                this.busTieT78Config,
                this.timerList4T8,
                this.timerList4T78_left,
                this.timerList4T78_right,
                this.children4T78,
                undefined,
                undefined,
                true,
              );
            }
          } else {
            // 断开212开关
            if (this.busTieT8Config.trunk_road_line_list[0].state > 1) {
              this.updateState(this.busTieT8Config.trunk_road_line_list, 1);
            }
            this.timer4BusTieSwitch(
              false,
              false,
              this.ATS3,
              this.switch228,
              this.busTieT8Config,
              this.busTieT78Config,
              this.timerList4T8,
              this.timerList4T78_left,
              this.timerList4T78_right,
              this.children4T78,
              this.Line59.state > 0,
              true,
            );
          }
          break;
        case '245':
          if (switch_obj.on) {
            // 如果中间的母联闭合的话，要断开中间的母联，延时闭合下口的母联
            if (this.Line26.state === 1 && this.Line34.state === 0) {
              // console.log('左侧有电流，调整右侧');
              // 这里需要考虑左侧供电部分的电流，以及中间的母联是否闭合
              [
                this.Line9,
                this.Line26,
                this.Line34,
                this.Line10,
                this.Line11,
                this.Line12,
                this.Line13,
              ].forEach((obj) => (obj.direction = true));
              this.Line27.direction = false;
              this.updateState(this.trunck_line_group2, 1);
              this.timer4BusTieSwitch(
                true,
                false,
                this.ATS1,
                this.switch226,
                this.busTieT6Config,
                this.busTieT36Config,
                this.timerList4T6,
                this.timerList4T36_left,
                this.timerList4T36_right,
                this.children4T36,
                undefined,
                undefined,
                true,
              );
              this.timer4BusTieSwitch(
                true,
                false,
                this.ATS2,
                this.switch227,
                this.busTieT4Config,
                this.busTieT45Config,
                this.timerList4T4,
                this.timerList4T45_left,
                this.timerList4T45_right,
                this.children4T45,
                undefined,
                undefined,
                true,
              );
              this.timer4BusTieSwitch(
                true,
                false,
                this.ATS3,
                this.switch228,
                this.busTieT8Config,
                this.busTieT78Config,
                this.timerList4T8,
                this.timerList4T78_left,
                this.timerList4T78_right,
                this.children4T78,
                undefined,
                undefined,
                true,
              );
            } else if (this.Line26.state === 0 && this.Line34.state === 1) {
              // console.log('右侧有电流，调整左侧');
              [
                this.Line11,
                this.Line10,
                this.Line34,
                this.Line26,
                this.Line9,
                this.Line8,
                this.Line7,
              ].forEach((line) => (line.direction = false));
              [this.Line27].forEach((line) => (line.direction = true));
              this.updateState(this.trunck_line_group1, 1);
              this.timer4BusTieSwitch(
                true,
                true,
                this.ATS1,
                this.switch212,
                this.busTieT3Config,
                this.busTieT36Config,
                this.timerList4T3,
                this.timerList4T36_left,
                this.timerList4T36_right,
                this.children4T36,
                undefined,
                undefined,
                true,
              );
              this.timer4BusTieSwitch(
                true,
                true,
                this.ATS2,
                this.switch213,
                this.busTieT5Config,
                this.busTieT45Config,
                this.timerList4T5,
                this.timerList4T45_left,
                this.timerList4T45_right,
                this.children4T45,
                undefined,
                undefined,
                true,
              );
              this.timer4BusTieSwitch(
                true,
                true,
                this.ATS3,
                this.switch214,
                this.busTieT7Config,
                this.busTieT78Config,
                this.timerList4T7,
                this.timerList4T78_left,
                this.timerList4T78_right,
                this.children4T78,
                undefined,
                undefined,
                true,
              );
            } else if (this.Line26.state === 1 && this.Line34.state === 1) {
              // console.error('此时245不能闭合，前面互斥判断有误');
            } else {
              // console.log('245开关两侧都没有电，点击没有其他反应');
            }
          } else if ((this.switch201.on || this.switch210.on) && this.Line10.state >= 1) {
            // 电流来自左侧
            // 要判断左侧的干路电流是否还存在
            this.updateState(this.trunck_line_group2, 0);
            this.updateState(this.getTrunkLineList245(true), 1);
            // this.updateState([this.Line28,this.Line38,this.Line42,this.Line44,this.Line47,this.Line48],0,canvas);
            this.transformer2.setState(0);
            // this.updateState([this.Line29,this.Line39,this.Line50,this.Line54,this.Line57,this.Line58],0,canvas);
            this.transformer4.setState(0);
            // this.updateState([this.Line30,this.Line40,this.Line52,this.Line60,this.Line63,this.Line64],0,canvas);
            this.transformer6.setState(0);
            // this.timer4T6(false);
            this.timer4BusTieSwitch(
              false,
              false,
              this.ATS1,
              this.switch226,
              this.busTieT6Config,
              this.busTieT36Config,
              this.timerList4T6,
              this.timerList4T36_left,
              this.timerList4T36_right,
              this.children4T36,
              this.Line43.state >= 1,
              true,
            );
            this.timer4BusTieSwitch(
              false,
              false,
              this.ATS2,
              this.switch227,
              this.busTieT4Config,
              this.busTieT45Config,
              this.timerList4T4,
              this.timerList4T45_left,
              this.timerList4T45_right,
              this.children4T45,
              this.Line53.state >= 1,
              true,
            );
            this.timer4BusTieSwitch(
              false,
              false,
              this.ATS3,
              this.switch228,
              this.busTieT8Config,
              this.busTieT78Config,
              this.timerList4T8,
              this.timerList4T78_left,
              this.timerList4T78_right,
              this.children4T78,
              this.Line59.state >= 1,
              true,
            );
          } else if ((this.switch202.on || this.switch220.on) && this.Line9.state >= 1) {
            // 要判断右侧的干路电流是否还存在
            // console.log('电流来自右侧');
            this.updateState(this.getTrunkLineList245(false), 1);
            this.updateState(this.trunck_line_group1, 0);
            this.transformer5.setState(0);
            // this.updateState([this.Line24,this.Line36,this.Line49,this.Line53,this.Line55,this.Line56],0,canvas);
            this.transformer3.setState(0);
            // this.updateState([this.Line25,this.Line37,this.Line41,this.Line43,this.Line45,this.Line46],0,canvas);
            this.transformer1.setState(0);
            // this.timer4T3(false);
            this.timer4BusTieSwitch(
              false,
              true,
              this.ATS1,
              this.switch212,
              this.busTieT3Config,
              this.busTieT36Config,
              this.timerList4T3,
              this.timerList4T36_left,
              this.timerList4T36_right,
              this.children4T36,
              this.Line44.state >= 1,
              false,
            );
            this.timer4BusTieSwitch(
              false,
              true,
              this.ATS2,
              this.switch227,
              this.busTieT5Config,
              this.busTieT45Config,
              this.timerList4T5,
              this.timerList4T45_left,
              this.timerList4T45_right,
              this.children4T45,
              this.Line55.state >= 1,
              false,
            );
            this.timer4BusTieSwitch(
              false,
              true,
              this.ATS3,
              this.switch228,
              this.busTieT7Config,
              this.busTieT78Config,
              this.timerList4T7,
              this.timerList4T78_left,
              this.timerList4T78_right,
              this.children4T78,
              this.Line60.state >= 1,
              false,
            );
          } else {
            // console.log('245开关已经断开，之前是错误状态');
          }
          break;
        default:
        // console.log(switch_obj);
      }
    } else if (switch_obj instanceof ATS) {
      // 点击到的是ATS
      switch (switch_obj.svgUid) {
        case 'ATS1':
          this.clearTimer(this.timerList4T36_right);
          this.clearTimer(this.timerList4T36_left);
          this.clearTimer(this.timerList4T6);
          this.clearTimer(this.timerList4T3);
          break;
        case 'ATS2':
          this.clearTimer(this.timerList4T45_right);
          this.clearTimer(this.timerList4T45_left);
          this.clearTimer(this.timerList4T5);
          this.clearTimer(this.timerList4T4);
          break;
        case 'ATS3':
          this.clearTimer(this.timerList4T78_right);
          this.clearTimer(this.timerList4T78_left);
          this.clearTimer(this.timerList4T7);
          this.clearTimer(this.timerList4T8);
          break;
        default:
        // console.error(`点击到未能识别的ATS${switch_obj.svgUid}`);
      }
    } else if (switch_obj instanceof BusTieSwitch) {
      // 母联开关
      switch (switch_obj.name) {
        case 'AA-T3-01':
          this.busTieSwitchClickFunc(
            this.timerList4T3,
            typeof click_state !== 'undefined',
            this.getTrunkLineList(switch_obj),
            this.busTieT3Config,
            this.busTieT36Config,
            this.children4T36,
            true,
          );
          break;
        case 'AA-T6-01':
          this.busTieSwitchClickFunc(
            this.timerList4T6,
            typeof click_state !== 'undefined',
            this.getTrunkLineList(switch_obj),
            this.busTieT6Config,
            this.busTieT36Config,
            this.children4T36,
            false,
          );
          break;
        case 'AA-T5-01':
          this.busTieSwitchClickFunc(
            this.timerList4T5,
            typeof click_state !== 'undefined',
            this.getTrunkLineList(switch_obj),
            this.busTieT5Config,
            this.busTieT45Config,
            this.children4T45,
            true,
          );
          break;
        case 'AA-T4-01':
          this.busTieSwitchClickFunc(
            this.timerList4T4,
            typeof click_state !== 'undefined',
            this.getTrunkLineList(switch_obj),
            this.busTieT4Config,
            this.busTieT45Config,
            this.children4T45,
            false,
          );
          break;
        case 'AA-T7-01':
          this.busTieSwitchClickFunc(
            this.timerList4T7,
            typeof click_state !== 'undefined',
            this.getTrunkLineList(switch_obj),
            this.busTieT7Config,
            this.busTieT78Config,
            this.children4T78,
            true,
          );
          break;
        case 'AA-T8-01':
          this.busTieSwitchClickFunc(
            this.timerList4T8,
            typeof click_state !== 'undefined',
            this.getTrunkLineList(switch_obj),
            this.busTieT8Config,
            this.busTieT78Config,
            this.children4T78,
            false,
          );
          break;
        case 'AA-T36-01':
          this.updateBusTieConfigByDirection(this.busTieT36Config);
          this.busTieSwitchClickFunc4Center(
            this.timerList4T3
              .concat(this.timerList4T6)
              .concat(this.timerList4T36_right)
              .concat(this.timerList4T36_left),
            typeof click_state !== 'undefined',
            this.busTieT36Config,
            this.children4T36,
          );
          break;
        case 'AA-T45-01':
          this.updateBusTieConfigByDirection(this.busTieT45Config);
          this.busTieSwitchClickFunc4Center(
            this.timerList4T4
              .concat(this.timerList4T5)
              .concat(this.timerList4T45_right)
              .concat(this.timerList4T45_left),
            typeof click_state !== 'undefined',
            this.busTieT45Config,
            this.children4T45,
          );
          break;
        case 'AA-T78-01':
          this.updateBusTieConfigByDirection(this.busTieT78Config);
          this.busTieSwitchClickFunc4Center(
            this.timerList4T7
              .concat(this.timerList4T8)
              .concat(this.timerList4T78_right)
              .concat(this.timerList4T78_left),
            typeof click_state !== 'undefined',
            this.busTieT78Config,
            this.children4T78,
          );
          break;
        default:
        // console.error(`点击到未能识别的母联开关${switch_obj.name}`);
      }
    }
  };

  /**
   * 低压3合2互斥函数
   * */
  clickPermission = (obj) => {
    /**
     * 如果是要断开某个开关不必请求这函数
     * 只有闭合开关的时候才会判断是否满足3合2的条件
     * 母联开关闭合的互斥条件
     * */
    let permission = true;
    switch (obj.name) {
      case 'AA-T36-01':
      case 'AA-T3-01':
      case 'AA-T6-01':
        if (
          (this.BusTieSwitchT3.on && this.BusTieSwitchT6.on) ||
          (this.BusTieSwitchT3.on && this.BusTieSwitchT36.on) ||
          (this.BusTieSwitchT6.on && this.BusTieSwitchT36.on)
        ) {
          permission = false;
        }

        break;
      case 'AA-T4-01':
      case 'AA-T5-01':
      case 'AA-T45-01':
        if (
          (this.BusTieSwitchT4.on && this.BusTieSwitchT5.on) ||
          (this.BusTieSwitchT4.on && this.BusTieSwitchT45.on) ||
          (this.BusTieSwitchT5.on && this.BusTieSwitchT45.on)
        ) {
          permission = false;
        }
        break;
      case 'AA-T7-01':
      case 'AA-T8-01':
      case 'AA-T78-01':
        if (
          (this.BusTieSwitchT7.on && this.BusTieSwitchT8.on) ||
          (this.BusTieSwitchT7.on && this.BusTieSwitchT78.on) ||
          (this.BusTieSwitchT8.on && this.BusTieSwitchT78.on)
        ) {
          permission = false;
        }
        break;
      default:
      // console.error(`点击到的母联开关没有被检测到${obj.name}`);
    }
    return permission;
  };

  /**
   * 高压5合2互斥函数
   * */
  clickPermission4HighVoltage = (obj) => {
    let permission = true;
    switch (obj.name) {
      case '201':
        if (
          // this.Line65.state === 1 &&
          this.switch210.on ||
          (this.switch202.on && this.switch245.on) ||
          (this.switch220.on && this.switch245.on)
        ) {
          permission = false;
        }
        break;
      case '202':
        if (
          // this.Line66.state === 1 &&
          this.switch220.on ||
          (this.switch201.on && this.switch245.on) ||
          (this.switch210.on && this.switch245.on)
        ) {
          permission = false;
        }
        break;
      case '210':
        if (
          // this.Line65.state === 1 &&
          this.switch201.on ||
          (this.switch220.on && this.switch245.on) ||
          (this.switch202.on && this.switch245.on)
        ) {
          permission = false;
        }
        break;
      case '220':
        if (
          // this.Line66.state === 1 &&
          this.switch202.on ||
          (this.switch210.on && this.switch245.on) ||
          (this.switch201.on && this.switch245.on)
        ) {
          permission = false;
        }
        break;
      case '245':
        if (
          this.switch201.on &&
          // && this.elec1.on
          this.switch202.on
          // && this.elec2.on
        ) {
          permission = false;
        }
        if (
          this.switch210.on &&
          // && this.Line65.state === 1
          this.switch220.on
          // && this.Line66.state === 1
        ) {
          permission = false;
        }
        if ((this.switch201.on && this.switch220.on) || (this.switch202.on && this.switch210.on)) {
          permission = false;
        }
        break;
      default:
      // console.log(`点击到的开关不需要被检查${obj.name}`);
    }
    return permission;
  };

  onclick = (e, diesel) => {
    let flag = false; // 是否有点击到开关
    for (let i = 0; i < this.switchGroup.length; i += 1) {
      if (this.switchGroup[i].isPointInPath(e.evt.layerX, e.evt.layerY)) {
        // console.log(`点击到某个开关：${this.switchGroup[i].config.svgUid}`);
        let permission = true;
        if (!this.switchGroup[i].on) {
          permission = this.clickPermission4HighVoltage(this.switchGroup[i]);
        }
        if (permission) {
          this.switchGroup[i].onclick();
          this.trigger(this.switchGroup[i]);
        } else {
          this.props.trigger4Notice(`此时禁止闭合${this.switchGroup[i].name}开关`);
        }
        flag = true;
        break;
      }
    }
    if (!flag) {
      // 没有点击到普通开关
      let flag1 = false;
      for (let i = 0; i < this.busTieGroup.length; i += 1) {
        // 点击母联开关
        if (this.busTieGroup[i].isPointInPath(e.evt.layerX, e.evt.layerY)) {
          // console.log(`点击到某个母联开关：${this.busTieGroup[i].name}`);
          if (this.busTieGroup[i].state === 2) {
            this.props.trigger4Notice(`开关${this.busTieGroup[i].name}处于抽出位，闭合开关无效`);
          } else if (this.busTieGroup[i].state === 1) {
            this.props.trigger4Notice(`开关${this.busTieGroup[i].name}处于试验位，闭合开关无效`);
          } else {
            let permission = true;
            if (!this.busTieGroup[i].on) {
              permission = this.clickPermission(this.busTieGroup[i]);
            }
            if (permission) {
              this.busTieGroup[i].onclick(undefined, false);
              this.trigger(this.busTieGroup[i]);
            } else {
              this.props.trigger4Notice('此时不能闭合开关');
            }
          }
          flag1 = true;
          break;
        }
        if (this.busTieGroup[i].isPointInStateRect(e.evt.layerX, e.evt.layerY)) {
          // console.log(`点击到母联开关对应的状态：${this.busTieGroup[i].name}`);
          this.busTieGroup[i].stateRectClick();
          this.trigger(this.busTieGroup[i], true);
          flag1 = true;
          break;
        }
      }
      if (!flag1) {
        // 没有点击到母联开关
        let flag2 = false;
        for (let i = 0; i < this.ATSGroup.length; i += 1) {
          // 点击ATS
          if (this.ATSGroup[i].isPointInPath(e.evt.layerX, e.evt.layerY)) {
            // console.log(`点击到某个ATS：${this.ATSGroup[i].svgUid}`);
            const images = this.parentLayer.find('Image');
            for (let j = 0; j < images.length; j += 1) {
              if (images[j].attrs.svgUid === this.ATSGroup[i].svgUid) {
                images[j].remove();
                // canvas.remove(images[j]);
                this.ATSGroup[i].onclick();
                flag2 = true;
                this.uploadATSUnit(
                  this.ATSGroup[i].ATSState,
                  this.ATSGroup[i].getConfig(),
                  this.ATSGroup[i].svgUid,
                );
                this.trigger(this.ATSGroup[i]);
                break;
              }
            }
            break;
          }
        }
        if (!flag2) {
          // 没有点击到ATS开关，判断是否点击到市电开关
          // if (this.faultSimulation) { 市电操作
          if (true) {
            // if (this.menu.trouble) {
            // 支持故障模拟
            if (this.elec1.isPointInPath(e.evt.layerX, e.evt.layerY)) {
              // 点击到市电1，市电1部分断电，判断市电2是否断电，如果市电2断电，那么启动柴发
              this.elec1.onclick(e.evt.layerX, e.evt.layerY);
              if (!this.isDieselDischarge(true)) {
                // 不是柴发供电
                this.trigger4201();
              } else if (this.elec1.on) {
                this.updateState([this.Line1], 1);
              } else {
                this.updateState([this.Line1], 0);
              }
              if (!this.elec1.on && !this.elec2.on && !diesel.working) {
                diesel.start();
              } else if (this.elec1.on && this.elec2.on && diesel.working) {
                diesel.shutdown();
              }
            } else if (this.elec2.isPointInPath(e.evt.layerX, e.evt.layerY)) {
              this.elec2.onclick(e.evt.layerX, e.evt.layerY);
              if (!this.isDieselDischarge(false)) {
                // 柴发供电
                this.trigger4202();
              } else if (this.elec2.on) {
                this.updateState([this.Line19], 1);
              } else {
                this.updateState([this.Line19], 0);
              }
              if (!this.elec1.on && !this.elec2.on && !diesel.working) {
                diesel.start();
              } else if (this.elec1.on && this.elec2.on && diesel.working) {
                diesel.shutdown();
              }
            }
          }
        }
      }
    }
  };

  /** 判断是否是柴发供电 */
  isDieselDischarge = (left) => {
    if (left) {
      // 市电1
      return (
        (this.Line65.state >= 1 && this.switch210.on) ||
        (this.switch245.on && this.switch220.on && this.Line66.state >= 1)
      ); // 柴发供电
    }
    return (
      (this.Line66.state >= 1 && this.switch220.on) ||
      (this.switch245.on && this.switch210.on && this.Line65.state >= 1)
    ); // 柴发供电
  };

  /**
   * 更新线段的位置
   * */
  update = () => {
    for (let i = 0; i < this.lineGroup.length; i += 1) {
      if (this.lineGroup[i].state === 2) {
        // 有电流的状态
        this.lineGroup[i].flow();
      }
    }
  };

  destroy1 = () => {
    [
      this.Line36,
      this.Line39,
      this.Line49,
      this.Line50,
      this.Line53,
      this.Line54,
      this.Line55,
      this.Line56,
      this.Line57,
      this.Line58,
      this.Line35,
      this.Line40,
      this.Line51,
      this.Line52,
      this.Line59,
      this.Line60,
      this.Line61,
      this.Line62,
      this.Line63,
      this.Line64,
    ].forEach((line) => line.destroy());
    [this.transformer3, this.transformer4, this.transformer5, this.transformer6].forEach((trans) =>
      trans.destroy(),
    );
    [
      this.BusTieSwitchT45,
      this.BusTieSwitchT78,
      this.BusTieSwitchT5,
      this.BusTieSwitchT4,
      this.BusTieSwitchT7,
      this.BusTieSwitchT8,
    ].forEach((bus_tie) => bus_tie.destroy());
    const images = this.parentLayer.find('Image');
    for (let i = 0; i < images.length; i += 1) {
      if (images[i].attrs.svgUid === 'ATS3' || images[i].attrs.svgUid === 'ATS2') {
        images[i].remove();
      }
    }
  };

  show1 = () => {
    [
      this.Line36,
      this.Line39,
      this.Line49,
      this.Line50,
      this.Line53,
      this.Line54,
      this.Line55,
      this.Line56,
      this.Line57,
      this.Line58,
      this.Line35,
      this.Line40,
      this.Line51,
      this.Line52,
      this.Line59,
      this.Line60,
      this.Line61,
      this.Line62,
      this.Line63,
      this.Line64,
    ].forEach((line) => line.show());
    [this.transformer3, this.transformer4, this.transformer5, this.transformer6].forEach((trans) =>
      trans.show(),
    );
    [
      this.BusTieSwitchT45,
      this.BusTieSwitchT78,
      this.BusTieSwitchT5,
      this.BusTieSwitchT4,
      this.BusTieSwitchT7,
      this.BusTieSwitchT8,
    ].forEach((bus_tie) => bus_tie.show());
    this.uploadATS([this.ATS3, this.ATS2], this.uploadATSUnit);
  };

  updateDiesel = (diesel) => {
    this.diesel = diesel;
  };
}
export default MainDistributionSystem;
