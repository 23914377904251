/* --
 --- Feature：市电 1 & 2
 --- Author: arya
 * // 取消注释
--*/

import React from 'react';
import Konva from 'konva';
import moment from 'moment';
import { CenterLineLen, CityElecHeight, Width } from '../Constants';

class MunicipalElectricity extends React.Component {
  constructor(props) {
    super(props);
    // left,top,canvas,serial
    const { x, y, serial, menu, canvas } = props;

    this.menu = menu;
    this.parentLayer = canvas;
    this.on = true;
    this.serial = serial;
    this.config = this.getConfig(x, y - 10);
    this.rect = new Konva.Rect(this.config);
    this.parentLayer.add(this.rect);
    this.content = new Konva.Text({
      text: `市电${serial}电源10kv`,
      x: this.config.x,
      y: this.config.y,
      width: this.config.width,
      height: this.config.height,
      fill: 'white',
      align: 'center',
      verticalAlign: 'middle',
      fontSize: (22 * Width) / 2560,
    });
    this.parentLayer.add(this.content);
  }

  getConfig = (x, y) => ({
    x,
    y,
    fill: '',
    width: 0.1 * window.innerWidth - 50,
    height: 30,
    stroke: 'red',
    strokeWidth: 4,
    perfectDrawEnabled: false,
  });

  isPointInPath = (x, y) =>
    x - this.rect.x() >= 0 &&
    x - this.rect.x() <= this.rect.width() &&
    y - this.rect.y() >= 0 &&
    y - this.rect.y() <= this.rect.height();
  // Math.abs(x - this.config.x) <= CenterLineLen / 2 &&
  // Math.abs(y - this.config.y) <= CityElecHeight / 2;

  onclick = () => {
    const { setSimulateLogs } = this.props;
    this.on = !this.on;
    if (this.on) {
      this.rect.setAttr('stroke', 'red');
      setSimulateLogs({
        time: moment().format('YYYY/MM/DD HH:mm'),
        status: '报警',
        value: `市电${this.serial}恢复！`,
      });
    } else {
      this.rect.setAttr('stroke', 'green');
      setSimulateLogs({
        time: moment().format('YYYY/MM/DD HH:mm'),
        status: '报警',
        value: `市电${this.serial}掉电！`,
      });
    }
  };
}

export default MunicipalElectricity;
