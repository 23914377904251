import fetch from '@/utils/fetch';
import { currentEnv } from '../../basic/Common';

const model = {
  state: {
    // 用户
    accountInfo: {}, // 当前登录账号数据
    loading: false,
    excelExportProgress: 0,
    lastPath: '',
    videoCourseList: {},
    videoInfo: {},
    token: '',
  },
  reducers: {
    setState(state, partialState) {
      return { ...state, ...partialState };
    },
  },
  actions: ({ dispatch: { VRPlayer } }) => ({
    // 获取当前登录账户相关数据 (http://wiki.sftcwl.com/pages/viewpage.action?pageId=3225256)
    async getVideoInfo(videoId) {
      const data = await fetch(currentEnv + 'videoCourse/'.concat(videoId));
      // console.log('videoInfo:', data);
      VRPlayer.setState({ videoInfo: data, token: new Date() });
    },
  }),
};

export default model;
