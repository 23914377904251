import fetch from '@/utils/fetch';
import { message } from 'antd';

const ENV_BaiduYun = 'https://study.dccloudclass.com/api/';
const ENV_BaiduYunLocal = 'http://61.147.93.160:8080/opscoursesystem4test/';
const ENV_LocalDev = 'http://192.168.1.102:8080/';
const currentEnv = ENV_BaiduYun;

const api = {
  getUserList: (params) => fetch(currentEnv + 'findUser', { params, type: 'POST' }),
  getUserLearnInfoList: (params) =>
    fetch(currentEnv + 'user/userLearnInfo', { params, type: 'POST' }),
  regist: (params) => fetch(currentEnv + 'regist', { params, type: 'POST' }),
  updateUser: (params) => fetch(currentEnv + 'updateUser', { params, type: 'POST' }),
  disableUser: (params) => fetch(currentEnv + 'disableUser', { params, type: 'POST' }),
  getLearnInfo: (params) =>
    fetch(currentEnv + 'videoCourse/getPlayedInfo', { params, type: 'POST' }),
  uploadExcel: (params) => fetch(currentEnv + '/excel/import.do', { params, type: 'POST' }),
  downloadTemplate: (params) => fetch(currentEnv + 'downloadTemplate.do?path=' + params),
};

const model = {
  state: {
    // 用户
    accountInfo: {}, // 当前登录账号数据
    data: [], //人员信息表数据
    learnInfo: [],
  },
  reducers: {
    setState(state, partialState) {
      return { ...state, ...partialState };
    },
  },
  actions: ({ dispatch: { MemberHandle } }) => ({
    // 获取当前登录账户相关数据 (http://wiki.sftcwl.com/pages/viewpage.action?pageId=3225256)

    async getUserList(searchParams) {
      console.log('searchParams:', searchParams);
      let data = await api.getUserList(searchParams);
      if (data) {
        if (data.length == 0) {
          message.error('没有查到相关学员信息！');
        }
        MemberHandle.setState({ data: data });
        console.log('getUserList:', data);
      }
    },

    async regist(params) {
      let data = await api.regist(params);

      console.log('regist:', data);
    },

    async getLearnInfo(params) {
      console.log('LearnInfoParams:' + params);
      let data = await api.getLearnInfo(params);
      console.log('LearnInfo:', data);
      if (data) {
        if (data.length == 0) {
          message.error('没有查到相关学员信息！');
        }
        MemberHandle.setState({ learnInfo: data });
      }
    },

    async disableUser(params) {
      let data = await api.disableUser(params);
      if (data.flag === 'suc') {
        message.success(data.msg);
      } else {
        message.error(data.msg);
      }
    },

    async updateUser(params) {
      let data = await api.updateUser(params);
      if (data.flag === 'suc') {
        message.success(data.msg);
      } else {
        message.error(data.msg);
      }
    },

    async uploadExcel(params) {
      let stat = await api.uploadExcel(params);
      console.log('uploadExcel:', stat);
    },

    async downloadExcel(params) {
      let stat = await api.downloadTemplate('user_upload_template.xls');
      console.log('download:', stat);
    },
  }),
};

export default model;
