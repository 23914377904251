/* --
 --- Feature：UPS模块（包含电池等）
 --- Author: arya
--*/
import React from 'react';
import Konva from 'konva';
import Line from './base/Line';
import Switch from './base/Switch';
import UPS from './base/UPS';
import {
  H1,
  H2,
  lineConfig,
  SwitchHeight,
  SwitchHeight1,
  UPSWidth,
  UPSHeight,
  Width,
} from './Constants';
import moment from 'moment';

const batteryImgOff = require('@/assets/desktop_exercise/UPSbatteryOff.png');
const batteryImgOn = require('@/assets/desktop_exercise/UPSbatteryOn.png');
const UBImgOn = require('@/assets/desktop_exercise/UBon.png');
const UBImgOff = require('@/assets/desktop_exercise/UBoff.png');

class UPSUnit extends React.Component {
  constructor(props) {
    super(props);

    const {
      parent,
      baseline_row,
      serial_num,
      output_switch_name,
      layer,
      flowLayer,
      setSimulateLogs,
      setVisible,
      setName,
      setLevel,
      mouseMenuVisible,
      mouseMenuName,
      mouseMenuLevel,
    } = props;

    this.serialNum = serial_num;
    this.parentLayer = layer;
    this.parent = parent;
    this.showMe = false;
    this.mainRoadVoltage = false; // 主路电压情况，该值与LowVoltage内的电压值概念不同，主要用于判断是否是电池放电
    this.upsUnitName = output_switch_name;

    this.Line1 = new Line({
      lineConfig,
      points: [
        baseline_row.getCoordinatesByPoints().x2,
        baseline_row.getCoordinatesByPoints().y1,
        baseline_row.getCoordinatesByPoints().x2,
        baseline_row.getCoordinatesByPoints().y1 + H2,
      ],
      direction: true,
      serialNum: 1,
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line2 = new Line({
      lineConfig,
      points: [
        baseline_row.getCoordinatesByPoints().x1,
        this.Line1.getCoordinatesByPoints().y1,
        baseline_row.getCoordinatesByPoints().x1,
        this.Line1.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 2,
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line3 = new Line({
      lineConfig,
      points: [
        this.Line1.getCoordinatesByPoints().x1,
        this.Line1.getCoordinatesByPoints().y2 + SwitchHeight,
        this.Line1.getCoordinatesByPoints().x1,
        this.Line1.getCoordinatesByPoints().y2 + SwitchHeight + H2,
      ],
      direction: true,
      serialNum: 3,
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line4 = new Line({
      lineConfig,
      points: [
        this.Line2.getCoordinatesByPoints().x1,
        this.Line3.getCoordinatesByPoints().y1,
        this.Line2.getCoordinatesByPoints().x1,
        this.Line3.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 4,
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    // left, top, state, name, menu, parentLayer
    this.UPS = new UPS({
      x: baseline_row.getCoordinatesByPoints().x1 - UPSWidth / 3,
      y: this.Line4.getCoordinatesByPoints().y2,
      state: 0,
      name: this.upsUnitName,
      parentLayer: this.parentLayer,
      setVisible,
      setName,
      setLevel,
      setSimulateLogs,
    }).add2Canvas();

    this.Line5 = new Line({
      lineConfig,
      points: [
        this.UPS.rectConfig.x + UPSWidth / 2,
        this.Line4.getCoordinatesByPoints().y2 + UPSHeight,
        this.UPS.rectConfig.x + UPSWidth / 2,
        this.Line4.getCoordinatesByPoints().y2 + UPSHeight + H1,
      ],
      direction: true,
      serialNum: 5,
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line6 = new Line({
      lineConfig,
      points: [
        this.Line5.getCoordinatesByPoints().x1,
        this.Line5.getCoordinatesByPoints().y2 + SwitchHeight1,
        this.Line5.getCoordinatesByPoints().x1,
        this.Line5.getCoordinatesByPoints().y2 + SwitchHeight1 + H2,
      ],
      direction: true,
      serialNum: 6,
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();

    this.switchBypass = new Switch({
      baseline: this.Line1,
      angle: 0,
      closed: false,
      load: false,
      state: 0,
      name: this.getByPassSwitchName(),
      type: 1,
      parentLayer: this.parentLayer,
      setSimulateLogs,
    }).add2Canvas();
    this.switchMainRoad = new Switch({
      baseline: this.Line2,
      angle: 0,
      closed: false,
      load: false,
      state: 0,
      name: this.getMainRoadSwitchName(),
      type: 1,
      parentLayer: this.parentLayer,
      setSimulateLogs,
    }).add2Canvas();
    this.switch_type3 = new Switch({
      baseline: this.Line5,
      angle: 0,
      closed: false,
      load: false,
      state: 0,
      name: this.getOutputSwitchName(),
      type: 3,
      parentLayer: this.parentLayer,
      setSimulateLogs,
    }).add2Canvas();
    let fontColor;
    if (this.upsUnitName.indexOf('UPS-A') > -1) {
      fontColor = '#065F9E';
    } else {
      fontColor = '#DCA311';
    }
    this.rect = new Konva.Rect({
      x: this.UPS.rectConfig.x + UPSWidth / 2 - (((3 / 2) * 30 + 50) * (Width / 2560)) / 2,
      y: this.UPS.rectConfig.y + UPSHeight / 2 - (26 * Width) / 2560 / 2,
      fill: fontColor,
      width: ((3 / 2) * 30 + 50) * (Width / 2560),
      height: (26 * Width) / 2560,
      align: 'center', // 字体对齐方式
      verticalAlign: 'middle',
      // stroke: 'white',
      strokeWidth: 1,
      fontSize: (18 * Width) / 2560,
      perfectDrawEnabled: false,
    });
    this.text = new Konva.Text({
      text: this.upsUnitName,
      x: this.UPS.rectConfig.x + UPSWidth / 2 - (((3 / 2) * 30 + 50) * (Width / 2560)) / 2,
      y: this.UPS.rectConfig.y + UPSHeight / 2 - (26 * Width) / 2560 / 2,
      width: ((3 / 2) * 30 + 50) * (Width / 2560),
      height: (26 * Width) / 2560,
      fill: 'white',
      align: 'center',
      verticalAlign: 'middle',
      fontStyle: 'bold',
      fontSize: (18 * Width) / 2560,
    });
    this.parentLayer.add(this.rect); // 隐藏ups的name的方块
    this.parentLayer.add(this.text); // 隐藏ups的name的文字
    // points, layer, state, direction, left, serialNum;
    this.switchBattery = new Switch({
      baseline: new Line({
        points: [
          this.UPS.rectConfig.x + (3 * UPSWidth) / 4,
          this.Line3.getCoordinatesByPoints().y2 + UPSHeight,
          this.UPS.rectConfig.x + (3 * UPSWidth) / 4,
          this.Line3.getCoordinatesByPoints().y2 + UPSHeight + 1,
        ],
        lineConfig,
        layer: this.parentLayer,
        state: 0,
        direction: true,
        left: true,
        serialNum: 0,
      }),
      angle: 0,
      closed: false,
      load: false,
      state: 0,
      name: 'type2_1',
      type: 2,
      parentLayer: this.parentLayer,
      setSimulateLogs,
    }).add2Canvas();
    this.loadBattery(this.UPS);
    // if (typeof UBon !== 'undefined' && this.showMe) {
    //   this.loadBattery(this.UPS);
    // } else if (this.showMe) {
    //   this.timer4loadUPS = setTimeout(this.loadBattery, 2000, this.UPS);
    // }
    this.touchableObjs = [
      this.switchBypass,
      this.switchMainRoad,
      this.switchBattery,
      this.switch_type3,
    ];

    this.Line7 = new Line({
      lineConfig,
      points: [
        this.UPS.rectConfig.x - H2,
        this.Line6.getCoordinatesByPoints().y2,
        this.UPS.rectConfig.x + UPSWidth / 4,
        this.Line6.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 7,
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line8 = new Line({
      lineConfig,
      points: [
        this.Line7.getCoordinatesByPoints().x2,
        this.Line6.getCoordinatesByPoints().y2,
        this.Line6.getCoordinatesByPoints().x1,
        this.Line6.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 8,
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line9 = new Line({
      lineConfig,
      points: [
        this.Line8.getCoordinatesByPoints().x2,
        this.Line6.getCoordinatesByPoints().y2,
        this.switchBattery.baseline.getCoordinatesByPoints().x1,
        this.Line6.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 9,
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line10 = new Line({
      lineConfig,
      points: [
        this.Line9.getCoordinatesByPoints().x2,
        this.Line6.getCoordinatesByPoints().y2,
        this.Line9.getCoordinatesByPoints().x2 + UPSWidth / 4 + H2,
        this.Line6.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 10,
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.lineGroup = [];
    this.getAllGroup();
  }

  /** 主路开关的名称 */
  getMainRoadSwitchName = () =>
    `${this.parent.head()}AT${this.parent.tail()}${parseInt(
      this.upsUnitName.charAt(this.upsUnitName.length - 1),
      10,
    ) + 1}-1`;

  /** 旁路开关名字 */
  getByPassSwitchName = () =>
    `${this.parent.head()}AT${this.parent.tail()}${parseInt(
      this.upsUnitName.charAt(this.upsUnitName.length - 1),
      10,
    ) + 1}-2`;
  // return this.parent.head() + "AT" + this.parent.tail() + parseInt(this.upsUnitName.charAt(this.upsUnitName.length - 1)) + 1 + "-2";

  getOutputSwitchName = () =>
    `${this.parent.head()}AU${this.parent.tail()}1-${this.upsUnitName.charAt(
      this.upsUnitName.length - 1,
    )}`;

  getAllGroup = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const k in this) {
      //   if (!this.hasOwnProperty(k)) continue;
      if (this[k] instanceof Line) {
        this.lineGroup.push(this[k]);
      }
    }
  };

  update = () => {
    for (let i = 0; i < this.lineGroup.length; i += 1) {
      if (this.lineGroup[i].state === 2) {
        // 有电流的状态
        this.lineGroup[i].flow();
      }
    }
  };

  destroy = () => {
    this.showMe = false;
    clearTimeout(this.timer4loadUPS);
    // eslint-disable-next-line no-restricted-syntax
    for (const k in this) {
      //   if (!this.hasOwnProperty(k)) continue;
      if (this[k] instanceof Line || this[k] instanceof Switch || this[k] instanceof UPS) {
        this[k].destroy();
      }
    }
    const images = this.parentLayer.find('Image');
    for (let i = 0; i < images.length; i += 1) {
      if (images[i].attrs.svgUid === 'UBbattery') {
        // 开关机按钮
        images[i].remove();
        this.parentLayer.batchDraw();
      } else if (
        typeof images[i].attrs.svgUid !== 'undefined' &&
        images[i].attrs.svgUid.substring(0, 8) === 'battery_'
      ) {
        // 电池按钮
        images[i].remove();
        this.parentLayer.batchDraw();
      }
    }
    this.rect.setAttr('opacity', 0);
    this.text.setAttr('opacity', 0);
    // this.parentLayer.batchDraw();
  };

  show = () => {
    this.showMe = true;
    // eslint-disable-next-line no-restricted-syntax
    for (const k in this) {
      //   if (!this.hasOwnProperty(k)) continue;
      if (this[k] instanceof Line || this[k] instanceof Switch || this[k] instanceof UPS) {
        this[k].show();
      }
    }
    this.loadBattery(this.UPS);
    this.rect.setAttr('opacity', 1);
    this.text.setAttr('opacity', 1);
  };

  reloadBattery = (ups) => {
    const images = this.parentLayer.find('Image');
    images.forEach((obj) => {
      if (obj.attrs.svgUid === `battery_${ups.upsName}`) {
        obj.remove();
        this.parentLayer.batchDraw();
        // canvas.remove(obj);
      }
    });
    if (this.switchBattery.on) {
      Konva.Image.fromURL(batteryImgOn, (img) => {
        if (this.showMe) {
          img.setAttrs(ups.getUBatteryConfig());
          this.parentLayer.add(img);
          this.parentLayer.batchDraw();
        }
      });
    } else {
      Konva.Image.fromURL(batteryImgOff, (img) => {
        if (this.showMe) {
          img.setAttrs(ups.getUBatteryConfig());
          this.parentLayer.add(img);
          this.parentLayer.batchDraw();
        }
      });
    }
  };
  loadBattery = (ups) => {
    Konva.Image.fromURL(UBImgOn, (img) => {
      if (this.showMe) {
        img.setAttrs(ups.getUBConfig(true));
        this.parentLayer.add(img);
        this.parentLayer.batchDraw();
      }
    });
    Konva.Image.fromURL(UBImgOff, (img) => {
      if (this.showMe) {
        img.setAttrs(ups.getUBConfig(false));
        this.parentLayer.add(img);
        this.parentLayer.batchDraw();
      }
    });
    this.reloadBattery(ups);
  };

  addName4Line = (canvas) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const k in this) {
      //   if (!this.hasOwnProperty(k)) continue;
      if (this[k] instanceof Line) {
        this[k].addSerial2Canvas(canvas);
      }
    }
  };

  updateLineState = (line_list, set_state) => {
    for (let i = 0; i < line_list.length; i += 1) {
      line_list[i].setState(set_state);
    }
  };

  /** 设置UPS单元的主路电压值 */
  setMainRoadVoltage = (voltage) => {
    this.mainRoadVoltage = voltage;
  };

  /** 判断是否是电池放电 */
  isBatteryDischarge = () => !this.mainRoadVoltage && this.UPS.workingState() === 2; // 主路没有供电，还是工作状态说明是电池放电

  /** 电池放电输出 */
  isBatteryOutput = () => this.isBatteryDischarge() && this.switch_type3.on;
  /**
   * 切断电压
   * */
  shutdownVoltage = () => {
    this.updateLineState(this.lineGroup, 0);
    this.UPS.updateState(0, 0); // 更新UPS的状态
  };

  isPointInPath = (x, y) =>
    x <= this.UPS.rectConfig.x + UPSWidth &&
    x >= this.UPS.rectConfig.x &&
    y <= this.Line6.getCoordinatesByPoints().y2 &&
    y >= this.Line1.getCoordinatesByPoints().y1;

  /** 获取该UPS单元内影响判定UPS真正工作模式得元素 */
  getKernelElem = () => ({
    ups: this.UPS,
    bypass_switch: this.switchBypass,
    main_road_switch: this.switchMainRoad,
    battery_switch: this.switchBattery,
    switch: this.switch_type3,
  });

  /**
   * 判断是否右负载
   * 上口无压的情况下，不可能主路工作
   * */
  isLoad = () => {
    const kernelElem = this.getKernelElem();
    return (
      (kernelElem.bypass_switch.on ||
        (kernelElem.main_road_switch.on &&
          kernelElem.battery_switch.on &&
          kernelElem.ups.battery)) &&
      kernelElem.switch.on
    );
  };

  judgeMutualExclusionBypassCondition = () => {
    // 若某个UPS的下口开关闭合，且该UPS的真实的工作状态是主路工作状态的话，禁止闭合旁路开关
    const kernelElem = this.getKernelElem();
    // return kernelElem.switch.on && ((kernelElem.ups.actualState === 2) || kernelElem.ups.actualState === 1 && (kernelElem.battery_switch.on || kernelElem.main_road_switch.on));
    return kernelElem.switch.on && kernelElem.ups.workingState() === 2;
  };

  updateOutputLine = () => {
    let Line5HasUpdated = false;
    if (this.Line1.state === 2) {
      // 旁路工作状态，有电流通过
      Line5HasUpdated = true;
      this.updateLineState([this.Line3, this.Line5, this.Line6], 2);
    } else if (this.Line1.state === 1) {
      // 有压无流的状态
      if (this.switchBypass.on) {
        this.updateLineState([this.Line3], 1);
        // fixme 如果没有主路工作的UPS，那么该UPS需要变成旁路工作状态
      } else {
        this.updateLineState([this.Line3], 0);
      }
    } else {
      this.updateLineState([this.Line3], 0);
    }
    if (this.Line2.state === 2) {
      // 主路工作状态,若是主路工作状态，说明沿路各个开关都已经闭合了
      Line5HasUpdated = true;
      this.updateLineState([this.Line4, this.Line5, this.Line6], 2);
    } else if (this.Line2.state === 1) {
      if (this.switchMainRoad.on) {
        this.updateLineState([this.Line4], 1);
      } else {
        this.updateLineState([this.Line4], 0);
      }
    } else {
      this.updateLineState([this.Line4], 0);
    }
    if (!Line5HasUpdated) {
      // Line5还没有更新过
      // console.log(
      //   `${this.upsUnitName}UPS状态：${this.UPS.workingState()}`,
      //   `输出开关是否闭合：${this.switch_type3.on}`,
      //   `UPS组是否存在闭合开关：${this.parent.existSwitchOn()}`,
      // );
      if (this.UPS.workingState() >= 1 && this.switch_type3.on && this.parent.existSwitchOn()) {
        // 存在输出
        this.updateLineState([this.Line5, this.Line6], 2);
      } else if (this.UPS.workingState() >= 1) {
        this.updateLineState([this.Line5], 1);
      } else if (this.switch_type3.on && this.parent.existVoltageFromOtherUPS()) {
        this.updateLineState([this.Line5], 1);
      } else {
        this.updateLineState([this.Line5], 0); // fixme Line5的状态还取决于是否存在电池放电的开关并且该输出开关是否闭合
      }
    }
  };

  /**
   * 更新整个UPS状态
   * Unit内部的Line1和Line2的状态由外部引导更新
   * 内部更新Line1和Line2的状态判断状态设置
   * Line6的状态还收到旁路开关的闭合状态影响，所以Line6的状态更新由父类控制
   * state变量表示的是外部线路的通电或者断电，而不是UPS内部的state
   * */
  // fixme 非电池放电
  updateUnitStateByBackground = (state) => {
    // console.log(
    //   `#-------------------${this.upsUnitName} updateUnitStateByBackground` +
    //     `--------------------#`,
    // );
    const kernelElem = this.getKernelElem();
    let flag = true;
    // const flag1 = true;
    if (this.Line1.state === 2) {
      // 旁路工作状态，有电流通过
      flag = false;
      this.updateLineState([this.Line3, this.Line5, this.Line6], 2);
    } else if (this.Line1.state === 1) {
      // 有压无流的状态
      if (this.switchBypass.on) {
        this.updateLineState([this.Line3], 1);
        // fixme 如果没有主路工作的UPS，那么该UPS需要变成旁路工作状态
      } else {
        this.updateLineState([this.Line3], 0);
      }
    } else {
      this.updateLineState([this.Line3], 0);
    }
    if (this.Line2.state === 2) {
      // 主路工作状态,若是主路工作状态，说明沿路各个开关都已经闭合了
      flag = false;
      this.updateLineState([this.Line4, this.Line5, this.Line6], 2);
    } else if (this.Line2.state === 1) {
      if (this.switchMainRoad.on) {
        this.updateLineState([this.Line4], 1);
      } else {
        this.updateLineState([this.Line4], 0);
      }
    } else {
      this.updateLineState([this.Line4], 0);
    }
    // fixme UPS更新状态

    if (flag) {
      // Line1,Line2没有电流，有压无流或者无压的状态
      // console.log(`${kernelElem.ups.upsName}UPS还没有更新状态`);
      if (typeof state !== 'undefined' && state === 0) {
        // fixme 该种情况是外部UPS组点击开关1或者2，断电情况，但是UPS状态不一定就是0，有可能电池供电
        if (this.isBatteryDischarge()) {
          // 电池放电
          // console.log(`${kernelElem.ups.upsName}电池放电`);
          kernelElem.ups.updateState(0, 2);
          if (this.isBatteryOutput() && this.parent.existSwitchOn()) {
            // 电池放电输出,并且存在闭合下口开关
            // console.log(`${kernelElem.ups.upsName}电池放电输出,并且存在闭合下口开关`);
            this.updateLineState([this.Line5, this.Line6], 2);
          } else if (this.isBatteryOutput()) {
            // 电池放电没有输出
            this.updateLineState([this.Line5, this.Line6], 1);
          } else {
            this.updateLineState([this.Line5], 1);
          }
        } else {
          // 不是电池放电
          kernelElem.ups.updateState(0, 0);
          if (this.switch_type3.on && this.parent.existVoltageFromOtherUPS()) {
            this.updateLineState([this.Line5], 1);
          } else {
            this.updateLineState([this.Line5], 0); // fixme Line5的状态还取决于是否存在电池放电的开关并且该输出开关是否闭合
          }
          // this.updateLineState([this.Line5],0,canvas);
        }
      } else if (typeof state !== 'undefined') {
        // 通电了,通电的时候实际上是所有的UPS一起改变的状态，所以不能根据其他的UPS状态来恢复，而是根据断电之前的状态恢复
        if (
          kernelElem.main_road_switch.on &&
          kernelElem.battery_switch.on &&
          kernelElem.ups.battery
        ) {
          // console.log(`${this.upsUnitName} UPS实际工作状态：2`);
          kernelElem.ups.setActualState(2);
        } else if (this.isBatteryDischarge()) {
          // 电池放电
          // console.log(`${this.upsUnitName} 不需要改变UPS状态`);
        } else if (kernelElem.bypass_switch.on) {
          // 旁路开关闭合
          // console.log(`${this.upsUnitName}  UPS实际工作状态：1`);
          kernelElem.ups.setActualState(1);
        }
        // console.log(`${this.upsUnitName} 根据断电之前的状态恢复UPS状态：`);
        const brothers = this.parent.getUPSUnitBrother(this.serialNum);
        const upsConfigList = this.parent.getAllWorkingState(brothers);
        if (typeof kernelElem.ups.lastState4Rectifier === 'undefined') {
          if (kernelElem.ups.actualState === 2) {
            if (
              upsConfigList.by_pass_list.filter((unit) => unit.UPS.workingState() === 1).length > 0
            ) {
              // 旁路工作的
              // console.log(`${this.upsUnitName} 改UPS主路工作，存在旁路工作的UPS，不改变状态`);
            } else {
              kernelElem.ups.updateState(kernelElem.ups.actualState, kernelElem.ups.actualState);
            }
          } else if (kernelElem.ups.actualState === 1) {
            if (
              upsConfigList.main_road_list.filter((unit) => unit.UPS.workingState() === 2).length >
              0
            ) {
              // console.log(`${this.upsUnitName} 改UPS旁路工作，存在主路工作的UPS，不改变状态`);
            } else {
              kernelElem.ups.updateState(kernelElem.ups.actualState, kernelElem.ups.actualState);
            }
          }
        } else if (kernelElem.ups.lastState4Rectifier === 2 && kernelElem.main_road_switch.on) {
          // 还有一种情况两个都是主路工作，关机一个，然后关闭干路，再闭合的时候两个就都变成主路了
          if (kernelElem.battery_switch.on && kernelElem.ups.battery) {
            kernelElem.ups.updateState(2, 2);
          }
        } else if (
          kernelElem.ups.lastState4Inverter === 0 &&
          kernelElem.ups.actualState === 1 &&
          upsConfigList.main_road_list.length === 0
        ) {
          kernelElem.ups.updateState(1, 1);
        } else if (
          kernelElem.ups.lastState4Rectifier === 0 &&
          kernelElem.ups.lastState4Inverter === 2 &&
          kernelElem.main_road_switch.on
        ) {
          kernelElem.ups.updateState(2, 2);
        } else {
          // console.log(kernelElem.ups.lastState4Rectifier, kernelElem.ups.lastState4Inverter);
          kernelElem.ups.updateState(
            kernelElem.ups.lastState4Rectifier,
            kernelElem.ups.lastState4Inverter,
          );
        }
        // kernelElem.ups.updateState(kernelElem.ups.actualState,kernelElem.ups.actualState);
      } else if (kernelElem.ups.workingState() === 2 || kernelElem.ups.workingState() === 1) {
        // 没有设置state
        // fixme 闭合输出开关下口开关又断开，Line5和Line6没有电流
        if (this.parent.existSwitchOn() && this.switch_type3.on) {
          this.updateLineState([this.Line5, this.Line6], 2);
        } else {
          this.updateLineState([this.Line5], 1);
        }
      } else if (this.switch_type3.on && this.parent.existVoltageFromOtherUPS()) {
        this.updateLineState([this.Line5], 1);
      } else {
        this.updateLineState([this.Line5], 0); // fixme Line5的状态还取决于是否存在电池放电的开关并且该输出开关是否闭合
      }
    } else {
      // console.log(`${kernelElem.ups.upsName}UPS和Line5状态已经更新过了`);
    }
  };

  /* 用于判断UPS是否有输出
   * 判断是否有输出的时候UPS的状态信息是准确可靠的，而单元内部的线段的信息是需要依照标准进行调整的
   * working state已经判断了干路的开关状态
   * working state===1，输出开关闭合
   * working state === 2,输出开关闭合
   *
   *
   * */
  outputState = () => {
    if (this.switch_type3.on) {
      return this.UPS.workingState(); //
    }
    return 0;
  };

  reSetUPSState = () => {
    const kernelElem = this.getKernelElem();
    const upsBrothers = this.parent.getUPSUnitBrother(this.serialNum);
    const upsListConfig = this.parent.getAllWorkingState(upsBrothers);
    if (kernelElem.ups.actualState === 0) {
      kernelElem.ups.updateState(0, 0);
    } else if (kernelElem.ups.actualState === 1) {
      kernelElem.ups.updateState(1, 1);
    } else if (kernelElem.ups.actualState === 2) {
      if (kernelElem.main_road_switch.on && kernelElem.battery_switch.on) {
        kernelElem.ups.updateState(2, 2);
      } else if (kernelElem.battery_switch.on) {
        kernelElem.ups.updateState(0, 2);
      }
    }
    // 如果有主路工作的UPS，旁路工作互斥，如果旁路工作，主路工作互斥
    if (upsListConfig.main_road_list.length !== 0) {
      if (kernelElem.ups.actualState === 1) {
        // console.log('存在主路工作的UPS，互斥');
        kernelElem.ups.updateState(0, 0);
      }
    } else if (upsListConfig.by_pass_list.length !== 0) {
      if (kernelElem.ups.actualState === 2) {
        // console.log('存在旁路工作的UPS，互斥');
        kernelElem.ups.updateState(0, 0);
      }
    }
  };

  /* UPS开机或者关机函数 */
  upsBootOrShut = (bootUp) => {
    const kernelElem = this.getKernelElem();
    const upsBrothers = this.parent.getUPSUnitBrother(this.serialNum);
    const upsListConfig = this.parent.getAllUPSActualState(upsBrothers);
    if (bootUp) {
      // 点击到开机按钮
      // console.log('点击到开机按钮');
      if (kernelElem.ups.workingState() === 0) {
        if (this.parent.judgeMutualExclusionBypassCondition()) {
          // 如果旁路开关闭合，点击开机按钮不反应
          // console.log('旁路开关闭合，不开机');
        } else if (kernelElem.battery_switch.on && kernelElem.main_road_switch.on) {
          kernelElem.ups.setActualState(2);
          const ls = upsListConfig.main_road_list.filter(
            (upsUnit) => upsUnit.UPS.workingState() === 2,
          ); // 真正处于工作模式的UPS
          if (ls.length !== 0 || (ls.length === 0 && upsListConfig.by_pass_list.length === 0)) {
            // 正在处于工作模式
            kernelElem.ups.updateState(2, 2);
            upsListConfig.main_road_list.forEach((upsUnit) => upsUnit.UPS.updateState(2, 2)); // fixme 主路工作的更新状态
            this.updateLineState([this.Line5], 1);
          } else {
            // 没有正在处于工作模式
            // 全都是不工作模式，或者是旁路工作模式
            // 如果有旁路工作模式，那一定是真的旁路工作模式，这时候不改变状态
            // console.log('该UPS此时不改变状态');
          }
        }
      } else if (kernelElem.ups.workingState() === 1) {
        if (this.parent.judgeMutualExclusionBypassCondition()) {
          // console.log('旁路工作，不能开机');
        } else if (upsListConfig.by_pass_list.length !== 0) {
          // console.log('兄弟UPS存在旁路工作状态，');
          if (kernelElem.main_road_switch.on && kernelElem.battery_switch.on) {
            // this.UPS.battery = true;//不管单机并机肯定都开机了，但是UPS的状态要根据其他UPS的状态来判定
            kernelElem.ups.setActualState(2);
          }
        } else if (kernelElem.main_road_switch.on && kernelElem.battery_switch.on) {
          // this.UPS.battery = true;//不管单机并机肯定都开机了，但是UPS的状态要根据其他UPS的状态来判定
          kernelElem.ups.setActualState(2);
          kernelElem.ups.updateState(2, 2);
          upsListConfig.main_road_list.forEach((upsUnit) => upsUnit.UPS.updateState(2, 2));
          this.updateLineState([this.Line5], 1);
        }
      } else if (kernelElem.ups.workingState() === 2) {
        // console.log('工作模式，点击开机按钮不影响');
      }
      if (
        kernelElem.battery_switch.on &&
        kernelElem.main_road_switch.on &&
        !this.parent.judgeMutualExclusionBypassCondition()
      ) {
        this.UPS.setBatteryState(true);
        this.props.trigger4Notice('开机申请已经提交');
      }
    } else if (kernelElem.ups.workingState() === 0) {
      // 点击到关机按钮
      // console.log('点击到关机按钮');
      if (!kernelElem.bypass_switch.on) {
        kernelElem.ups.updateState(0, 0);
        kernelElem.ups.setActualState(0);
        this.updateLineState([this.Line5], 0);
      } else if (upsListConfig.main_road_list.length !== 0) {
        kernelElem.ups.updateState(0, 0);
        kernelElem.ups.setActualState(1);
        this.updateLineState([this.Line5], 0);
      } else {
        kernelElem.ups.updateState(1, 1);
        kernelElem.ups.setActualState(1);
        this.updateLineState([this.Line5], 1);
      }
      this.props.trigger4Notice('关机申请已经提交');
    } else if (kernelElem.ups.workingState() === 1) {
      this.UPS.setBatteryState(false);
      // console.log('旁路工作状态，点击关机按钮不影响');
      this.props.trigger4Notice('关机申请已经提交');
    } else if (kernelElem.ups.workingState() === 2) {
      this.UPS.setBatteryState(false);
      if (!kernelElem.bypass_switch.on) {
        kernelElem.ups.updateState(0, 0);
        kernelElem.ups.setActualState(0);
        this.updateLineState([this.Line5], 0);
        if (upsListConfig.main_road_list.length === 0) {
          // console.log(`${this.upsUnitName}更新别的UPS状态到旁路工作`);
          upsListConfig.by_pass_list.forEach((upsUnit) => upsUnit.UPS.updateState(1, 1));
        }
      } else {
        kernelElem.ups.setActualState(1);
        // console.log('旁路开关闭合，要看别的状态决定');
        if (upsListConfig.main_road_list.length !== 0) {
          // 实际的主路工作状态不是0的话
          /**
           * 1. 有主路工作的UPS
           * 2. 没有主路工作的UPS，有旁路工作的UPS
           * */
          kernelElem.ups.updateState(0, 0);
          this.updateLineState([this.Line5], 0);
        } else {
          kernelElem.ups.updateState(1, 1);
          // console.log(`${this.upsUnitName}更新别的UPS状态到旁路工作`);
          upsListConfig.by_pass_list.forEach((upsUnit) => upsUnit.UPS.updateState(1, 1));
          this.updateLineState([this.Line5], 1);
        }
      }
      this.props.trigger4Notice('关机申请已经提交');
    }
  };

  /**
   * 补充逻辑
   * 若最旁路开关闭合，点击电池开关没有反应
   *
   * UPS旁路供电可以闭合旁路开关，
   * 如果旁路开关闭合，禁止闭合UPS内的主路开关
   *
   * 点击开关1的效果取决于UPS的状态；
   * 若UPS输出都断开，点击旁路开关有输出
   * */
  trigger = (obj) => {
    const kernelElem = this.getKernelElem();
    const upsBrothers = this.parent.getUPSUnitBrother(this.serialNum);
    const upsListConfig = this.parent.getAllUPSActualState(upsBrothers);
    if (obj instanceof Switch) {
      if (obj === kernelElem.battery_switch) {
        // 点击到电池开关
        if (!(this.parent.judgeMainRoadState() && kernelElem.main_road_switch.on) && !obj.on) {
          this.props.trigger4Notice('主路未供电无法闭合电池开关');
          // document.getElementById('dialog').innerText = '主路未供电无法闭合电池开关';
          // $('#dialog').dialog('open');
          // }else if(this.parent.judgeMutualExclusionBypassCondition()){//UPS组的旁路开关闭合
          //     console.log("此时点击电池开关没有反应");
        } else {
          obj.onclick();
          this.reloadBattery(this.UPS);

          if (kernelElem.ups.workingState() === 0) {
            // 不工作
            if (!kernelElem.ups.battery) {
              // console.log('ups未开机，点击电池开关不影响');
            } else if (kernelElem.main_road_switch.on && obj.on) {
              kernelElem.ups.updateState(2, 2);
              kernelElem.ups.setActualState(2);
              upsListConfig.main_road_list.forEach((upsUnit) => upsUnit.UPS.updateState(2, 2));
            } else {
              // console.log('不需要改变ups状态');
            }
          } else if (kernelElem.ups.workingState() === 1) {
            // 旁路
            if (kernelElem.ups.battery) {
              kernelElem.ups.updateState(2, 2);
              kernelElem.ups.setActualState(2);
            } else {
              // console.log('旁路工作模式，点击电池开关不影响');
            }
          } else if (kernelElem.ups.workingState() === 2) {
            // 主路
            if (kernelElem.main_road_switch.on) {
              // console.log('主路开关闭合，点击电池按钮不影响');
              if (this.Line2.state === 0) {
                // 电池主路放电
                kernelElem.ups.updateState(0, 0);
                // fixme 这里需要判断此时UPS的真实工作状态是
                kernelElem.ups.setActualState(0);
                kernelElem.ups.battery = false;
              }
            } else if (!kernelElem.bypass_switch.on) {
              kernelElem.ups.updateState(0, 0);
              kernelElem.ups.setActualState(0);
              // console.log('旁路开关未闭合，转为不工作模式');
            } else if (upsListConfig.main_road_list.length === 0) {
              // 断开
              kernelElem.ups.updateState(1, 1);
              kernelElem.ups.setActualState(1);
            } else if (upsListConfig.main_road_list.length !== 0) {
              kernelElem.ups.updateState(0, 0);
              kernelElem.ups.setActualState(0);
            }
          }
        }
      } else if (obj === kernelElem.main_road_switch) {
        // 点击到主路开关
        // if(this.parent.judgeMainRoadState() && this.parent.judgeMutualExclusionBypassCondition() && kernelElem.switch.on){
        //     document.getElementById("dialog").innerText = "此时禁止闭合UPS主路开关";
        //     $("#dialog").dialog("open");
        // }else{
        obj.onclick();
        if (kernelElem.ups.workingState() === 0) {
          // 不工作
          if (!kernelElem.ups.battery) {
            // console.log('点击主路开关，ups未开机，不影响');
          } else {
            // console.log('点击主路开关，ups开机，不影响');
          }
        } else if (kernelElem.ups.workingState() === 1) {
          // 旁路
          // console.log('旁路状态，点击主路开关不影响');
        } else if (kernelElem.ups.workingState() === 2) {
          // 主路
          if (kernelElem.battery_switch.on) {
            if (obj.on) {
              // console.log('整流器工作');
              kernelElem.ups.updateState(2, 2);
              kernelElem.ups.setActualState(2);
            } else {
              // console.log('整流器不工作');
              kernelElem.ups.updateState(0, 2);
              kernelElem.ups.setActualState(2);
            }
          } else if (!kernelElem.bypass_switch.on) {
            kernelElem.ups.updateState(0, 0);
            kernelElem.ups.setActualState(0);
          } else if (upsListConfig.main_road_list.length === 0) {
            kernelElem.ups.updateState(1, 1);
            kernelElem.ups.setActualState(1);
          } else if (upsListConfig.main_road_list.length !== 0) {
            kernelElem.ups.updateState(0, 0);
            kernelElem.ups.setActualState(0);
          }
        }
        // }
      } else if (obj === kernelElem.bypass_switch) {
        // 点击到旁路开关
        obj.onclick();
        if (this.Line1.state >= 1) {
          if (kernelElem.ups.workingState() === 0) {
            // 不工作
            // 有一个UPS在旁路工作状态
            // 另一个UPS不工作模式，但是已经提交了开机申请
            kernelElem.ups.setActualState(1);
            if (upsListConfig.main_road_list.length === 0) {
              kernelElem.ups.updateState(1, 1);
            } else {
              const workingList = upsListConfig.main_road_list.filter(
                (upsUnit) => upsUnit.UPS.workingState() === 2,
              ); // 目前正在处于工作状态的列表
              if (workingList.length !== 0) {
                // console.log('兄弟开关主路工作');
              } else {
                kernelElem.ups.updateState(1, 1);
              }
            }
          } else if (kernelElem.ups.workingState() === 1) {
            // 旁路
            kernelElem.ups.setActualState(0);
            kernelElem.ups.updateState(0, 0);
            if (upsListConfig.by_pass_list.length === 0) {
              upsListConfig.main_road_list.forEach((unit) => unit.UPS.updateState(2, 2));
            }
          } else if (kernelElem.ups.workingState() === 2) {
            // 主路
            // console.log('主路工作模式，点击旁路开关不影响');
          }
        }
        // this.parent.updateGroupLine();
      } else if (obj === kernelElem.switch) {
        // UPS下口开关,输出开关
        if (obj.on) {
          // 原来是闭合状态，现在需要断开
          obj.onclick();
        } else if (
          this.parent.judgeMutualExclusionBypassCondition() &&
          kernelElem.ups.workingState() === 2
        ) {
          // 原来是断开状态，现在需要闭合
          // 判断该UPS的输出状态和已经有处处的
          // 旁路开关闭合,并且UPS再工作状态中
          // console.log('输出开关不允许闭合');
          this.props.trigger4Notice('此时禁止闭合UPS输出开关');
          // document.getElementById('dialog').innerText = '此时禁止闭合UPS输出开关';
          // $('#dialog').dialog('open');
        } else {
          obj.onclick();
        }
      }
    }
  };
  /**
   *
   * 该UPS改变工作模式得时候需要检查其他得UPS状态
   *
   * 1. 旁路工作模式：
   *      单机
   *      主路开关不影响，电池开关不影响，关机按钮不影响
   *      若主路开关电池开关闭合，开机主路工作模式
   *      并机：
   *          点击旁路开关（旁路工作模式，点击旁路开关一定是断开旁路），转为不工作模式；
   *          点击主路开关电池开关不影响；
   *          点击开机按钮
   *              若兄弟UPS部分旁路工作，不改变状态，待其他UPS更改工作模式时一并更改；
   *              若兄弟UPS全部不工作或者部分主路工作，则该UPS转为主路工作模式；
   *          点击关机按钮
   *              若该UPS提交过开机申请，则取消关机申请
   * 2. 不工作模式：
   *      并机：
   *          点击主路开关电池开关：
   *              若未开机，不影响；
   *              若开机：
   *                  点击主路开关不影响；
   *                  点击电池开关，若主路开关闭合，则转为工作模式
   *          点击旁路开关：
   *              若兄弟UPS部分旁路工作或者都不工作，该UPS旁路工作
   *              若兄弟UPS部分主路工作，该UPS不工作，待其他UPS更改工作模式时一并更改该UPS得状态
   *          点击开机按钮（主路开关电池开关闭合）：
   *              若兄弟UPS部分主路工作，该UPS主路工作
   *          点击关机按钮（原来是工作模式）：
   *              若旁路开关断开，该UPS不工作；
   *              若旁路开关闭合：
   *              若兄弟UPS部分主路工作，该UPS不工作，待兄弟UPS更改状态时一并更新该UPS工作模式
   *              若兄弟UPS部分旁路工作或者全部不工作，转为旁路工作模式；
   *
   * 3. 工作模式：
   *      点击旁路开关不影响；
   *      点击电池按钮
   *          若主路开关闭合，不影响；
   *          若主路开关断开：
   *              若旁路开关断开，转为不工作模式；
   *              若旁路开关闭合：
   *
   *                  若兄弟UPS部分旁路工作模式或者部分不工作，但是旁路开关闭合，则转为旁路工作模式；
   *              若兄弟UPS部分工作模式或者全部不工作，转为不工作模式；
   *     点击主路开关：
   *          若电池开关闭合，UPS整流器不工作；
   *          若电池开关断开：
   *              若旁路开关断开，转为不工作模式；
   *              若旁路开关闭合：
   *                  若兄弟UPS部分旁路工作模式或者部分不工作，但是旁路开关闭合，则转为旁路工作模式；
   *              若兄弟UPS部分工作模式或者全部不工作，转为不工作模式；
   *点击开机按钮：不影响；
   *    点击关机按钮：
   *          若旁路开关断开，转为不工作模式；
   *          若旁路开关闭合：
   *              若兄弟UPS部分主路工作模式或者全部不工作，转为不工作模式
   *              若兄弟UPS部分旁路工作模式，转为旁路工作模式；
   *
   *
   * */
  /**
   * UPSUnit内部的UPS状态已经更新过了，在执行updateGroupLine函数的时候避免二次更新UPS状态
   * */
  onclick = (timeMinus, e, setVisible, setName, setLevel) => {
    if (e.evt.button === 0) {
      let flag = true;
      // 点击到UPS单元中的普通开关
      for (let i = 0; i < this.touchableObjs.length; i += 1) {
        // alert(e.evt.button)

        if (this.touchableObjs[i].isPointInPath(e.evt.layerX, e.evt.layerY)) {
          this.trigger(this.touchableObjs[i]);
          // this.parent.clickUpload(this,this.touchableObjs[i]);
          this.parent.updateGroupLine(undefined, true);
          flag = false;
          // alert(this.touchableObjs[i].name);

          break;
        }
      }
      if (flag) {
        // 点击到开机或者关机按钮
        if (this.UPS.isPointInPath(e.evt.layerX, e.evt.layerY, true)) {
          flag = true;
          this.upsBootOrShut(true);
          // this.parent.clickUpload(this,true);
          this.parent.updateGroupLine(undefined, true);
        } else if (this.UPS.isPointInPath(e.evt.layerX, e.evt.layerY, false)) {
          flag = true;
          if (this.UPS.battery) {
            if (timeMinus <= 3 * 1000) {
              this.props.trigger4Notice('请长按3秒关机');
              // document.getElementById('dialog').innerText = '请长按3秒关机';
              // $('#dialog').dialog('open');
            } else {
              // console.log('时间差');
              // console.log(timeMinus);
              this.upsBootOrShut(false);
              // this.parent.clickUpload(this,false);
              this.parent.updateGroupLine(undefined, true);
            }
          }
        }
      }
      return flag; // 返回true表示确实点击到UPS单元内的某个可点击元素，需要更新UPS组的状态
    } else if (e.evt.button === 2) {
      /*
       * UPSunit 鼠标右击显示菜单
       * */

      const { setVisible, setLevel } = this.props;
      const kernelElem = this.getKernelElem();

      setVisible();
      setLevel();

      //必须是iframe加载完成后才可以向子域发送数据
      const childFrameObj = document.getElementById('mouseMenuFrame');
      childFrameObj.contentWindow.postMessage(kernelElem.ups.upsName, '*');
    }
  };
}

export default UPSUnit;
