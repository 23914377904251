import React from 'react';

const Loading = ({ error, pastDelay }) => {
  if (error) {
    return (
      <div className="error">
        <h2>ERROR</h2>
        <p>{error.message}</p>
      </div>
    );
  }
  if (pastDelay) {
    return <div className="loading" />;
  }
  return null;
};

export default Loading;
