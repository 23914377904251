import fetch from '@/utils/fetch';
// import filterNull from '@/utils/filterNull';
import { currentEnv } from '../../basic/Common';

const model = {
  state: {
    // menuList: [],
  },
  reducers: {
    setState(state, partialState) {
      return { ...state, ...partialState };
    },
  },
  actions: ({ dispatch: { login3D }, getState }) => ({
    // async getMenuData() {
    //   const data = await fetch(currentEnv + 'menu/getMenuByUser');
    //   if(data) {
    //     console.log("menu," ,data)
    //     login3D.setState({menuList: data});
    //   }
    // },
  }),
};

export default model;
