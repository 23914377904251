import fetch from '@/utils/fetch';
import { currentEnv } from '../basic/Common';

// const ENV_BaiduYun = 'https://study.dccloudclass.com/api/';
// const ENV_BaiduYunLocal = 'http://61.147.93.160:8080/opscoursesystem4test/';
// const ENV_LocalDev = 'http://192.168.1.102:8080/';
// const currentEnv = ENV_BaiduYun;

const model = {
  state: {
    // 用户
    accountInfo: {}, // 当前登录账号数据
    menuList: [],
    loading: false,
    excelExportProgress: 0,
    lastPath: '',
    islogout: false,
  },
  reducers: {
    setState(state, partialState) {
      return { ...state, ...partialState };
    },
  },
  actions: ({ dispatch: { basic } }) => ({
    // 获取当前登录账户相关数据 (http://wiki.sftcwl.com/pages/viewpage.action?pageId=3225256)
    async getAccountInfo() {
      const data = await fetch(currentEnv + 'user/currentUser');
      if (data) {
        basic.setState({ accountInfo: data });
      }
    },
    async getMenuData() {
      const data = await fetch(currentEnv + 'menu/getMenuByUser');
      if (data) {
        let list = [];
        list = data.map((item, index) => ({
          name: item.name,
          path: item.href,
        }));
        // console.log("menu," ,list)
        basic.setState({ menuList: list });
      }
    },
    async setLastPath(path) {
      basic.setState({ lastPath: path });
    },
    async logout() {
      const data = await fetch(currentEnv + 'logout');
      if (data) {
        basic.setState({ islogout: true });
        basic.setState({ menuList: [] });
      }
    },
  }),
};

export default model;
