/**
 * Feature：整体低压系统
 * Author: arya
 * */
import React from 'react';
import Line from './base/Line';
import Switch from './base/Switch';
import UPSGroup from './UPSGroup';
import Module from './base/Module';
import { lineConfig, LowVoltageFirstRowLen, LowVoltageFirstRowLen1, H1 } from './Constants';

class LowVoltage extends React.Component {
  constructor(props) {
    super(props);
    /**
     * 左右两路的通电情况0--无电，1-电压，2-电流
     * 每个group分左右就可以判断上口电压情况
     * 当点击上口的一些开关触发这两个变量状态改变的时候应该触发级联反应
     * 点击下口的开关的时候应该根据这两个量来判断修改状态
     * */

    const {
      main,
      index,
      canvas,
      flowLayer,
      trigger4Notice,
      setSimulateLogs,
      mouseMenuVisible,
      mouseMenuName,
      mouseMenuLevel,
      setVisible,
      setName,
      setLevel,
    } = props;
    this.index = index;
    this.parent = main;
    this.parentLayer = canvas;
    this.flowLayer = flowLayer;
    this.left_elec_state = 0;
    this.right_elec_state = 0;

    /* -----------Line-------------*/
    this.Line1 = new Line({
      lineConfig,
      points: [
        this.parent.Line45.getCoordinatesByPoints().x1 - LowVoltageFirstRowLen,
        this.parent.Line45.getCoordinatesByPoints().y1,
        this.parent.Line45.getCoordinatesByPoints().x1,
        this.parent.Line45.getCoordinatesByPoints().y1,
      ],
      direction: false,
      serialNum: 1,
      state: this.left_elec_state,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line2 = new Line({
      lineConfig,
      points: [
        this.Line1.getCoordinatesByPoints().x1 - LowVoltageFirstRowLen1,
        this.Line1.getCoordinatesByPoints().y1,
        this.Line1.getCoordinatesByPoints().x1,
        this.Line1.getCoordinatesByPoints().y1,
      ],
      direction: false,
      serialNum: 2,
      state: this.left_elec_state,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line3 = new Line({
      lineConfig,
      points: [
        this.Line2.getCoordinatesByPoints().x1 - H1,
        this.Line2.getCoordinatesByPoints().y1,
        this.Line2.getCoordinatesByPoints().x1,
        this.Line2.getCoordinatesByPoints().y1,
      ],
      direction: false,
      serialNum: 2,
      state: this.left_elec_state,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line4 = new Line({
      lineConfig,
      points: [
        this.Line1.getCoordinatesByPoints().x1,
        this.Line1.getCoordinatesByPoints().y1,
        this.Line1.getCoordinatesByPoints().x1,
        this.Line1.getCoordinatesByPoints().y1 + H1,
      ],
      direction: true,
      serialNum: 4,
      state: this.left_elec_state,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line5 = new Line({
      lineConfig,
      points: [
        this.Line2.getCoordinatesByPoints().x1,
        this.Line2.getCoordinatesByPoints().y1,
        this.Line2.getCoordinatesByPoints().x1,
        this.Line2.getCoordinatesByPoints().y1 + H1,
      ],
      direction: true,
      serialNum: 5,
      state: this.left_elec_state,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();

    this.Line6 = new Line({
      lineConfig,
      points: [
        this.parent.Line48.getCoordinatesByPoints().x2,
        this.parent.Line48.getCoordinatesByPoints().y1,
        this.parent.Line48.getCoordinatesByPoints().x2 + LowVoltageFirstRowLen,
        this.parent.Line48.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 6,
      state: this.right_elec_state,
      left: false,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line7 = new Line({
      lineConfig,
      points: [
        this.Line6.getCoordinatesByPoints().x2,
        this.Line6.getCoordinatesByPoints().y1,
        this.Line6.getCoordinatesByPoints().x2 + LowVoltageFirstRowLen1,
        this.Line6.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 7,
      state: this.right_elec_state,
      left: false,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line8 = new Line({
      lineConfig,
      points: [
        this.Line7.getCoordinatesByPoints().x2,
        this.Line7.getCoordinatesByPoints().y1,
        this.Line7.getCoordinatesByPoints().x2 + H1,
        this.Line7.getCoordinatesByPoints().y1,
      ],
      direction: true,
      serialNum: 8,
      state: this.right_elec_state,
      left: false,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line9 = new Line({
      lineConfig,
      points: [
        this.Line6.getCoordinatesByPoints().x2,
        this.Line6.getCoordinatesByPoints().y2,
        this.Line6.getCoordinatesByPoints().x2,
        this.Line6.getCoordinatesByPoints().y2 + H1,
      ],
      direction: true,
      serialNum: 9,
      state: this.right_elec_state,
      left: false,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line10 = new Line({
      lineConfig,
      points: [
        this.Line7.getCoordinatesByPoints().x2,
        this.Line7.getCoordinatesByPoints().y2,
        this.Line7.getCoordinatesByPoints().x2,
        this.Line7.getCoordinatesByPoints().y2 + H1,
      ],
      direction: true,
      serialNum: 10,
      state: this.right_elec_state,
      left: false,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    // parent, baseline, left, ups_num, state, group, layer
    /** UPSGroup */
    this.ups_left_1 = new UPSGroup({
      parent: this,
      baseline: this.Line4,
      left: true,
      ups_num: 2,
      state: this.left_elec_state,
      group: 1,
      layer: this.parentLayer,
      flowLayer,
      setName,
      setVisible,
      setLevel,
      trigger4Notice,
      setSimulateLogs,
    });
    this.ups_right_1 = new UPSGroup({
      parent: this,
      baseline: this.Line9,
      left: false,
      ups_num: 2,
      state: this.right_elec_state,
      group: 1,
      layer: this.parentLayer,
      flowLayer,
      setName,
      setVisible,
      setLevel,
      trigger4Notice,
      setSimulateLogs,
    });
    this.ups_left_2 = new UPSGroup({
      parent: this,
      baseline: this.Line5,
      left: true,
      ups_num: 3,
      state: this.left_elec_state,
      group: 2,
      layer: this.parentLayer,
      flowLayer,
      setName,
      setVisible,
      setLevel,
      trigger4Notice,
      setSimulateLogs,
    });
    this.ups_right_2 = new UPSGroup({
      parent: this,
      baseline: this.Line10,
      left: false,
      ups_num: 3,
      state: this.right_elec_state,
      group: 2,
      layer: this.parentLayer,
      flowLayer,
      setName,
      setVisible,
      setLevel,
      trigger4Notice,
      setSimulateLogs,
    });

    this.upsGroupList = [];
    this.moduleGroup = [];
    this.lineGroup = [];
    this.getAllGroup();
    // this.addName4Line(canvas);
    this.moduleBaseUpsGroup = [this.ups_left_1, this.ups_left_2];

    for (let i = 0; i < this.moduleBaseUpsGroup.length; i += 1) {
      for (let j = 0; j < this.moduleBaseUpsGroup[i].baseLineGroup1.length; j += 1) {
        if (this.moduleBaseUpsGroup[i].ups_num === 3) {
          let num = '';
          let x = 0;
          switch (j) {
            case 0:
            case 1:
            case 2:
              x = 1;
              num = '一';
              break;
            case 3:
            case 4:
            case 5:
              x = 2;
              num = '二';
              break;
            default:
              x = 8;
              num = '八';
          }
          // y, num_str, num_abc, group, menu, parentLayer;
          this.moduleGroup.push(
            new Module({
              y: this.moduleBaseUpsGroup[i].baseLineGroup1[j].getCoordinatesByPoints().y2,
              num_str: num,
              num_abc: (j % 3) + 1,
              group: this.moduleBaseUpsGroup[i].group,
              parentLayer: this.parentLayer,
              setSimulateLogs,
              power: this.parseModuleNum(x, (j % 3) + 1),
            }).add2Canvas(),
          );
        } else {
          this.moduleGroup.push(
            new Module({
              y: this.moduleBaseUpsGroup[i].baseLineGroup1[j].getCoordinatesByPoints().y2,
              num_str: '四',
              num_abc: j + 1,
              group: this.moduleBaseUpsGroup[i].group,
              parentLayer: this.parentLayer,
              setSimulateLogs,
              power: this.parseModuleNum(4, j + 1),
            }).add2Canvas(),
          );
        }
      }
    }
  }

  parseModuleNum = (x, y) => {
    const { lowPressurePower } = this.props;
    return {
      a: lowPressurePower[`${x}-${y}-1`],
      b: lowPressurePower[`${x}-${y}-2`],
      c: lowPressurePower[`${x}-${y}-3`],
    };
  };

  updateModulePower = (lowPressurePower) => {
    this.moduleGroup.forEach((m) => {
      const prefix = m.getPrefix4Power();
      m.setPower({
        a: lowPressurePower[`${prefix}1`],
        b: lowPressurePower[`${prefix}2`],
        c: lowPressurePower[`${prefix}3`],
      });
    });
  };

  componentDidUpdate = () => {
    const { lowPressurePower } = this.props;
    this.moduleGroup.forEach((m) => {
      const prefix = m.getPrefix4Power();
      m.setPower({
        a: lowPressurePower[`${prefix}-1`],
        b: lowPressurePower[`${prefix}-2`],
        c: lowPressurePower[`${prefix}-3`],
      });
    });
  };

  /**
   * 获取模块的功率值
   * @param idx:tab页的索引
   * */
  getPower = (idx) =>
    this.moduleGroup.map((m) => {
      m.setIndex(idx);
      return m.getPower();
    });

  /**
   * @param {ThreePhasePower} power:三相功率
   * @param {Number} idx:1（A相）/2（B相）/3（C相）
   * @param {Number} value
   * */
  // setPower = (powessr, idx, value) => {
  //   this.moduleGroup
  //     .filter((module) => module.name4Distinct === power.moduleName)
  //     .forEach((module) => module.setPower(idx, value));
  // };

  /**
   * 修改电压的状态值时应该区分左侧还是右侧
   * @param {Number} state:0,1
   * @param {Boolean} left
   * */
  setHighVoltage = (state, left) => {
    if (left) {
      this.left_elec_state = state;
      this.updateLineState(
        [this.Line1, this.Line2, this.Line3, this.Line4, this.Line5],
        this.left_elec_state,
      );
      this.upsGroupList.forEach((group) => {
        if (group.left) {
          group.setHighVoltage(state);
        }
      });
      // if(state === 0){
      //     this.moduleGroup.forEach(function (module) {
      //         module.setState_A(state);
      //     })
      // }
    } else {
      this.right_elec_state = state;
      this.updateLineState(
        [this.Line6, this.Line7, this.Line8, this.Line9, this.Line10],
        this.right_elec_state,
      );
      this.upsGroupList.forEach((group) => {
        if (!group.left) {
          group.setHighVoltage(state);
        }
      });
      // if(state === 0){
      //     this.moduleGroup.forEach(function (module) {
      //         module.setState_B(state);
      //     })
      // }
    }
  };

  /**
   * group内部全部由电池主路供电，需要更新负类的干路线路状态，而干路的线路状态收到其他的group的影响
   * @param {Number} group:1,2
   * @param {Boolean} left
   * */
  getLineList2Silence = (group, left) => {
    let list;
    if (group === 1) {
      if (left) {
        if (this.Line2.state === 2) {
          list = [this.Line4];
        } else {
          list = [this.Line1, this.Line4];
        }
      } else if (this.Line7.state === 2) {
        list = [this.Line9];
      } else {
        list = [this.Line6, this.Line9];
      }
    } else if (group === 2) {
      if (left) {
        if (this.Line4.state === 2) {
          list = [this.Line2, this.Line5];
        } else {
          list = [this.Line1, this.Line2, this.Line5];
        }
      } else if (this.Line9.state === 2) {
        list = [this.Line7, this.Line10];
      } else {
        list = [this.Line6, this.Line7, this.Line10];
      }
    }
    return list;
  };

  setSilence = (group, left) => {
    this.updateLineState(this.getLineList2Silence(group, left), 1);
  };

  /**
   * 更新干路状态以及高压负载
   * @param {Boolean} load:负载
   * @param {Boolean} left
   * @param {Number} group
   * */
  setLoad = (load, left, group) => {
    if (!load) {
      // 没有负载
      if (left) {
        // 左侧没有负载
        if (group === 1) {
          // 变化的是group 1，判定group 2的状态
          if (this.Line2.state === 2) {
            // 另一组有电流，已经更新过高压的状态
            this.updateLineState([this.Line4], 1);
          } else if (this.Line2.state === 1) {
            this.updateLineState([this.Line1, this.Line4], 1);
            this.parent.setHighVoltageLoad(3, false); // 如果此时，另一个group有电流那就不需要更新高压负载了
          } else {
            // console.error('group2还是无压的状态，group1怎么会更新负载呢');
          }
        } else if (group === 2) {
          if (this.Line4.state === 2) {
            // group1已经有电流了
            this.updateLineState([this.Line2, this.Line5], 1);
          } else if (this.Line4.state === 1) {
            this.parent.setHighVoltageLoad(3, false); // 如果此时，另一个group有电流那就不需要更新高压负载了
            this.updateLineState([this.Line2, this.Line5, this.Line1], 1);
          }
        }
      } else if (group === 1) {
        // 右侧没有负载
        if (this.Line7.state === 2) {
          this.updateLineState([this.Line9], 1);
        } else if (this.Line7.state === 1) {
          this.parent.setHighVoltageLoad(6, false);
          this.updateLineState([this.Line6, this.Line9], 1);
        }
      } else if (group === 2) {
        if (this.Line9.state === 2) {
          this.updateLineState([this.Line7, this.Line10], 1);
        } else if (this.Line9.state === 1) {
          this.parent.setHighVoltageLoad(6, false);
          this.updateLineState([this.Line6, this.Line7, this.Line10], 1);
        }
      }
    } else if (left) {
      // 有负载
      // 左侧有负载
      if (group === 1) {
        if (this.Line2.state !== 2) {
          // group2有电流
          // 更新高压负载了
          this.parent.setHighVoltageLoad(3, true);
        }
        this.updateLineState([this.Line4, this.Line1], 2);
      } else if (group === 2) {
        if (this.Line4.state !== 2) {
          this.parent.setHighVoltageLoad(3, true);
        }
        this.updateLineState([this.Line5, this.Line2, this.Line1], 2);
      }
    } else if (group === 1) {
      if (this.Line7.state !== 2) {
        this.parent.setHighVoltageLoad(6, true);
      }
      this.updateLineState([this.Line6, this.Line9], 2);
    } else if (group === 2) {
      if (this.Line6.state !== 2) {
        this.parent.setHighVoltageLoad(6, true);
      }
      this.updateLineState([this.Line6, this.Line7, this.Line10], 2);
    }
  };

  /**
   * 设置模块状态的时候说明通电或者断电，若通电则更新干路电流，若断电，则断开
   * @param { UPSGroup} groupObject：UPS单元
   * @param {Number} group：UPS组号
   * @param {Boolean} left
   * @param {Array} rowBaseLineGroup:模块对应的横向线段，据此判断模块应该转变的状态
   * @param {Array} switchGroup：模块对应的开关列表，根据索引可以找到开关类
   * */
  setModuleState = (groupObject, group, left, rowBaseLineGroup, switchGroup) => {
    // console.log(`setModuleState 来自UPS组：${group},左侧？${left}`);
    const modules = this.moduleGroup.filter(
      (module) => module.group === group, // 组号相同
    );
    let flag = true;
    for (let i = 0; i < rowBaseLineGroup.length; i += 1) {
      if (rowBaseLineGroup[i].state >= 1) {
        flag = false;
        const totalCurrent =
          ((modules[i].getPower().A + modules[i].getPower().B + modules[i].getPower().C) * 1000) /
          220;
        if (left) {
          modules[i].setState_A(1);
          if (modules[i].judgeDoubleRoad() === 2) {
            if (switchGroup[i].currentLimit <= totalCurrent / 2) {
              groupObject.tripDelay(switchGroup[i], i, modules[i], 'A');
            } else {
              // console.log('电流没有超过限值');
            }
          } else if (modules[i].judgeDoubleRoad() === 1) {
            if (switchGroup[i].currentLimit <= totalCurrent) {
              groupObject.tripDelay(switchGroup[i], i, modules[i], 'A');
            } else {
              // console.log('电流没有超过限值');
            }
          } else {
            // console.log('模块没有供电，函数多余调用,前后矛盾');
          }
        } else {
          modules[i].setState_B(1);
          if (modules[i].judgeDoubleRoad() === 2) {
            if (switchGroup[i].currentLimit <= totalCurrent / 2) {
              groupObject.tripDelay(switchGroup[i], i, modules[i], 'B');
            } else {
              // console.log('电流没有超过限值');
            }
          } else if (modules[i].judgeDoubleRoad() === 1) {
            if (switchGroup[i].currentLimit <= totalCurrent) {
              groupObject.tripDelay(switchGroup[i], i, modules[i], 'B');
            } else {
              // console.log('电流没有超过限值');
            }
          } else {
            // console.log('模块没有供电，函数多余调用,前后矛盾');
          }
        }
        // console.log(
        //   `A:${modules[i].getPower().A},B:${modules[i].getPower().B},C:${
        //     modules[i].getPower().C
        //   },计算得到总电流:${totalCurrent},单路供电？${modules[i].judgeDoubleRoad()},电流限值：${
        //     switchGroup[i].currentLimit
        //   }`,
        // );
      } else if (left) {
        modules[i].setState_A(0);
      } else {
        modules[i].setState_B(0);
      }
    }
    if ((left && this.left_elec_state === 1) || (!left && this.right_elec_state === 1)) {
      this.setLoad(!flag, left, group);
    }
  };

  /**
   * 判断是否右电流
   * */
  isFlow = (left) => {
    if (left) {
      return this.Line1.state === 2;
    }
    return this.Line6.state === 2;
  };

  /**
   * 判断是否右负载
   * */
  isLoad = (left) =>
    this.upsGroupList.filter((s) => s.left === left).filter((s) => s.isLoad()).length > 0;

  addName4Line = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const k in this) {
      // if (!this.hasOwnProperty(k)) continue;
      if (this[k] instanceof Line) {
        this[k].addSerial2Canvas(this.parentLayer);
      }
    }
  };

  getAllGroup = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const k in this) {
      // if (!this.hasOwnProperty(k)) continue;
      if (this[k] instanceof UPSGroup) {
        this.upsGroupList.push(this[k]);
      } else if (this[k] instanceof Line) {
        this.lineGroup.push(this[k]);
      }
    }
  };

  /** 动画效果，每隔一定的时间刷新虚线的位置，形成流动的效果 */
  update = () => {
    for (let i = 0; i < this.lineGroup.length; i += 1) {
      if (this.lineGroup[i].state === 2) {
        // 有电流的状态
        this.lineGroup[i].flow();
      }
    }
    for (let i = 0; i < this.upsGroupList.length; i += 1) {
      this.upsGroupList[i].update();
    }
  };

  updateLineState = (line_list, set_state) => {
    for (let i = 0; i < line_list.length; i += 1) {
      line_list[i].setState(set_state);
    }
  };

  onclick = (timeMinus, e) => {
    let flag = true;
    if (flag) {
      for (let i = 0; i < this.upsGroupList.length; i += 1) {
        if (this.upsGroupList[i].isPointInPath(e.evt.layerX, e.evt.layerY)) {
          // console.log('点击到upsGroup');
          flag = false;
          this.upsGroupList[i].onclick(timeMinus, e);
        }
      }
    }
  };

  destroy = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const k in this) {
      // if (!this.hasOwnProperty(k)) continue;
      if (this[k] instanceof Line || this[k] instanceof Switch || this[k] instanceof UPSGroup) {
        this[k].destroy();
      } else if (this[k] instanceof Array) {
        this[k].forEach((r) => {
          if (typeof r.destroy === 'function') {
            r.destroy();
          }
        });
      }
    }
  };

  show = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const k in this) {
      // if (!this.hasOwnProperty(k)) continue;
      if (this[k] instanceof Line || this[k] instanceof Switch || this[k] instanceof UPSGroup) {
        this[k].show();
      } else if (this[k] instanceof Array) {
        this[k].forEach((r) => {
          if (typeof r.show === 'function') {
            r.show();
          }
        });
      }
    }
  };
}

export default LowVoltage;
