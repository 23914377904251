/* eslint-disable no-unreachable */
import React from 'react';
import Konva from 'konva';
import { connect } from 'react-redux';
import withStore from '@/store/withStore';
import { Modal, Progress, Menu, Dropdown, message } from 'antd';
import ReactDOM from 'react-dom';
import moment from 'moment';
// import classnames from 'classnames';
import { StartY, Width, lineConfig } from './canvas/Constants';
import MainDistributionSystem from './canvas/MainDistributionSystem';
import LowVoltage from './canvas/LowVoltage';
import DieselGenerator from './canvas/DieselGenerator';
import Button from './canvas/base/Button';
import { Redirect } from 'react-router-dom';

// 日志、参数等信息展示面板
import SimulateLog from './panels/SimulateLog/Index';
import ParamInfo from './panels/ParamInfo/Index';

import styles from './index.scss';
import fetch from '@/utils/fetch';

const tabBox = [
  {
    value: 'dataMonitor',
    name: '数据监控',
  },
  {
    value: 'floorPlan',
    name: '楼层平面图',
  },
  {
    value: 'paramInfo',
    name: '参数表信息',
  },
  {
    value: 'eventWindow',
    name: '事件窗口',
  },
  {
    value: 'faultSimulate',
    name: '故障模拟',
  },
  {
    value: 'expertDiagnosis',
    name: '专家诊断',
  },
  {
    value: 'systemHelp',
    name: '系统帮助',
  },
];
const menu = (
  <Menu>
    <Menu.Item key="1">1st menu item</Menu.Item>
    <Menu.Item key="2">2nd menu item</Menu.Item>
    <Menu.Item key="3">3rd menu item</Menu.Item>
  </Menu>
);
class DesktopExercise1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedTabs: [], // 默认不选中任何 tab
    };
    this.fps = 10;
    this.then = 0;
    this.interval = 1000 / this.fps;
    this.timer = 0;
  }

  componentWillMount() {
    // this.handleLogin();
  }

  // handleLogin = async () => {
  //   const { history } = this.props;
  //   // const params = { username: 'root' };
  //   const result = await fetch('https://study.dccloudclass.com/api/logined', { type: 'GET' });
  //   if (!result) {
  //     history.push({ pathname: '/login' });
  //     document.getElementById('bg').style.display = 'none';
  //     message.error('请登录');
  //   }
  // };

  init = () => {
    const {
      setSimulateLogs,
      paramsValue,
      mouseMenuVisible,
      mouseMenuLevel,
      setName,
      setVisible,
      setLevel,
    } = this.props;
    const lowPressurePower = paramsValue['lowPressure-1'];
    if (mouseMenuVisible === true) {
      setLevel();
      setVisible();
    }
    Konva.pixelRatio = 3; // 降低图像的分辨率，默认是2
    this.stage = new Konva.Stage({
      container: 'container',
      width: window.innerWidth - 40,
      height: 1500,
    });
    this.layer = new Konva.Layer({ hitGraphEnabled: false });
    this.flowLayer = new Konva.Layer();
    this.flowLayer.hitGraphEnabled(false); // 虚线layer不需要监听事件，优化时间
    this.myMuni = new MainDistributionSystem({
      parentLayer: this.layer,
      flowLayer: this.flowLayer,
      trigger4Notice: this.trigger4Notice,
      setSimulateLogs,
    });
    this.myLowVoltage = new LowVoltage({
      main: this.myMuni,
      canvas: this.layer,
      index: 1,
      flowLayer: this.flowLayer,
      trigger4Notice: this.trigger4Notice,
      setSimulateLogs,
      setVisible,
      setName,
      setLevel,
      lowPressurePower,
      paramsValue,
    });
    this.diesel = new DieselGenerator({
      main: this.myMuni,
      parentLayer: this.layer,
      flowLayer: this.flowLayer,
      trigger4Notice: this.trigger4Notice,
      updatePercent4Progress: this.updatePercent4Progress,
      setSimulateLogs,
    });
    this.button = new Button({
      width: (150 * Width) / 1536,
      height: 30,
      x: (300 * Width) / 1536,
      y: StartY + 20,
      contentList: ['显示柴发配电系统', '隐藏柴发配电系统'],
      contentIndex: 1,
      parentLayer: this.layer,
    }).add2Canvas();
    this.button1 = new Button({
      width: (150 * Width) / 1536,
      height: 30,
      x: (300 * Width) / 1536,
      y: StartY + 60,
      contentList: ['显示低压1#配电系统', '隐藏低压1#配电系统'],
      contentIndex: 1,
      parentLayer: this.layer,
    }).add2Canvas();
    this.myMuni.getChildren([this.myLowVoltage], [], []);
    this.myMuni.updateDiesel(this.diesel);
    this.myLowVoltage.destroy();
    this.diesel.destroy();
    this.stage.add(this.layer);
    this.stage.add(this.flowLayer);
  };

  componentDidMount = () => {
    this.init();
    window.addEventListener('message', this.receiveMessage, false);
    window.addEventListener('contextmenu', this.handleContextMenu);

    let countDownTime = 0;
    let countUpTime = 0;

    this.stage.on('mousedown', () => {
      countDownTime = moment();
    });
    this.stage.on('mouseup', (e) => {
      countUpTime = moment();
      if (this.button.isPointInPath(e.evt.layerX, e.evt.layerY)) {
        this.button.click((index) => {
          if (index === 1) {
            lineConfig.stroke = 'green';
            this.diesel.show();
          } else {
            this.diesel.destroy();
          }
        });
      } else if (this.button1.isPointInPath(e.evt.layerX, e.evt.layerY)) {
        clearTimeout(this.myMuni.timer4load);

        this.button1.click((index) => {
          if (index === 1) {
            if (this.myLowVoltage instanceof LowVoltage) {
              this.myLowVoltage.show();
              this.myMuni.setLowVoltageShow(true);
              this.myMuni.destroy1();
            } else {
              this.myLowVoltage = new LowVoltage({ canvas: this.layer, main: this.myMuni });
              this.myMuni.setLowVoltageShow(true);
              this.myMuni.destroy1();
            }
          } else {
            this.myLowVoltage.destroy();
            this.myMuni.setLowVoltageShow(false);
            this.myMuni.show1();
          }
        });
      } else if (this.button.isShow() && this.diesel.isPointInPath(e.evt.layerX, e.evt.layerY)) {
        this.diesel.onclick(e.evt.layerX, e.evt.layerY);
      } else if (this.myMuni.isPointInPath(e.evt.layerX, e.evt.layerY)) {
        this.myMuni.onclick(e, this.diesel);
      } else if (this.button1.contentIndex === 1) {
        this.myLowVoltage.onclick(countUpTime - countDownTime, e);
      }
      // setTimeout(this.layer.draw, 200);
      this.layer.batchDraw();
    });
    this.updatePercent4Progress(`柴发启动时间:15秒`, 0);
    this.xunhuan = 1;
    this.animate();

    // this.dongtai();
    this.diesel.show();
    this.myLowVoltage.show();
    this.myMuni.setLowVoltageShow(true);
    this.myMuni.destroy1();
  };

  animate = () => {
    this.timer += 1;
    if (this.timer % 10 === 0) {
      this.myMuni.update();
      if (this.myLowVoltage instanceof LowVoltage && this.button1.isShow()) {
        this.myLowVoltage.update();
      }
      if (this.diesel instanceof DieselGenerator && this.button.isShow()) {
        this.diesel.update();
      }
      this.flowLayer.batchDraw();
      // this.layer.batchDraw();
    }
    this.timer = requestAnimationFrame(this.animate.bind(this));
  };

  handleContextMenu = (event) => {
    event.preventDefault();
  };

  componentDidUpdate = () => {
    const { paramsValue } = this.props;
    const lowPressurePower = paramsValue['lowPressure-1'];
    const { checkedTabs } = this.state;
    const isSimulate = checkedTabs.indexOf('故障模拟') > -1;
    this.myMuni.updateFaultSimulation(isSimulate);
    this.myLowVoltage.updateModulePower(lowPressurePower);
  };

  writeMessage = (message, text, layer) => {
    text.text(message);
    layer.draw();
  };

  handleTab = (e, name) => {
    const { checkedTabs } = this.state;
    let result = [];
    const indexFlag = checkedTabs.indexOf(name);
    // 操作涉及：如果已经选中的，再次点击就取消选中；否则，选中
    if (indexFlag > -1) {
      const temp = [...checkedTabs];
      temp.splice(indexFlag, 1);
      result = temp;
    } else {
      result = [...checkedTabs, name];
    }
    this.setState({ checkedTabs: result });
  };

  trigger4Notice = (description) => {
    Modal.error({
      title: 'Emergency',
      content: (
        <div>
          <p>{description}</p>
        </div>
      ),
      onOk() {
        Modal.destroyAll();
      },
    });
  };
  componentWillUnmount() {
    window.removeEventListener('message', this.receiveMessage);
  }
  receiveMessage = (event) => {
    const {
      simulateLogs,
      paramsValue,
      setParam,
      mouseMenuVisible,
      mouseMenuLevel,
      mouseMenuName,
      setVisible,
      setName,
      setLevel,
    } = this.props;

    if (event !== undefined && event.data && event.data.name) {
      // console.log('我是react,我接受到了来自iframe的数据：', event.data);
      if (event.data.data === 1 || event.data.data === 4) {
        let step = event.data.data;
        let { history } = this.props;
        // history.push({pathname: '/ThreeDModeling'});
        history.push({ pathname: '/device-information', state: { step } });
      } else if (event.data.data === 2) {
        let step = event.data.data;
        let { history } = this.props;
        // history.push({pathname: '/ThreeDModeling'});
        history.push({ pathname: '/capacity-analysis', state: { step } });
      } else if (event.data.data === 3) {
        setVisible();
        setLevel();
      }
    }
  };

  updatePercent4Progress = (remain_content, percent) => {
    // ReactDOM.render(
    //   <div className={styles.progress}>
    //     <div className={styles.timerContent}>{remain_content}</div>
    //     <Progress className={styles.progressBar} percent={percent} status="active" />
    //   </div>,
    //   document.getElementById('progress'),
    // );
  };

  render() {
    const { checkedTabs } = this.state;
    const {
      simulateLogs,
      paramsValue,
      setParam,
      mouseMenuVisible,
      mouseMenuName,
      setVisible,
      setName,
      mouseMenuLevel,
      setLevel,
    } = this.props;
    const isCanvas =
      checkedTabs.indexOf('故障模拟') > -1 ? checkedTabs.length === 1 : checkedTabs.length === 0;

    return (
      <div className={styles.desktop}>
        <div
          id={'frameDiv'}
          style={{ height: '85%', width: '100%', position: 'absolute', zIndex: mouseMenuLevel }}
        >
          <iframe
            id={'mouseMenuFrame'}
            title="title"
            style={{
              width: '100%',
              height: '100%',
              visibility: mouseMenuVisible ? 'visible' : 'hidden',
              border: 'none',
            }}
            src={'./../../../rightclick.html'}
          />
        </div>
        <div className={styles.content} style={{ opacity: isCanvas ? 1 : 0.08 }}>
          {/* <div className={classnames(styles.angle, styles.topLeft)} />
          <div className={classnames(styles.angle, styles.bottomLeft)} />
          <div className={classnames(styles.angle, styles.topRight)} />
          <div className={classnames(styles.angle, styles.bottomRight)} /> */}
          <div className={styles.title}>模拟监控系统</div>
          <div className={styles.canvasScroll}>
            <div id="alert" />
            <div id="progress" style={{ position: 'relative' }} />
            {/*<Dropdown overlay={menu} trigger={['contextMenu']} overlayStyle={{display :mouseMenuVisible ? 'block':'none'}}>*/}
            <div id="container" />
            {/*</Dropdown>,*/}
          </div>
        </div>
        <div className={styles.contentPanel} style={{ display: isCanvas ? 'none' : 'flex' }}>
          {checkedTabs.indexOf('事件窗口') > -1 && <SimulateLog logs={simulateLogs} />}
          {checkedTabs.indexOf('参数表信息') > -1 && (
            <ParamInfo paramsValue={paramsValue} submit={setParam} />
          )}
        </div>
        {/*<div className={styles.tabContainer}>*/}
        {/*  {tabBox.map((ele) => (*/}
        {/*    <div className={styles.item} key={ele.name}>*/}
        {/*      <div*/}
        {/*        className={*/}
        {/*          checkedTabs.indexOf(ele.name) > -1 ? styles.iconActive : styles.iconContainer*/}
        {/*        }*/}
        {/*        onClick={(e) => this.handleTab(e, ele.name)}*/}
        {/*      >*/}
        {/*        <div*/}
        {/*          className={styles[ele.value]}*/}
        {/*          style={{ backgroundSize: '100%', width: '0.32rem', height: '0.32rem' }}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*      <span className={styles.text}>{ele.name}</span>*/}
        {/*    </div>*/}
        {/*  ))}*/}
        {/*</div>*/}
      </div>
    );
  }
}

export default connect(...withStore('basic', 'desktopExercise'))(DesktopExercise1);
