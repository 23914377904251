const strokeWidth = 3;
// Konva.Object.prototype.originX = Konva.Object.prototype.originY = 'center';

// 定义全局变量，可控制
const PROTOCOL = 'http'; // 協議
const HOST = 'localhost'; // 系統域名
const PORT = '3030'; // 系統端口號
const ROOT = 'verge'; // 接口入口
const INIT = false; // 初始插入數據庫--true,初始化之後設置false
const BASEURL = `${PROTOCOL}://${HOST}:${PORT}/${ROOT}`;
const Width = window.innerWidth;
const TitleHeight = 0;
const MenuHeight = 0;
const DieselGeneratorStartY = TitleHeight + MenuHeight + 80;
const CenterX = Width / 2;
const CenterPercentage = 0.1;
const FirstRowPercentage = 0.048;
const FirstRowLineLen = FirstRowPercentage * Width;
const CenterLineLen = CenterPercentage * Width - 50;
const CityElecHeight = 20;
const H1 = 50;
const H2 = 10;
const SwitchHeight = 56;
const SwitchWidth = 0;
const StartY = 3 * H2 + 2 * SwitchHeight + DieselGeneratorStartY;
const CenterLineY = StartY + CityElecHeight + H1 + 3 * H2 + 2 * SwitchHeight;
const BusTieSwitchHeight = 20;
const BusTieSwitchWidth = 10;
const TransformerHeight = 60;

const TransformerWidth = 20;
const SpaceHeight = 50;
const FirstRowY = CenterLineY - 2 * H2 - SwitchHeight;
const FirstColumnY2 = FirstRowY + H2;
const SecondColumnY1 = FirstColumnY2 + SwitchHeight;
// ------------------------低压配置--------------------//

const SwitchHeight1 = 29;
const UPSWidth = ((H1 + H2) * Width) / 1536;
const UPSHeight = ((H1 + H2) * Width) / 1536;
const UPSSpace = H1;
const MoudleSapce = 2;
const MoudleWidth = CenterLineLen;
const MoudleHeight = 2 * H2;
const LowVoltageFirstRowLen = 2 * UPSWidth;
const LowVoltageFirstRowLen1 = 3 * UPSWidth + 2 * H2;
const lineConfig = {
  strokeWidth: 3,
  stroke: 'green',
};
const globalTimerMap = new Map([
  [1, new Map()],
  [2, new Map()],
]); // 低压1的跳闸定时器管理map，以及低压2的跳闸定时器管理map

const cancelTripTime = () => {
  globalTimerMap.forEach((k, map) => {
    // number->Map
    if (map.length < 0) {
      map.forEach((kk, dict) => {
        // string->{number,number}
        clearTimeout(dict.timer);
      });
    }
  });
};

export {
  strokeWidth,
  INIT,
  BASEURL,
  Width,
  TitleHeight,
  MenuHeight,
  DieselGeneratorStartY,
  CenterX,
  CenterPercentage,
  FirstRowPercentage,
  FirstRowLineLen,
  CenterLineLen,
  CityElecHeight,
  H1,
  H2,
  SwitchHeight,
  SwitchWidth,
  StartY,
  CenterLineY,
  BusTieSwitchHeight,
  BusTieSwitchWidth,
  TransformerHeight,
  TransformerWidth,
  SpaceHeight,
  FirstRowY,
  FirstColumnY2,
  SecondColumnY1,
  SwitchHeight1,
  UPSSpace,
  UPSHeight,
  UPSWidth,
  MoudleSapce,
  MoudleWidth,
  MoudleHeight,
  LowVoltageFirstRowLen,
  LowVoltageFirstRowLen1,
  lineConfig,
  globalTimerMap,
  cancelTripTime,
};
