/* --
 --- Feature：机房模块 16个
 --- Author: arya
--*/

import React from 'react';
import Konva from 'konva';
import moment from 'moment';
import { Width, MoudleHeight, MoudleWidth, CenterX, CenterLineLen } from '../Constants';
import ThreePhasePower from '../ThreePhasePower';

class Module extends React.Component {
  constructor(props) {
    super(props);

    const { y, num_str, num_abc, group, menu, parentLayer, power } = props;

    this.menu = menu;
    this.parentLayer = parentLayer;
    this.group = group; // 模块所属的组
    this.ABCWidth = MoudleWidth / 4;
    // console.log(y, '机房模块--------------------------------');// 左侧小方块
    this.name = `机房模块${num_str}`;
    this.num_str = num_str;
    this.num_abc = num_abc;
    this.init = true; // 是否是初始化状态，主要用户模块负载均衡的提示日志，初始为true，表示不需要提示，在第一次双路供电之后init=false
    this.name4Distinct = `${this.name}: A${this.num_abc}/B${this.num_abc}`;
    this.Arect = new Konva.Rect({
      x: CenterX - CenterLineLen / 2,
      y: y - MoudleHeight / 2,
      width: this.ABCWidth,
      height: MoudleHeight,
      name: `A${num_abc}`,
      align: 'Left',
      stroke: 'black',
      fill: 'green',
      strokeWidth: 1,
      perfectDrawEnabled: false,
    });
    this.Brect = new Konva.Rect({
      // 右侧小方块
      x: CenterX + CenterLineLen / 2 - this.ABCWidth,
      y: y - MoudleHeight / 2,
      width: this.ABCWidth,
      height: MoudleHeight,
      name: `B${num_abc}`,
      align: 'Left',
      stroke: 'black',
      fill: 'green',
      strokeWidth: 1,
      perfectDrawEnabled: false,
    });
    this.centerRect = new Konva.Rect({
      // 机房模块中间的小方块
      x: CenterX - (MoudleWidth - 2 * this.ABCWidth) / 2,
      y: y - MoudleHeight / 2,
      num_int: this.num_int,
      name: this.name,
      width: MoudleWidth - 2 * this.ABCWidth,
      height: MoudleHeight,
      align: 'center',
      verticalAlign: 'middle',
      stroke: 'black',
      fill: '', // 填充无
      strokeWidth: 1,
      perfectDrawEnabled: false,
    });
    // this.moudleText = new fabric.Text("机房模块" + num_str,{left:CenterX - 10,top:top,width:MoudleWidth - 2 * this.ABCWidth,height:MoudleHeight,textAlign:"center",fontSize:8,scale:0.6,fontWeight:"bold",selectable:false});
    this.moudleText = new Konva.Text({
      // 中间小方块文字
      text: this.name, // Left
      x: CenterX - (MoudleWidth - 2 * this.ABCWidth) / 2,
      y: y - MoudleHeight / 2,
      width: MoudleWidth - 2 * this.ABCWidth,
      height: MoudleHeight,
      align: 'center',
      fill: 'white',
      verticalAlign: 'middle',
      fontSize: (Width / 2560) * 16,
      // scaleX: (1 * Width) / 1536,
      // scaleY: (1 * Width) / 1536,
      // fontStyle: 'bold',
    });
    this.AText = new Konva.Text({
      text: `A${num_abc}`,
      x: this.Arect.x(),
      y: y - MoudleHeight / 2,
      width: this.ABCWidth,
      height: MoudleHeight,
      align: 'center',
      verticalAlign: 'middle',
      fontSize: (Width / 2560) * 16,
      // scaleX: (0.8 * Width) / 1536,
      // scaleY: (1 * Width) / 1536,
      fill: 'white',
      // fontStyle: 'bold',
    });
    this.BText = new Konva.Text({
      text: `B${num_abc}`,
      x: CenterX + CenterLineLen / 2 - this.ABCWidth,
      y: y - MoudleHeight / 2,
      width: this.ABCWidth,
      height: MoudleHeight,
      align: 'center',
      verticalAlign: 'middle',
      fontSize: (Width / 2560) * 16,
      // scaleX: (0.8 * Width) / 1536,
      // scaleY: (1 * Width) / 1536,
      fill: 'white',
      // fontStyle: 'bold',
    });
    this.power = new ThreePhasePower({
      name: this.getName4Parameter(),
      moduleName: this.name4Distinct,
      power,
    });
    this.A_load = false; // A供电
    this.B_load = false; // B供电
    this.opacity = 0;
  }

  getName4Parameter = () => `${this.name}: A${this.num_abc}/B${this.num_abc}三相功率(kw)`;
  translate = () => {
    switch (this.num_str) {
      case '四':
        return 4;
      // break;
      case '一':
        return 1;
      // break;
      case '二':
        return 2;
      // break;
      case '八':
        return 8;
      // break;
      default:
        return 4;
    }
  };
  getPrefix4Power = () => `${this.translate()}-${this.num_abc}-`;

  setIndex = (idx) => {
    this.power.setIndex(idx);
  };
  getPower = () => this.power;

  setPower = (power) => {
    this.power.setPower(power);
  };

  add2Canvas = () => {
    this.parentLayer.add(
      this.Arect,
      this.Brect,
      this.centerRect,
      this.AText,
      this.BText,
      this.moudleText,
    );
    return this;
  };

  /** 线路状态变化更改模块颜色变化 */
  setState_A = (A_state) => {
    // console.log(`${this.name4Distinct}修改模块状态：A=${A_state},原来的模块状态：${this.A_load}`);
    if (A_state === 0 && this.A_load) {
      const originJudgeDoubleRoad = this.judgeDoubleRoad();
      this.A_load = false;
      this.Arect.setAttr('fill', 'green');
      if (this.opacity === 1) {
        this.judgeRisk(true, originJudgeDoubleRoad);
      }
    } else if (A_state === 1 && !this.A_load) {
      const originJudgeDoubleRoad = this.judgeDoubleRoad();
      this.A_load = true;
      this.Arect.setAttr('fill', '#065F9E');
      if (this.opacity === 1) {
        this.judgeRisk(true, originJudgeDoubleRoad);
      }
    } else {
      // console.log(`不需要修改模块${this.name4Distinct}的状态`);
    }
    this.Arect.draw();
    this.AText.draw();
  };

  setState_B = (B_state) => {
    // console.log(`${this.name4Distinct}修改模块状态：B=${B_state},原来的模块状态：${this.B_load}`);
    if (B_state === 0 && this.B_load) {
      const originJudgeDoubleRoad = this.judgeDoubleRoad();
      this.B_load = false;
      this.Brect.setAttr('fill', 'green');
      if (this.opacity === 1) {
        this.judgeRisk(false, originJudgeDoubleRoad);
      }
    } else if (B_state === 1 && !this.B_load) {
      const originJudgeDoubleRoad = this.judgeDoubleRoad();
      this.B_load = true;
      this.Brect.setAttr('fill', '#DCA311');
      if (this.opacity === 1) {
        this.judgeRisk(false, originJudgeDoubleRoad);
      }
    } else {
      // console.log(`不需要修改模块${this.name4Distinct}的状态`);
    }
    this.Brect.draw();
    this.BText.draw();
  };

  /**
   * 模块修改A相或者B相状态的时候需要判断系统是否有风险
   * 如果全部失电或者双侧供电的时候，需要提示原来是哪个告警消除
   * A_change字段表示是A相变化触发函数，还是B相变化触发函数
   * this.opacity=1的时候才需要调用该函数,函数内部不需要判断
   *
   * */
  judgeRisk = (A_change) => {
    const { setSimulateLogs } = this.props;
    if (this.A_load && this.B_load) {
      // 风险消除
      if (A_change) {
        // 如果是A相变化，说明不均衡告警属于B
        if (!this.init) {
          // this.menu.insertLog('提示', `${this.name}A${this.num_abc}电流不均衡告警已消除！`);
          setSimulateLogs({
            time: moment().format('YYYY/MM/DD HH:mm'),
            status: '提示',
            value: `${this.name}A${this.num_abc}电流不均衡告警已消除！`,
          });
        }
      } else if (!this.init) {
        // this.menu.insertLog('提示', `${this.name}B${this.num_abc}电流不均衡告警已消除！`);
        setSimulateLogs({
          time: moment().format('YYYY/MM/DD HH:mm'),
          status: '提示',
          value: `${this.name}B${this.num_abc}电流不均衡告警已消除！`,
        });
      }

      if (this.init) {
        this.init = false;
      }
    } else if (this.A_load) {
      // A相不均衡
      if (!this.init) {
        // this.menu.insertLog('风险', `${this.name}B${this.num_abc}电流不均衡！`);
        setSimulateLogs({
          time: moment().format('YYYY/MM/DD HH:mm'),
          status: '风险',
          value: `${this.name}B${this.num_abc}电流不均衡！`,
        });
      }
    } else if (this.B_load) {
      // B相不均衡
      if (!this.init) {
        // this.menu.insertLog('风险', `${this.name}A${this.num_abc}电流不均衡！`);
        setSimulateLogs({
          time: moment().format('YYYY/MM/DD HH:mm'),
          status: '风险',
          value: `${this.name}A${this.num_abc}电流不均衡！`,
        });
      }
    } else if (!this.init) {
      // 风险消除
      // this.menu.insertLog('提示', `${this.name}A${this.num_abc}/B${this.num_abc}失电！`);
      setSimulateLogs({
        time: moment().format('YYYY/MM/DD HH:mm'),
        status: '提示',
        value: `${this.name}A${this.num_abc}/B${this.num_abc}失电！`,
      });
      // if(A_change){//都没有通电的时候如果是A变化，那么就是A的电流不均衡提示
      //     if(!this.init){menu.insertLog("提示",`${this.name}A${this.num_abc}电流不均衡告警已消除！`);}
      // }else{
      //     if(!this.init){menu.insertLog("提示",`${this.name}B${this.num_abc}电流不均衡告警已消除！`);}
      // }
    }
  };

  /**
   * 判断是否是双路供电
   */

  judgeDoubleRoad = () => {
    if (this.A_load && this.B_load) {
      return 2; // 双路供电
    }
    if (this.A_load || this.B_load) {
      return 1; // 单路
    }
    return 0; // 不供电
  };

  destroy = () => {
    this.opacity = 0;
    [
      this.Arect,
      this.Brect,
      this.centerRect,
      this.AText,
      this.BText,
      this.moudleText,
    ].forEach((r) => r.setAttr('opacity', 0));
  };
  show = () => {
    this.opacity = 1;
    [
      this.Arect,
      this.Brect,
      this.centerRect,
      this.AText,
      this.BText,
      this.moudleText,
    ].forEach((r) => r.setAttr('opacity', 1));
  };
}
export default Module;
