/* eslint-disable default-case */
/* eslint-disable no-duplicate-case */
import React, { Component, PureComponent } from 'react';

import {
  Form,
  Input,
  Tooltip,
  Icon,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
} from 'antd';
import MemberHandle from '..';
import styles from './Index.scss';

const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;

class MemberForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      this.props.regist(values);
      if (!err) {
        console.log('Received values of form: ', values);
        this.props.isEdit ? this.props.updateUser(values) : this.props.regist(values);
        this.props.isEdit ? console.log('更新') : console.log('新增');
        this.props.handleCancel(e);
      }
    });
    this.props.handleCancel(e);
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('与第一次输入的密码不同!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  handleWebsiteChange = (value) => {
    let autoCompleteResult;
    if (!value) {
      autoCompleteResult = [];
    } else {
      autoCompleteResult = ['.com', '.org', '.net'].map((domain) => `${value}${domain}`);
    }
    this.setState({ autoCompleteResult });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isEdit, selectUserData } = this.props;
    const { autoCompleteResult } = this.state;
    console.log('isEdit:', isEdit);
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: '86',
    })(
      <Select style={{ width: 70 }}>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>,
    );

    const websiteOptions = autoCompleteResult.map((website) => (
      <AutoCompleteOption key={website}>{website}</AutoCompleteOption>
    ));

    return (
      <div className={styles.container}>
        <Form {...formItemLayout} onSubmit={this.handleSubmit} className={styles.modelForm}>
          <Form.Item label="登陆账号">
            {getFieldDecorator('account', {
              rules: [
                {
                  required: true,
                  message: '请输入学员的登陆账号!',
                },
              ],
              initialValue: isEdit ? selectUserData.userName : '',
            })(<Input />)}
          </Form.Item>
          <Form.Item label="密码" hasFeedback>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: !isEdit,
                  message: '请输入学员的登陆密码!',
                },
                {
                  validator: this.validateToNextPassword,
                },
              ],
              initialValue: isEdit ? '不修改可跳过' : '',
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item label="确认密码" hasFeedback>
            {getFieldDecorator('confirm', {
              rules: [
                {
                  required: !isEdit,
                  message: '与第一次输入密码不同!',
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
              initialValue: isEdit ? '不修改可跳过' : '',
            })(<Input.Password onBlur={this.handleConfirmBlur} />)}
          </Form.Item>
          <Form.Item label={<span>学员姓名&nbsp;</span>}>
            {getFieldDecorator('学员姓名', {
              rules: [{ required: true, message: '请输入学员姓名!', whitespace: true }],
              initialValue: isEdit ? selectUserData.userName : '',
            })(<Input />)}
          </Form.Item>
          <Form.Item label="联系电话">
            {getFieldDecorator('mobile', {
              rules: [{ required: true, message: '请输入学员联系电话!' }],
              initialValue: isEdit ? selectUserData.mobile : '',
            })(<Input addonBefore={prefixSelector} style={{ width: '100%' }} />)}
          </Form.Item>
          <Form.Item label={<span>学期&nbsp;</span>}>
            {getFieldDecorator('term', {
              rules: [{ required: true, message: '请输入学员所在学期!', whitespace: true }],
              initialValue: isEdit ? selectUserData.term : '',
            })(<Input />)}
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              {isEdit ? '修改' : '添加'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Form.create()(MemberForm);
