/* --
 --- Feature：ATS开关
 --- Author: arya
--*/

import React from 'react';

class ATS extends React.Component {
  constructor(props) {
    super(props);
    const { left, top, svgUid, status } = props;
    // console.log(`status:${status}`);
    this.ATSState = status; // 0--自投自复，1--自投手复，2--手动
    this.left = left + 20;
    this.top = top + 33;
    this.svgUid = svgUid;
    this.increase = true; // state的变化趋势

    this.ATS_WIDTH = 600;
    this.ATS_HEIGHT = 300;
    this.scale = 0.5;
    this.scaleX = 0.22;
    this.scaleY = 0.33;
  }
  // 如果不以left 和top为计算基准
  isPointInPath = (x, y) =>
    x - this.getConfig().x >= 0 &&
    x - this.getConfig().x <= this.ATS_WIDTH * this.scaleX &&
    y - this.getConfig().y >= 0 &&
    y - this.getConfig().y <= this.ATS_HEIGHT * this.scaleY;

  getConfig = () => ({
    x: this.left - (this.ATS_WIDTH * this.scaleX) / 2,
    y: this.top - this.ATS_HEIGHT * this.scaleY,
    width: this.ATS_WIDTH,
    height: this.ATS_HEIGHT,
    scaleX: this.scaleX,
    scaleY: this.scaleY,
    selectable: false,
    svgUid: this.svgUid,
  });

  onclick = () => {
    if (this.increase) {
      this.ATSState = this.ATSState + 1; // 更新状态
    } else {
      this.ATSState = this.ATSState - 1;
    }

    if (this.ATSState === 2) {
      this.increase = false;
      // console.log(`${this.svgUid}当前状态是：手动`);
    } else if (this.ATSState === 1) {
      // console.log(`${this.svgUid}当前状态是：自投手复`);
    } else if (this.ATSState === 0) {
      this.increase = true;
      // console.log(`${this.svgUid}当前状态是：自投自复`);
    }
  };
}

export default ATS;
