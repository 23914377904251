/* eslint-disable default-case */
/* eslint-disable no-duplicate-case */
import React, { Component } from 'react';
import { Divider, Table, Tag, Modal, Descriptions, Badge, Button } from 'antd';
import { connect } from 'react-redux';
import withStore from '@/store/withStore';
import moment from 'moment';
import styles from './index.scss';
import fetch from '../../utils/fetch';
import SearchBar from './searchBar/Index';
import MemberFormPanel from './MemberFormPanel/Index';

const columns = [];
const { confirm } = Modal;

class MemberHandle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createVisible: false,
      editVisible: false,
      viewVisible: false,
      clickedNo: '',
      isEdit: false,
      selectUserData: {},
    };
    this.columns = [
      {
        title: '姓名',
        dataIndex: 'userName',
        key: 'userName',
        render: (text) => <a>{text}</a>,
      },
      {
        title: '学期',
        dataIndex: 'term',
        key: 'term',
      },
      {
        title: '上次登陆日期',
        dataIndex: 'lastLoginDate',
        key: 'lastLoginDate',
        render: (lastLoginDate) => moment(lastLoginDate).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        title: '账号状态',
        key: 'loginFlag',
        dataIndex: 'loginFlag',
        render: (loginFlag) => (
          <span>
            <Tag color={loginFlag === 0 ? 'green' : 'volcano'} key={loginFlag}>
              {loginFlag === 0 ? '使用中' : '已禁用'}
            </Tag>
          </span>
        ),
      },
      {
        title: '操作',
        key: 'action',
        render: (text, record) => (
          <span>
            <a key="i" onClick={this.cellActionClick}>
              查看
            </a>
            <Divider type="vertical" />
            <a key="u" onClick={this.cellActionClick}>
              编辑
            </a>
            <Divider type="vertical" />
            <a key="d" onClick={this.cellActionClick}>
              删除
            </a>
          </span>
        ),
      },
    ];
    this.desColumns = [
      {
        title: '课程名',
        dataIndex: 'name',
        key: 'name',
        render: (text) => <a>{text}</a>,
      },
      {
        title: '听课次数',
        dataIndex: 'playedTime',
        key: 'playedTime',
        render: (text) => <a>{text}</a>,
      },
      {
        title: '听课总时长',
        dataIndex: 'durationTime',
        key: 'durationTime',
        render: (text) => moment.duration(text, 'milliseconds').minutes() + '分钟', // .format('HH:mm:ss'),
      },
    ];
  }

  componentDidMount() {}

  showModal = (visi) => {
    if (visi === 1) {
      this.setState({
        viewVisible: true,
      });
    } else if (visi === 2) {
      this.setState({
        editVisible: true,
      });
    } else if (visi === 4) {
      this.setState({
        createVisible: true,
      });
    }
  };

  showCreateModal = () => {
    this.setState({
      createVisible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
      viewVisible: false,
      editVisible: false,
      createVisible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
      viewVisible: false,
      editVisible: false,
      createVisible: false,
    });
  };

  showDeleteConfirm(id) {
    confirm({
      title: '确定要删除该学员?',
      content: '无法恢复，学员将无法登陆',
      okText: '删除',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        console.log('OK', id);
        this.props.disableUser(id);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  cellActionClick = (e) => {
    e.preventDefault();
    let rowKey = e.target.parentNode.parentNode.parentNode.getAttribute('data-row-key');
    if (e.target.innerHTML.includes('查看')) {
      let id = this.props.data[rowKey].id;
      const dic = { videoId: null, userId: id };
      this.props.getLearnInfo(dic);
      this.setState({ clickedNo: rowKey, selectUserData: this.props.data[rowKey] });
      this.showModal(1);
      console.log('1');
    } else if (e.target.innerHTML.includes('编辑')) {
      this.setState({ clickedNo: rowKey, selectUserData: this.props.data[rowKey], isEdit: true });
      this.showModal(2);
      console.log('2', this.state.isEdit);
    } else {
      this.setState({ clickedNo: rowKey, selectUserData: this.props.data[rowKey] });
      this.showDeleteConfirm(this.props.data[rowKey].id);
      console.log('3');
    }
    console.log(e.target.parentNode.parentNode.parentNode.getAttribute('data-row-key')); // data-row-key
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };

  handleSelectChange = (value) => {
    console.log(value);
    this.props.form.setFieldsValue({
      note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
    });
  };

  onShowSizeChange = (current, pageSize) => {
    console.log(current, pageSize);
  };

  render() {
    const { getUserList, data, regist, learnInfo, downloadExcel } = this.props;
    const { clickedNo, isEdit, selectUserData } = this.state;
    console.log('selectUserData', selectUserData, isEdit);
    return (
      <>
        <div className={styles.container}>
          <SearchBar
            getUserList={getUserList}
            showCreateModal={this.showCreateModal}
            downloadTemplate={downloadExcel}
          />
          <div className={styles.table}>
            <Table
              columns={this.columns}
              dataSource={data}
              rowKey={data.id}
              size="small"
              scroll={false}
              pagination={{ showSizeChanger: true, pageSize: '20' }}
            />
          </div>
          <Modal
            title="新增学员"
            visible={this.state.createVisible}
            // onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <MemberFormPanel regist={regist} handleCancel={this.handleCancel} />
          </Modal>
          <Modal
            title="学员信息"
            visible={this.state.viewVisible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            className={styles.viewModel}
            width={'6.4rem'}
          >
            <Descriptions bordered size="small" layout="vertical" column={4}>
              <Descriptions.Item label="姓名">{selectUserData.userName}</Descriptions.Item>
              <Descriptions.Item label="学期">{selectUserData.term}</Descriptions.Item>
              <Descriptions.Item label="上次登陆日期">
                {moment(selectUserData.lastLoginDate).format('YYYY-MM-DD HH:mm:ss')}
              </Descriptions.Item>
              <Descriptions.Item label="账号状态">
                <Badge
                  status={selectUserData.loginFlag === 0 ? 'processing' : 'Error'}
                  text={selectUserData.loginFlag === 0 ? '使用中' : '禁用'}
                />
              </Descriptions.Item>
              <Descriptions.Item label="听课统计">
                <Table columns={this.desColumns} dataSource={learnInfo} rowKey={learnInfo.id} />
              </Descriptions.Item>
            </Descriptions>
          </Modal>
          <Modal
            title="学员信息编辑"
            visible={this.state.editVisible}
            // onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <MemberFormPanel
              regist={regist}
              isEdit={isEdit}
              selectUserData={selectUserData}
              handleCancel={this.handleCancel}
            />
          </Modal>
        </div>
      </>
    );
  }
}

export default connect(...withStore('basic', 'MemberHandle'))(MemberHandle);
