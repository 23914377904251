import fetch from '@/utils/fetch';
import { currentEnv } from '../../basic/Common';

// const ENV_BaiduYun = 'https://study.dccloudclass.com/api/';
// const ENV_BaiduYunLocal = 'http://61.147.93.160:8080/opscoursesystem4test/';
// const ENV_LocalDev = 'http://192.168.1.102:8080/';
// const currentEnv = ENV_BaiduYun;

const model = {
  state: {
    // 用户
    accountInfo: {}, // 当前登录账号数据
    loading: false,
    excelExportProgress: 0,
    lastPath: '',
    arVideoCourseList: {},
    vrVideoCourseList: {},
    videoCourseList: {},
    videoInfo: {},
  },
  reducers: {
    setState(state, partialState) {
      return { ...state, ...partialState };
    },
  },
  actions: ({ dispatch: { videoCourse } }) => ({
    // 获取当前登录账户相关数据 (http://wiki.sftcwl.com/pages/viewpage.action?pageId=3225256)

    async getCourseList() {
      const data = await fetch(currentEnv + 'videoCourse/getAll');

      let result = [];
      const chunk = 6;
      //console.log(result);
      if (data) {
        for (let i = 0, j = data.length; i < j; i += chunk) {
          result.push(data.slice(i, i + chunk));
        }
        videoCourse.setState({ videoCourseList: result });
      }
    },
    async getVRCourseList() {
      const data = await fetch(currentEnv + 'videoCourse/getAllVRVideo');

      let result = [];
      const chunk = 6;
      //console.log(result);
      if (data) {
        for (let i = 0, j = data.length; i < j; i += chunk) {
          result.push(data.slice(i, i + chunk));
        }
        videoCourse.setState({ vrVideoCourseList: result });
      }
    },
    async getARCourseList() {
      const data = await fetch(currentEnv + 'videoCourse/getAllARVideo');

      let result = [];
      const chunk = 6;
      //console.log(result);
      if (data) {
        for (let i = 0, j = data.length; i < j; i += chunk) {
          result.push(data.slice(i, i + chunk));
        }
        videoCourse.setState({ arVideoCourseList: result });
      }
    },
    async setLastPath(path) {
      videoCourse.setState({ lastPath: path });
    },
    async getVideoInfo(videoId) {
      const data = await fetch(currentEnv + 'videoCourse/'.concat(videoId));
      //console.log('videoInfo:', data);
      videoCourse.setState({ videoInfo: data });
    },
  }),
};

export default model;
