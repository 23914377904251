/* eslint-disable default-case */
/* eslint-disable no-duplicate-case */
import React, { Component, PureComponent } from 'react';
import {
  Form,
  Select,
  Input,
  Modal,
  Descriptions,
  Badge,
  Button,
  Upload,
  Icon,
  message,
} from 'antd';
import styles from './Index.scss';

const { Option } = Select;

class SearchBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.props.getUserList(values);
      }
    });
  };

  handleSelectChange = (value) => {
    console.log(value);
    this.props.form.setFieldsValue({
      note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  download = (e) => {
    console.log('');
    this.props.downloadTemplate('user_upload_template.xls');
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { showCreateModal, downloadTemplate } = this.props;
    const props = {
      name: 'file',
      action: 'http://localhost:8080/excel/import.do',
      headers: {
        authorization: 'authorization-text',
      },
      accept: '.xlsx, .xls',
      onChange(info) {
        if (info.file.status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };
    return (
      <>
        <div className={styles.container}>
          <div className={styles.form}>
            <Form layout="inline" onSubmit={this.handleSubmit} className={styles.searchForm}>
              <Form.Item label="姓名">
                {getFieldDecorator('userName', {
                  rules: [{ required: false, message: '请输入要查询的姓名!', default: 'null' }],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="学期">
                {getFieldDecorator('term', {
                  rules: [{ required: false, message: '请输入要查询的学期!', default: 'null' }],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="是否可用" wrapperCol={{ span: 200 }}>
                {getFieldDecorator('loginFlag', {
                  rules: [{ required: false, message: '选择是否可用!', default: 'null' }],
                })(
                  <Select
                    placeholder="选择账号是否可用"
                    style={{ width: 180 }}
                    onChange={this.handleSelectChange}
                  >
                    <Option value="true">使用中</Option>
                    <Option value="false">停用</Option>
                  </Select>,
                )}
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  搜索
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={showCreateModal}>
                  新增学员
                </Button>
              </Form.Item>
              <Form.Item>
                <Upload {...props}>
                  <Button>
                    <Icon type="upload" /> 批量新增
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item>
                <Button type="primary" onClick={this.download}>
                  下载模板
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </>
    );
  }
}

export default Form.create()(SearchBar);
