import fetch from '@/utils/fetch';

const model = {
  state: {
    // 用户
    accountInfo: {}, // 当前登录账号数据
    loading: false,
    excelExportProgress: 0,
    lastPath: '',
  },
  reducers: {
    setState(state, partialState) {
      return { ...state, ...partialState };
    },
  },
  actions: ({ dispatch: { basic } }) => ({
    // 获取当前登录账户相关数据 (http://wiki.sftcwl.com/pages/viewpage.action?pageId=3225256)
    async setLastPath(path) {
      basic.setState({ lastPath: path });
    },
  }),
};

export default model;
