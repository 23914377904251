/* --
 --- Feature：页面中两个按钮
 --- Author: arya
--*/

import React from 'react';
import Konva from 'konva';
import { Width } from '../Constants';

class Button extends React.Component {
  constructor(props) {
    super(props);
    const { width, height, x, y, contentList, contentIndex, parentLayer } = props;
    this.parentLayer = parentLayer;
    this.config = {
      x,
      y,
      fill: 'green',
      align: 'center', // 字体对齐方式
      verticalAlign: 'middle',
      width,
      height,
      stroke: 'white',
      strokeWidth: 0.8,
      fontSize: (20 * Width) / 2560,
      perfectDrawEnabled: false,
    };
    this.contentList = contentList;
    this.contentIndex = contentIndex;
    this.rect = new Konva.Rect(this.config);
    // this.config.text = this.contentList[contentIndex];
    this.text = new Konva.Text({
      text: this.contentList[contentIndex],
      x,
      y,
      fill: 'white',
      align: 'center', // 字体对齐方式
      verticalAlign: 'middle',
      width,
      height,
      stroke: 'white',
      strokeWidth: 0.6,
      fontSize: (20 * Width) / 2560,
      perfectDrawEnabled: false,
    });
  }

  /** 元素是否是展示状态 */
  isShow = () => this.contentIndex === 1;
  add2Canvas = () => {
    this.parentLayer.add(this.rect);
    this.parentLayer.add(this.text);
    this.text.draw();
    return this;
  };
  click = (callback) => {
    // callback回调函数
    this.contentIndex = (this.contentIndex + 1) % this.contentList.length;
    this.text.destroy();
    this.config.text = this.contentList[this.contentIndex];
    this.text = new Konva.Text({
      text: this.contentList[this.contentIndex],
      x: this.props.x,
      y: this.props.y,
      fill: 'white',
      align: 'center', // 字体对齐方式
      verticalAlign: 'middle',
      width: this.props.width,
      height: this.props.height,
      stroke: 'white',
      strokeWidth: 0.8,
      fontSize: (20 * Width) / 2560,
      perfectDrawEnabled: false,
    });
    this.parentLayer.add(this.text);
    this.text.draw();
    callback(this.contentIndex);
  };

  isPointInPath = (x1, y1) => {
    const { x, y, width, height } = this.rect.getClientRect();
    return x1 - x >= 0 && x1 - x <= width && y1 - y >= 0 && y1 - y <= height;
  };
}
export default Button;
