/* --
 --- Feature：三种常用类型开关
 --- Author: arya
 * // fixme 取消注释
--*/

import React from 'react';
import Konva from 'konva';
import moment from 'moment';
import { INIT, Width } from '../Constants';

class Switch extends React.Component {
  constructor(props) {
    super(props);
    const { baseline, angle, closed, load, state, name, type, left, menu, parentLayer } = props;
    this.menu = menu;
    this.parentLayer = parentLayer;

    this.name = name;
    this.left = left;
    this.currentLimit = 500;
    this.SwitchWidth = 10;
    this.timer_limit = 3000;
    this.clickTimeout = 100; // ms
    if (INIT) {
      this.add2Database();
    } else {
      this.getFromDatabaseByName();
    }
    this.baseline = baseline;

    // console.log(name, 'type:', type, 'baseline', baseline);
    this.load = load; // 负载每个开关闭合或者断开更新上口开关的负载状态，true--有负载，false--没有负载
    this.state = state; // 开关的状态，0--无压无流,1--电压，2--电流
    this.opacity = 1;
    if (typeof type !== 'undefined') {
      // 3种开关的类型
      this.type = type;
    } else {
      this.type = 1;
    }
    this.scale = this.getScale();
    this.partialScale = this.getPartialScale();
    this.SwitchHeight = this.getSwitchHeight();

    this.config = this.getConfig(
      this.SwitchWidth,
      this.SwitchHeight,
      this.baseline,
      this.name,
      angle,
    );
    if (this.type === 2) {
      this.path1 = new Konva.Path({
        data: 'm114 0 l50 50 m0 -50 l-50 50',
        stroke: 'white',
        fill: 'transparent',
        strokeWidth: 10,
        perfectDrawEnabled: false,
      });
      this.pathON = new Konva.Path({
        data: 'm139 0   l 0 150',
        stroke: 'red',
        fill: 'transparent',
        strokeWidth: 15,
        originX: 'center',
        perfectDrawEnabled: false,
      });
      this.pathOFF = new Konva.Path({
        data: 'm109 0 l31 150',
        stroke: 'green',
        fill: 'transparent',
        strokeWidth: 15,
        perfectDrawEnabled: false,
      });
    } else if (this.type === 3) {
      this.path1 = new Konva.Path({
        data: 'm139 0 l0 44 m-24 0 l48 0 m-42 0 a18,18 0 1,0 36,0',
        stroke: 'white',
        fill: 'transparent',
        strokeWidth: 7,
        perfectDrawEnabled: false,
      });
      this.pathON = new Konva.Path({
        data: 'm139 0 l 0 150',
        stroke: 'red',
        fill: 'transparent',
        strokeWidth: 15,
        originX: 'center',
        perfectDrawEnabled: false,
      });
      this.pathOFF = new Konva.Path({
        data: 'm100 0 l40 150',
        stroke: 'green',
        fill: 'transparent',
        strokeWidth: 15,
        perfectDrawEnabled: false,
      });
    } else {
      this.path1 = new Konva.Path({
        data:
          'm169 37l-30-37l-30 37m0 18.5l30 -37l30 37m-30 -37l0 55.5m-10 -10l20 20m0 -20l-20 20m5 120l0 55.5m-30 -37l30 37l30 -37m0 18.5l-30 37l-30 -37',
        stroke: 'white',
        fill: 'transparent',
        strokeWidth: 4,
        perfectDrawEnabled: false,
      });
      this.pathON = new Konva.Path({
        data: 'm139 74l 0 125',
        stroke: 'red',
        fill: 'transparent',
        strokeWidth: 15,
        originX: 'center',
        perfectDrawEnabled: false,
      });
      this.pathOFF = new Konva.Path({
        data: 'm109 80l31 120',
        stroke: 'green',
        fill: 'transparent',
        strokeWidth: 15,
        perfectDrawEnabled: false,
      });
    }
    this.on = closed; // false--断开，true--闭合
    this.timer = this.timer_limit;

    if (this.name !== '') {
      const shadowOffset = 0.1;
      const blur = 0.1;
      let fontColor;
      let rectWidth;
      let strokeBorder = 'transparent';
      let strokeBorderWidth = 1;
      if (this.left === true) {
        // 此处为高压处 A路开关 根据父级传的this.left值判断
        fontColor = '#065F9E';
        rectWidth = 50;
        if (this.name.indexOf('AA-T') > -1) {
          // A路上端低压配电柜
          rectWidth = this.name.length * 14;
        }
        if (this.name.indexOf('AT') > -1) {
          // A路下端低压配电柜
          rectWidth = this.name.length * 16;
        }
      } else if (this.left === false) {
        // 此处为高压处 B路开关  根据父级传的this.left值判断
        fontColor = '#DCA311';
        rectWidth = 50;
        if (this.name.indexOf('AA-T6') > -1) {
          // B路上端低压配电柜
          rectWidth = this.name.length * 14;
        }
        if (this.name.indexOf('AT') > -1) {
          // B路下端低压配电柜
          rectWidth = this.name.length * 16;
        }
      }
      if (this.name.indexOf('AH') > -1) {
        // A路柴油发电机断路器
        rectWidth = this.name.length * 16;
        fontColor = '';
        strokeBorder = 'red';
        strokeBorderWidth = 2;
      }
      this.name_rect = new Konva.Rect({
        x: this.baseline.getCoordinatesByPoints().x1 + 10,
        y: this.config.y,
        width: (rectWidth * Width) / 2560,
        height: (26 * Width) / 2560,
        align: 'center',
        verticalAlign: 'middle',
        fill: fontColor,
        stroke: strokeBorder,
        strokeWidth: strokeBorderWidth,
        fontSize: (26 * Width) / 2560,
        perfectDrawEnabled: false,
      });
      this.name_object = new Konva.Text({
        text: this.name,
        x: this.baseline.getCoordinatesByPoints().x1 + 10,
        y: this.config.y,
        width: (rectWidth * Width) / 2560,
        height: (26 * Width) / 2560,
        fontSize: (26 * Width) / 2560,
        align: 'center',
        verticalAlign: 'middle',
        fill: 'white',
        shadowColor: 'white',
        shadowOpacity: this.opacity,
        shadowOffset: { x: shadowOffset, y: shadowOffset },
        shadowBlur: blur,
      });
    }
  }

  updateSwitchByName = () => {
    // $.ajax({
    //   url: `${BASEURL}/switch/update`,
    //   type: 'POST',
    //   data: { switch_name: this.name, current: this.currentLimit, timeout: this.clickTimeout },
    //   dataType: 'json',
    //   success: (results) => {
    //     if (results.errCode === 0) {
    //       console.log('update switch by name success ');
    //     } else {
    //       console.error(results.msg);
    //     }
    //   },
    //   error: (error) => {
    //     console.error(error);
    //   },
    // });
  };

  getFromDatabaseByName = () => {
    // $.ajax({
    //   url: `${BASEURL}/switch/get`,
    //   type: 'POST',
    //   data: { switch_name: this.name },
    //   dataType: 'json',
    //   success: (results) => {
    //     if (results.errCode === 0) {
    //       this.currentLimit = results.data.current;
    //       this.clickTimeout = results.data.timeout;
    //     } else {
    //       console.error(results.msg);
    //     }
    //   },
    //   error: (error) => {
    //     console.error(error);
    //   },
    // });
  };

  add2Database = () => {
    // const data = { switch_name: this.name, current: this.currentLimit, timeout: this.clickTimeout };
    // $.ajax({
    //   url: `${BASEURL}/switch/add`,
    //   type: 'POST',
    //   data,
    //   dataType: 'json',
    //   success: (results) => {
    //     if (results.errCode === 0) {
    //       console.log('switch success');
    //     } else {
    //       console.error(results.msg);
    //     }
    //   },
    //   error: (error) => {
    //     console.error(error);
    //   },
    // });
  };

  getPartialScale = () => {
    if (this.type === 2) {
      return 0.14;
    }
    if (this.type === 3) {
      return 0.25;
    }
    return 0;
  };
  getSwitchHeight = () => {
    if (this.type === 1) return 56;
    return 29;
  };
  getScale = () => {
    if (this.type === 1) return 0.2;
    return 0.15;
  };

  add2Canvas = (click) => {
    // console.log('add', click);
    if (typeof click === 'undefined') {
      this.path1.setAttrs(this.config);
      if (this.type === 3) {
        this.path1.setAttr('x', this.config.x + 6);
        this.path1.setAttr('y', this.baseline.getCoordinatesByPoints().y2);
      } else if (this.type === 2) {
        this.path1.setAttr('x', this.config.x + 6);
        this.path1.setAttr('y', this.baseline.getCoordinatesByPoints().y2);
      }
      this.path1.scale({ x: this.scale, y: this.scale });

      // this.path1.scale(this.scale);
      this.parentLayer.add(this.path1);
    }

    if (this.on) {
      // console.log("添加闭合开关");
      this.pathON.setAttrs(this.config);
      // this.pathON.setAttr('x', this.config.x + 6);
      if (this.type === 2) {
        this.pathON.setAttr('x', this.config.x + 6);
        this.pathON.setAttr(
          'y',
          this.baseline.getCoordinatesByPoints().y2 + this.partialScale * this.SwitchHeight + 7,
        );
      } else if (this.type === 3) {
        this.pathON.setAttr('x', this.config.x + 6);
        this.pathON.setAttr(
          'y',
          this.baseline.getCoordinatesByPoints().y2 + this.partialScale * this.SwitchHeight + 7,
        );
      }
      this.pathON.scale({ x: this.scale, y: this.scale });

      // this.pathON.scale(this.scale);
      this.pathOFF.remove();
      // const rect = this.pathOFF.getClientRect();
      // this.parentLayer.clear({ x: rect.x, y: rect.y, width: rect.width, height: rect.height });
      this.parentLayer.add(this.pathON);
      this.pathON.draw();
    } else {
      // console.log('添加断开开关');
      this.pathOFF.setAttrs(this.config);
      this.pathOFF.setAttr('x', this.config.x - 1);
      if (this.type === 2) {
        this.pathOFF.setAttr('x', this.config.x + 6);
        this.pathOFF.setAttr(
          'y',
          this.baseline.getCoordinatesByPoints().y2 + this.partialScale * this.SwitchHeight + 7,
        );
      } else if (this.type === 3) {
        this.pathOFF.setAttr('x', this.config.x + 6);
        this.pathOFF.setAttr(
          'y',
          this.baseline.getCoordinatesByPoints().y2 + this.partialScale * this.SwitchHeight + 7,
        );
      }
      this.pathOFF.scale({ x: this.scale, y: this.scale });

      // this.pathOFF.scale(this.scale);
      this.pathON.remove();
      // const rect = this.pathON.getClientRect();
      // this.parentLayer.clear({ x: rect.x, y: rect.y, width: rect.width, height: rect.height });
      this.parentLayer.add(this.pathOFF);
      this.pathOFF.draw();
    }
    return this;
  };

  setLoad = (load) => {
    this.load = load;
  };
  setOpacity = () => {
    this.path1.setAttr('opacity', this.opacity);
    this.pathOFF.setAttr('opacity', this.opacity);
    this.pathON.setAttr('opacity', this.opacity);
    if (typeof this.name_object !== 'undefined') {
      this.name_object.setAttr('opacity', this.opacity);
    }
    if (typeof this.name_rect !== 'undefined') {
      this.name_rect.setAttr('opacity', this.opacity);
    }
  };
  destroy = () => {
    this.opacity = 0;
    this.setOpacity();
  };

  show = () => {
    this.opacity = 1;
    this.setOpacity();
  };

  addName2Canvas = () => {
    if (this.name_rect) {
      this.parentLayer.add(this.name_rect);
    }
    this.parentLayer.add(this.name_object);
    return this;
  };

  removeSwitch = () => {
    this.path1.remove();
    if (this.on) {
      this.pathON.remove();
    } else {
      this.pathOFF.remove();
    }
    return this;
  };
  getConfig = (width, height, baseline, uid, angle) => {
    const config = {
      x: baseline.getCoordinatesByPoints().x1 - 27, //
      y: baseline.getCoordinatesByPoints().y2,
      align: 'center',
      width,
      height,
      angle,
      svgUid: uid,
    };
    return config;
  };

  translateClick = () => {
    if (this.on) return '闭合';
    return '断开';
  };

  onclick = (closed) => {
    const { setSimulateLogs } = this.props;
    if (typeof closed === 'undefined') {
      if (this.on) {
        this.pathON.remove();
        // const rect = this.pathON.getClientRect();
        // this.parentLayer.clear({ x: rect.x, y: rect.y, width: rect.width, height: rect.height });
        // console.log(`开关${this.config.svgUid}原来是闭合状态`);
      } else {
        this.pathOFF.remove();
        // const rect = this.pathOFF.getClientRect();
        // this.parentLayer.clear({ x: rect.x, y: rect.y, width: rect.width, height: rect.height });
        // console.log(`开关${this.config.svgUid}原来是断开状态`);
      }
      this.on = !this.on;
      this.add2Canvas(true);
      this.parentLayer.batchDraw();
      if (this.opacity === 1) {
        if (this.type !== 2) {
          // fixme 取消注释
          // this.menu.insertLog('手动', this.name + this.translateClick());
          setSimulateLogs({
            time: moment().format('YYYY/MM/DD HH:mm'),
            status: '手动',
            value: this.name + this.translateClick(),
          });
        }
      }
      return true;
    }
    if (this.on === closed) {
      // console.log('操作相同不需要断开');
      return false;
    } //
    if (!closed) {
      // 这种情况是跳闸断开的情况//this.on==true
      this.pathON.remove();
      // const rect = this.pathON.getClientRect();
      // this.parentLayer.clear({ x: rect.x, y: rect.y, width: rect.width, height: rect.height });
      this.on = closed;
      this.add2Canvas(true);
      this.parentLayer.batchDraw();
      if (this.opacity === 1) {
        if (this.type !== 2) {
          // fixme 取消注释
          // this.menu.insertLog('自动', this.name + this.translateClick());
          setSimulateLogs({
            time: moment().format('YYYY/MM/DD HH:mm'),
            status: '自动',
            value: this.name + this.translateClick(),
          });
        }
      }
      return true;
    }
    // this.on==false//柴发闭合
    this.pathOFF.remove();
    // const rect = this.pathOFF.getClientRect();
    // this.parentLayer.clear({ x: rect.x, y: rect.y, width: rect.width, height: rect.height });
    this.on = closed;
    this.add2Canvas(true);
    if (this.opacity === 1) {
      if (this.type !== 2) {
        // fixme 取消注释
        // this.menu.insertLog('自动', this.name + this.translateClick());
        setSimulateLogs({
          time: moment().format('YYYY/MM/DD HH:mm'),
          status: '自动',
          value: this.name + this.translateClick(),
        });
      }
    }
    this.parentLayer.batchDraw();
    return false; // 没有操作
  };

  setSwitchState = (state) => {
    this.state = state;
  };

  isPointInPath = (x1, y1) => {
    // 这里只考虑了开关没有旋转的情况
    if (this.type === 1) {
      const rect = this.path1.getClientRect();
      const { x, y, width, height } = rect;
      return x1 - x >= 0 && x1 - x <= width && y1 - y >= 0 && y1 - y <= height;
      // return (
      //   x - this.path1.getClientRect().x >= 0 && x - this.path1.getClientRect().x <= this.path1.getClientRect().width && y - this.path1.getClientRect()
      //   x - this.path1.x() <= this.config.width && y - this.path1.y() <= this.config.height
      //   // Math.abs(this.path1.left - x) <= this.config.width / 2 &&
      //   // Math.abs(this.path1.top - y) <= this.config.height / 2
      // );
    }
    if (this.type === 2) {
      return (
        x1 - this.path1.getClientRect().x >= 0 &&
        x1 - this.path1.getClientRect().x <= this.config.width &&
        y1 - this.baseline.getCoordinatesByPoints().y2 >= 0 &&
        y1 - this.baseline.getCoordinatesByPoints().y2 <= this.SwitchHeight
        // Math.abs(this.path1.left - x) <= this.config.width / 2 &&
        // Math.abs(this.baseline.y2 + this.SwitchHeight / 2 - y) <= this.SwitchHeight / 2
      );
    }
    const path1Rect = this.path1.getClientRect();
    const path2Rect = this.pathOFF.getClientRect();
    const x = Math.min(path1Rect.x, path2Rect.x);
    const width = Math.max(path1Rect.width + path1Rect.x, path2Rect.width + path2Rect.x) - x;
    const height = path2Rect.y + path2Rect.height - path1Rect.y;
    return x1 - x >= 0 && x1 - x <= width && y1 - path1Rect.y >= 0 && y1 - path1Rect.y <= height;
  };

  countDown = (callback) =>
    // 倒计时结束之后触发回调函数
    setTimeout(callback, this.timer, this.state); // 返回一个ID可以取消倒计时执行回调函数，param1,param2传递给函数的其他参数

  clearCountDown = (timer_id) => {
    clearTimeout(timer_id);
  };

  render() {
    return <div />;
  }
}

export default Switch;
