import fetch from '@/utils/fetch';
// import filterNull from '@/utils/filterNull';

const model = {
  state: {
    shopList: [], // 店铺列表
    shopListTotal: 0, // 店铺总数
  },
  reducers: {
    setState(state, partialState) {
      return { ...state, ...partialState };
    },
  },
  actions: ({ dispatch: { home }, getState }) => ({
    // 获取店铺信息数据 (http://wiki.sftcwl.com/pages/viewpage.action?pageId=10100692)
    async getShopList() {
      const { shopListFilter } = getState().home;
      const data = await fetch('/account/merchant/shoplist', { params: shopListFilter });
      if (data) {
        const shopList = Object.entries(data.accountDetail).map(([id, item]) => ({ ...item, id }));
        // 设置店铺列表
        home.setState({ shopList, shopListTotal: data.total });
      }
    },
  }),
};

export default model;
