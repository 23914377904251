/* --
 --- Feature：低压模块组（4块）
 --- Author: arya
 // 取消注释
--*/
import React from 'react';
import moment from 'moment';
import Line from './base/Line';
import Switch from './base/Switch';
import UPS from './base/UPS';
import UPSUnit from './UPSUnit';

import {
  globalTimerMap,
  lineConfig,
  UPSWidth,
  SwitchHeight,
  H2,
  H1,
  UPSHeight,
  SwitchHeight1,
  MoudleSapce,
  MoudleHeight,
  MoudleWidth,
  CenterX,
} from './Constants';

class UPSGroup extends React.Component {
  constructor(props) {
    super(props);

    const {
      parent,
      baseline,
      left,
      ups_num,
      state,
      group,
      layer,
      flowLayer,
      trigger4Notice,
      setSimulateLogs,
      mouseMenuVisible,
      mouseMenuName,
      mouseMenuLevel,
      setVisible,
      setName,
      setLevel,
    } = props;

    this.parent = parent;
    this.parentLayer = layer;
    this.group = group; // 组号
    this.left = left;
    globalTimerMap.get(1).set(this.getGlobalTimerKeyInMap(), []);

    this.UPSGroupVoltage = state; // UPS组的干路电压状态
    this.load = false; // UPS组负载
    this.isShow = false; // 该模块是否可以看到

    this.UPSGroupWorkingState = false; // 当前UPS组的工作状态,true表示工作状态，false表示不工作状态
    this.ups_num = ups_num;
    this.groupName = this.getUPSGroupName();
    this.switchList = [];
    this.baseLineGroup = []; // UPS单元下面的竖线
    this.baseLineGroup1 = []; // 开关下面的竖线
    this.rowLineGroup = []; // 开关下面的横线
    this.switchGroup = []; // 竖线下面的开关
    this.timerMap4Trip = new Map();
    let line8_len = SwitchHeight + H2;
    if (typeof ups_num !== 'undefined' && ups_num === 3) {
      line8_len = 4 * H1 + SwitchHeight;
    }

    this.Line1 = new Line({
      lineConfig,
      points: [
        baseline.getCoordinatesByPoints().x1,
        baseline.getCoordinatesByPoints().y2 + SwitchHeight,
        baseline.getCoordinatesByPoints().x1,
        baseline.getCoordinatesByPoints().y2 + line8_len,
      ],
      direction: true,
      serialNum: 1,
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line2 = new Line({
      lineConfig,
      points: [
        this.Line1.getCoordinatesByPoints().x1,
        this.Line1.getCoordinatesByPoints().y2 + SwitchHeight,
        this.Line1.getCoordinatesByPoints().x1,
        this.Line1.getCoordinatesByPoints().y2 + SwitchHeight + H2,
      ],
      direction: true,
      serialNum: 2,
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    // baseline, angle, closed, load, state, name, type, menu, parentLayer
    const switch_names = this.getSwitchDesignationName();
    this.switch1 = new Switch({
      baseline,
      angle: 0,
      closed: false,
      load: false,
      state: 0,
      name: switch_names.switch1,
      type: 1,
      left: this.left,
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.switch2 = new Switch({
      baseline: this.Line1,
      angle: 0,
      closed: false,
      load: false,
      state: 0,
      name: switch_names.switch2,
      type: 1,
      left: this.left,
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();

    this.Line3 = new Line({
      lineConfig,
      points: [
        this.Line2.getCoordinatesByPoints().x1 - H2 - UPSWidth / 3,
        this.Line2.getCoordinatesByPoints().y2,
        this.Line2.getCoordinatesByPoints().x1,
        this.Line2.getCoordinatesByPoints().y2,
      ],
      direction: false,
      serialNum: 3,
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line4 = new Line({
      lineConfig,
      points: [
        this.Line3.getCoordinatesByPoints().x1 - UPSWidth / 3,
        this.Line3.getCoordinatesByPoints().y1,
        this.Line3.getCoordinatesByPoints().x1,
        this.Line3.getCoordinatesByPoints().y1,
      ],
      direction: false,
      serialNum: 4,
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line5 = new Line({
      lineConfig,
      points: [
        this.Line4.getCoordinatesByPoints().x1 - UPSWidth / 3 - H2,
        this.Line4.getCoordinatesByPoints().y1,
        this.Line4.getCoordinatesByPoints().x1,
        this.Line4.getCoordinatesByPoints().y1,
      ],
      direction: false,
      serialNum: 5,
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line5_1 = new Line({
      lineConfig,
      points: [
        this.Line5.getCoordinatesByPoints().x1 - H2,
        this.Line5.getCoordinatesByPoints().y1,
        this.Line5.getCoordinatesByPoints().x1,
        this.Line5.getCoordinatesByPoints().y1,
      ],
      direction: false,
      serialNum: '5_1',
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();

    this.Line6 = new Line({
      lineConfig,
      points: [
        this.Line2.getCoordinatesByPoints().x1,
        this.Line2.getCoordinatesByPoints().y2,
        this.Line2.getCoordinatesByPoints().x1 + H2 + UPSWidth / 3,
        this.Line3.getCoordinatesByPoints().y1,
      ],
      direction: true,
      serialNum: 6,
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line7 = new Line({
      lineConfig,
      points: [
        this.Line6.getCoordinatesByPoints().x2,
        this.Line6.getCoordinatesByPoints().y1,
        this.Line6.getCoordinatesByPoints().x2 + UPSWidth / 3,
        this.Line6.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 7,
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line8 = new Line({
      lineConfig,
      points: [
        this.Line7.getCoordinatesByPoints().x2,
        this.Line7.getCoordinatesByPoints().y1,
        this.Line7.getCoordinatesByPoints().x2 + UPSWidth / 3 + H2,
        this.Line7.getCoordinatesByPoints().y1,
      ],
      direction: true,
      serialNum: 8,
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line8_1 = new Line({
      lineConfig,
      points: [
        this.Line8.getCoordinatesByPoints().x2,
        this.Line8.getCoordinatesByPoints().y1,
        this.Line8.getCoordinatesByPoints().x2 + H2,
        this.Line8.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: '8_1',
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();

    this.left_offset = this.Line5_1.getCoordinatesByPoints().x1;
    this.right_offset = this.Line8_1.getCoordinatesByPoints().x2;

    this.Line9 = new Line({
      lineConfig,
      points: [
        this.Line8.getCoordinatesByPoints().x2,
        this.Line8.getCoordinatesByPoints().y1,
        this.Line8.getCoordinatesByPoints().x2,
        this.Line8.getCoordinatesByPoints().y2 + H2,
      ],
      direction: true,
      serialNum: 9,
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    });

    this.Line10 = new Line({
      lineConfig,
      points: [
        this.Line9.getCoordinatesByPoints().x1,
        this.Line9.getCoordinatesByPoints().y2 + SwitchHeight,
        this.Line9.getCoordinatesByPoints().x2,
        this.Line9.getCoordinatesByPoints().y2 +
          SwitchHeight +
          H2 +
          UPSHeight +
          H2 +
          H1 +
          SwitchHeight1,
      ],
      direction: true,
      serialNum: 10,
      state: 0,
      left: true,
      layer: this.parentLayer,
      flowLayer,
    });
    if (!left) {
      this.Line9 = new Line({
        lineConfig,
        points: [
          this.Line5.getCoordinatesByPoints().x1,
          this.Line8.getCoordinatesByPoints().y1,
          this.Line5.getCoordinatesByPoints().x1,
          this.Line8.getCoordinatesByPoints().y2 + H2,
        ],
        direction: true,
        serialNum: 9,
        state: 0,
        left: true,
        layer: this.parentLayer,
        flowLayer,
      });
      this.Line10 = new Line({
        lineConfig,
        points: [
          this.Line9.getCoordinatesByPoints().x1,
          this.Line9.getCoordinatesByPoints().y2 + SwitchHeight,
          this.Line9.getCoordinatesByPoints().x2,
          this.Line9.getCoordinatesByPoints().y2 +
            SwitchHeight +
            H2 +
            UPSHeight +
            H2 +
            H1 +
            SwitchHeight1,
        ],
        direction: true,
        serialNum: 10,
        state: 0,
        left: true,
        layer: this.parentLayer,
        flowLayer,
      });
    }
    this.Line9.add2Canvas();
    this.Line10.add2Canvas();

    this.switch3 = new Switch({
      baseline: this.Line9,
      angle: 0,
      closed: false,
      load: false,
      state: 0,
      name: switch_names.switch3,
      type: 1,
      left: this.left,
      parentLayer: this.parentLayer,
      setSimulateLogs,
    }).add2Canvas(); // 旁路开关，若该开关闭合有流，UPS组不能主路工作
    this.UPSUnitList = [];
    this.ups_unit1 = new UPSUnit({
      parent: this,
      baseline_row: this.Line4,
      layer: this.parentLayer,
      flowLayer,
      serial_num: '1',
      output_switch_name: this.getOutputSwitchName(false, !this.left),
      setName,
      setVisible,
      setLevel,
      trigger4Notice,
      setSimulateLogs,
    }); // 如果是在左侧的话，1是远的，2是近的，如果是在右侧的话，1近，2远
    this.ups_unit2 = new UPSUnit({
      parent: this,
      baseline_row: this.Line7,
      layer: this.parentLayer,
      flowLayer,
      serial_num: '2',
      output_switch_name: this.getOutputSwitchName(false, this.left),
      setName,
      setVisible,
      setLevel,
      trigger4Notice,
      setSimulateLogs,
    });
    if (left) {
      this.UPSUnitList.push(this.ups_unit2);
      this.UPSUnitList.push(this.ups_unit1);
    } else {
      this.UPSUnitList.push(this.ups_unit1);
      this.UPSUnitList.push(this.ups_unit2);
    }

    let x_offset = 0;
    if (typeof ups_num !== 'undefined' && ups_num === 3) {
      if (left) {
        x_offset = this.Line5_1.getCoordinatesByPoints().x1 - UPSWidth;
        this.left_offset = x_offset;
      } else {
        x_offset = this.Line8_1.getCoordinatesByPoints().x2;
      }
      this.Line11 = new Line({
        lineConfig,
        points: [
          x_offset,
          this.Line2.getCoordinatesByPoints().y2,
          x_offset + UPSWidth / 3,
          this.Line2.getCoordinatesByPoints().y2,
        ],
        direction: false,
        serialNum: 11,
        state: 0,
        left: true,
        layer: this.parentLayer,
        flowLayer,
      }).add2Canvas();
      this.Line12 = new Line({
        lineConfig,
        points: [
          this.Line11.getCoordinatesByPoints().x2,
          this.Line11.getCoordinatesByPoints().y2,
          this.Line11.getCoordinatesByPoints().x2 + UPSWidth / 3,
          this.Line11.getCoordinatesByPoints().y2,
        ],
        direction: false,
        serialNum: 12,
        state: 0,
        left: true,
        layer: this.parentLayer,
        flowLayer,
      }).add2Canvas();
      this.Line13 = new Line({
        lineConfig,
        points: [
          this.Line12.getCoordinatesByPoints().x2,
          this.Line12.getCoordinatesByPoints().y1,
          this.Line12.getCoordinatesByPoints().x2 + UPSWidth / 3,
          this.Line12.getCoordinatesByPoints().y2,
        ],
        direction: false,
        serialNum: 13,
        state: 0,
        left: true,
        layer: this.parentLayer,
        flowLayer,
      }).add2Canvas();
      if (!left) {
        this.right_offset = this.Line13.getCoordinatesByPoints().x2;
      }
      this.ups_unit3 = new UPSUnit({
        parent: this,
        baseline_row: this.Line12,
        layer: this.parentLayer,
        flowLayer,
        serial_num: '3',
        output_switch_name: this.getOutputSwitchName(true, !this.left),
        trigger4Notice,
        setName,
        setVisible,
        setLevel,
        setSimulateLogs,
      });
      this.UPSUnitList.push(this.ups_unit3);
    }
    if (left) {
      this.Line14 = new Line({
        lineConfig,
        points: [
          this.Line10.getCoordinatesByPoints().x1,
          this.Line10.getCoordinatesByPoints().y2,
          this.Line10.getCoordinatesByPoints().x1 + H2,
          this.Line10.getCoordinatesByPoints().y2,
        ],
        direction: true,
        serialNum: 14,
        state: 0,
        left,
        layer: this.parentLayer,
        flowLayer,
      }).add2Canvas();
    } else {
      this.Line14 = new Line({
        lineConfig,
        points: [
          this.Line10.getCoordinatesByPoints().x1 - H2,
          this.Line10.getCoordinatesByPoints().y2,
          this.Line10.getCoordinatesByPoints().x1,
          this.Line10.getCoordinatesByPoints().y2,
        ],
        direction: true,
        serialNum: 14,
        state: 0,
        left,
        layer: this.parentLayer,
        flowLayer,
      }).add2Canvas();
    }
    const y = this.Line10.getCoordinatesByPoints().y2;

    const offset = 1;
    for (let i = 0; i < this.UPSUnitList.length; i += 1) {
      // UPS列表顺序是自中间向两边排序
      const line1 = new Line({
        lineConfig,
        points: [
          this.UPSUnitList[i].Line7.getCoordinatesByPoints().x2,
          y,
          this.UPSUnitList[i].Line7.getCoordinatesByPoints().x2,
          y + H2,
        ],
        direction: true,
        serialNum: 0,
        state: 0,
        left,
        layer: this.parentLayer,
        flowLayer,
      }).add2Canvas();
      const line2 = new Line({
        lineConfig,
        points: [
          this.UPSUnitList[i].Line9.getCoordinatesByPoints().x2,
          y,
          this.UPSUnitList[i].Line9.getCoordinatesByPoints().x2,
          y + H2,
        ],
        direction: true,
        serialNum: 0,
        state: 0,
        left,
        layer: this.parentLayer,
        flowLayer,
      }).add2Canvas();
      if (left) {
        this.baseLineGroup.push(line2);
      } else {
        this.baseLineGroup.push(line1);
      }
      if (this.UPSUnitList.length === 2) {
        this.baseLineGroup.push(
          new Line({
            lineConfig,
            points: [
              this.UPSUnitList[i].Line8.getCoordinatesByPoints().x2,
              y,
              this.UPSUnitList[i].Line8.getCoordinatesByPoints().x2,
              y + H2,
            ],
            direction: true,
            serialNum: 0,
            state: 0,
            left,
            layer: this.parentLayer,
            flowLayer,
          }).add2Canvas(),
        );
      }
      if (left) {
        this.baseLineGroup.push(line1);
      } else {
        this.baseLineGroup.push(line2);
      }

      if (
        (ups_num === 3 && (i === 0 || i === 1)) ||
        ((ups_num === 2 || typeof ups_num === 'undefined') && i === 0)
      ) {
        if (left) {
          this.baseLineGroup.push(
            new Line({
              lineConfig,
              points: [
                this.UPSUnitList[i].Line7.getCoordinatesByPoints().x1,
                y,
                this.UPSUnitList[i].Line7.getCoordinatesByPoints().x1,
                y + H2,
              ],
              direction: true,
              serialNum: 0,
              state: 0,
              left,
              layer: this.parentLayer,
              flowLayer,
            }).add2Canvas(),
          );
        } else {
          this.baseLineGroup.push(
            new Line({
              lineConfig,
              points: [
                this.UPSUnitList[i].Line10.getCoordinatesByPoints().x2,
                y,
                this.UPSUnitList[i].Line10.getCoordinatesByPoints().x2,
                y + H2,
              ],
              direction: true,
              serialNum: 0,
              state: 0,
              left,
              layer: this.parentLayer,
              flowLayer,
            }).add2Canvas(),
          );
        }
      }
    } // UPS列表循环结束
    if (typeof ups_num === 'undefined' || ups_num === 2) {
      this.baseLineGroup.splice(
        0,
        0,
        new Line({
          lineConfig,
          points: [
            this.Line10.getCoordinatesByPoints().x2,
            y,
            this.Line10.getCoordinatesByPoints().x2,
            y + H2,
          ],
          direction: true,
          serialNum: 0,
          state: 0,
          left,
          layer: this.parentLayer,
          flowLayer,
        }).add2Canvas(),
      );
    }

    for (let i = 0; i < this.baseLineGroup.length; i += 1) {
      const switch_tmp = new Switch({
        baseline: this.baseLineGroup[i],
        angle: 0,
        closed: false,
        load: false,
        state: 0,
        name: this.getSwitchName(i + 1),
        type: 1,
        parentLayer: this.parentLayer,
        setSimulateLogs,
      }).add2Canvas();
      this.switchGroup.push(switch_tmp); // 开关
      const line_tmp = new Line({
        lineConfig,
        points: [
          this.baseLineGroup[i].getCoordinatesByPoints().x1,
          this.baseLineGroup[i].getCoordinatesByPoints().y2 + SwitchHeight,
          this.baseLineGroup[i].getCoordinatesByPoints().x1,
          this.baseLineGroup[i].getCoordinatesByPoints().y2 +
            SwitchHeight +
            H1 +
            offset * i * (MoudleSapce + MoudleHeight),
        ],
        direction: true,
        serialNum: `${this.baseLineGroup[i].serialNum}_1`,
        state: 0,
        left,
        layer: this.parentLayer,
        flowLayer,
      }).add2Canvas();
      this.baseLineGroup1.push(line_tmp); // 开关下面的竖线
      // this.configGroup[i] = {"line1":this.baseLineGroup[i],"switch":switch_tmp,"line2":line_tmp};
    } // 初始化竖线的循环结束

    for (let i = 0; i < this.baseLineGroup1.length; i += 1) {
      let line_tmp;
      if (left) {
        line_tmp = new Line({
          lineConfig,
          points: [
            this.baseLineGroup1[i].getCoordinatesByPoints().x1,
            this.baseLineGroup1[i].getCoordinatesByPoints().y2,
            CenterX - MoudleWidth / 2,
            this.baseLineGroup1[i].getCoordinatesByPoints().y2,
          ],
          direction: left,
          serialNum: `${this.baseLineGroup1[i].serialNum}_1`,
          state: 0,
          left,
          layer: this.parentLayer,
          flowLayer,
        }).add2Canvas(); // 开关下面的横线
      } else {
        line_tmp = new Line({
          lineConfig,
          points: [
            CenterX + MoudleWidth / 2,
            this.baseLineGroup1[i].getCoordinatesByPoints().y2,
            this.baseLineGroup1[i].getCoordinatesByPoints().x1,
            this.baseLineGroup1[i].getCoordinatesByPoints().y2,
          ],
          direction: left,
          serialNum: `${this.baseLineGroup1[i].serialNum}_1`,
          state: 0,
          left,
          layer: this.parentLayer,
          flowLayer,
        }).add2Canvas();
      }
      // this.configGroup[i].line3 = line_tmp;//第一条横线
      this.rowLineGroup.push(line_tmp);
    }

    this.lineGroup = this.baseLineGroup.concat(this.baseLineGroup1).concat(this.rowLineGroup);
    this.getAllGroup();
    // this.addName4Line();
  }

  /**
   * 获取模块上口的开关名称
   * @param {Number} index:列表索引
   * */
  getSwitchName = (index) => {
    let a = '';
    let b = '';
    if (this.ups_num === 3) {
      if (index >= 7) {
        a = '4';
        if (index === 7) b = '3';
        else b = '4';
      } else {
        a = '3';
        if (this.left) {
          if (index === 1) b = '3';
          else if (index === 2) b = '2';
          else if (index === 3) b = '1';
          else if (index === 4) b = '5';
          else if (index === 5) b = '6';
          else if (index === 6) b = '7';
        } else if (index === 1) b = '1';
        else if (index === 2) b = '2';
        else if (index === 3) b = '3';
        else if (index === 4) b = '5';
        else if (index === 5) b = '6';
        else if (index === 6) b = '7';
      }
    } else if (index <= 4) {
      a = '4';
      if (index === 1) b = '4';
      else if (index === 2) b = '3';
      else if (index === 3) b = '2';
      else if (index === 4) b = '1';
    } else {
      a = '3';
      if (index === 5) b = '4';
      else if (index === 6) b = '3';
      else if (index === 7) b = '2';
      else if (index === 8) b = '1';
    }
    return `${this.head()}AU${this.tail()}${a}-${b}`;
  };

  head = () => {
    if (this.ups_num === 3) return '1';
    return '2';
  };

  tail = () => {
    if (this.left) return 'A';
    return 'B';
  };

  getUPSGroupName = () => `${this.head()}-UPS-${this.tail()}`;
  /**
   * 获取输出开关的编号
   * redundant:true--第三个输出开关，false--另外两个开关
   * closer:true--距离中间最近的开关，false--中间的开关
   *
   * */
  getOutputSwitchName = (redundant, closer) =>
    this.groupName + this.getUPSUnitSerialNum(redundant, closer);

  getUPSUnitSerialNum = (redundant, closer) => {
    if (redundant) {
      return '1';
    }
    if (this.ups_num === 2) {
      if (closer) return '2';
      return '1';
    }
    if (closer) return '3';
    return '2';
    // if (this.ups_num === 3) {
    //   if (closer) return '3';
    //   return '2';
    // }
  };

  /**
   * 获取开关的编号
   * */
  getSwitchDesignationName = () => {
    if (this.left && this.ups_num === 2) {
      return {
        switch1: 'AA-T3-04',
        switch2: '2ATA1-1',
        switch3: '1AUB1-3',
      };
    }
    if (this.left && this.ups_num === 3) {
      return {
        switch1: 'AA-T3-05',
        switch2: '1ATA1-1',
        switch3: '2ATA3-2',
      };
    }
    if (this.ups_num === 2) {
      return {
        switch1: 'AA-T6-04',
        switch2: '2ATB1-1',
        switch3: '2ATB6-1',
      };
    }
    // if (this.ups_num === 3) {
    //   return {
    //     switch1: 'AA-T6-05',
    //     switch2: '1ATB1-1',
    //     switch3: '2ATB1-1',
    //   };
    // }
    return {
      switch1: 'AA-T6-05',
      switch2: '1ATB1-1',
      switch3: '2ATB1-1',
    };
  };

  /**
   * 更新状态值
   * */
  setHighVoltage = (state) => {
    if (this.UPSGroupVoltage !== state) {
      this.UPSGroupVoltage = state;
      if (this.UPSGroupVoltage === 0) {
        // 断电，提前通知UPS单元
        this.UPSUnitList.forEach((unit) => {
          if (unit.switchMainRoad.on) {
            unit.setMainRoadVoltage(false);
          }
          unit.updateLineState([unit.Line1, unit.Line2], 0);
          unit.updateUnitStateByBackground(0); // 有压，更新线路的状态
        });
      } else if (this.switch1.on && this.switch2.on) {
        // console.log('ups组有电压，并且上面的两个开关闭合');
        // 通电需要检查开关1，2是否闭合
        // if (this.switch1.on && this.switch2.on) {
        this.UPSUnitList.forEach((unit) => {
          if (unit.switchMainRoad.on) {
            unit.setMainRoadVoltage(true);
          }
          unit.updateLineState([unit.Line1, unit.Line2], 1);
          unit.updateUnitStateByBackground(1); // 有压，更新线路的状态
        });
        // }
      }

      this.updateGroupLine(false, true);
    }
  };

  update = () => {
    for (let i = 0; i < this.lineGroup.length; i += 1) {
      if (this.lineGroup[i].state === 2) {
        // 有电流的状态
        this.lineGroup[i].flow();
      }
    }
    for (let i = 0; i < this.UPSUnitList.length; i += 1) {
      this.UPSUnitList[i].update();
    }
  };

  updateLineState = (line_list, set_state) => {
    for (let i = 0; i < line_list.length; i += 1) {
      line_list[i].setState(set_state);
    }
  };

  /** 判断下口开关是否有闭合，用于UPS单元判断 */
  existSwitchOn = () => this.switchGroup.filter((s) => s.on).length > 0;

  /**
   * 在有输出电压的情况下，仍然需要考虑没有输出电压的哪些UPS的干路线段的状态变化
   * 先更新没有输出电压的线路，再更新有电压的线路
   * */
  getLineList2Silence = (index, left) => {
    let config;
    if (index === 0) {
      if (left) {
        config = [this.Line6, this.Line7, this.Line8, this.Line8_1];
      } else {
        config = [this.Line3, this.Line4, this.Line5, this.Line5_1];
      }
    } else if (index === 1) {
      if (left) {
        config = [this.Line3, this.Line4, this.Line5, this.Line5_1];
      } else {
        config = [this.Line6, this.Line7, this.Line8, this.Line8_1];
      }
    } else if (index === 2) {
      if (left) {
        config = [
          this.Line3,
          this.Line4,
          this.Line5,
          this.Line5_1,
          this.Line11,
          this.Line12,
          this.Line13,
        ];
      } else {
        config = [
          this.Line6,
          this.Line7,
          this.Line8,
          this.Line8_1,
          this.Line11,
          this.Line12,
          this.Line13,
        ];
      }
    }
    return config;
  };

  /**
   * 获得UPS对应的干路的线路列表，state === 2的情况，需要判断主路还是旁路还是电池供电
   * */
  getLineList2Flow = (index, left, working_state) => {
    let list;
    if (working_state === 2) {
      if (index === 0) {
        if (left) {
          list = [this.Line6];
        } else {
          list = [this.Line3, this.Line4];
        }
      } else if (index === 1) {
        if (left) {
          list = [this.Line3, this.Line4];
        } else {
          list = [this.Line6];
        }
      } else if (index === 2) {
        if (left) {
          list = [this.Line3, this.Line4, this.Line5, this.Line5_1, this.Line13, this.Line12];
        } else {
          list = [this.Line6, this.Line7, this.Line8, this.Line8_1, this.Line11];
        }
      }
    } else if (working_state === 1) {
      if (index === 0) {
        if (left) {
          list = [this.Line6, this.Line7];
        } else {
          list = [this.Line3];
        }
      } else if (index === 1) {
        if (left) {
          list = [this.Line3];
        } else {
          list = [this.Line6, this.Line7];
        }
      } else if (index === 2) {
        if (left) {
          list = [this.Line3, this.Line4, this.Line5, this.Line5_1, this.Line13];
        } else {
          list = [this.Line6, this.Line7, this.Line8, this.Line8_1, this.Line11, this.Line12];
        }
      }
    }
    return list;
  };

  /**
   * 判断是否右负载
   * */
  isLoad = () =>
    this.switch1.on &&
    this.switch2.on &&
    this.switchGroup.filter((s) => s.on).length > 0 &&
    (this.UPSUnitList.filter((s) => s.isLoad()).length > 0 || this.switch3.on);

  /**
   * 在执行该函数之前应该先更新UPS的状态和Line9的状态，下口有压无压据此判断
   * UPSUnit执行点击函数之后也会调用该函数，此时的ups的状态就不应该再改变了
   * */
  updateGroupLine = (reset, hasUpdatedUPS) => {
    if (this.UPSGroupVoltage >= 1) {
      if (typeof reset !== 'undefined' && reset) {
        if (this.switch1.on && this.switch2.on) {
          // 恢复UPS状态
          this.UPSUnitList.forEach((unit) => {
            unit.reSetUPSState();
          });
        }
      }
      if (this.switch1.on) {
        // 更新UPS组的横排线路状态
        this.updateLineState([this.Line1], 1);
        if (this.switch2.on) {
          this.UPSUnitList.forEach((unit) => {
            if (unit.switchMainRoad.on) {
              unit.setMainRoadVoltage(true);
            } else {
              unit.setMainRoadVoltage(false);
            }
          });
          this.updateLineState(
            [
              this.Line2,
              this.Line3,
              this.Line4,
              this.Line5,
              this.Line5_1,
              this.Line6,
              this.Line7,
              this.Line8,
              this.Line8_1,
              this.Line9,
            ],
            1,
          );
          if (typeof this.Line11 !== 'undefined') {
            this.updateLineState([this.Line11, this.Line12, this.Line13], 1);
          }
        } else {
          this.UPSUnitList.forEach((unit) => unit.setMainRoadVoltage(false));
          this.updateLineState(
            [
              this.Line2,
              this.Line3,
              this.Line4,
              this.Line5,
              this.Line5_1,
              this.Line6,
              this.Line7,
              this.Line8,
              this.Line8_1,
              this.Line9,
            ],
            0,
          );
          if (typeof this.Line11 !== 'undefined') {
            this.updateLineState([this.Line11, this.Line12, this.Line13], 0);
          }
        }
      } else {
        this.UPSUnitList.forEach((unit) => unit.setMainRoadVoltage(false));
        this.updateLineState(
          [
            this.Line1,
            this.Line2,
            this.Line3,
            this.Line4,
            this.Line5,
            this.Line5_1,
            this.Line6,
            this.Line7,
            this.Line8,
            this.Line8_1,
            this.Line9,
          ],
          0,
        );
        if (typeof this.Line11 !== 'undefined') {
          this.updateLineState([this.Line11, this.Line12, this.Line13], 0);
        }
      } // 更新完上口的线路状态
      const list = this.UPSUnitList.filter((unit) => unit.outputState() >= 1); // UPS工作状态>=1，并且输出开关闭合
      // fixme 存在主路供电的UPS，这些UPS的状态更新为0,2，Line5的状态根据输出开关是否闭合以及下口下官是否闭合判断1或者2，6通过是否存在电池放电的情况以及输出开关的闭合情况以及上口UPS是否有电流判断其状态0，1，2
      // fixme 即使是电池放电下口状态有电流的情况，也需要更新高压部分的负载情况以更新高压线路的电流情况，那么模块有电流通过可能是高压有负载也可能是高压放电，模块没有电流通过肯定是没有负载的
      // const isBatteryDischargeList = this.UPSUnitList.filter((unit) => unit.isBatteryDischarge()); // 电池放电
      const isBatteryOutputList = this.UPSUnitList.filter((unit) => unit.isBatteryOutput()); // UPS主路工作由电池供电列表，已经考虑了输出开关闭合
      if (list.length === 0 && !(this.Line9.state >= 1 && this.switch3.on)) {
        // console.log(`UPS组：${this.group}下口输出没有电压，不需要考虑电池放电的情况`);
        this.updateLineState(this.baseLineGroup.concat([this.Line10, this.Line14]), 0);
        this.UPSUnitList.forEach((unit) => {
          unit.updateLineState([unit.Line6, unit.Line7, unit.Line8, unit.Line9, unit.Line10], 0);
        });
        for (let i = 0; i < this.switchGroup.length; i += 1) {
          if (this.switchGroup[i].on) {
            this.updateLineState([this.baseLineGroup1[i], this.rowLineGroup[i]], 0);
            this.parent.setModuleState(
              this,
              this.group,
              this.left,
              this.rowLineGroup,
              this.switchGroup,
            );
          }
        }
      } else {
        // console.log(`UPS组：${this.group}下口输出有电压`);
        const noOutputUPSList = this.UPSUnitList.filter((unit) => unit.outputState() === 0); // UPS没有输出，可能是输出开关断开或者UPS本身是不工作状态
        // 那些没有电流流过的UPS更新UPS组的干路状态
        for (let i = 0; i < noOutputUPSList.length; i += 1) {
          // fixme 对于电池放电的UPS需要更新UPS内部的线路状态
          const index = this.UPSUnitList.indexOf(noOutputUPSList[i]);
          const ll = this.getLineList2Silence(index, this.left); // 获取需要设置0或1状态的UPS组横线线路列表
          if (this.UPSGroupVoltage >= 1 && this.switch1.on && this.switch2.on) {
            this.updateLineState(ll, 1); // 如果上口有电压
          } else {
            this.updateLineState(ll, 0);
          }
        }
        // 有电压的情况下下口会直接更新所有的状态，再重新覆盖状态
        this.updateLineState(this.baseLineGroup.concat([this.Line10, this.Line14]), 1);
        this.UPSUnitList.forEach((unit) => {
          unit.updateLineState([unit.Line6, unit.Line7, unit.Line8, unit.Line9, unit.Line10], 1); // 目前仅仅知道有电压的状态
        });
        for (let i = 0; i < this.switchGroup.length; i += 1) {
          if (this.switchGroup[i].on) {
            this.updateLineState(
              [this.baseLineGroup[i], this.baseLineGroup1[i], this.rowLineGroup[i]],
              2,
            ); // 说明有负载，有电流的状态
          } else {
            // 开关断开
            this.updateLineState([this.baseLineGroup1[i], this.rowLineGroup[i]], 0);
          }
        }
        this.parent.setModuleState(
          this,
          this.group,
          this.left,
          this.rowLineGroup,
          this.switchGroup,
        ); // 有电流更新模块
        if (this.existSwitchOn()) {
          // 生成电流

          if (list.length === isBatteryOutputList.length && list.length > 0) {
            //
            // console.log('都是电池放电，旁路肯定不能工作');
            this.parent.setLoad(false, this.left, this.group);
            // 这是在voltage>=1的情况，说明主路一定有电压，都是旁路工作，父类的线路状态一定是1
            this.parent.setSilence(this.group, this.left);
          } else {
            // 长度不等，主路供电&电池放电，UPS旁路&维修旁路
            this.updateLineState([this.Line1, this.Line2], 2);
            if (list.length > 0 && list[0].UPS.workingState() === 2) {
              // UPS主路或者电池放电，这两种都可能存在
              for (let i = 0; i < list.length; i += 1) {
                const index = this.UPSUnitList.indexOf(list[i]);
                if (list[i].isBatteryOutput()) {
                  // 电池供电
                  // console.log('长度不等，主路供电&电池放电，UPS旁路&维修旁路');
                  const ll = this.getLineList2Silence(index, this.left);
                  this.updateLineState(ll, 1);
                } else {
                  const ll = this.getLineList2Flow(index, this.left, list[i].UPS.workingState());
                  this.updateLineState(ll, 2);
                }
              }
            } else if (list.length > 0 && list[0].UPS.workingState() === 1) {
              // UPS旁路，维修旁路可能也有电流
              this.updateLineState([this.Line1, this.Line2], 2);
              for (let i = 0; i < list.length; i += 1) {
                const index = this.UPSUnitList.indexOf(list[i]);
                if (list[i].isBatteryOutput()) {
                  // 电池供电
                  const ll = this.getLineList2Silence(index, this.left);
                  this.updateLineState(ll, 1);
                } else {
                  const ll = this.getLineList2Flow(index, this.left, list[i].UPS.workingState());
                  this.updateLineState(ll, 2);
                }
              }
              if (this.Line2.state >= 1 && this.switch3.on) {
                if (this.left) {
                  this.updateLineState(
                    [this.Line6, this.Line7, this.Line8, this.Line9, this.Line10],
                    2,
                  );
                } else {
                  this.updateLineState(
                    [this.Line3, this.Line4, this.Line5, this.Line9, this.Line10],
                    2,
                  );
                }
              }
            } else {
              // 维修旁路
              // console.log('维修旁路供电');
              this.updateLineState([this.Line1, this.Line2], 2);
              this.updateLineState([this.Line1, this.Line2], 2);
              if (this.left) {
                this.updateLineState(
                  [this.Line6, this.Line7, this.Line8, this.Line9, this.Line10],
                  2,
                );
              } else {
                this.updateLineState(
                  [this.Line3, this.Line4, this.Line5, this.Line9, this.Line10],
                  2,
                );
              }
            }
          }
        } else {
          // 没有电流,有电压
          this.updateLineState([this.Line10], 1);
        }
      }
      this.UPSUnitList.forEach((obj) => this.clickUpload(obj, hasUpdatedUPS));
    } else {
      // 如果电压值小于1，所有线路都需要恢复到无压状态
      this.UPSUnitList.forEach((unit) => {
        unit.setMainRoadVoltage(false);
        unit.updateLineState([unit.Line1, unit.Line2, unit.Line3, unit.Line4], 0);
      });
      this.updateLineState(this.lineGroup, 0);
      // fixme 如果存在电池放电的UPS并且输出开关闭合的话需要更新整个线路的状态
      const isBatteryDischargeList = this.UPSUnitList.filter((unit) => unit.isBatteryDischarge());
      if (isBatteryDischargeList.length > 0) {
        const isBatteryOutputList = this.UPSUnitList.filter((unit) => unit.isBatteryOutput());
        if (isBatteryOutputList.length > 0) {
          // 有电池输出
          this.updateLineState([this.Line10, this.Line14], 1);
          this.UPSUnitList.forEach((unit) =>
            unit.updateLineState([unit.Line7, unit.Line8, unit.Line9, unit.Line10], 1),
          );
          for (let i = 0; i < this.switchGroup.length; i += 1) {
            if (this.switchGroup[i].on) {
              this.updateLineState(
                [this.baseLineGroup[i], this.baseLineGroup1[i], this.rowLineGroup[i]],
                2,
              ); // 说明有负载，有电流的状态
              // this.parent.setModuleState(this,this.group,this.left,this.rowLineGroup,this.switchGroup);
            } else {
              // 开关断开
              this.updateLineState([this.baseLineGroup[i]], 1);
              this.updateLineState([this.baseLineGroup1[i], this.rowLineGroup[i]], 0);
              // this.parent.setModuleState(this,this.group,this.left,this.rowLineGroup,this.switchGroup);
            }
          }
          // console.log(`ups上口没有电源，通过电池放电输出，更新父类的模块状态`);
          this.parent.setModuleState(
            this,
            this.group,
            this.left,
            this.rowLineGroup,
            this.switchGroup,
          );
        } else {
          this.UPSUnitList.forEach((unit) =>
            unit.updateLineState([unit.Line7, unit.Line8, unit.Line9, unit.Line10], 0),
          );
          this.parent.setModuleState(
            this,
            this.group,
            this.left,
            this.rowLineGroup,
            this.switchGroup,
          );
        }
        this.UPSUnitList.forEach((unit) => {
          if (unit.isBatteryOutput() && unit.parent.existSwitchOn()) {
            // 该UPS有电池放电输出
            unit.UPS.updateState(0, 2);
            unit.updateLineState([unit.Line5, unit.Line6], 2);
          } else if (unit.isBatteryOutput()) {
            // 有电池输出
            unit.UPS.updateState(0, 2);
            unit.updateLineState([unit.Line5, unit.Line6], 1);
          } else if (unit.isBatteryDischarge()) {
            // 该UPS有电池放电没有输出
            unit.UPS.updateState(0, 2);
            unit.updateLineState([unit.Line5], 1);
            if (isBatteryOutputList.length > 0) {
              unit.updateLineState([unit.Line6], 1);
            } else {
              unit.updateLineState([unit.Line6], 0);
            }
          } else {
            unit.UPS.updateState(0, 0);
            if (isBatteryOutputList.length > 0) {
              unit.updateLineState([unit.Line6], 1);
              if (unit.switch_type3.on) {
                unit.updateLineState([unit.Line5], 1);
              } else {
                unit.updateLineState([unit.Line5], 0);
              }
            } else {
              unit.updateLineState([unit.Line5, unit.Line6], 0);
            }
          }
        });
      } else {
        // 没有电池放电
        this.UPSUnitList.forEach((unit) => unit.shutdownVoltage());
        this.parent.setModuleState(
          this,
          this.group,
          this.left,
          this.rowLineGroup,
          this.switchGroup,
        );
      }
    }
  };

  /** 点击UPSUnit中的点击开关需要判断电压状态 */
  judgeMainRoadState = () => this.switch1.on && this.switch2.on && this.UPSGroupVoltage >= 1;

  getAllGroup = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const k in this) {
      //   if (!this.hasOwnProperty(k)) continue;
      if (this[k] instanceof UPSUnit) {
        // this.UPSUnitList.push(this[k]);
      } else if (this[k] instanceof Switch) {
        this.switchList.push(this[k]);
      } else if (this[k] instanceof Line) {
        this.lineGroup.push(this[k]);
      }
    }
  };

  destroy = () => {
    this.isShow = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const k in this) {
      //   if (!this.hasOwnProperty(k)) continue;
      if (
        this[k] instanceof Line ||
        this[k] instanceof Switch ||
        this[k] instanceof UPS ||
        this[k] instanceof UPSUnit
      ) {
        this[k].destroy();
      } else if (this[k] instanceof Array) {
        this[k].forEach((r) => {
          if (typeof r.destroy === 'function') {
            r.destroy();
          }
        });
      }
    }
    this.parentLayer.batchDraw();
  };

  show = () => {
    this.isShow = true;
    // eslint-disable-next-line no-restricted-syntax
    for (const k in this) {
      //   if (!this.hasOwnProperty(k)) continue;
      if (
        this[k] instanceof Line ||
        this[k] instanceof Switch ||
        this[k] instanceof UPS ||
        this[k] instanceof UPSUnit
      ) {
        this[k].show();
      } else if (this[k] instanceof Array) {
        this[k].forEach((r) => {
          if (typeof r.show === 'function') {
            r.show();
          }
        });
      }
    }
  };

  getGlobalTimerKeyInMap = () => {
    let left1 = '';
    if (this.left) {
      left1 = '左侧';
    } else {
      left1 = '右侧';
    }
    return `低压${this.parent.index}${left1}组号:${this.group}`;
  };

  addName4Line = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const k in this) {
      //   if (!this.hasOwnProperty(k)) continue;
      if (this[k] instanceof Line) {
        this[k].addSerial2Canvas();
      }
    }
  };

  isPointInPath = (x, y) =>
    x <= this.right_offset &&
    x >= this.left_offset &&
    y <= this.Line10.getCoordinatesByPoints().y2 + H2 + SwitchHeight &&
    y >= this.Line1.getCoordinatesByPoints().y1 - SwitchHeight;

  /* 获取兄弟UPSUnit对象，return List[UPSUnit] */
  getUPSUnitBrother = (upsSerialNum) =>
    this.UPSUnitList.filter((upsUnit) => upsUnit.serialNum !== upsSerialNum);

  judgeMutualExclusionBypassCondition = () => this.switch3.on;

  /**
   * 目前正在工作的状态列表
   * */
  getAllWorkingState = (list) => {
    const noWorkingList = list.filter((upsUnit) => upsUnit.UPS.workingState() === 0);
    const byPassList = list.filter((upsUnit) => upsUnit.UPS.workingState() === 1);
    const mainRoadList = list.filter((upsUnit) => upsUnit.UPS.workingState() === 2);
    return {
      no_working_list: noWorkingList,
      by_pass_list: byPassList,
      main_road_list: mainRoadList,
    };
  };

  /** 获取UPS状态分类列表 */
  getAllUPSActualState = (list) => {
    const noWorkingList = list.filter((upsUnit) => upsUnit.UPS.actualState === 0);
    const byPassList = list.filter((upsUnit) => upsUnit.UPS.actualState === 1);
    const mainRoadList = list.filter((upsUnit) => upsUnit.UPS.actualState === 2);
    return {
      no_working_list: noWorkingList,
      by_pass_list: byPassList,
      main_road_list: mainRoadList,
    };
  };

  /**
   * UPS单元点击事件的效应上传到父类
   * 负类需要判断干路的线路状态变化，以及影响的单元内部的线路变化包括Line1,Line2,Line6以及内部其他线段状态的变化
   * 子类updateUnitStateByBackground
   * unit:影响的单元
   * obj：单元内被点击的元素
   * */
  clickUpload = (unit, hasUpdatedUPS) => {
    if (this.UPSGroupVoltage >= 1 && this.switch1.on && this.switch2.on) {
      if (unit.UPS.actualState === 0 && unit.switchBypass.on) {
        unit.UPS.setActualState(1);
      }

      if (this.existSwitchOn() && unit.outputState() === 2 && unit.switchMainRoad.on) {
        // 该UPS单元有输出并且有负载
        unit.updateLineState([unit.Line2], 2);
        unit.updateLineState([unit.Line1], 1);
      } else if (this.existSwitchOn() && unit.outputState() === 2) {
        //
        unit.updateLineState([unit.Line1, unit.Line2], 1);
      } else if (this.existSwitchOn() && unit.outputState() === 1) {
        // 旁路输出
        // console.log(`${unit.upsUnitName}旁路输出`);
        unit.updateLineState([unit.Line2], 1);
        unit.updateLineState([unit.Line1], 2);
      } else {
        // 主路开关没有闭合，而且输出状态是0

        unit.updateLineState([unit.Line1, unit.Line2], 1);
      }
    } else {
      unit.updateLineState([unit.Line1, unit.Line2], 0);
    }
    if (typeof hasUpdatedUPS === 'undefined') {
      unit.updateUnitStateByBackground();
    } else {
      unit.updateOutputLine();
    }
  };

  /** 是否存在其他UPS输出应该某个UPS的Line5的状态 */
  existVoltageFromOtherUPS = () =>
    this.UPSUnitList.filter((unit) => unit.UPS.workingState() >= 1 && unit.switch_type3.on).length >
    0;

  /** 跳闸函数
   * switch_obj:动作的开关
   * index:在该类中，该开关对应的索引，用于取消定时器的时候用
   * module:该开关对应的模块
   * phase:A相或者B相
   * */
  tripDelay = (switch_obj, index, module, phase) => {
    // 每一个开关对应一个索引，由索引指向定时器列表的映射
    const { setSimulateLogs } = this.props;
    // console.log('触发跳闸定时器');
    const timer = setTimeout(
      () => {
        if (switch_obj.onclick(false)) {
          // 有操作
          if (this.isShow) {
            setSimulateLogs({
              time: moment().format('YYYY/MM/DD HH:mm'),
              status: '报警',
              value: `${module.name}${phase}${module.num_abc}断路器${switch_obj.name}开关动作！`,
            });
          }
          this.updateGroupLine();
        } else {
          // console.log('开关没有操作不用更新');
        }
      },
      switch_obj.clickTimeout,
      switch_obj,
    );
    // fixme 如果触发了一个定时器，索引值得是当前组中的开关索引，如果触发定时器之前点击干路开关，当前组的所有定时器都取消，如果点击模块上口开关，只根据索引匹配对应的定时器进行取消
    if (globalTimerMap.has(this.parent.index)) {
      // 对应的低压
      if (globalTimerMap.get(this.parent.index).has(this.getGlobalTimerKeyInMap())) {
        globalTimerMap
          .get(this.parent.index)
          .get(this.getGlobalTimerKeyInMap())
          .push({ index, timer });
      } else {
        // console.log('有低压的映射，没有group的映射');
        globalTimerMap
          .get(this.parent.index)
          .set(this.getGlobalTimerKeyInMap(), [{ index, timer }]);
      }
    } else {
      // console.log('初始化时未存储映射');
      globalTimerMap.set(
        this.parent.index,
        new Map([this.getGlobalTimerKeyInMap(), [{ index, timer }]]),
      );
    }
  };

  /**
   * 点击到该UPSGroup触发事件
   * 点击到每个元素都需要判断一下负载
   * */
  trigger = (timeMinus, obj, e) => {
    const { setSimulateLogs, setVisible, setName, setLevel } = this.props;
    if (obj instanceof UPSUnit) {
      obj.onclick(timeMinus, e, setVisible, setName, setLevel); // true点击到可点击的元素

      // obj.onContextMenu(timeMinus, e, setVisible, setName,setLevel, ); // true点击到可点击的元素
    } else if (obj instanceof Switch) {
      switch (obj) {
        case this.switch1:
          obj.onclick();
          if (this.UPSGroupVoltage >= 1) {
            if (obj.on) {
              if (this.switch2.on) {
                this.UPSUnitList.forEach((unit) => {
                  if (unit.switchMainRoad.on) {
                    unit.setMainRoadVoltage(true);
                  }
                  unit.updateLineState([unit.Line1, unit.Line2], 1);
                  unit.updateUnitStateByBackground(1); // 有压，更新线路的状态
                });
              }
            } else if (this.switch2.on) {
              this.UPSUnitList.forEach((unit) => {
                unit.setMainRoadVoltage(false);
                unit.updateLineState([unit.Line1, unit.Line2], 0);
                unit.updateUnitStateByBackground(0); // 无压
              });
            }
            this.updateGroupLine(undefined, true);
          } else if (this.UPSGroupVoltage === 0) {
            // console.log('上口无压，无须变化');
          }
          // this.updateGroupLine(undefined,true);
          break;
        case this.switch2:
          obj.onclick();
          if (this.UPSGroupVoltage >= 1) {
            if (this.Line1.state >= 1) {
              if (obj.on) {
                this.UPSUnitList.forEach((unit) => {
                  unit.updateLineState([unit.Line1, unit.Line2], 1);
                  if (unit.switchMainRoad.on) {
                    unit.setMainRoadVoltage(true);
                  }
                  unit.updateUnitStateByBackground(1); // 有压
                });
              } else {
                this.UPSUnitList.forEach((unit) => {
                  unit.setMainRoadVoltage(false);
                  unit.updateLineState([unit.Line1, unit.Line2], 0);
                  unit.updateUnitStateByBackground(0); // 无压
                });
              }
            }
            this.updateGroupLine(undefined, true);
          } else if (this.UPSGroupVoltage === 0) {
            // console.log('上口无压，无须变化');
          }
          // this.updateGroupLine();
          break;
        case this.switch3:
          // fixme 负载保护日志
          if (this.switch3.on) {
            // 原来是闭合状态，要断开
            this.switch3.onclick();
            this.updateGroupLine();
          } else {
            // 原来是断开状态，要闭合
            const upsList = this.UPSUnitList.filter((upsUnit) =>
              upsUnit.judgeMutualExclusionBypassCondition(),
            );
            if (this.judgeMainRoadState() && upsList.length !== 0) {
              // 存在UPS可能主路工作，点击会有弹窗提醒
              this.props.trigger4Notice('此时禁止闭合旁路开关');
              // document.getElementById('dialog').innerText = '此时禁止闭合旁路开关';
              // $('#dialog').dialog('open');
            } else {
              this.switch3.onclick();
              if (this.isShow) {
                // 取消注释
                // this.menu.insertLog('报警', `负载不受保护（${this.switch3.name}维修旁路）`);
                setSimulateLogs({
                  time: moment().format('YYYY/MM/DD HH:mm'),
                  status: '报警',
                  value: `负载不受保护（${this.switch3.name}维修旁路）`,
                });
              }
              this.updateGroupLine();
            }
          }
          break;
        default:
          obj.onclick(); // 可以计算该开关对应的变化的线路，据此改变线路的状态
          this.updateGroupLine();
      }
    }
  };

  onclick = (timeMinus, e) => {
    let flag = true;
    for (let i = 0; i < this.UPSUnitList.length; i += 1) {
      //
      if (this.UPSUnitList[i].isPointInPath(e.evt.layerX, e.evt.layerY)) {
        flag = false;
        this.trigger(timeMinus, this.UPSUnitList[i], e);
        break;
      }
    }
    if (flag) {
      for (let i = 0; i < this.switchList.length; i += 1) {
        // 干路开关，1，2，以及旁路开关
        if (this.switchList[i].isPointInPath(e.evt.layerX, e.evt.layerY)) {
          flag = false;
          this.trigger(timeMinus, this.switchList[i], e);
          break;
        }
      }
      // 点击位置不在UPS组内，检查其他开关
    }
    if (flag) {
      // UPS下口开关
      for (let i = 0; i < this.switchGroup.length; i += 1) {
        if (this.switchGroup[i].isPointInPath(e.evt.layerX, e.evt.layerY)) {
          flag = false;
          this.trigger(timeMinus, this.switchGroup[i], e);
          break;
        }
      }
    }
  };
}
export default UPSGroup;
