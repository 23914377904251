import React from 'react';
import { Button } from 'antd';
import warningIcon from '@/assets/desktop_exercise/panel/报警.png';
import normalIcon from '@/assets/desktop_exercise/panel/报警消除.png';
import classnames from 'classnames';
import moment from 'moment';
import styles from './Index.scss';

const colorBox = {
  自动: '#E1FDFF',
  报警: '#FF7171',
  溯源: '#FF7171',
  提示: '#6AE082',
};
const SimulateLog = ({ logs }) => {
  const handleGet = () => {};
  const handleSave = () => {
    const element = document.createElement('a');
    const filename = `log_${moment().format('YYYY-MM-DD-HH-mm-ss')}.txt`;
    let content = '';
    logs.forEach((item) => (content += `${item.time} (${item.status}) ${item.value} \n`));
    const file = new Blob([content], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    element.click();
  };

  return (
    <div className={styles.container}>
      <div className={classnames(styles.angle, styles.topLeft)} />
      <div className={classnames(styles.angle, styles.bottomLeft)} />
      <div className={classnames(styles.angle, styles.topRight)} />
      <div className={classnames(styles.angle, styles.bottomRight)} />
      <div className={styles.title}>仿真演练日志</div>
      <div>
        <Button type="primary" size="small" className={styles.btn} onClick={handleGet}>
          日志读取
        </Button>
        <Button size="small" className={styles.btn} onClick={handleSave}>
          日志保存
        </Button>
      </div>
      <div className={styles.content}>
        {logs.map((ele, index) => (
          <div
            className={styles.item}
            key={index}
            style={ele.status === '溯源' ? { color: '#FF7171', background: '#fff' } : {}}
          >
            {ele.time}
            <div className={styles.status} style={{ color: colorBox[ele.status] }}>
              {`(${ele.status})`}
            </div>
            <div className={styles.value}>{ele.value}</div>
            {ele.status === '报警' && (
              <img className={styles.icon} src={warningIcon} alt="报警 icon" />
            )}
            {ele.status === '提示' && (
              <img className={styles.icon} src={normalIcon} alt="提示 icon" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SimulateLog;
