import React from 'react';

const AR = () => (
  <div style={{ height: 'calc(100vh - 1.6rem)', width: '100vw' }}>
    <iframe
      id="mouseMenuFrame"
      title="title"
      style={{ width: '100%', height: '100%', border: 'none' }}
      src="./../../../AR_index/nav.html"
    />
  </div>
);
export default AR;
