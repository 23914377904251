/* --
 --- Feature：AC-DC / DC-AC模块
 --- Author: arya
 * // fixme 取消注释
--*/

import React from 'react';
import Konva from 'konva';
import moment from 'moment';
import { UPSWidth, UPSHeight, Width, SwitchHeight } from '../Constants';

class UPS extends React.Component {
  constructor(props) {
    super(props);

    const { x, y, state, name, menu, parentLayer } = props;
    // console.log(name);

    this.menu = menu;
    this.parentLayer = parentLayer;
    this.battery = false;
    this.state4Rectifier = state; // 整流器得状态，0--绿色，1--橙色，2--红色
    this.state4Inverter = state; // 逆流器得状态
    this.upsName = name;
    this.opacity = 0;
    /* 点击相关按钮的时候需要更新此状态值 */
    this.lastState4Rectifier = undefined;
    this.lastState4Inverter = undefined;
    this.actualState = 0; // UPS应该变化的工作状态，0--不工作模式，1--旁路工作模式，2--主路工作模式

    this.rectifier = new Konva.Path({
      data: `m 0 0 l ${UPSWidth} 0 l -${UPSWidth} ${UPSHeight}z`,
      x,
      y,
      stroke: 'black',
      fill: 'green',
      //   originX: 'left',
      //   originY: 'top',
      selectable: false,
    });
    // console.log(this.rectifier);
    this.inverter = new Konva.Path({
      data: `m 0 ${UPSHeight} l ${UPSWidth} 0 l 0 -${UPSHeight}z`,
      x,
      y,
      stroke: 'black',
      fill: 'green',
      //   originX: 'left',
      //   originY: 'top',
      selectable: false,
    });
    // console.log(this.inverter);

    this.rectConfig = this.getRectConfig(x, y);
    // this.rect = new fabric.Rect(this.rectConfig);
    this.line = new Konva.Line({
      points: [
        this.rectConfig.x + UPSWidth,
        this.rectConfig.y,
        this.rectConfig.x,
        this.rectConfig.y + UPSHeight,
      ],
      stroke: 'black',
      strokeWidth: 1,
      selectable: false,
    });
    this.rect1 = new Konva.Rect({
      x: this.rectConfig.x + UPSWidth / 12,
      y: this.rectConfig.y + UPSHeight / 12,
      width: UPSWidth / 2,
      height: UPSHeight / 4,
      fill: 'white',
      stroke: 'black',
      strokeWidth: 1,
    });
    this.rect2 = new Konva.Rect({
      x: this.rectConfig.x + UPSWidth - UPSWidth / 12 - UPSWidth / 2,
      y: this.rectConfig.y + UPSHeight - UPSHeight / 12 - UPSHeight / 4,
      width: UPSWidth / 2,
      height: UPSHeight / 4,
      fill: 'white',
      stroke: 'black',
      strokeWidth: 1,
    });
    this.text1 = new Konva.Text({
      text: 'AC-DC',
      x: this.rect1.x(),
      y: this.rect1.y(),
      width: this.rect1.width(),
      height: this.rect1.height(),
      align: 'center',
      verticalAlign: 'middle',
      // scaleX: (0.6 * Width) / 1536,
      // scaleY: (0.6 * Width) / 1536,
      fontSize: (Width / 2560) * 14,
      fontStyle: 'bold',
    });
    this.text2 = new Konva.Text({
      text: 'DC-AC',
      x: this.rect2.x(),
      y: this.rect2.y(),
      width: UPSWidth / 2,
      height: this.rect1.height(),
      align: 'center',
      verticalAlign: 'middle',
      // scaleX: (0.6 * Width) / 1536,
      // scaleY: (0.6 * Width) / 1536,
      fontSize: (Width / 2560) * 14,
      fontStyle: 'bold',
    });
  }

  add2Canvas = () => {
    this.parentLayer.add(
      this.rectifier,
      this.inverter,
      this.rect1,
      this.rect2,
      this.line,
      this.text1,
      this.text2,
    );
    return this;
  };
  /** 电池配置 */
  getUBatteryConfig = () => ({
    x: this.rectConfig.x + (3 / 4) * UPSWidth - 7,
    y: this.rectConfig.y + UPSHeight + SwitchHeight / 2 + 2,
    originY: 'top',
    originX: 'center',
    scaleX: (0.7 * Width) / 1536,
    scaleY: (0.7 * Width) / 1536,
    selectable: false,
    svgUid: `battery_${this.upsName}`,
  });
  /* 开关机按钮的配置 */
  getUBConfig = (on) => {
    if (on)
      return {
        x: this.rectConfig.x + 5,
        y: this.rectConfig.y + UPSHeight + 15,
        width: 20,
        height: 20,
        scaleX: (0.7 * Width) / 1536,
        scaleY: (0.7 * Width) / 1536,
        selectable: false,
        svgUid: 'UBbattery',
      };
    return {
      x: this.rectConfig.x + 5,
      y: this.rectConfig.y + UPSHeight + 40,
      width: 20,
      height: 20,
      scaleX: (0.7 * Width) / 1536,
      scaleY: (0.7 * Width) / 1536,
      selectable: false,
      svgUid: 'UBbattery',
    };
  };

  getRectConfig = (left, top) => ({
    x: left,
    y: top,
    width: UPSWidth,
    height: UPSHeight,
    stroke: 'black',
    fill: 'green',
    strokeWidth: 1,
    perfectDrawEnabled: false,
  });

  isPointInPath = (x, y, on) => {
    const config = this.getUBConfig(on);
    return (
      Math.abs(config.x - x) <= config.width / 2 && Math.abs(config.y - y) <= config.height / 2
    );
  };

  setActualState = (state) => {
    // console.log("真实的UPS状态是：");
    // console.log(state);
    this.actualState = state;
  };

  /** 判断UPS得工作状态，0--不工作，1--旁路工作，2--主路工作 */
  workingState = () => this.state4Inverter; // 0--不工作，1--旁路工作，2--主路工作

  /** 判断UPS开机还是关机状态 */
  bootOrShut = () => this.battery; // true--开机，false--关机

  /* 修改UPS开关机状态 */
  setBatteryState = (boot) => {
    const { setSimulateLogs } = this.props;
    let log = '';
    if (this.battery && !boot) {
      log = '关机告警';
    } else if (!this.battery && boot) {
      log = '开机告警';
    }
    this.battery = boot;
    setSimulateLogs({
      time: moment().format('YYYY/MM/DD HH:mm'),
      status: '提示',
      value: this.upsName + log,
    });
  };

  updateColor = (state, obj) => {
    if (state === 0) {
      obj.setAttr('fill', 'green');
    } else if (state === 1) {
      obj.setAttr('fill', 'red');
    } else if (state === 2) {
      obj.setAttr('fill', 'red');
    }
    obj.draw();
    this.rect1.draw();
    this.rect2.draw();
    this.text1.draw();
    this.text2.draw();
  };

  updateState = (state4Rectifier, state4Inverter) => {
    const { setSimulateLogs } = this.props;
    this.lastState4Inverter = this.state4Inverter;
    this.lastState4Rectifier = this.state4Rectifier;
    if (this.opacity === 1) {
      if (this.state4Inverter !== state4Inverter) {
        // 不相同的话肯定是要改变的
        if (state4Inverter === 2) {
          setSimulateLogs({
            time: moment().format('YYYY/MM/DD HH:mm'),
            status: '提示',
            value: `${this.upsName}负载受保护`,
          });
        } else if (state4Inverter === 1) {
          setSimulateLogs({
            time: moment().format('YYYY/MM/DD HH:mm'),
            status: '报警',
            value: `${this.upsName}负载不受保护（静态旁路）`,
          });
        } else {
          // 不工作状态
        }
      }
    }

    this.state4Inverter = state4Inverter;
    this.state4Rectifier = state4Rectifier;
    // this.lastState4Inverter = this.state4Inverter;
    // this.lastState4Rectifier = this.state4Rectifier;
    this.updateColor(this.state4Inverter, this.inverter);
    this.updateColor(this.state4Rectifier, this.rectifier);
  };

  destroy = () => {
    this.opacity = 0;
    Object.values(this).forEach((obj) => {
      if (obj instanceof Konva.Shape) {
        obj.setAttr('opacity', 0);
      }
    });
  };
  show = () => {
    this.opacity = 1;
    Object.values(this).forEach((obj) => {
      if (obj instanceof Konva.Shape) {
        obj.setAttr('opacity', 1);
      }
    });
  };
}
export default UPS;
