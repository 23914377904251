import theRealFetch from 'isomorphic-fetch';
import qs from 'qs';
import { message } from 'antd';
import cloneDeep from 'lodash/cloneDeep';

/** Utils
 -------------------------------------------------- */
const isNil = (value) => value === undefined || value === null || value === '';
const deleteDeep = (target) =>
  Object.keys(target).forEach((key) => {
    if (typeof target[key] === 'object' && target[key] !== null) {
      deleteDeep(target[key]);
    } else if (isNil(target[key])) {
      delete target[key];
    }
  });
const parseParams = (params) => {
  const paramsCopy = cloneDeep(params);
  deleteDeep(paramsCopy);
  return paramsCopy;
};

/** Request
 -------------------------------------------------- */
const defaultOptions = {
  credentials: 'include',
  mode: 'cors',
  headers: {
    Accept: 'application/json',
    // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    'Content-Type': 'application/json;charset=UTF-8',
    // 'Accept-Encoding': 'gzip',
  },
};

/**
 * permissionRequestFirst
 * @param {} url
 * @param {*} param1
 */

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url              The URL we want to request
 * @param  {string} [type]           The HTTP method for our request
 * @param  {object} [params]         The HTTP message body for our request
 * @param  {boolean} [notFormat]     Whether format params using `qs`
 * @param  {object} [otherOptions]   Other options except "method" & "body" for our request
 * @param  {string} [errMsg]         The message we want to show when request got error
 */
const fetch = (url, { type = 'GET', params, notFormat = true, errMsg, ...otherOptions } = {}) => {
  let newUrl = url;
  let newOptions = { ...defaultOptions, ...otherOptions, method: type };
  if (params) {
    if (type === 'GET') {
      newUrl = `${newUrl}?${qs.stringify(parseParams(params))}`;
    } else if (type === 'POST') {
      newOptions = {
        ...newOptions,
        body: notFormat ? JSON.stringify(params) : qs.stringify(params),
      };
    }
  }

  return theRealFetch(newUrl, newOptions)
    .then((res) => {
      const { status, statusText, url, headers } = res;
      message.loading();
      // 接口正常，返回 { errno, errmsg, data }
      if (status >= 200 && status < 300) {
        // const json = res.json();
        if (url.indexOf('download') > 0) {
          return res.blob();
        }
        // console.log("fetch:", res, res.json());
        return res.json();
      }

      // errno 不为 0，账号未登录、被禁用等，跳转到登录页面
      if (status === 309) window.location.replace(`${window.location.origin}/`);
      // ① 接口 500 等，返回错误
      const err = `${errMsg ? `${errMsg} | ` : ''}接口 ${status}: ${statusText}`;
      return Promise.reject(new Error(err));
    })
    .then((resData) => {
      // message.success();
      const exportStr = resData;

      const { resultCode, data, flag, msg } = exportStr;

      //todo判断是否为下载的数据
      if (!flag) {
        console.log('blob');
        let bl = new Blob([resData]);
        let fileName = Date.parse(new Date()) + '.xlsx'; //我这里给了个时间戳命名,可以根据需求自定义
        //下面就是下载文件的方法了
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(resData);
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(link.href);
        return '开始下载';
      }
      // 登录超时，过期
      if (flag === 'login') {
        if (newUrl.includes('menu/')) {
          return;
        }
        // console.log('fetch:', flag);
        message.error('未登录或已过期,请重新登陆！');
        window.location.replace(`${window.location.origin}/`);
      }
      // 1. 请求成功，errno 为 0，直接返回 data dcos版本
      // if (resultCode && resultCode.code === 200) return data;
      // 1.请求成功，errno 为 0，直接返回 data 网课版本
      if (newUrl.includes('user/')) {
        return exportStr;
      }
      if (flag && flag === 'suc') {
        // message.success(msg.msg);
        return msg;
      } else {
        message.error(msg.msg);
        return msg;
      }

      // if (newUrl.includes('videoCourse') && flag !== 'suc') {
      // console.log('VRvideo&&fail')
      // window.location.replace(`${window.location.origin}/vr`);
      // }
      // ② 请求接口成功，errno 不为 0，返回错误
      // const err = `${errMsg ? `${errMsg} | ` : ''}${msg || ''}`;
      const err = `${msg ? `${msg} | ` : ''}${msg || ''}`;
      return Promise.reject(new Error(err));
    })
    .catch((err) => {
      // 2. 请求失败，① 接口 500 等，② 请求接口成功，errno 不为 0
      message.error(err.message);
    });
};

export default fetch;
