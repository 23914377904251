/* --
 --- Feature：直线line模块
 --- Author: arya
--*/

import React from 'react';
import Konva from 'konva';

class Line extends React.Component {
  constructor(props) {
    super(props);
    const { points, layer, state, direction, left, serialNum, flowLayer } = props;

    this.parentLayer = layer; // 线段的父节点
    this.flowLayer = flowLayer;
    this.state = state; // 线路的状态，无压无流0，有压无流1，有压有流2
    this.direction = direction; // 电流流动的方向
    if (typeof left !== 'undefined') {
      this.left = left;
    } else {
      this.left = false;
    }
    this.serialNum = serialNum;
    // this.left = left; // 线段在左边还是右边，决定线段标注的位置
    this.points = points; // 线段定位的坐标列表
    // this.points.forEach((x) => {
    //   // eslint-disable-next-line no-restricted-globals
    //   if (isNaN(x)) console.log('serialNum:', serialNum, 'points:Nan');
    // });
    // if(this.points[0] !== NaN && this.points[1] !== NaN && this.points[])
    // console.log(`points:${this.points},serialNum:${serialNum}`);

    this.config = this.getConfig(true, this.points); // 获取线段绘制的配置，包括底线和电流线
    this.line = this.getThisLine();
    this.opacity = 1;
    this.vertical = this.points[0] === this.points[2];
    this.dottedLineList = [];

    this.IntervalLen = 8; // 虚线中间间隔的距离
    this.MoveDistance = 8; // 虚线移动的距离
    this.DottedLineLen = this.getDottedLineLen(); // 底线对应的虚线总长度

    this.text = new Konva.Text({
      text: this.getLineName(),
      x: this.line.x(),
      y: this.line.y(),
      fontSize: 12,
      textAlign: 'center',
      selectable: false,
    });
    // this.printNum = 0;
    // this.genFlowDottedLine();
  }
  componentWillUnmount() {
    // this.text.destroy();
    // this.flowLayer = null;
  }
  getCoordinatesByPoints = () => ({
    x1: this.line.points()[0],
    y1: this.line.points()[1],
    x2: this.line.points()[2],
    y2: this.line.points()[3],
  });

  /** 获取底线对应的电流虚线的总长度 */
  getDottedLineLen = () => {
    const [x1, y1, x2, y2] = this.points;
    let result = 0;
    if (this.vertical && y2 - y1 < 15) {
      result = (3 * (y2 - y1)) / 4;
    } else if (!this.vertical && x2 - x1 < 15) {
      result = (3 * (x2 - x1)) / 4;
    } else {
      result = 15;
    }
    return result;
  };

  getLineName = () => `L${this.serialNum}`;

  /** 获取底线和虚线的配置，isLine:true--底线，false--虚线，points--线段对应的坐标 */
  getConfig = (isLine, points) => {
    let config1 = {};
    const lineWidth = 3;
    // const shadowOffset = 0.5;
    // const blur = 1;
    if (isLine) {
      if (this.state === 0) {
        config1 = {
          strokeWidth: lineWidth,
          stroke: 'green',
          // shadowColor: 'white',
          // shadowOpacity: this.opacity,
          // shadowOffset: { x: shadowOffset, y: shadowOffset },
          // shadowBlur: blur,
          opacity: this.opacity,
        };
      }
      if (this.state === 1) {
        config1 = {
          strokeWidth: lineWidth,
          stroke: 'red',
          // shadowColor: 'white',
          // shadowOpacity: this.opacity,
          // shadowBlur: blur,
          // shadowOffset: { x: shadowOffset, y: shadowOffset },
          opacity: this.opacity,
        };
      }
      config1 = {
        strokeWidth: lineWidth,
        stroke: '#DCDCDC',
        // shadowColor: 'white',
        // shadowOpacity: this.opacity,
        // shadowBlur: blur,
        // shadowOffset: { x: shadowOffset, y: shadowOffset },
        opacity: this.opacity,
      };
    } else {
      config1 = {
        strokeWidth: lineWidth,
        stroke: 'red',
        // shadowColor: 'white',
        // shadowOpacity: this.opacity,
        // shadowBlur: blur,
        // shadowOffset: { x: shadowOffset, y: shadowOffset },
        opacity: this.opacity,
      };
    }
    config1.points = points;
    return config1;
  };

  destroy = (line) => {
    this.opacity = 0;
    this.setOpacity();
    if (line) {
      line.destroy();
    }
  };

  setOpacity = () => {
    this.line.setAttr('opacity', this.opacity);
    if (this.dottedLineList.length > 0) {
      this.dottedLineList.forEach((line) => {
        line.setAttr('opacity', this.opacity);
        // if (line) {
        //   line.destroy();
        // }
      });
    }
  };

  show = () => {
    this.opacity = 1;
    this.setOpacity();
  };

  setColor = (color) => {
    if (!this.line.setAttr || !this.line.draw) return;
    this.line.setAttr('stroke', color);
    this.line.draw();
  };

  setState = (state) => {
    /**
     * 如果原来的state和现在的state一样不需要操作
     * 如果新的state === 0，需要把导线换成绿色，如果有虚线的话，把虚线去掉
     * 如果新的state === 1, 有电压，把导线换成红色，如果有虚线的话，把虚线去掉
     * 如果新的state === 2, 导线换成灰色，添加虚线
     * */
    if (this.state === state) {
      // console.log(this.getLineName() + "状态相同不需要改变")
    } else {
      if (this.state === 2 && this.dottedLineList.length !== 0) {
        // 原来的线段状态时流动的
        this.clearDottedLineList();
      }
      if (state === 0) {
        this.setColor('green');
      } else if (state === 1) {
        this.setColor('red');
      } else if (state === 2) {
        this.setColor('#DCDCDC');
        this.genFlowDottedLine();
      } else {
        // console.error('state error');
      }
      this.state = state;
    }
  };

  /** 获取底线 */
  getThisLine = () => {
    if (this.state === 0) {
      this.config.stroke = 'green';
    } else if (this.state === 1) {
      this.config.stroke = 'red';
    } else {
      this.config.stroke = '#DCDCDC';
      this.config.opacity = 0.5;
    }
    return new Konva.Line(this.config);
  };

  clearDottedLineList = () => {
    this.dottedLineList.forEach((obj) => {
      obj.destroy();
      // const rect = obj.getClientRect();
      // this.flowLayer.clear({ x: rect.x, y: rect.y, width: rect.width, height: rect.height });
    });
    this.dottedLineList.splice(0, this.dottedLineList.length);
    // this.flowLayer.batchDraw();
  };

  addSerial2Canvas = () => {
    this.parentLayer.add(this.text);
    return this;
  };

  genFlowDottedLine = () => {
    // 该函数只考虑了线段是直线段的情况
    const [x1, y1, x2, y2] = this.points;
    let dottedLineStartX;
    let dottedLineStartY;
    let dottedLineEndX;
    let dottedLineEndY;
    let dottedLineNum;
    // let dottedLineTmp;
    // 定义虚线的个数和部分坐标
    if (this.vertical) {
      // console.log(this.line.height, this.line.height);
      dottedLineNum = (y2 - y1) / (this.DottedLineLen + this.IntervalLen);
      dottedLineStartX = x1;
      dottedLineEndX = x1;
    } else {
      dottedLineNum = (x2 - x1) / (this.DottedLineLen + this.IntervalLen);
      dottedLineStartY = y1;
      dottedLineEndY = y1;
    }
    dottedLineNum = Math.ceil(dottedLineNum);
    // console.log(`dotted line num:${dottedLineNum}`);
    for (let i = 0; i < dottedLineNum; i += 1) {
      if (this.vertical) {
        dottedLineStartY = y1 + i * (this.DottedLineLen + this.IntervalLen);
        dottedLineEndY = Math.min(dottedLineStartY + this.DottedLineLen, y2); // 避免生成的虚线出界
      } else {
        dottedLineStartX = x1 + i * (this.DottedLineLen + this.IntervalLen);
        dottedLineEndX = Math.min(dottedLineStartX + this.DottedLineLen, x2);
      }
      this.config.points = [dottedLineStartX, dottedLineStartY, dottedLineEndX, dottedLineEndY];
      this.config.stroke = 'red';
      this.config.strokeWidth = 3;
      // dottedLineTmp = new Konva.Line(this.config);
      // this.dottedLineList.push(dottedLineTmp);
      this.dottedLineList.push(new Konva.Line(this.config));
    }
    // console.log(`dash lines:${this.dottedLineList}`);
    this.addDottedLineList2Canvas(this.flowLayer);
  };

  addDottedLineList2Canvas = (layer) => {
    for (let i = 0; i < this.dottedLineList.length; i += 1) {
      // console.log('add');
      // console.log(this.dottedLineList[i]);
      layer.add(this.dottedLineList[i]);
    }
  };

  add2Canvas = () => {
    this.parentLayer.add(this.line);
    if (this.state === 2) {
      this.config.stroke = 'red';
      this.genFlowDottedLine();
    }
    return this;
  };

  setDirection = (direction) => {
    this.direction = direction;
  };

  getInitBaseValue = () => {
    const [x1, y1, x2, y2] = this.points;
    // console.log(x1, y1, x2, y2); // 100, 200, 100, 500
    let initValue = x1;
    if (this.vertical) {
      if (this.direction) initValue = y2;
      else initValue = y1;
    } else if (this.direction) initValue = x2;
    else initValue = x1;
    return initValue;
  };

  removeDashLine = (index) => {
    this.dottedLineList[index].destroy();
    this.dottedLineList.splice(index, 1);
    // console.log(`移除虚线，对应的索引index=${index}`);
  };

  move = (dottedListIndex, base_value) => {
    let baseValue; // 初始化新线段的坐标列表，移动后的线段是否满足移除的条件，以及新增虚线的baseValue
    const [last_x1, last_y1, last_x2, last_y2] = this.dottedLineList[dottedListIndex].points();
    const [base_x1, base_y1, base_x2, base_y2] = this.line.points();
    if (this.vertical && this.direction) {
      // 垂直线段，正向电流
      if (last_y2 + this.MoveDistance > base_y2) {
        this.removeDashLine(dottedListIndex);
      } else {
        this.dottedLineList[dottedListIndex].points([
          last_x1,
          last_y1 + this.MoveDistance,
          last_x2,
          last_y2 + this.MoveDistance,
        ]);
      }
      baseValue = Math.min(last_y1 + this.MoveDistance, base_value);
    } else if (this.vertical) {
      // 垂直线段，反向电流
      if (last_y1 - this.MoveDistance < base_y1) {
        this.removeDashLine(dottedListIndex);
      } else {
        this.dottedLineList[dottedListIndex].points([
          last_x1,
          last_y1 - this.MoveDistance,
          last_x2,
          last_y2 - this.MoveDistance,
        ]);
      }
      baseValue = Math.max(last_y2 - this.MoveDistance, base_value);
    } else if (this.direction) {
      // 水平线段，正向电流
      if (last_x2 + this.MoveDistance > base_x2) {
        this.removeDashLine(dottedListIndex);
      } else {
        this.dottedLineList[dottedListIndex].points([
          last_x1 + this.MoveDistance,
          last_y1,
          last_x2 + this.MoveDistance,
          last_y2,
        ]);
      }
      baseValue = Math.min(base_value, last_x1 + this.MoveDistance);
    } else {
      // 水平线段，反向电流
      if (last_x1 - this.MoveDistance < base_x1) {
        this.removeDashLine(dottedListIndex);
      } else {
        this.dottedLineList[dottedListIndex].points([
          last_x1 - this.MoveDistance,
          last_y1,
          last_x2 - this.MoveDistance,
          last_y2,
        ]);
      }
      baseValue = Math.max(base_value, last_x2 - this.MoveDistance);
    }
    return baseValue;
  };

  generateHeadOrTailDashLine = (baseValue) => {
    let [tmp_x1, tmp_y1, tmp_x2, tmp_y2] = this.line.points();
    const operation_index = this.direction ? 0 : this.dottedLineList.length - 1; // 要操作的列表索引，正向是第一个，反向是最后一个元素
    const [elem_x1, elem_y1, elem_x2, elem_y2] = this.dottedLineList[operation_index].points(); // 要操作的元素的目前坐标信息
    const dashLineLen = this.vertical ? elem_y2 - elem_y1 : elem_x2 - elem_x1; // 目前要操作的线段的长度
    const compareValue = this.vertical
      ? this.direction
        ? tmp_y1
        : tmp_y2
      : this.direction
      ? tmp_x1
      : tmp_x2;
    const generateDashLineLen = Math.abs(baseValue - compareValue) - this.IntervalLen;
    let tmp;
    if (dashLineLen < this.DottedLineLen) {
      // 要操作的元素的长度还不是完整的虚线长度
      if (this.vertical) {
        tmp_y2 = this.direction
          ? elem_y2
          : tmp_y2 - tmp_y1 > this.DottedLineLen
          ? tmp_y1 + this.DottedLineLen
          : tmp_y2;
        tmp_y1 = this.direction
          ? tmp_y2 - tmp_y1 > this.DottedLineLen
            ? tmp_y2 - this.DottedLineLen
            : tmp_y1
          : elem_y1;
      } else if (this.direction) {
        tmp_x2 = this.direction
          ? elem_x2
          : tmp_x2 - tmp_x1 > this.DottedLineLen
          ? tmp_x1 + this.DottedLineLen
          : tmp_x2;
        tmp_x1 = this.direction
          ? tmp_x2 - tmp_x1 > this.DottedLineLen
            ? tmp_x2 - this.DottedLineLen
            : tmp_x1
          : elem_x1;
      } else {
        tmp_x1 = this.direction
          ? tmp_x2 - tmp_x1 > this.DottedLineLen
            ? tmp_x2 - this.DottedLineLen
            : tmp_x1
          : elem_x1;
        tmp_x2 = this.direction
          ? elem_x2
          : tmp_x2 - tmp_x1 > this.DottedLineLen
          ? tmp_x1 + this.DottedLineLen
          : tmp_x2;
      }
      this.config = this.getConfig(false, [tmp_x1, tmp_y1, tmp_x2, tmp_y2]);
      tmp = new Konva.Line(this.config);
      this.dottedLineList[operation_index].destroy();
      this.dottedLineList.splice(operation_index, 1, tmp);
      this.flowLayer.add(tmp);
    } else if (generateDashLineLen > 0) {
      // 第一个元素已经超过了完整的虚线长度，需要新生成小的虚线
      if (this.vertical) {
        if (this.direction) tmp_y2 = tmp_y1 + generateDashLineLen;
        else tmp_y1 = tmp_y2 - generateDashLineLen;
      } else if (this.direction) tmp_x2 = tmp_x1 + generateDashLineLen;
      else tmp_x1 = tmp_x2 - generateDashLineLen;
      this.config = this.getConfig(false, [tmp_x1, tmp_y1, tmp_x2, tmp_y2]);
      tmp = new Konva.Line(this.config);
      this.flowLayer.add(tmp);
      if (this.direction) this.dottedLineList.splice(0, 0, tmp);
      else this.dottedLineList.push(tmp);
    }
  };

  flow = () => {
    if (this.opacity === 1) {
      let baseValue = this.getInitBaseValue();
      for (let i = 0; i < this.dottedLineList.length; i += 1) {
        baseValue = this.move(i, baseValue);
      }
      if (this.dottedLineList.length === 0) {
        this.genFlowDottedLine();
      } else {
        this.generateHeadOrTailDashLine(baseValue);
      }
      this.dottedLineList.forEach((line) => line.draw());
    }
  };

  render() {
    return <div />;
  }
}

export default Line;
