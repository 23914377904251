/* --
 --- Feature：变压器（高压中 T3 - T8）
 --- Author: arya
--*/

import React from 'react';
import Konva from 'konva';
import { Width } from '../Constants';

class Transformer extends React.Component {
  constructor(props) {
    super(props);

    const { height, startX, startY, lineConfig, canvas, state, left, name } = props;

    this.parentLayer = canvas;
    this.TextOffset = 78;
    this.state = state; // 0--断电，1--电压，2--电流
    this.left = left; // true--左侧，false--右侧
    this.name = name;
    this.crossLen = 0.1 * height;
    this.radius = (0.85 / 4) * height;
    this.spaceLineLen = (1 / 8) * height;
    this.centerY1 = startY + this.spaceLineLen + this.radius;

    this.centerY2 = this.centerY1 + 2 * this.radius - this.crossLen;
    this.lineList = [];
    // main
    if (this.state >= 1) {
      lineConfig.stroke = 'red';
    } else {
      lineConfig.stroke = 'green';
    }
    lineConfig.points = [startX, startY, startX, startY + this.spaceLineLen];
    this.line1 = new Konva.Line(lineConfig);
    // console.log(this.line1);
    lineConfig.points = [startX, this.centerY2 + this.radius, startX, startY + height];
    this.line2 = new Konva.Line(lineConfig);
    this.circle1 = new Konva.Circle({
      x: startX,
      y: startY + this.spaceLineLen + this.radius,
      radius: this.radius,
      fill: '',
      stroke: lineConfig.stroke,
      strokeWidth: 2,
      perfectDrawEnabled: false,
    });
    this.circle2 = new Konva.Circle({
      x: startX,
      y: startY + height - this.radius - this.spaceLineLen,
      radius: this.radius,
      fill: '',
      stroke: lineConfig.stroke,
      strokeWidth: 2,
      perfectDrawEnabled: false,
    });
    // right
    lineConfig.strokeWidth = 1;
    lineConfig.points = [startX, this.centerY2, startX + this.radius + 6, this.centerY2];
    this.line3 = new Konva.Line(lineConfig);
    lineConfig.points = [
      this.getCoordinatesByPoints(this.line3).x2,
      this.centerY2,
      this.getCoordinatesByPoints(this.line3).x2,
      this.centerY2 + 6,
    ];
    this.line4 = new Konva.Line(lineConfig);
    // Triangle
    lineConfig.strokeWidth = 2; // 恢复原来的宽度
    lineConfig.points = [
      startX - (1 / 2) * this.radius,
      this.centerY1 + (1 / 4) * this.radius,
      startX + (1 / 2) * this.radius,
      this.centerY1 + (1 / 4) * this.radius,
    ];

    this.line8 = new Konva.Line(lineConfig);
    lineConfig.points = [
      this.getCoordinatesByPoints(this.line8).x1,
      this.getCoordinatesByPoints(this.line8).y1,
      startX,
      this.centerY1 - (1 / 2) * this.radius,
    ];
    this.line9 = new Konva.Line(lineConfig);
    lineConfig.points = [
      startX,
      this.getCoordinatesByPoints(this.line9).y2,
      this.getCoordinatesByPoints(this.line8).x2,
      this.getCoordinatesByPoints(this.line8).y2,
    ];

    this.line10 = new Konva.Line(lineConfig);
    lineConfig.points = [
      startX - (1 / 2) * this.radius,
      this.centerY2 - (1 / 2) * this.radius,
      startX,
      this.centerY2,
    ];

    this.line11 = new Konva.Line(lineConfig);
    lineConfig.points = [
      startX,
      this.centerY2,
      startX + (1 / 2) * this.radius,
      this.centerY2 - (1 / 2) * this.radius,
    ];

    this.line12 = new Konva.Line(lineConfig);
    lineConfig.points = [startX, this.centerY2, startX, this.centerY2 + (1 / 2) * this.radius];

    this.line13 = new Konva.Line(lineConfig);

    lineConfig.stroke = 'grey';
    lineConfig.points = [
      this.getCoordinatesByPoints(this.line4).x1 - 3,
      this.getCoordinatesByPoints(this.line4).y2,
      this.getCoordinatesByPoints(this.line4).x1 + 3,
      this.getCoordinatesByPoints(this.line4).y2,
    ];

    this.line5 = new Konva.Line(lineConfig);
    lineConfig.points = [
      this.getCoordinatesByPoints(this.line4).x1 - 2,
      this.getCoordinatesByPoints(this.line4).y2 + 1,
      this.getCoordinatesByPoints(this.line4).x1 + 2,
      this.getCoordinatesByPoints(this.line4).y2 + 1,
    ];

    this.line6 = new Konva.Line(lineConfig);
    lineConfig.points = [
      this.getCoordinatesByPoints(this.line4).x1 - 1,
      this.getCoordinatesByPoints(this.line4).y2 + 2,
      this.getCoordinatesByPoints(this.line4).x1 + 1,
      this.getCoordinatesByPoints(this.line4).y2 + 2,
    ];

    this.line7 = new Konva.Line(lineConfig);
    // lineConfig.stroke = "green";//恢复原来的颜色
    this.group1 = new Konva.Group();
    this.group1.add(
      this.line1,
      this.line2,
      this.line3,
      this.line4,
      this.line8,
      this.line9,
      this.line10,
      this.line11,
      this.line12,
      this.line13,
      this.circle1,
      this.circle2,
    );
    this.group = new Konva.Group();
    this.group.add(this.line5, this.line6, this.line7, this.group1);
    if (this.left) {
      this.rect = new Konva.Rect({
        x: this.getCoordinatesByPoints(this.line1).x1 - 135,
        y: this.centerY2 - 25,
        width: (115 * Width) / 2560,
        height: (26 * Width) / 2560,
        fill: '#065F9E',
        // stroke: 'white',
        strokeWidth: 1,
        fontSize: (26 * Width) / 2560,
        align: 'center',
        verticalAlign: 'middle',
        perfectDrawEnabled: false,
      });
      this.text = new Konva.Text({
        text: `${this.name}变压器`,
        x: this.getCoordinatesByPoints(this.line1).x1 - 135,
        y: this.centerY2 - 25,
        width: (115 * Width) / 2560,
        height: (26 * Width) / 2560,
        fontSize: (26 * Width) / 2560,
        align: 'center',
        verticalAlign: 'middle',
        // fontStyle: 'bold',
        fill: 'white',
      });
    } else {
      this.rect = new Konva.Rect({
        x: this.getCoordinatesByPoints(this.line1).x1 + 30,
        y: this.centerY2 - 25,
        width: (120 * Width) / 2560,
        height: (26 * Width) / 2560,
        fill: '#DCA311',
        align: 'center', // 字体对齐方式
        verticalAlign: 'middle',
        // stroke: 'white',
        strokeWidth: 1,
        fontSize: (26 * Width) / 2560,
        perfectDrawEnabled: false,
      });
      this.text = new Konva.Text({
        text: `${this.name}变压器`,
        x: this.getCoordinatesByPoints(this.line1).x1 + 30,
        y: this.centerY2 - 25,
        width: (120 * Width) / 2560,
        fontSize: (26 * Width) / 2560,
        align: 'center', // 字体对齐方式
        verticalAlign: 'middle',
        // fontStyle: 'bold',
        fill: 'white',
      });
    }

    canvas.add(this.group);
  }
  getCoordinatesByPoints = (Konva_line) => ({
    x1: Konva_line.points()[0],
    y1: Konva_line.points()[1],
    x2: Konva_line.points()[2],
    y2: Konva_line.points()[3],
  });
  addName2Canvas = () => {
    this.parentLayer.add(this.rect);
    this.parentLayer.add(this.text);
    return this;
  };
  destroy = () => {
    Object.values(this).forEach((obj) => {
      if (obj instanceof Konva.Shape) {
        obj.setAttr('opacity', 0);
      }
    });
  };
  show = () => {
    Object.values(this).forEach((obj) => {
      if (obj instanceof Konva.Shape) {
        obj.setAttr('opacity', 1);
      }
    });
  };

  setColor = (color) => {
    // console.log(this.group1.getChildren());
    for (let i = 0; i < this.group1.getChildren().length; i += 1) {
      // console.log(this.group1.getChildren()[i]);
      // this.group1[i]
      this.group1.getChildren()[i].setAttr('stroke', color);
      this.group1.draw();
    }
  };

  setState = (state) => {
    // console.log(this.group1.items);
    if (this.state !== state) {
      // console.log('变压器的前后状态相同，不需要改变');
      // } else {
      this.state = state;
      if (this.state === 2 || this.state === 1) {
        this.setColor('red');
      } else {
        this.setColor('green');
      }
    }
  };
}

export default Transformer;
