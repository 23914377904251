/* --
 --- Feature：母联开关（高压处用到 - 9处）
 --- Author: arya
 * // fixme 取消注释
--*/

import React from 'react';
import Konva from 'konva';
import moment from 'moment';
import { Width } from '../Constants';

class BusTieSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.offset = 6;
    this.StateWidth = 45;
    this.StateHeight = 19;
    this.timer_limit = 3;
    this.StateOffset = 15;
    this.TextOffset = 35;
    const { x1, y1, x2, y2, state, closed, row, name, load, left, menu, parentLayer } = props;
    // console.log(`x1:${x1} y1:${y1} x2:${x2} y2:${y2}`);

    this.x1 = x1;
    this.x2 = x2;
    this.y1 = y1;
    this.y2 = y2;
    this.menu = menu;
    this.parentLayer = parentLayer;
    this.state = state; //  0--工作位，1--试验位，2--抽出位
    this.opacity = 1;
    if (this.state === 2) {
      // console.log(this.state);
      this.increase = false;
    } else {
      this.increase = true; // state的更改方向
    }
    // fixme 在demo中母联开关的负载一直都是true
    this.load = load; // 负载状态，有负载和无负载
    this.on = closed; // true--闭合，false--断开
    this.config = this.getConfig(name);
    // console.log(this.config);
    this.row = row; // true -- 横排开关，false -- 竖排开关
    this.name = `AA-${name}-01`;
    this.line = this.getThisLine();
    // console.log(this.line);
    if (!this.row) {
      this.left = left;
    } else {
      // 横排的母联开关
      this.left_load = false;
      this.right_load = false;
    }
    this.stateRect = this.getStateRect();
    this.stateContent = this.getStateContent();
    this.timer = this.timer_limie;

    if (!this.row && this.left) {
      this.rect = new Konva.Rect({
        x: this.stateRect.x() - (3 / 2) * this.StateWidth - 60,
        y: this.stateRect.y(),
        width: ((3 / 2) * this.StateWidth + 50) * (Width / 2560),
        height: (26 * Width) / 2560,
        fill: '#065F9E',
        // stroke: 'white',
        strokeWidth: 1,
        fontSize: (26 * Width) / 2560,
        align: 'center', // 字体对齐方式
        verticalAlign: 'middle',
        perfectDrawEnabled: false,
      });
      this.text = new Konva.Text({
        text: this.name,
        x: this.stateRect.x() - (3 / 2) * this.StateWidth - 60,
        y: this.stateRect.y(),
        width: ((3 / 2) * this.StateWidth + 50) * (Width / 2560),
        fill: 'white',
        fontSize: (26 * Width) / 2560,
        align: 'center', // 字体对齐方式
        verticalAlign: 'middle',
        // fontStyle: 'bold',
      });
    } else if (!this.row) {
      this.rect = new Konva.Rect({
        x: this.stateRect.x() + this.StateWidth + 10,
        y: this.stateRect.y() - 3,
        width: ((3 / 2) * this.StateWidth + 50) * (Width / 2560),
        height: (26 * Width) / 2560,
        fill: '#DCA311',
        align: 'center', // 字体对齐方式
        verticalAlign: 'middle',
        // stroke: 'white',
        strokeWidth: 1,
        fontSize: (26 * Width) / 2560,
        perfectDrawEnabled: false,
      });
      this.text = new Konva.Text({
        text: this.name,
        x: this.stateRect.x() + this.StateWidth + 10,
        y: this.stateRect.y(),
        width: ((3 / 2) * this.StateWidth + 50) * (Width / 2560),
        fontSize: (26 * Width) / 2560,
        // fontStyle: 'bold',
        align: 'center', // 字体对齐方式
        verticalAlign: 'middle',
        fill: 'white',
      });
    }
  }

  setLoad = (load, left) => {
    if (this.row) {
      if (left && this.left_load !== load) {
        this.left_load = load;
        // console.log(`${this.name}设置左侧负载:${this.left_load}`);
      } else if (!left && this.right_load !== load) {
        this.right_load = load;
        // console.log(`${this.name}设置右侧负载:${this.left_load}`);
      }
    } else if (this.load !== load) {
      this.load = load;
      // console.log(`${this.name}设置负载:${this.load}`);
    }
  };

  getConfig = (name) => {
    const config = {
      points: [this.x1, this.y1, this.x2, this.y2],
      strokeWidth: 2,
      stroke: 'red',
      originX: 'center',
      originY: 'center',
      svgUid: name,
      selectable: false,
      opacity: this.opacity,
    };
    if (!this.on) {
      config.stroke = 'green';
      config.points = this.row
        ? [this.x1, this.y1 - this.offset, this.x2, this.y2]
        : [this.x1 - this.offset, this.y1, this.x2, this.y2];
    }
    return config;
  };

  add2Canvas = () => {
    this.parentLayer.add(this.line);
    this.parentLayer.add(this.stateRect);
    this.parentLayer.add(this.stateContent);
    return this;
  };

  getThisLine = () => {
    const config = this.getConfig(this.name);
    return new Konva.Line(config);
  };

  getCoordinatesByPoints = (Konva_line) => ({
    x1: Konva_line.points()[0],
    y1: Konva_line.points()[1],
    x2: Konva_line.points()[2],
    y2: Konva_line.points()[3],
  });

  getStateRect = () => {
    // 绘制试验位、工作位、抽出位
    if (this.row) {
      // 横排在线下面绘制
      return new Konva.Rect({
        x: this.getCoordinatesByPoints(this.line).x1 - 10,
        y: this.getCoordinatesByPoints(this.line).y1 + this.StateOffset,
        fill: '',
        originX: 'center',
        originY: 'center',
        width: this.StateWidth,
        height: this.StateHeight,
        stroke: 'green',
        strokeWidth: 2,
        perfectDrawEnabled: false,
      });
    }
    // 竖排分左右
    if (this.left) {
      return new Konva.Rect({
        x: this.getCoordinatesByPoints(this.line).x1 - this.StateOffset - 40,
        y: this.getCoordinatesByPoints(this.line).y1,
        fill: '',
        originX: 'center',
        originY: 'center',
        align: 'left',
        width: this.StateWidth,
        height: this.StateHeight,
        stroke: 'green',
        strokeWidth: 2,
        selectable: false,
      });
    }
    return new Konva.Rect({
      x: this.getCoordinatesByPoints(this.line).x1 + this.StateOffset + 5,
      y: this.getCoordinatesByPoints(this.line).y1,
      fill: '',
      originX: 'center',
      originY: 'center',
      width: this.StateWidth,
      height: this.StateHeight,
      stroke: 'green',
      strokeWidth: 1,
      perfectDrawEnabled: false,
    });
  };

  getBusTieSwitchName = () => this.name;

  addName2Canvas = () => {
    this.parentLayer.add(this.rect);
    this.parentLayer.add(this.text);

    return this;
  };
  getStateContent = () => {
    let content = 1;
    if (this.state === 0) {
      content = '工作位';
    } else if (this.state === 1) {
      content = '试验位';
    } else if (this.state === 2) {
      content = '抽出位';
    } else {
      //   console.log(this.state);
      // console.error(`母联开关${this.name}状态错误`);
    }
    // return new fabric.IText(content,{left:this.stateRect.left - this.stateRect.width / 2 + 2,top:this.stateRect.top + this.stateRect.height / 2 - 2,width:this.stateRect.width,height:this.stateRect.height,textAlign:"center",scaleX:0.7,scaleY:0.7,fontSize:1,selectable:false})
    return new Konva.Text({
      text: content,
      x: this.stateRect.x() - 2,
      y: this.stateRect.y(),
      width: this.stateRect.width(),
      height: this.stateRect.height(),
      align: 'center',
      fill: 'white',
      verticalAlign: 'middle',
      fontSize: (14 * Width) / 2560,
    });
  };

  setColor = (color) => {
    this.line.remove();
    const rect = this.line.getClientRect();
    this.parentLayer.clear({ x: rect.x, y: rect.y, width: rect.width, height: rect.height });
    this.line.setAttr('stroke', color);
    this.parentLayer.add(this.line);
  };

  destroy = () => {
    this.opacity = 0;
    Object.values(this).forEach((obj) => {
      if (obj instanceof Konva.Shape) {
        obj.setAttr('opacity', 0);
      }
    });
  };
  show = () => {
    this.opacity = 1;
    Object.values(this).forEach((obj) => {
      if (obj instanceof Konva.Shape) {
        obj.setAttr('opacity', 1);
      }
    });
  };

  translateClick = () => {
    if (this.on) return '闭合';
    return '断开';
  };
  translateClick1 = (auto) => {
    if (auto) return '自动';
    return '手动';
  };

  onclick = (closed, auto, event) => {
    /**
     * closed:有输入的时候为断开市电下口的开关的连锁反应，如果该母联开关的状态是闭合的应该断开
     * 没有输入的时候为点击母联开关，直接按照断开闭合的连续动作就好
     * */
    const { setSimulateLogs } = this.props;
    if (typeof closed === 'undefined') {
      this.line.remove();
      const rect = this.line.getClientRect();
      this.parentLayer.clear({ x: rect.x, y: rect.y, width: rect.width, height: rect.height });
      this.on = !this.on;
      this.line = this.getThisLine();
      this.parentLayer.add(this.line);
      this.line.draw();
      if (this.opacity === 1 && !this.row) {
        setSimulateLogs({
          time: moment().format('YYYY/MM/DD HH:mm'),
          status: this.translateClick1(auto),
          value: this.name + this.translateClick(),
        });
      }
    } else if (this.on !== closed) {
      this.line.remove();
      const rect = this.line.getClientRect();
      this.parentLayer.clear({ x: rect.x, y: rect.y, width: rect.width, height: rect.height });
      this.on = closed;
      this.line = this.getThisLine();
      this.parentLayer.add(this.line);
      this.line.draw();
      // this.parentLayer.batchDraw();
      if (this.opacity === 1 && !this.row) {
        setSimulateLogs({
          time: moment().format('YYYY/MM/DD HH:mm'),
          status: this.translateClick1(auto),
          value: this.name + this.translateClick(),
        });
      }
    }
    // console.log(`${this.name}母联的闭合情况：${this.on}`);
  };

  isPointInPath = (x, y) =>
    // 这里因为根据线的外接矩形来判断点击，所以可能不太好点
    // 如果这里需要修改鼠标点击的点的话，需要确保鼠标点击的开关位置和状态信息位置不会重复
    x - this.line.getClientRect().x >= 0 &&
    x - this.line.getClientRect().x <= this.line.getClientRect().width &&
    y - this.line.getClientRect().y >= 0 &&
    y - this.line.getClientRect().y <= this.line.getClientRect().height;

  isPointInStateRect = (x, y) =>
    x - this.stateRect.x() >= 0 &&
    x - this.stateRect.x() <= this.stateRect.width() &&
    y - this.stateRect.y() >= 0 &&
    y - this.stateRect.y() <= this.stateRect.height();
  // Math.abs(this.stateRect.left - x) <= this.stateRect.width / 2 &&
  // Math.abs(this.stateRect.top - y) <= this.stateRect.height / 2;

  stateRectClick = () => {
    this.stateContent.destroy();
    if (this.increase) {
      this.state += 1;
    } else {
      this.state -= 1;
    }
    if (this.state === 2) {
      this.increase = false;
    } else if (this.state === 0) {
      this.increase = true;
    }
    // this.state = (this.state + 1) % 3;
    this.stateContent = this.getStateContent();
    this.parentLayer.add(this.stateContent);
  };

  countDown = (callback) =>
    // 倒计时结束之后触发回调函数
    setTimeout(callback, this.timer, this.state); // 返回一个ID可以取消倒计时执行回调函数，param1,param2传递给函数的其他参数

  clearCountDown = (timer_id) => {
    clearTimeout(timer_id);
  };
}

export default BusTieSwitch;
