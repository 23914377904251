import React, { Component } from 'react';

class coldSiteIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <iframe
        id="coldSiteIndex"
        title="title"
        style={{
          width: '100%',
          height: '80vh',
          border: 'none',
        }}
        scrolling="no"
        src="./../../../coldsite/html/coldsite.html"
      />
    );
  }
}

export default coldSiteIndex;
