import React from 'react';
// localization
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import 'moment/locale/zh-cn';
// store
import { Provider, connect } from 'react-redux';
import store from '@/store';
// router
import { BrowserRouter, Switch, Route, withRouter } from 'react-router-dom';
// load
import Loadable from 'react-loadable';
// base
import Layout from '@/basic/Layout';
// pages
import VideoCourse from '@/pages/VideoCourse';
import Loading from '@/pages/Loading';
import Login3D from '@/pages/Login3D';
import Home from '@/pages/Home';
import Lock from '@/pages/Lock';
import ARPlayer from '@/pages/ARPlayer';
import VRPlayer from '@/pages/VRPlayer';
import AR from '@/pages/AR';
import DesktopExercise from '@/pages/DesktopExercise1';
import ClassTest from '@/pages/ClassTest';
import Ba from '@/pages/ColdSite';
import MemberHandle from '@/pages/MemberHandle';

// import Redirect from '@pages/Result';
const load = (loader) => Loadable({ loader, loading: Loading });
// 404
const NotFound = load(() => import('@/pages/NotFound'));
// 路由列表
const routes = [
  // 首页
  { path: '/', component: AR },
  { path: '/login', component: Login3D },
  { path: '/log-manage', component: Home },
  { path: '/lock', component: Lock },
  // { path: '/capacity-analysis/module-manage', component: ModuleManage},
  // { path: '/capacity-analysis/data-handle', component: DataHandle},
  // 404
  { path: '*', component: NotFound },
];

const ConnectedRoutes = withRouter(
  connect()(() => (
    <Switch>
      {/* <Route exact key="/" path="/" component={Login3D}>
        <Switch>
        <Route key="/video-course" path="/video-course" component={VideoCourse}>
          <Route exact key="/video-play/:videoId(\d+)" path="/video-play/:videoId(\d+)" component={VideoPlayer}/>
        </Route>  
        </Switch>
        <Route key="/checkin" path="/checkin" component={Home} />
        <Route key="/ar" path="/ar" component={AR} />
        <Route key="/desktop-exercise" path="/desktop-exercise" component={DesktopExercise} />
        <Route key="/lock" path="/lock" component={Lock} />
        <Route path="*" component={NotFound} />
      </Route> */}
      <Route exact key="/" path="/" component={Login3D} />
      <Route exact key="/video-course" path="/video-course" component={VideoCourse} />
      <Route exact key="/vr" path="/vr" component={VideoCourse} />
      <Route exact key="/video-course/:id" path="/video-course/:id" component={ARPlayer} />
      <Route exact key="/vr/:id" path="/vr/:id" component={VRPlayer} />
      <Route exact key="/member-handle" path="/member-handle" component={MemberHandle} />
      <Route key="/checkin" path="/checkin" component={Home} />
      <Route key="/ar" path="/ar" component={AR} />
      <Route key="/desktop-exercise" path="/desktop-exercise" component={DesktopExercise} />
      <Route key="/test-course" path="/test-course" component={ClassTest} />
      <Route key="/znqk" path="/znqk" component={Ba} />
      <Route key="/lock" path="/lock" component={Lock} />
      <Route path="*" component={NotFound} />
      {/* {routes.map(({ path, component }) => (
        path === '/' ? <Route exact key={path} path={path} component={component} /> ? <Route key={path} path={path} component={component} />
      ))} */}
    </Switch>
  )),
);

const App = () => (
  <ConfigProvider locale={zhCN}>
    <Provider store={store}>
      <BrowserRouter>
        <Layout>
          <ConnectedRoutes />
        </Layout>
      </BrowserRouter>
    </Provider>
  </ConfigProvider>
);

export default App;
