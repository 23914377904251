/* eslint-disable no-useless-constructor */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Card } from 'antd';
import { Link } from 'react-router-dom';

const { Meta } = Card;

class VideoPanel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { videoTitle, videoImage, videoDesc, videoId, match } = this.props;
    // if(videoImage.indexOf('vr') === -1){
      return (
        <>
          <Link to={`${match.url}/${videoId}`}>
            <Card hoverable cover={<img alt="" src={videoImage} />}>
              <Meta title={videoTitle} description={videoDesc} />
            </Card>
          </Link>
        </>
      );
    // }else{
    //   return(
    //     <>
    //       <Link to={`${match.url}/${videoId}`}>
    //         <Card hoverable cover={<img alt="" src={videoImage} />}>
    //           <Meta title={videoTitle} description={videoDesc} />
    //         </Card>
    //       </Link>
    //     </>
    //   )
    // }
  }
}

export default VideoPanel;
