import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStore from '@/store/withStore';
import styles from './index.scss';
import { isEmpty } from 'lodash';

class VideoDemoPlayer extends Component {
  constructor(props) {
    super(props);

    window.addEventListener('amessage', this.receiveMessage, false);
  }

  componentDidMount() {
    const { getVideoInfo } = this.props;
    getVideoInfo(this.props.match.params.id);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { history } = this.props;
    // console.log('shouldComponentUpdate', this.props, nextProps)
    // if(this.props.videoInfo.id !== nextProps.videoInfo.id) {
    // this.player.dispose();
    // }
    if (isEmpty(this.props.videoInfo)) {
      history.push({ pathname: '/vr' });
      return false;
    }
    return this.props.videoInfo.id !== nextProps.videoInfo.id;
  }

  componentWillUnmount() {}

  receiveMessage = (event) => {
    const wind = document.getElementById('VrPlayer');
    if (wind) {
      // console.log(wind.contentWindow)
      // wind.contentWindow.postMessage(this.props.match.params.id,'*'); //window.postMessage
    }
    console.log('react:receiveMessage', event, this.props.match.params.id);
  };

  render() {
    const videoInfo = this.props.videoInfo;
    if (JSON.stringify(videoInfo) !== '{}') {
      return (
        <>
          <div className={styles.box}>
            <div className={styles.header}>
              <div className={styles.title}>{videoInfo.name}</div>
              {/* <div className={styles.time}>视频时长</div> */}
            </div>
            <div className={styles.content}>
              {/* <link rel="stylesheet" type="text/css" href="/video-js.css" /> */}
              {/* <VideoPlayer {...this.videoJsOptions} /> */}
              <iframe
                id="VrPlayer"
                title="title"
                style={{
                  width: '100%',
                  height: '60vh',
                  border: 'none',
                }}
                scrolling="no"
                src="./../../../VRPlayer/index.html"
              />
              <div className={styles.brief}>{videoInfo.description}</div>
            </div>
            <div className={styles.moreVideoList}>
              <div className={styles.title}>更多同类视频</div>
              <div className={styles.list}></div>
            </div>
          </div>
        </>
      );
    }
    return null;
  }
}
export default connect(...withStore('basic', 'VRPlayer'))(VideoDemoPlayer);
