import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStore from '@/store/withStore';
import styles from './index.scss';
import { Link } from 'react-router-dom';
import { currentEnv } from '../../basic/Common';

class VideoPlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videoSrc: '',
      token: '',
    };

    this.videoJsOptions = null;
    this.ttoken = '';
  }

  componentDidMount() {
    const { getVideoInfo } = this.props;
    getVideoInfo(this.props.match.params.id);
    // console.log("componentDidMount", this.props, this.ttoken )

    // videojs.addLanguage('zh-CN', videozhCN);
  }

  shouldComponentUpdate(nextProps) {
    const videoInfo = this.props.videoInfo;
    const token = this.props.token;
    // console.log(videoInfo)
    // if (!videoInfo.url && this.ttoken === token) {
    //   return false;
    // }
    // this.setOption(videoInfo);
    // this.ttoken = token;
    // this.forceUpdate()
    return this.props.videoInfo.url !== nextProps.videoInfo.url;
  }

  componentDidUpdate() {
    // this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
    //   console.log('onPlayerReady', this);
    // });
  }

  setOption(videoInfo) {
    const videoUrl = videoInfo.url;
    const imageUrl = videoInfo.imageURL;
    // 播放器的配置
    const videoJsOptions = {
      autoplay: false, // 自动播放
      language: 'zh-CN',
      // controls: true,  //控制条
      preload: true, // 自动加载
      // errorDisplay: true,  //错误展示
      // width: videoContainer.clientWidth,  //宽
      // height: videoContainer.clientHeight,  //高
      // fluid: true,  //跟随外层容器变化大小，跟随的是外层宽度
      // controlBar: false,  // 设为false不渲染控制条DOM元素，只设置controls为false虽然不展示，但还是存在
      // textTrackDisplay: false,  // 不渲染字幕相关DOM
      poster:
        imageUrl.indexOf('png') !== -1
          ? currentEnv.concat(imageUrl)
          : currentEnv.concat(imageUrl).concat('.png'),
      userActions: {
        // hotkeys: true  //是否支持热键
      },
      sources: [
        {
          src:
            videoUrl.indexOf('mp4') !== -1
              ? currentEnv.concat(videoUrl).concat('.mp4')
              : currentEnv.concat(videoUrl),
          type: 'video/mp4',
        },
        {
          src:
            videoUrl.indexOf('webm') !== -1
              ? currentEnv.concat(videoUrl).concat('.webm')
              : currentEnv.concat(videoUrl),
          type: 'video/webm',
        },
      ],
    };

    this.videoJsOptions = videoJsOptions;
  }

  receiveMessage = (event) => {
    const { history } = this.props;
    //console.log('我是Login3D,我接受到了来自iframe的数据：', event.data);
    if (event.data.data === 'suc') {
      // history.push({ pathname: '/video-course' });
    }
    if (event.data.data === 'error') {
      // message.error('账号名或密码有误,请重新输入');
    }
  };

  relatedVideo() {
    // this.props.videoInfo;
    const { match } = this.props;
    var html = [];
    if (this.props.relatedVideoInfo.length > 0) {
      this.props.relatedVideoInfo.map((item, index) => {
        html.push(
          <div className={styles.singleCourse}>
            <a href={item.id}>
              <div className={styles.singleCourseImg}>
                <span>
                  <img src={currentEnv + item.imageURL + '.png'}></img>
                </span>
                <span className={styles.imgAsideText}>{index}</span>
              </div>
              <div className={styles.singleCourseContent}>
                <span>{item.name}</span>
              </div>
            </a>
          </div>,
        );
      });
      return html;
    } else {
      return '暂无其他相关视频';
    }
  }

  render() {
    const videoInfo = this.props.videoInfo;
    const videoUrl = videoInfo.url;
    const imageUrl = videoInfo.imageURL;
    let videoJsOptions = {};
    if (JSON.stringify(videoInfo) !== '{}') {
      videoJsOptions = {
        autoplay: false,
        controls: true,
        language: 'zh-CN',
        poster:
          imageUrl.indexOf('png') !== -1
            ? currentEnv.concat(imageUrl)
            : currentEnv.concat(imageUrl).concat('.png'),
        sources: [
          {
            src: currentEnv.concat(videoUrl),
          },
        ],
      };
      // console.log("videoJsOptions", this.videoJsOptions)
      // if(this.videoJsOptions === null){
      //   return null
      // }

      return (
        <>
          <div className={styles.box}>
            {/* <link rel="stylesheet" type="text/css" href="/video-js.css" /> */}
            {/* <VideoPlayer {...this.videoJsOptions} /> */}
            {/* <VideoPlayer {...videoJsOptions} /> */}
            <div className={styles.videoPlayer}>
              <iframe
                id="ARPlayer"
                title="title"
                style={{
                  width: '100%',
                  height: '60vh',
                  border: 'none',
                }}
                scrolling="no"
                src="./../../../ARPlayer/index.html"
              />
            </div>
            <div className={styles.content}>
              <div className={styles.videoInfo}>
                <div className={styles.title}>{videoInfo.name}</div>
                <div className={styles.brief}>{videoInfo.description}</div>
              </div>
              {/* <div className={styles.time}>视频时长</div> */}

              {/* <div>
                <div data-vjs-player>
                  <video
                    ref={(node) => (this.videoNode = node)}
                    className="video-js vjs-default-skin vjs-big-play-centered"
                  >
                    <track kind="captions" />
                    <p className="vjs-no-js">您的浏览器不支持HTML5，请升级浏览器。</p>
                  </video>
                </div>
              </div> */}
              <div className={styles.moreVideoList}>
                <div className={styles.title}>更多同类视频</div>
                <div className={styles.list}>{this.relatedVideo()}</div>
              </div>
            </div>
          </div>
        </>
      );
    }
    return null;
  }
}
export default connect(...withStore('basic', 'ARPlayer'))(VideoPlay);
