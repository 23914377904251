import createStore from '@/store/createStore';

// 基本
import basic from '@/basic/model';
//登录页
import login3D from '@/pages/Login3D/model';
// 首页
import home from '@/pages/Home/model';
// 视频课程
import videoCourse from '@/pages/VideoCourse/model';
// 视频播放
import ARPlayer from '@/pages/ARPlayer/model';
// demo视频播放
import VRPlayer from '@/pages/VRPlayer/model';
// // 桌面演练系统
import desktopExercise from '@/pages/DesktopExercise1/model';
// 人员管理
import MemberHandle from '@/pages/MemberHandle/model';
// // 成本分析系统
// import costAnalysis from '@/pages/CostAnalysis/model';
// // 动力环境系统
// import powerEnvironment from '@/pages/PowerEnvironment/model';
// // 3D建模
// import threeDModeling from '@/pages/ThreeDModeling/model';// // 资产挂办理系统
// import assetsManage from '@/pages/AssetsManage/model';
// // 报表系统
// import capacityAnalysis from '@/pages/CapacityAnalysis/model';
// // 容量管理系统
// import capacityManage from '@/pages/CapacityManage/model';
// // 运维日志系统
// import systemLog from '@/pages/SystemLog/model';
// 锁屏页面
import lock from '@/pages/Lock/model';

const models = {
  // 基本
  basic,
  // 首页
  home,
  // 登录页
  login3D,
  // 视频课程
  videoCourse,
  // AR视频播放
  ARPlayer,
  // VR视频播放
  VRPlayer,
  // 桌面演练系统
  desktopExercise,
  // 人员管理
  MemberHandle,
  // // 成本分析系统
  // costAnalysis,
  // // 动力环境系统
  // powerEnvironment,
  // // 3D建模
  // threeDModeling,
  // // 资本管理系统
  // assetsManage,
  // // 报表系统
  // capacityAnalysis,
  // // 容量管理系统
  // capacityManage,
  // // 运维日志系统
  // systemLog,
  // 锁屏页面
  lock,
};

export default createStore(models);
