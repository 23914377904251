import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import withStore from '@/store/withStore';
import { message } from 'antd';

class Login3D extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    // this.handleLogin();
  }

  componentDidMount() {
    window.addEventListener('message', this.receiveMessage, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.receiveMessage);
  }

  // handleLogin = async () => {
  //   const { history } = this.props;
  //   // const params = { username: 'root' };
  //   const result = await fetch('https://study.dccloudclass.com/api/logined', { type: 'GET' });
  //   if (!result) {
  //     history.push({ pathname: '/login' });
  //     document.getElementById('bg').style.display = 'none';
  //     message.error('请登录');
  //   }
  // };

  receiveMessage = (event) => {
    const { history } = this.props;
    //console.log('我是Login3D,我接受到了来自iframe的数据：', event.data);
    if (event.data.data === 'suc') {
      this.props.getMenuData();
      history.push({ pathname: '/desktop-exercise' });
    }
    if (event.data.data === 'error') {
      message.error('账号名或密码有误,请重新输入');
    }
  };
  render() {
    return (
      <iframe
        id="login3D"
        title="title"
        style={{
          width: '100%',
          height: '100vh',
          border: 'none',
        }}
        scrolling="no"
        src="./../../../dcos3DLogin/login.html"
      />
    );
  }
}

export default connect(...withStore('basic', 'login3D'))(Login3D);
