import React, { Component } from 'react';
import { Input, Checkbox, Button } from 'antd';
import { sha256 } from 'js-sha256';
import withStore from '@/store/withStore';
import fetch from '@/utils/fetch';
import styles from './index.scss';
import { connect } from 'react-redux';

class Lock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // remember: false,
      username: '',
      password: '',
      loading: false,
    };
  }

  componentDidMount() {
    //console.log('lock-didMount');
  }

  handleCheck = (event) => {
    // this.setState({ remember: event.target.checked });
    if (!event.target.checked) {
      this.setState({ username: '' });
      this.setState({ password: '' });
    }
  };

  handleChangeUserName = ({ target: { value } }) => {
    this.setState({ username: value });
  };

  handleChange4Password = ({ target: { value } }) => {
    this.setState({ password: value });
  };

  handleLogin = async () => {
    const { history } = this.props;
    const { username, password } = this.state;
    this.setState({ loading: true });
    const params = { ms_username: username, password: sha256(password) };
    // eslint-disable-next-line
    //console.log(params, fetch);
    // const result = await fetch('/login', { type: 'POST', params });
    // this.setState({ loading: false });
    // if (result) {
    //   history.push({ pathname: '/cost-analysis' });
    // }
    document.getElementById('bg').style.display = 'none';
    // document.getElementById("show").style.display ="none";
    if (this.props.lastPath) {
      history.push({ pathname: this.props.lastPath });
    } else {
      history.push({ pathname: '/desktop-exercise' });
    }
  };

  render() {
    const { loading } = this.state;
    return (
      <>
        <div id="show" className="show">
          <div className={styles.container}>
            <div className={styles.board}>
              <div className={styles.login}>
                <span className={styles.title}>欢迎您！</span>
                <div className={styles.content}>
                  <span className={styles.name}>用户名</span>
                  <Input
                    className={styles.value}
                    style={{ marginBottom: '0.24rem' }}
                    placeholder="请输入用户名"
                    defaultValue={this.state.username}
                    onChange={this.handleChangeUserName}
                  />
                  <span className={styles.name}>密码</span>
                  <Input.Password
                    className={styles.value}
                    placeholder="请输入密码"
                    defaultValue={this.state.password}
                    onChange={this.handleChange4Password}
                  />
                </div>
                <div className={styles.tip}>
                  <Checkbox className={styles.check} onChange={this.handleCheck}>
                    记住我
                  </Checkbox>
                  <span style={{ cursor: 'pointer' }}>忘记密码</span>
                </div>
                <Button
                  type="primary"
                  className={styles.confirm}
                  onClick={this.handleLogin}
                  loading={loading}
                >
                  登录
                </Button>
                <div className={styles.registry}>
                  还没有账号？<span style={{ color: '#F8B902', cursor: 'pointer' }}>去注册</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(...withStore('basic'))(Lock);
