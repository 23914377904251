/**
 * Feature：发动机模块
 * Author: arya
 * */
import React from 'react';
import Konva from 'konva';
import Line from './base/Line';
import Switch from './base/Switch';
import {
  CenterX,
  DieselGeneratorStartY,
  H2,
  FirstRowLineLen,
  SwitchHeight,
  lineConfig,
  CenterLineLen,
} from './Constants';

const motorImgOn = require('@/assets/desktop_exercise/motoron.png');
const motorImgOff = require('@/assets/desktop_exercise/motoroff.png');

class DieselGenerator extends React.Component {
  constructor(props) {
    super(props);
    const { menu, main, parentLayer, flowLayer, setSimulateLogs } = props;
    this.menu = menu;
    this.main = main;
    this.parentLayer = parentLayer;
    this.isShow = false;
    this.Line2 = new Line({
      lineConfig,
      points: [CenterX, DieselGeneratorStartY, CenterX, DieselGeneratorStartY + H2],
      direction: true,
      serialNum: 2,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line5 = new Line({
      lineConfig,
      points: [
        CenterX,
        this.Line2.getCoordinatesByPoints().y2 + SwitchHeight,
        CenterX,
        this.Line2.getCoordinatesByPoints().y2 + SwitchHeight + H2,
      ],
      direction: true,
      serialNum: 5,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    const line1_x = CenterX - CenterLineLen / 2 - FirstRowLineLen;
    this.Line1 = new Line({
      lineConfig,
      points: [line1_x, DieselGeneratorStartY, line1_x, this.Line2.getCoordinatesByPoints().y2],
      direction: true,
      serialNum: 1,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    const line3_x = CenterX + CenterLineLen / 2 + FirstRowLineLen;
    this.Line3 = new Line({
      lineConfig,
      points: [line3_x, DieselGeneratorStartY, line3_x, this.Line2.getCoordinatesByPoints().y2],
      direction: true,
      serialNum: 3,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line4 = new Line({
      lineConfig,
      points: [
        line1_x,
        this.Line5.getCoordinatesByPoints().y1,
        line1_x,
        this.Line5.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 4,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line6 = new Line({
      lineConfig,
      points: [
        line3_x,
        this.Line5.getCoordinatesByPoints().y1,
        line3_x,
        this.Line5.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 6,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line7 = new Line({
      lineConfig,
      points: [
        line1_x - FirstRowLineLen,
        this.Line4.getCoordinatesByPoints().y2,
        line1_x,
        this.Line4.getCoordinatesByPoints().y2,
      ],
      direction: false,
      serialNum: 7,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line8 = new Line({
      lineConfig,
      points: [
        line1_x,
        this.Line4.getCoordinatesByPoints().y2,
        CenterX,
        this.Line4.getCoordinatesByPoints().y2,
      ],
      direction: false,
      serialNum: 8,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line9 = new Line({
      lineConfig,
      points: [
        CenterX,
        this.Line4.getCoordinatesByPoints().y2,
        line3_x,
        this.Line4.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 9,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line10 = new Line({
      lineConfig,
      points: [
        line3_x,
        this.Line4.getCoordinatesByPoints().y2,
        line3_x + FirstRowLineLen,
        this.Line4.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 10,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line11 = new Line({
      lineConfig,
      points: [
        this.Line7.getCoordinatesByPoints().x1,
        this.Line4.getCoordinatesByPoints().y2,
        this.Line7.getCoordinatesByPoints().x1,
        this.Line4.getCoordinatesByPoints().y2 + H2,
      ],
      direction: true,
      serialNum: 11,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();
    this.Line12 = new Line({
      lineConfig,
      points: [
        this.Line10.getCoordinatesByPoints().x2,
        this.Line4.getCoordinatesByPoints().y2,
        this.Line10.getCoordinatesByPoints().x2,
        this.Line11.getCoordinatesByPoints().y2,
      ],
      direction: true,
      serialNum: 12,
      state: 0,
      layer: this.parentLayer,
      flowLayer,
    }).add2Canvas();

    // baseline,angle,closed,load,state,name,type
    this.Switch_AHE3 = new Switch({
      baseline: this.Line1,
      angle: 0,
      closed: false,
      load: false,
      state: 0,
      name: 'AH-E3',
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.Switch_AHE4 = new Switch({
      baseline: this.Line2,
      angle: 0,
      closed: false,
      load: false,
      state: 0,
      name: 'AH-E4',
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.Switch_AHE5 = new Switch({
      baseline: this.Line3,
      angle: 0,
      closed: false,
      load: false,
      state: 0,
      name: 'AH-E5',
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.Switch_AHE1 = new Switch({
      baseline: this.Line11,
      angle: 0,
      closed: false,
      load: false,
      state: 0,
      name: 'AH-E1',
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();
    this.Switch_AHE2 = new Switch({
      baseline: this.Line12,
      angle: 0,
      closed: false,
      load: false,
      state: 0,
      name: 'AH-E2',
      parentLayer: this.parentLayer,
      setSimulateLogs,
    })
      .add2Canvas()
      .addName2Canvas();

    this.timeLeft = 15;
    this.working = false;

    this.lineGroup = [];
    this.switchGroup = [];
    this.timer4LoadDiesel = [];
    this.getAllGroup();
    // 可以设置程序第一次启动的时候用延迟，等到后面加载的时候就可以不用延迟
    this.loadDelay();
  }

  loadDelay = () => {
    this.loadDiesel(
      [
        this.getMotorConfig(
          this.Line1.getCoordinatesByPoints().x1,
          this.Line1.getCoordinatesByPoints().y1 - 12,
        ),
        this.getMotorConfig(
          this.Line2.getCoordinatesByPoints().x1,
          this.Line1.getCoordinatesByPoints().y1 - 12,
        ),
        this.getMotorConfig(
          this.Line3.getCoordinatesByPoints().x1,
          this.Line1.getCoordinatesByPoints().y1 - 12,
        ),
      ],
      this.loadMotor,
      false,
    );
  };

  addName4Line = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const k in this) {
      //   if (!this.hasOwnProperty(k)) continue;
      if (this[k] instanceof Line) {
        this[k].addSerial2Canvas();
      }
    }
  };

  loadMotor = (config, on) => {
    if (on) {
      Konva.Image.fromURL(motorImgOn, (c) => {
        if (this.isShow) {
          c.setAttrs(config);
          c.scale({ x: 0.5, y: 0.5 });
          this.parentLayer.add(c);
          this.parentLayer.batchDraw();
        }
      });
    } else {
      Konva.Image.fromURL(motorImgOff, (c) => {
        if (this.isShow) {
          c.setAttrs(config);
          c.scale({ x: 0.5, y: 0.5 });
          this.parentLayer.add(c);
          this.parentLayer.batchDraw();
        }
      });
    }
  };
  loadDiesel = (pos_list, callback, on) => {
    pos_list.forEach((config) => callback(config, on));
  };

  destroy = () => {
    this.isShow = false;
    this.lineGroup.forEach((object) => object.destroy());
    this.switchGroup.forEach((object) => object.destroy());
    const images = this.parentLayer.find('Image');
    for (let i = 0; i < images.length; i += 1) {
      if (images[i].attrs.svgUid === 'motor') {
        images[i].remove();
      }
    }
  };

  show = () => {
    this.isShow = true;
    this.lineGroup.forEach((obj) => obj.show());
    this.switchGroup.forEach((obj) => obj.show());
    this.loadDelay();
  };

  getOpacity = (show) => {
    if (show) return 1;
    return 0;
  };

  getAllGroup = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const k in this) {
      //   if (!this.hasOwnProperty(k)) continue;
      if (this[k] instanceof Line) {
        this.lineGroup.push(this[k]);
      } else if (this[k] instanceof Switch) {
        this.switchGroup.push(this[k]);
      }
    }
  };

  updateLineState = (line_list, set_state) => {
    for (let i = 0; i < line_list.length; i += 1) {
      line_list[i].setState(set_state);
    }
  };

  startDieselDelay = (
    start,
    switch_list,
    line_list,
    updateLineCallback,
    loadDieselCallback,
    loadMotorCallback,
    config_list,
  ) => {
    if (start) {
      // 取消注释
      // this.menu.insertLog('提示', '柴油发电机稳定输出！');
      switch_list.forEach((switch1) => switch1.onclick(true));
      updateLineCallback(line_list, 1);
      this.main.updateState([this.main.Line65, this.main.Line66], 1);
    } else {
      // 取消注释
      // this.menu.insertLog('提示', '柴油发电机关机！');
      const images = this.parentLayer.find('Image');
      for (let i = 0; i < images.length; i += 1) {
        if (images[i].attrs.svgUid === 'motor') {
          images[i].remove();
        }
      }
      loadDieselCallback(config_list, loadMotorCallback, false);
      switch_list.forEach((switch1) => switch1.onclick(false));
      updateLineCallback(line_list, 0);
      this.main.updateState([this.main.Line65, this.main.Line66], 0);
    }
    this.main.trigger(this.main.switch210);
    this.main.trigger(this.main.switch220);
  };

  interval4Percent = (startTime, start) => {
    let start_time = startTime;
    function getContent(t) {
      if (start) {
        return `柴发启动时间:${t}秒`;
      }
      return `柴发停止时间:${t}秒`;
    }
    const tt = startTime;
    const interval = setInterval(() => {
      start_time -= 1;
      this.props.updatePercent4Progress(
        getContent(start_time),
        ((100 * (tt - start_time)) / tt).toFixed(1),
      );
      if (start_time === 0) {
        clearInterval(interval);
      }
    }, 1000);
  };

  update = () => {
    for (let i = 0; i < this.lineGroup.length; i += 1) {
      if (this.lineGroup[i].state === 2) {
        // 有电流的状态
        this.lineGroup[i].flow();
      }
    }
  };

  start = () => {
    this.working = true;
    this.updateLineState([this.Line1, this.Line2, this.Line3], 1);
    // 取消注释
    // this.menu.insertLog('提示', '柴油发电机启动！');
    const images = this.parentLayer.find('Image');
    for (let i = 0; i < images.length; i += 1) {
      if (images[i].attrs.svgUid === 'motor') {
        images[i].remove();
      }
    }
    this.loadDiesel(
      [
        this.getMotorConfig(
          this.Line1.getCoordinatesByPoints().x1,
          this.Line1.getCoordinatesByPoints().y1 - 12,
        ),
        this.getMotorConfig(
          this.Line2.getCoordinatesByPoints().x1,
          this.Line1.getCoordinatesByPoints().y1 - 12,
        ),
        this.getMotorConfig(
          this.Line3.getCoordinatesByPoints().x1,
          this.Line1.getCoordinatesByPoints().y1 - 12,
        ),
      ],
      this.loadMotor,
      true,
    );
    setTimeout(
      this.startDieselDelay,
      this.timeLeft * 1000,
      true,
      this.switchGroup,
      this.lineGroup,
      this.updateLineState,
    );
    this.initPercent(true, this.timeLeft);
    this.interval4Percent(this.timeLeft, true);
  };

  getMotorConfig = (left, top) => ({
    x: left - 20.5,
    y: top - 35,
    width: 80,
    height: 106,
    svgUid: 'motor',
    // opacity: this.getOpacity(this.isShow),
    selectable: false,
  });

  initPercent = (start, t) => {
    // let initTime = t;
    function getContent() {
      if (start) {
        return `柴发启动时间:${t}秒`;
      }
      return `柴发停止时间:${t}秒`;
    }
    this.props.updatePercent4Progress(getContent(), 0);
  };

  /** 柴发关机倒计时 */
  shutdown = () => {
    this.working = false;
    setTimeout(
      this.startDieselDelay,
      this.timeLeft * 1000,
      false,
      this.switchGroup,
      this.lineGroup,
      this.updateLineState,
      this.loadDiesel,
      this.loadMotor,
      [
        this.getMotorConfig(
          this.Line1.getCoordinatesByPoints().x1,
          this.Line1.getCoordinatesByPoints().y1 - 12,
        ),
        this.getMotorConfig(
          this.Line2.getCoordinatesByPoints().x1,
          this.Line1.getCoordinatesByPoints().y1 - 12,
        ),
        this.getMotorConfig(
          this.Line3.getCoordinatesByPoints().x1,
          this.Line1.getCoordinatesByPoints().y1 - 12,
        ),
      ],
    );
    this.initPercent(false, this.timeLeft);

    this.interval4Percent(this.timeLeft, false);
  };

  isPointInPath = (x, y) =>
    y >= DieselGeneratorStartY && y <= this.Line11.getCoordinatesByPoints().y2 + SwitchHeight;

  trigger = (switch_obj) => {
    switch (switch_obj) {
      case this.Switch_AHE1:
        switch_obj.onclick();
        if (this.Line11.state >= 1) {
          if (switch_obj.on) {
            this.main.updateState([this.main.Line65], 1);
          } else {
            if (this.Line11.state === 2) {
              this.updateLineState([this.Line7, this.Line8, this.Line11], 1);
            }
            this.main.updateState([this.main.Line65], 0);
          }
          this.main.trigger(this.main.switch210);
        } else {
          // console.log('柴发系统没有电，点击无效');
        }
        break;
      case this.Switch_AHE2:
        switch_obj.onclick();
        if (this.Line12.state >= 1) {
          if (switch_obj.on) {
            this.main.updateState([this.main.Line66], 1);
          } else {
            if (this.Line12.state === 2) {
              this.updateLineState([this.Line9, this.Line10, this.Line12], 1);
            }
            this.main.updateState([this.main.Line66], 0);
          }
          this.main.trigger(this.main.switch220);
        } else {
          // console.log('柴发系统没有电，点击无效');
        }
        break;
      case this.Switch_AHE3:
      case this.Switch_AHE4:
      case this.Switch_AHE5:
        // console.log('这几个开关不能点击');
        break;
      default:
      // console.log('柴发系统，没有匹配的开关');
    }
  };

  onclick = (x, y) => {
    for (let i = 0; i < this.switchGroup.length; i += 1) {
      if (this.switchGroup[i].isPointInPath(x, y)) {
        this.trigger(this.switchGroup[i]);
        break;
      }
    }
  };
}

export default DieselGenerator;
