import fetch from '@/utils/fetch';
import { bool } from 'prop-types';
// import filterNull from '@/utils/filterNull';

const model = {
  state: {
    simulateLogs: [], // 仿真模拟日志
    paramsValue: {
      dieselGenerator: {
        close1: 2,
        open1: 2,
        close2: 3,
        open2: 3,
        close3: 4,
        open3: 4,
        close4: 5,
        open4: 5,
        close5: 6,
        open5: 6,
      },
      'lowPressure-1': {
        '4-1-1': 20,
        '4-1-2': 20,
        '4-1-3': 20,
        '4-2-1': 20,
        '4-2-2': 20,
        '4-2-3': 20,
        '4-3-1': 20,
        '4-3-2': 20,
        '4-3-3': 20,
        '4-4-1': 20,
        '4-4-2': 20,
        '4-4-3': 20,
        '4-5-1': 20,
        '4-5-2': 20,
        '4-5-3': 20,
        '4-6-1': 20,
        '4-6-2': 20,
        '4-6-3': 20,
        '4-7-1': 20,
        '4-7-2': 20,
        '4-7-3': 20,
        '4-8-1': 20,
        '4-8-2': 20,
        '4-8-3': 20,
        '1-1-1': 20,
        '1-1-2': 20,
        '1-1-3': 20,
        '1-2-1': 20,
        '1-2-2': 20,
        '1-2-3': 20,
        '1-3-1': 20,
        '1-3-2': 20,
        '1-3-3': 20,
        '2-1-1': 20,
        '2-1-2': 20,
        '2-1-3': 20,
        '2-2-1': 20,
        '2-2-2': 20,
        '2-2-3': 20,
        '2-3-1': 20,
        '2-3-2': 20,
        '2-3-3': 20,
        '8-1-1': 20,
        '8-1-2': 20,
        '8-1-3': 20,
        '8-2-1': 20,
        '8-2-2': 20,
        '8-2-3': 20,
      },
    },
    mouseMenuVisible: true,
    mouseMenuName: '',
    mouseMenuLevel: 11,
    mouseMenuFlag: 3,
  },
  reducers: {
    setState(state, partialState) {
      return { ...state, ...partialState };
    },
  },
  actions: ({ dispatch: { desktopExercise }, getState }) => ({
    setParam(value) {
      desktopExercise.setState({ paramsValue: value });
    },
    setVisible(event) {
      const { mouseMenuVisible } = getState().desktopExercise;
      desktopExercise.setState({ mouseMenuVisible: !mouseMenuVisible });
    },
    setLevel() {
      const { mouseMenuLevel } = getState().desktopExercise;
      if (mouseMenuLevel === 0) {
        desktopExercise.setState({ mouseMenuLevel: 11 });
      } else {
        desktopExercise.setState({ mouseMenuLevel: 0 });
      }
    },
    setName(value) {
      const { mouseMenuName } = getState().desktopExercise;
      desktopExercise.setState({ mouseMenuName: value });
    },
    setFlag(value) {
      const { mouseMenuFlag } = getState().desktopExercise;
      desktopExercise.setState({ mouseMenuFlag: value });
    },
    setSimulateLogs(log) {
      const { simulateLogs } = getState().desktopExercise;
      desktopExercise.setState({ simulateLogs: simulateLogs.concat([log]) });
    },

    // 获取店铺信息数据 (http://wiki.sftcwl.com/pages/viewpage.action?pageId=10100692)
    async getShopList() {
      const { simulateLogs } = getState().desktopExercise;
      const data = await fetch('/account/merchant/shoplist', { params: simulateLogs });
      if (data) {
        const shopList = Object.entries(data.accountDetail).map(([id, item]) => ({ ...item, id }));
        // 设置店铺列表
        desktopExercise.setState({ shopList, shopListTotal: data.total });
      }
    },
  }),
};

export default model;
