/* eslint-disable default-case */
/* eslint-disable no-duplicate-case */
import React, { Component } from 'react';
import { Row, Col, Menu, Icon } from 'antd';
import { connect } from 'react-redux';
import withStore from '@/store/withStore';

import VideoPanel from './VideoPanel';
import styles from './index.scss';
import fetch from '../../utils/fetch';

const { SubMenu } = Menu;

class VideoCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { getAccountInfo, getCourseList, getVRCourseList, getARCourseList } = this.props;
    // getAccountInfo();
    if (window.location.href.indexOf('vr') !== -1) {
      getVRCourseList();
    } else {
      getARCourseList();
    }
    // getCourseList();
  }

  courseListMade = () => {
    const { arVideoCourseList, vrVideoCourseList, match, history } = this.props;
    const courseHtml = [];
    let videoCourseList;
    if (window.location.href.indexOf('vr') !== -1) {
      videoCourseList = vrVideoCourseList;
    } else {
      videoCourseList = arVideoCourseList;
    }
    for (let x = 0; x < videoCourseList.length; x += 1) {
      const list = videoCourseList[x];
      if (list.length === 6) {
        courseHtml.push(
          <Row gutter={[16, 16]} key={x * 3}>
            <Col span={4}>
              <VideoPanel
                videoImage={'https://study.dccloudclass.com/api/'
                  .concat(list[0].imageURL)
                  .concat('_thumb.png')}
                videoTitle={list[0].name}
                videoDesc={list[0].description}
                videoId={list[0].id}
                match={match}
                history={history}
                key={list[0].id}
              />
            </Col>
            <Col span={4}>
              <VideoPanel
                videoImage={'https://study.dccloudclass.com/api/'
                  .concat(list[1].imageURL)
                  .concat('_thumb.png')}
                videoTitle={list[1].name}
                videoDesc={list[1].description}
                videoId={list[1].id}
                match={match}
                history={history}
                key={list[1].id}
              />
            </Col>
            <Col span={4}>
              <VideoPanel
                videoImage={'https://study.dccloudclass.com/api/'
                  .concat(list[2].imageURL)
                  .concat('_thumb.png')}
                videoTitle={list[2].name}
                videoDesc={list[2].description}
                videoId={list[2].id}
                match={match}
                history={history}
                key={list[2].id}
              />
            </Col>
            <Col span={4}>
              <VideoPanel
                videoImage={'https://study.dccloudclass.com/api/'
                  .concat(list[3].imageURL)
                  .concat('_thumb.png')}
                videoTitle={list[3].name}
                videoDesc={list[3].description}
                videoId={list[3].id}
                match={match}
                history={history}
                key={list[3].id}
              />
            </Col>
            <Col span={4}>
              <VideoPanel
                videoImage={'https://study.dccloudclass.com/api/'
                  .concat(list[4].imageURL)
                  .concat('_thumb.png')}
                videoTitle={list[4].name}
                videoDesc={list[4].description}
                videoId={list[4].id}
                match={match}
                history={history}
                key={list[4].id}
              />
            </Col>
            <Col span={4}>
              <VideoPanel
                videoImage={'https://study.dccloudclass.com/api/'
                  .concat(list[5].imageURL)
                  .concat('_thumb.png')}
                videoTitle={list[5].name}
                videoDesc={list[5].description}
                videoId={list[5].id}
                match={match}
                history={history}
                key={list[5].id}
              />
            </Col>
          </Row>,
        );
      } else {
        switch (list.length) {
          case 1: {
            courseHtml.push(
              <Row gutter={[16, 16]} key={x * 3}>
                <Col span={4}>
                  <VideoPanel
                    videoImage={'https://study.dccloudclass.com/api/'
                      .concat(list[0].imageURL)
                      .concat('_thumb.png')}
                    videoTitle={list[0].name}
                    videoDesc={list[0].description}
                    videoId={list[0].id}
                    match={match}
                    history={history}
                    key={list[0].id}
                  />
                </Col>
              </Row>,
            );
            break;
          }
          case 2: {
            courseHtml.push(
              <Row gutter={[16, 16]} key={x * 3}>
                <Col span={4}>
                  <VideoPanel
                    videoImage={'https://study.dccloudclass.com/api/'
                      .concat(list[0].imageURL)
                      .concat('_thumb.png')}
                    videoTitle={list[0].name}
                    videoDesc={list[0].description}
                    videoId={list[0].id}
                    match={match}
                    history={history}
                    key={list[0].id}
                  />
                </Col>
                <Col span={4}>
                  <VideoPanel
                    videoImage={'https://study.dccloudclass.com/api/'
                      .concat(list[1].imageURL)
                      .concat('_thumb.png')}
                    videoTitle={list[1].name}
                    videoDesc={list[1].description}
                    videoId={list[1].id}
                    match={match}
                    history={history}
                    key={list[1].id}
                  />
                </Col>
              </Row>,
            );
            break;
          }
          case 3: {
            courseHtml.push(
              <Row gutter={[16, 16]} key={x * 3}>
                <Col span={4}>
                  <VideoPanel
                    videoImage={'https://study.dccloudclass.com/api/'
                      .concat(list[0].imageURL)
                      .concat('_thumb.png')}
                    videoTitle={list[0].name}
                    videoDesc={list[0].description}
                    videoId={list[0].id}
                    match={match}
                    history={history}
                    key={list[0].id}
                  />
                </Col>
                <Col span={4}>
                  <VideoPanel
                    videoImage={'https://study.dccloudclass.com/api/'
                      .concat(list[1].imageURL)
                      .concat('_thumb.png')}
                    videoTitle={list[1].name}
                    videoDesc={list[1].description}
                    videoId={list[1].id}
                    match={match}
                    history={history}
                    key={list[1].id}
                  />
                </Col>
                <Col span={4}>
                  <VideoPanel
                    videoImage={'https://study.dccloudclass.com/api/'
                      .concat(list[2].imageURL)
                      .concat('_thumb.png')}
                    videoTitle={list[2].name}
                    videoDesc={list[2].description}
                    videoId={list[2].id}
                    match={match}
                    history={history}
                    key={list[2].id}
                  />
                </Col>
              </Row>,
            );
            break;
          }
          case 4: {
            courseHtml.push(
              <Row gutter={[16, 16]} key={x * 3}>
                <Col span={4}>
                  <VideoPanel
                    videoImage={'https://study.dccloudclass.com/api/'
                      .concat(list[0].imageURL)
                      .concat('_thumb.png')}
                    videoTitle={list[0].name}
                    videoDesc={list[0].description}
                    videoId={list[0].id}
                    match={match}
                    history={history}
                    key={list[0].id}
                  />
                </Col>
                <Col span={4}>
                  <VideoPanel
                    videoImage={'https://study.dccloudclass.com/api/'
                      .concat(list[1].imageURL)
                      .concat('_thumb.png')}
                    videoTitle={list[1].name}
                    videoDesc={list[1].description}
                    videoId={list[1].id}
                    match={match}
                    history={history}
                    key={list[1].id}
                  />
                </Col>
                <Col span={4}>
                  <VideoPanel
                    videoImage={'https://study.dccloudclass.com/api/'
                      .concat(list[2].imageURL)
                      .concat('_thumb.png')}
                    videoTitle={list[2].name}
                    videoDesc={list[2].description}
                    videoId={list[2].id}
                    match={match}
                    history={history}
                    key={list[2].id}
                  />
                </Col>
                <Col span={4}>
                  <VideoPanel
                    videoImage={'https://study.dccloudclass.com/api/'
                      .concat(list[3].imageURL)
                      .concat('_thumb.png')}
                    videoTitle={list[3].name}
                    videoDesc={list[3].description}
                    videoId={list[3].id}
                    match={match}
                    history={history}
                    key={list[3].id}
                  />
                </Col>
              </Row>,
            );
            break;
          }
          case 5: {
            courseHtml.push(
              <Row gutter={[16, 16]} key={x * 3}>
                <Col span={4}>
                  <VideoPanel
                    videoImage={'https://study.dccloudclass.com/api/'
                      .concat(list[0].imageURL)
                      .concat('_thumb.png')}
                    videoTitle={list[0].name}
                    videoDesc={list[0].description}
                    videoId={list[0].id}
                    match={match}
                    history={history}
                    key={list[0].id}
                  />
                </Col>
                <Col span={4}>
                  <VideoPanel
                    videoImage={'https://study.dccloudclass.com/api/'
                      .concat(list[1].imageURL)
                      .concat('_thumb.png')}
                    videoTitle={list[1].name}
                    videoDesc={list[1].description}
                    videoId={list[1].id}
                    match={match}
                    history={history}
                    key={list[1].id}
                  />
                </Col>
                <Col span={4}>
                  <VideoPanel
                    videoImage={'https://study.dccloudclass.com/api/'
                      .concat(list[2].imageURL)
                      .concat('_thumb.png')}
                    videoTitle={list[2].name}
                    videoDesc={list[2].description}
                    videoId={list[2].id}
                    match={match}
                    history={history}
                    key={list[2].id}
                  />
                </Col>
                <Col span={4}>
                  <VideoPanel
                    videoImage={'https://study.dccloudclass.com/api/'
                      .concat(list[3].imageURL)
                      .concat('_thumb.png')}
                    videoTitle={list[3].name}
                    videoDesc={list[3].description}
                    videoId={list[3].id}
                    match={match}
                    history={history}
                    key={list[3].id}
                  />
                </Col>
                <Col span={4}>
                  <VideoPanel
                    videoImage={'https://study.dccloudclass.com/api/'
                      .concat(list[4].imageURL)
                      .concat('_thumb.png')}
                    videoTitle={list[4].name}
                    videoDesc={list[4].description}
                    videoId={list[4].id}
                    match={match}
                    history={history}
                    key={list[4].id}
                  />
                </Col>
              </Row>,
            );
            break;
          }
        }
      }
    }
    return courseHtml;
  };

  render() {
    return (
      <>
        <div className={styles.container}>
          {/* <div className={styles.leftBox}>
          <Menu
            onClick={this.handleClick}
            style={{ width: 256 }}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"
          >
            <SubMenu
              key="sub1"
              title={
                <span>
                  <Icon type="mail" />
                  <span>Navigation One</span>
                </span>
              }
            >
              <Menu.ItemGroup key="g1" title="Item 1">
                <Menu.Item key="1">Option 1</Menu.Item>
                <Menu.Item key="2">Option 2</Menu.Item>
              </Menu.ItemGroup>
              <Menu.ItemGroup key="g2" title="Item 2">
                <Menu.Item key="3">Option 3</Menu.Item>
                <Menu.Item key="4">Option 4</Menu.Item>
              </Menu.ItemGroup>
            </SubMenu>
            <SubMenu
              key="sub2"
              title={
                <span>
                  <Icon type="appstore" />
                  <span>Navigation Two</span>
                </span>
              }
            >
              <Menu.Item key="5">Option 5</Menu.Item>
              <Menu.Item key="6">Option 6</Menu.Item>
              <SubMenu key="sub3" title="Submenu">
                <Menu.Item key="7">Option 7</Menu.Item>
                <Menu.Item key="8">Option 8</Menu.Item>
              </SubMenu>
            </SubMenu>
            <SubMenu
              key="sub4"
              title={
                <span>
                  <Icon type="setting" />
                  <span>Navigation Three</span>
                </span>
              }
            >
              <Menu.Item key="9">Option 9</Menu.Item>
              <Menu.Item key="10">Option 10</Menu.Item>
              <Menu.Item key="11">Option 11</Menu.Item>
              <Menu.Item key="12">Option 12</Menu.Item>
            </SubMenu>
          </Menu>
          </div>
          <div className={styles.rightBox}>
            {this.courseListMade()}
          </div> */}
          <div className={styles.panelList}>{this.courseListMade()}</div>
        </div>
      </>
    );
  }
}

export default connect(...withStore('basic', 'videoCourse'))(VideoCourse);
