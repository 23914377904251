import React from 'react';
import styles from './index.scss';
import crcode from '@/assets/ClassTest/CrCode.png';

const ClassTest = () => (
  <>
    <div className={styles.crcode}>
      <img src={crcode} alt="crcode" />
    </div>
    <div className={styles.codeText}>
      <span>手机扫描二维码开始刷题</span>
    </div>
  </>
);

export default ClassTest;
