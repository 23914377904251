import fetch from '@/utils/fetch';

const ENV_BaiduYun = 'https://study.dccloudclass.com/api/';
const ENV_BaiduYunLocal = 'http://61.147.93.160:8080/opscoursesystem4test/';
const ENV_LocalDev = 'http://192.168.1.102:8080/';
const currentEnv = ENV_BaiduYun;

const model = {
  state: {
    // 用户
    accountInfo: {}, // 当前登录账号数据
    loading: false,
    excelExportProgress: 0,
    lastPath: '',
    videoCourseList: {},
    videoInfo: {},
    relatedVideoInfo: {},
    token: '',
  },
  reducers: {
    setState(state, partialState) {
      return { ...state, ...partialState };
    },
  },
  actions: ({ dispatch: { ARPlayer } }) => ({
    // 获取当前登录账户相关数据 (http://wiki.sftcwl.com/pages/viewpage.action?pageId=3225256)
    async getVideoInfo(videoId) {
      // const data = await fetch(
      //   'https://study.dccloudclass.com/api/videoCourse/'.concat(videoId),
      // );
      // const data = await fetch(
      //   'http://192.168.0.140:8082/opscoursesystem/videoCourse/'.concat(videoId),
      // );
      const data = await fetch(currentEnv + 'videoCourse/'.concat(videoId));
      // console.log('videoInfo:', data);
      ARPlayer.setState({
        videoInfo: data,
        relatedVideoInfo: data.relatedVideoList,
        token: new Date(),
      });
    },
  }),
};

export default model;
