import React from 'react';
import { Button, Collapse, Form, Row, Col, Input } from 'antd';
import classnames from 'classnames';
import styles from './Index.scss';

const params = [
  {
    name: '柴油发电机断路器',
    key: 'dieselGenerator',
    value: [
      { param: '1-1AHE1', key: ['close1', 'open1'] },
      { param: '1-1AHE2', key: ['close2', 'open2'] },
      { param: '1-1AHE3', key: ['close3', 'open3'] },
      { param: '1-1AHE4', key: ['close4', 'open4'] },
      { param: '1-1AHE5', key: ['close5', 'open5'] },
    ],
  },
  { name: '市电1路高压断路器', key: '11', value: [] },
  { name: '市电2路高压断路器', key: '22', value: [] },
  { name: '低压断路器', key: '33', value: [] },
  {
    name: '低压 1# IT负荷',
    key: 'lowPressure-1',
    value: [
      { param: '机房模块四: A1/B1三相功率(kw)', key: ['4-1-1', '4-1-2', '4-1-3'] },
      { param: '机房模块四: A2/B2三相功率(kw)', key: ['4-2-1', '4-2-2', '4-2-3'] },
      { param: '机房模块四: A3/B3三相功率(kw)', key: ['4-3-1', '4-3-2', '4-3-3'] },
      { param: '机房模块四: A4/B4三相功率(kw)', key: ['4-4-1', '4-4-2', '4-4-3'] },
      { param: '机房模块四: A5/B5三相功率(kw)', key: ['4-5-1', '4-5-2', '4-5-3'] },
      { param: '机房模块四: A6/B6三相功率(kw)', key: ['4-6-1', '4-6-2', '4-6-3'] },
      { param: '机房模块四: A7/B7三相功率(kw)', key: ['4-7-1', '4-7-2', '4-7-3'] },
      { param: '机房模块四: A8/B8三相功率(kw)', key: ['4-8-1', '4-8-2', '4-8-3'] },
      { param: '机房模块一: A1/B1三相功率(kw)', key: ['1-1-1', '1-1-2', '1-1-3'] },
      { param: '机房模块一: A2/B2三相功率(kw)', key: ['1-2-1', '1-2-2', '1-2-3'] },
      { param: '机房模块一: A3/B3三相功率(kw)', key: ['1-3-1', '1-3-2', '1-3-3'] },
      { param: '机房模块二: A1/B1三相功率(kw)', key: ['2-1-1', '2-1-2', '2-1-3'] },
      { param: '机房模块二: A2/B2三相功率(kw)', key: ['2-2-1', '2-2-2', '2-2-3'] },
      { param: '机房模块二: A3/B3三相功率(kw)', key: ['2-3-1', '2-3-2', '2-3-3'] },
      { param: '机房模块八: A1/B1三相功率(kw)', key: ['8-1-1', '8-1-2', '8-1-3'] },
      { param: '机房模块八: A2/B2三相功率(kw)', key: ['8-2-1', '8-2-2', '8-2-3'] },
    ],
  },
  { name: '低压 2# IT负荷', key: 'lowPressure-2', value: [] },
  { name: '机房模块四A3/B3支路负荷', key: '66', value: [] },
];

const customPanelStyle = {
  background: 'initial',
  border: 0,
  overflow: 'hidden',
  fontSize: '0.2rem',
  color: '#E1FDFF',
};

class ParamInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // activeKey: 'dieselGenerator',
      activeKey: 'lowPressure-1',
    };
  }

  componentDidMount() {
    const { paramsValue, form } = this.props;
    const { activeKey } = this.state;
    form.setFieldsValue(paramsValue[activeKey]);
  }

  handleCollapse = (key) => {
    const { paramsValue, form } = this.props;
    this.setState({ activeKey: key }, () => {
      (key === 'dieselGenerator' || key === 'lowPressure-1') &&
        form.setFieldsValue(paramsValue[key]);
    });
  };

  handleSubmit = (e) => {
    const { form, paramsValue, submit } = this.props;
    const { activeKey } = this.state;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        submit({ ...paramsValue, [activeKey]: { ...values } });
      }
    });
  };

  // 渲染《柴油发电机断路器》
  renderDiesel = (ele) => {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row key={ele.param} className={styles.paramRow}>
        <Col span={3}>{ele.param}</Col>
        <Col span={10} className={styles.item}>
          <Form.Item label="延时闭合时间">
            {getFieldDecorator(ele.key[0], {
              rules: [{ required: true, message: ' ' }],
            })(<Input size="small" suffix="秒" style={{ width: '1.2rem' }} />)}
          </Form.Item>
        </Col>
        <Col span={10} offset={1} className={styles.item}>
          <Form.Item label="延时分闸时间">
            {getFieldDecorator(ele.key[1], {
              rules: [{ required: true, message: ' ' }],
            })(<Input size="small" suffix="秒" style={{ width: '1.2rem' }} />)}
          </Form.Item>
        </Col>
      </Row>
    );
  };
  // 渲染《低压 1# IT负荷》
  renderLowPressure = (ele) => {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row key={ele.param} className={styles.paramRow}>
        <Col span={12}>{ele.param}</Col>
        <Col span={4} className={styles.item}>
          <Form.Item label=" ">
            {getFieldDecorator(ele.key[0], {
              rules: [{ required: true, message: ' ' }],
            })(<Input size="small" />)}
          </Form.Item>
        </Col>
        <Col span={4} className={styles.item}>
          <Form.Item label=" ">
            {getFieldDecorator(ele.key[1], {
              rules: [{ required: true, message: ' ' }],
            })(<Input size="small" />)}
          </Form.Item>
        </Col>
        <Col span={4} className={styles.item}>
          <Form.Item label=" ">
            {getFieldDecorator(ele.key[2], {
              rules: [{ required: true, message: ' ' }],
            })(<Input size="small" />)}
          </Form.Item>
        </Col>
      </Row>
    );
  };

  renderPanel = (value, panelKey) => {
    const refers = {
      dieselGenerator: this.renderDiesel,
      'lowPressure-1': this.renderLowPressure,
    };
    return (
      <Form
        className={styles.content}
        hideRequiredMark
        onSubmit={this.handleSubmit}
        key={panelKey}
        colon={false}
      >
        {value.map((ele) => refers[panelKey](ele))}
        {panelKey === 'dieselGenerator' || panelKey === 'lowPressure-1' ? (
          <Button type="primary" size="small" htmlType="submit" className={styles.btn}>
            确定
          </Button>
        ) : (
          <p style={{ color: 'orange' }}>敬请期待！</p>
        )}
      </Form>
    );
  };

  render() {
    const { activeKey } = this.state;
    return (
      <div className={styles.container}>
        <div className={classnames(styles.angle, styles.topLeft)} />
        <div className={classnames(styles.angle, styles.bottomLeft)} />
        <div className={classnames(styles.angle, styles.topRight)} />
        <div className={classnames(styles.angle, styles.bottomRight)} />
        <div className={styles.title}>参数表信息</div>
        <Collapse
          bordered={false}
          activeKey={activeKey}
          accordion
          expandIconPosition="right"
          onChange={this.handleCollapse}
          style={{ background: 'initial' }}
        >
          {params.map((ele) => (
            <Collapse.Panel
              header={ele.name}
              key={ele.key}
              style={customPanelStyle}
              className={styles.panel}
            >
              {ele.key === activeKey && this.renderPanel(ele.value, ele.key)}
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>
    );
  }
}

export default Form.create()(ParamInfo);
