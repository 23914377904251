/* --
 --- Feature：三项功率（机房模块属性）
 --- Author: arya
--*/

import React from 'react';
import { INIT } from './Constants';

class ThreePhasePower extends React.Component {
  constructor(props) {
    super(props);

    const { name, moduleName, power } = props;

    this.name = name;
    this.moduleName = moduleName;
    this.A = power.a;
    this.B = power.b;
    this.C = power.c;
    this.index = 0;
    if (INIT) {
      this.addModulePower2Database();
    } else {
      this.getModulePowerByName();
    }
  }

  setIndex = (idx) => {
    this.index = idx;
  };

  getModulePowerByName = () => {
    // $.ajax({
    //   url: `${BASEURL}/module/get`,
    //   type: 'POST',
    //   dataType: 'json',
    //   data: { module_name: this.moduleName },
    //   success: (result) => {
    //     if (result.errCode === 0) {
    //       this.A = result.data.power_a;
    //       this.B = result.data.power_b;
    //       this.C = result.data.power_c;
    //       infoTable.renderData(this.index, this);
    //       // console.log("get success");
    //     } else {
    //       console.error(result.msg);
    //     }
    //   },
    //   error: (error) => {
    //     console.error(`error:${error}`);
    //   },
    // });
  };

  addModulePower2Database = () => {
    // const url = `${BASEURL}/module/add`;
    // const data = {
    //   module_name: this.moduleName,
    //   power_a: this.A,
    //   power_b: this.B,
    //   power_c: this.C,
    // };
    // $.ajax({
    //   url,
    //   type: 'POST',
    //   dataType: 'json',
    //   data,
    //   success: (result) => {
    //     if (result.errCode === 0) {
    //       console.log('add success');
    //     } else {
    //       console.error(result.msg);
    //     }
    //   },
    //   error: (error) => {
    //     console.error(`error:${error}`);
    //   },
    // });
  };

  setPower = (power) => {
    this.A = parseFloat(power.a);
    this.B = parseFloat(power.b);
    this.C = parseFloat(power.c);
    // const data = { module_name: this.moduleName, power, idx: index };
    // $.ajax({
    //   url: `${BASEURL}/module/update`,
    //   type: 'POST',
    //   data,
    //   dataType: 'json',
    //   success: (result) => {
    //     if (result.errCode === 0) {
    //       console.log(`发送get请求${result}`);
    //     } else {
    //       console.error(result.msg);
    //     }
    //   },
    //   error: (error) => {
    //     console.log(`error: ${error}`);
    //   },
    // });
  };
}

export default ThreePhasePower;
