/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Layout as AntLayout, Button, Progress, message, Icon } from 'antd';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import withStore from '@/store/withStore';
import moment from 'moment';
import classnames from 'classnames';
import styles from './Layout.scss';

const { Header, Content } = AntLayout;
const menu = [
  {
    name: 'AR教学',
    path: '/ar',
  },
  {
    name: 'VR教学',
    path: '/vr',
  },
  {
    name: '桌面演练系统',
    path: '/desktop-exercise',
  },
  {
    name: '网课视频',
    path: '/video-course',
  },
  {
    name: '智能群控系统',
    path: '/znqk',
  },
  {
    name: '刷题',
    path: '/test-course',
  },
  // {
  //   name: '人员管理',
  //   path: '/member-handle',
  // },
  {
    name: '',
    path: '/energy-monitoring',
  },
  {
    name: '',
    path: '/capacity-manage',
  },
  {
    name: '',
    path: '/three3D-manage',
  },
  {
    name: '',
    path: '/system-log',
  },
  {
    name: '',
    path: '/capacity-analysis/module-manage',
  },
  {
    name: '',
    path: '/capacity-analysis/data-handle',
  },
];
let timerId;
let timeOut = undefined;
class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: moment(),
      percent: 0,
      menus: [],
    };
  }

  componentWillMount() {
    timerId = setInterval(() => this.setState({ currentTime: moment() }), 1000);
    // this.props.getMenuData()
    console.log('componentWillMount');
  }

  componentDidMount() {
    console.log('componentDidMount');
    // this.props.getAccountInfo();
    this.props.getMenuData();
    let hidden;
    let visibilityChange;
    if (typeof document.hidden !== 'undefined') {
      // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }

    // 如果浏览器不支持addEventListener 或 Page Visibility API 给出警告
    if (
      typeof document.addEventListener === 'undefined' ||
      typeof document[hidden] === 'undefined'
    ) {
      console.log(
        'This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.',
      );
    } else {
      // 处理页面可见属性的改变
      document.addEventListener(visibilityChange, this.handleVisibilityChange, false);

      // // 当视频暂停，设置title
      // // This shows the paused
      // videoElement.addEventListener("pause", function(){
      //   document.title = 'Paused';
      // }, false);

      // // 当视频播放，设置title
      // videoElement.addEventListener("play", function(){
      //   document.title = 'Playing';
      // }, false);
    }
  }

  // shouldComponentUpdate(nextProps, nextState){
  //   // console.log("layout, shouldComponentUpdate",nextProps, nextState)
  //   return true;
  // }

  componentWillUnmount() {
    clearInterval(timerId);
  }

  handleVisibilityChange = () => {
    // console.log("handleVisibilityChange", document.hidden);
    if (document.hidden) {
      clearTimeout(timeOut);
    } else {
      this.onScreenClick();
    }
    // if (document[hidden]) {
    //   videoElement.pause();
    // } else {
    //   videoElement.play();
    // }
  };

  onButtonClock = (e) => {
    e.preventDefault();
    message.warning('功能暂未开放');
    // if(e.target.search('电力监控') === -1 && e.target.search('电力监控') === -1 && e.target.search('电力监控') === -1) {
    //   console.log(e.target);
    // }
  };

  onScreenLock = () => {
    // console.log('visibilityState:', document.visibilityState);
    document.getElementById('bg').style.display = 'block';
    // document.getElementById("show").style.display ="block";
    const { history, location } = this.props;
    if (location.pathname === '/' || location.pathname === 'login') {
      return;
    }
    this.props.setLastPath(location.pathname);
    history.push({ pathname: '/lock' });
  };

  logout = () => {
    const { history, logout } = this.props;
    logout();
    history.push({ pathname: '/' });
  };

  onScreenClick = () => {
    // clearTimeout(timeOut);
    // timeOut = setTimeout(() => {
    //   this.onScreenLock();
    // }, 600 * 1000);
  };

  render() {
    const { currentTime } = this.state;
    const { children, location, accountInfo, loading, excelExportProgress, menuList } = this.props;
    const { pathname } = location;
    return (
      <AntLayout className={styles.basicLayout} onClick={this.onScreenClick}>
        {/* <link rel="stylesheet" type="text/css" href="/video-js.css" /> */}
        <div className="bg" id="bg" />
        <div className="bg" style={{ display: loading ? 'block' : 'none' }}>
          <Progress
            className={styles.basicProgress}
            type="circle"
            strokeColor={{
              '0%': '#108ee9',
              '100%': '#87d068',
            }}
            percent={excelExportProgress}
            style={{
              display: loading ? 'block' : 'none',
              position: 'relative',
              top: '50%',
              left: '50%',
              opacity: '1',
            }}
          />
        </div>
        <Header
          className={styles.header}
          style={pathname === '/login' || pathname === '/' ? { display: 'none' } : {}}
        >
          <div className={styles.title}>
            {currentTime.format('YYYY/MM/DD HH:mm:ss')}
            {/* <Button style={{ width: '20px', height: '20px'}} onClick={this.onScreenLock}></Button> */}
            <Icon style={{ marginLeft: '15px' }} onClick={this.logout} type="poweroff" />
          </div>
          <div className={styles.leftBox}>
            {menuList.slice(0, 4).map((ele) =>
              ele.name === '' ? (
                <a
                  key={ele.path}
                  href=""
                  onClick={(e) => this.onButtonClock(e)}
                  className={classnames(styles.tabundoleft)}
                >
                  {ele.name}
                </a>
              ) : (
                <NavLink
                  to={ele.path}
                  key={ele.path}
                  className={classnames(styles.tab, {
                    [styles.tabActive]: pathname.includes(ele.path),
                  })}
                >
                  {ele.name}
                </NavLink>
              ),
            )}
          </div>
          <div className={styles.rightBox}>
            {menuList.slice(4, 7).map((ele) =>
              ele.name === '资产管理系统' || ele.name === '容量分析系统' || ele.name === '' ? (
                <a
                  key={ele.path}
                  href=""
                  onClick={(e) => this.onButtonClock(e)}
                  // className={classnames(styles.tabundo)}
                >
                  {ele.name}
                </a>
              ) : (
                <NavLink
                  to={ele.path}
                  key={ele.path}
                  className={classnames(styles.tab, {
                    [styles.tabActive]: pathname.includes(ele.path),
                  })}
                >
                  {ele.name}
                </NavLink>
              ),
            )}
          </div>
        </Header>
        <Content className={styles.content}>{children}</Content>
      </AntLayout>
    );
  }
}

export default withRouter(connect(...withStore('basic', 'login3D'))(Layout));
